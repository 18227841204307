const titles = [
  "Data Strategy",
  "CDMP Fundamentals Exam",
  "CDMP Specialist Exam",
  "CIPP/E Exam",
  "CAP Exam",
  "Data Science",
  "Career Services",
  "Physical Products",
];

const categories = [
  "data-strategy",
  "cdmp-fundamentals",
  "cdmp-specialist",
  "data-privacy",
  "cap",
  "data-science",
  "service",
  "physical",
];

export { titles, categories };
