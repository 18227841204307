import BasePerson from "./BasePerson";
import people from "../../data/people/people";

const Person: React.FC<{ personName: string }> = ({ personName }) => {
  const person: Person = people.find((person) => person.name === personName) ?? {
    name: "",
    title: "",
    img: "",
    link: "",
  };

  const { name, title, img, link } = person;

  return <BasePerson personName={name} link={link} lineTwo={title} italics={true} img={img} />;
};

export default Person;
