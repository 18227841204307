import emailLists from "../../data/emailLists";

const CheckoutRightSide = ({ paymentName }) => {
  const emailList = emailLists.find((emailList) => emailList.payment === paymentName);

  return (
    <div className="teal-box" id="product-testimonials">
      <h4>What to expect</h4>
      <div
        className="blog"
        dangerouslySetInnerHTML={{
          __html: emailList.text,
        }}
      />
    </div>
  );
};

export default CheckoutRightSide;
