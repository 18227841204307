import { useState } from "react";
import { NavLink } from "react-router-dom";

import NavItemBurger from "./NavItemBurger";

const NavItem = ({ itemName }) => {
  const [hamburgerIsActive, setHamburgerIsActive] = useState(false);

  return (
    <li
      className="nav-item"
      onMouseEnter={() => setHamburgerIsActive(true)}
      onMouseLeave={() => setHamburgerIsActive(false)}
    >
      <NavLink activeClassName="active" to={"/" + itemName.toLowerCase().replace(/\s/g, "-")}>
        {itemName}
        <div
          className="nav-underline"
          style={{
            border: hamburgerIsActive ? "1px solid white" : "",
          }}
        />
      </NavLink>
      <NavItemBurger
        hamburgerIsActive={hamburgerIsActive}
        setHamburgerIsActive={setHamburgerIsActive}
        itemName={itemName}
      />
    </li>
  );
};

export default NavItem;
