import enterpriseStyles from "../../../helpers/enterpriseStyles";

const BootcampFAQs: React.VFC = () => {
  return (
    <div style={enterpriseStyles.container}>
      <p className="title product-title">Frequently Asked Questions</p>
      <p>
        <strong>How is the Two-Day Bootcamp structured?</strong>
      </p>
      <p>
        The Two-Day Bootcamp is intended to be intensive without being overwhelming. It is best for{" "}
        <a href="https://www.dama.org/cpages/chapters" target="_blank" rel="noreferrer">
          DAMA groups
        </a>{" "}
        or other organizations where members already possess familiarity with Data Management best practices. We plan
        for 9 hours on Day 1 and 7.5 hours on Day 2, which includes training, breaks, and two mock exam on Day 2. We
        suggest that DAMA groups consider holding a pay-if-you-pass CDMP exam at the end of Day 2.
      </p>
      <br />
      <p>
        <strong>What are the options for running the Bootcamp?</strong>
      </p>
      <p>
        The Bootcamp can be virtual (i.e., all participants on Zoom), hybrid (i.e., participants gathered together in a
        physical space with the instructor on Zoom), or in-person (includes reasonable travel costs for instructor). Our
        CDMP trainers are based in the east coast of the United States.
      </p>
      <br />
    </div>
  );
};

export default BootcampFAQs;
