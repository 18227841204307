import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import visualDescriptions from "../../data/visualDescriptions";

interface VisualDescriptionProps {
  product: productName;
}
const VisualDescription: React.FC<VisualDescriptionProps> = ({ product }) => {
  const tablet = useMediaQuery("(min-width: 640px) and (max-width: 1299px)");
  const desktop = useMediaQuery("(min-width:1300px)");

  const vizDescObj = visualDescriptions?.find((obj) => obj.product === product);

  let vertical = true;
  const bundle = vizDescObj.product.includes("Bundle");

  // Specialist Guides + Specialist Question Sets
  if (tablet && vizDescObj.stack) {
    vertical = false;
    // everything else
  } else if (!vizDescObj.stack && (tablet || desktop)) {
    vertical = false;
  }

  return (
    <>
      {vertical ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "1em 0px",
            textAlign: bundle ? "left" : "center",
            textWrap: "balance",
          }}
        >
          {vizDescObj.icons.map((icon, idx) => (
            <div
              className="visual-description-item"
              style={{
                display: bundle ? "flex" : "block",
                gap: 20,
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              key={idx}
            >
              {icon}
              <p
                dangerouslySetInnerHTML={{ __html: vizDescObj.descriptions[idx] }}
                style={{ width: bundle ? "80%" : "100%" }}
              />
              {bundle && (
                <p
                  dangerouslySetInnerHTML={{ __html: vizDescObj.prices[idx] }}
                  style={{ width: 40, paddingRight: 10, textAlign: "center" }}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          <div
            style={{
              margin: "1em 20px 2em 20px",
              display: "grid",
              gridTemplateColumns: `repeat(${vizDescObj.icons.length}, 1fr)`,
              justifyItems: "center",
              textAlign: "center",
              textWrap: "balance",
              columnGap: 40,
            }}
          >
            {vizDescObj.icons.map((icon, idx) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                key={idx}
              >
                {icon}
                <p dangerouslySetInnerHTML={{ __html: vizDescObj.descriptions[idx] }} />
                {bundle && vizDescObj.prices[idx] && (
                  <p style={{ marginTop: 8 }} dangerouslySetInnerHTML={{ __html: vizDescObj.prices[idx] }} />
                )}
              </div>
            ))}
          </div>

          <div className="blog" dangerouslySetInnerHTML={{ __html: vizDescObj.textDescription }} />
        </>
      )}
      {vizDescObj.partOf && (
        <p>
          <i>
            Note: this product is available as part of the{" "}
            <Link to={vizDescObj.partOfLink} target="_blank">
              {vizDescObj.partOf}
            </Link>
            <>
              {vizDescObj.update && (
                <>. Contents have been updated to reflect the latest material in the {vizDescObj.update}.</>
              )}
            </>
          </i>
        </p>
      )}
    </>
  );
};

export default VisualDescription;
