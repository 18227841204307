import { useContext } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { LayoutContext } from "../../../../contexts/LayoutContext";

import CDMPHeroPerson from "./CDMPHeroPerson";
import HeroBullets from "./HeroBullets";

export default function CDMPHero({ scroll }) {
  const { navHeight } = useContext(LayoutContext);
  const desktop = useMediaQuery("(min-width:600px)");

  return (
    <>
      <div
        style={{
          background: "#e9eff1",
          position: "relative",
          height: `calc(100vh - ${navHeight}px - 170px)`,
          minHeight: `calc(670px - ${navHeight}px)`,
          maxHeight: 1500,
          display: "flex",
          justifyContent: "center",
          minWidth: 300,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            height: `calc(100vh - ${navHeight}px - 150px)`,
            minHeight: `calc(700px - ${navHeight}px)`,
            maxHeight: 1500,
            width: "100%",
            zIndex: 2,
            maxWidth: 2000,
          }}
        >
          <CDMPHeroPerson />
          <div
            style={{
              position: "absolute",
              top: desktop ? "16%" : 35,
              left: "10%",
              color: "#051f36",
              textShadow: "-2px 2px 2px rgba(0, 0, 0, 0.2)",
              zIndex: 1000,
            }}
          >
            <p
              style={{
                fontSize: desktop ? "5.6em" : "em",
                fontFamily: "Permanent Marker",
                maxWidth: 650,
                lineHeight: 0.8,
              }}
            >
              Pass the
              <br />
              <span style={{ fontSize: "1.2em" }}>CDMP exam</span>
            </p>
            <p
              style={{
                fontSize: desktop ? "3.1em" : "2.4em",
                marginTop: 20,
                marginLeft: desktop ? 20 : 0,
                whiteSpace: "nowrap",
              }}
            >
              Save time with
              <br />
              <span style={{ marginLeft: 20 }}>
                quality{" "}
                <Link
                  to="/products"
                  style={{ borderBottomColor: "#fe7f78", bottomBorderWidth: "10px !important", minWidth: 268 }}
                  id="product"
                >
                  study materials
                </Link>
              </span>
            </p>
          </div>
          {scroll && <HeroBullets desktop={desktop} />}
        </div>
      </div>
    </>
  );
}
