import { Link } from "react-router-dom";

import Aiken from "../img/blog/AikenPyramid.webp";

import Post from "../components/blog/Post";
import CDMPad from "../components/blog/CDMPad";
import PostImage from "../components/blog/PostImage";
import Contents from "../components/blog/Contents";

const POSTNAME = "data-strategy";

/* TODO 
-  How to import Orbit / use web components with React 
-  Add updated videos (coworking sessions)
-  Data Governance -- https://youtu.be/9K9wUiUku5I
-  Data Architecture -- https://youtu.be/MsZtql6nROo
-  Data Modeling -- https://youtu.be/2mEDKgQBav0
-  */

const Foundations: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default Foundations;

const Rest = () => {
  const contents: Content[] = [
    {
      anchor: "introduction",
      text: "Introduction",
    },
    {
      anchor: "data-governance",
      text: "Data Governance",
    },
    {
      anchor: "data-quality",
      text: "Data Quality",
    },
    {
      anchor: "data-architecture",
      text: "Data Architecture",
    },
    {
      anchor: "data-modeling",
      text: "Data Modeling",
    },
    {
      anchor: "master-and-reference-data",
      text: "Master & Reference Data Management",
    },
    {
      anchor: "data-warehousing",
      text: "Data Warehousing & Business Intelligence",
    },
    {
      anchor: "conclusion",
      text: "Conclusion",
    },
  ];
  return (
    <>
      <p>
        This writeup presents the basics of Data Strategy, which outlines how data contributes to the vision, focus
        areas, guiding principles, essential capabilities, and goals of an organization. A strong Data Strategy
        positions an organization to capitalize on its strategic assets without jeopardizing its most important
        relationships with customers, employees, and partners.
      </p>

      <PostImage postName={POSTNAME} />
      <Contents contents={contents} />

      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <p>
        Data Strategy helps an organization mitigate risk and protect its stakeholders, including customers, suppliers,
        and employees. A strong Data Strategy allows an organization to use data to{" "}
        <Link to="/resources/data-driven-decision-making">drive decisions</Link>. Data provides a common language across
        business functions, allowing the organization to support existing capabilities and try new things.
      </p>
      <p>On the other hand, </p>
      <blockquote style={{ marginTop: "1em", marginBottom: "4em" }}>
        <p>
          "The very existence of an organization can be threatened by poor quality data."
          <br />—{" "}
          <a href="https://scholar.google.com/citations?user=bJoq2dEAAAAJ&hl=en" target="_blank" rel="noreferrer">
            Joe Peppard
          </a>
          , Principal Research Scientist at MIT Sloan School of Management
        </p>
      </blockquote>

      <figure>
        <Link to="/resources/aiken-pyramid" target="_blank">
          <img src={Aiken} alt="Aiken Pyramid" className="box-shadow" />
        </Link>
        <figcaption>
          <Link to="/resources/aiken-pyramid" target="_blank">
            Aiken Pyramid
          </Link>{" "}
          courtesy of{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>
        </figcaption>
      </figure>

      <p>
        As discussed in the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>Data Management Body of Knowledge (DMBOK)</em>
        </a>
        , the{" "}
        <Link to="/resources/aiken-pyramid" target="_blank">
          Aiken Pyramid
        </Link>{" "}
        outlines core Data Strategy concepts and illustrates how these functions build upon each other. The foundational
        layers of the pyramid are Data Governance, Data Quality, and Data Architecture. These areas are essential to the
        success of any data-related initiative. They provide the necessary infrastructure to support the other Data
        Management capabilities, including Data Modeling, Master & Reference Data Management, and Data Warehousing &
        Business Intelligence.
      </p>

      <h2 id={contents[1].anchor}>{contents[1].text}</h2>
      <h5>
        Percentage of the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        : 11%
      </h5>
      <p>
        It's important to begin a Data Management initiative with a conversation about the mission statement and
        strategic aims of the organization. <strong>Connecting data to business outcomes</strong> will drive the
        motivation to sustain Data Governance for the long haul.
      </p>
      <p>
        The team can then document this motivation in the{" "}
        <Link to="/resources/data-governance-charter">Data Governance Charter</Link>, which should include a clear
        vision of the organization's future state when it comes to data. You can review our{" "}
        <Link to="/product/document-checklist" target="_blank">
          Document Checklist
        </Link>{" "}
        for more information about what should be included in a Data Governance Charter and a list of other documents to
        support the Data Management business function.
      </p>
      <p>
        Beyond drafting a Data Governance Charter, here are some additional processes to consider implementing as part
        of a Data Governance initiative:
      </p>
      <ul>
        <li>Develop Operating Framework</li>
        <li>Develop RACI matrix</li>
        <li>Conduct Maturity Assessment</li>
        <li>Conduct Gap Assessment</li>
        <li>Develop Roadmap with transition steps</li>
      </ul>
      <p>
        As we turn to talking about the organizational structures required to support Data Governance, it's worth
        remembering that Data Governance is a process, not a project. There isn't a set end date. Therefore, it's
        important to maintain continuity by setting up Roles & Responsibilities that will outlast any specific
        individual contributor.
      </p>
      <p>Organizational structures to support Data Governance:</p>
      <ul>
        <li>Chief Data Officer</li>
        <li>Steering Committee</li>
        <li>Data Governance Council</li>
        <li>Program Office</li>
        <li>Data Stewards Working Groups</li>
      </ul>
      <p>How to get started — here are three practical initial steps for a Data Governance initiative:</p>
      <ul>
        <li>First, align data-related benefits and risks to the organization's strategic objectives.</li>
        <li>Second, set up an organizational structure for the long term.</li>
        <li>
          Finally, conduct a Data Maturity Assessment that includes conversations across the organization about data
          culture. This will help the Data Governance team get a better understanding of attitudes and behaviors around
          data. You can ask questions like: 
          <em>
            Is data freely shared across business units? Do data consumers have the skills and endpoints required to
            access the data they need in their work? Is data quality trusted?
          </em>
        </li>
      </ul>
      <p>
        Setting up Data Governance requires time and energy, but it's foundational to improving the organization's data
        infrastructure. It's truly the key to unlocking the potential of all other data-related activities. Keep in mind
        that Data Governance is an ongoing process, not a project.
      </p>
      <p
        style={{
          fontSize: ".9em",
        }}
      >
        <a href="https://youtu.be/Ttzxl-ljBJI" target="_blank" rel="noreferrer">
          <em>Watch a video about this topic</em>
        </a>
      </p>

      <h2 id={contents[2].anchor}>{contents[2].text}</h2>
      <h5>
        Percentage of the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        : 11%
      </h5>
      <p>
        The{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>{" "}
        identifies nine dimensions related to Data Quality: Accuracy, Completeness, Consistency, Integrity,
        Reasonability, Timeliness, Uniqueness/Deduplication, Validity, and Accessibility.
      </p>
      <p>
        <strong>Data should be fit for a purpose</strong>. It should meet the requirements of its authors, users, and
        administrators.
      </p>
      <p>How to get started:</p>
      <ul>
        <li>
          In order to move forward with a Data Quality initiative, you should first set up a formal Data Quality
          Reporting Process. In{" "}
          <a href="https://amzn.to/3l6bxgz" target="_blank" rel="noreferrer">
            <em>The Lean Startup</em>
          </a>
          , Eric Ries talks about the importance of asking “why” 5 times in order to reach the root cause of a problem.
          That exercise is relevant to investigating Data Quality issues and conducting root cause remediation.
        </li>
        <li>
          Second, you might consider data literacy training for data owners as well as data consumers across the
          organization.
        </li>
        <li>
          Third, a significant number of Data Quality issues stem from lack of quality metadata, especially in the era
          of Data Lakes. It's possible that data is loaded into the storage system and then lost due to poor quality
          metadata tags. Efforts to create a data catalog could be useful in resolving this challenge.
        </li>
      </ul>
      <p
        style={{
          fontSize: ".9em",
        }}
      >
        <a href="https://youtu.be/CE-S2Lk0PPw" target="_blank" rel="noreferrer">
          <em>Watch a video about this topic</em>
        </a>
      </p>

      <CDMPad />

      <h2 id={contents[3].anchor}>{contents[3].text}</h2>
      <h5>
        Percentage of the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        : 6%
      </h5>
      <p>
        This area represents the transformation of business needs into technical specifications. Architecture describes
        the current and future state of data infrastructure. The Enterprise Data Model and Data Flow Diagram are key
        tools that form the backbone of Data Architecture.
      </p>
      <p>
        An <strong>Enterprise Data Model</strong> is a holistic, enterprise-level, implementation-independent Conceptual
        or Logical data model that provides a common, consistent view of data across the enterprise.
      </p>
      <p>
        A <strong>Data Flow Diagram</strong> defines the requirements and master blueprint for storage and processing
        across databases, platforms, and networks. Here is an example Data Flow Diagram presented as a Matrix. A matrix
        provides a clear overview of data interchange. You could add more detail to this layout, such as comments
        indicating storage system.
      </p>
      <p>How to get started with Data Architecture:</p>
      <ul>
        <li>Develop an Enterprise Data Model (EDM) that provides a consistent view of data across the organization</li>
        <li>
          Develop a Data Flow Diagram that defines the requirements and a blueprint for storage and processing across
          databases, applications, platforms, and networks
        </li>
      </ul>
      <p
        style={{
          fontSize: ".9em",
        }}
      >
        <a href="https://youtu.be/O_OK--qLr5c" target="_blank" rel="noreferrer">
          <em>Watch a video about this topic</em>
        </a>
      </p>

      <h2 id={contents[4].anchor}>{contents[4].text}</h2>
      <h5>
        Percentage of the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        : 11%
      </h5>
      <p>Modeling provides a blueprint for how data is connected.</p>
      <ul style={{ marginTop: "1em" }}>
        <li>
          Starting with the Conceptual Data Model, business concepts and activities are documented as entities and
          relationships
        </li>
        <li>
          The Logical Data Model captures detailed requirements. This phase builds on requirements and existing
          documentation to add associative entities
        </li>
        <li>
          Finally, the development of the Physical Data Model outlines how data will be stored in the enterprise system
        </li>
      </ul>
      <p>
        Once the modeling process is complete, the team may choose to undertake a process of{" "}
        <strong>reverse engineering</strong> from Physical Model, to Logical Model, to Conceptual Model, in order to
        ensure requirements are met.
      </p>
      <p>
        At large enterprises, there's often a tendency to jump right into Logical Model and skip the Conceptual Model
        entirely. However, this is a mistake — there's significant value in starting with the Conceptual Model as it is
        a significant aid to creating a common understanding across the team of data entities and relationships.
      </p>
      <p>How to get started with Data Modeling:</p>
      <ul>
        <li>Select scheme and notation</li>
        <li>Gather entities and relationships</li>
        <li>Utilize organization-specific terminology</li>
      </ul>
      <p
        style={{
          fontSize: ".9em",
          marginBottom: "3em",
        }}
      >
        <a href="https://youtu.be/O_OK--qLr5c" target="_blank" rel="noreferrer">
          <em>Watch a video about this topic</em>
        </a>
      </p>

      <h2 id={contents[5].anchor}>{contents[5].text}</h2>
      <h5>
        Percentage of the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        : 10%
      </h5>
      <p>
        This was one of the concepts that I was most unfamiliar with when I started reading the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        . So I hope to provide you with a more intuitive understanding of what this area entails.
      </p>
      <p>
        Master Data is <strong>information about business entities</strong>. It is collected and preserved as a “source
        of truth” and a resource across the entire organization. This reduces variation in how critical entities are
        defined and identified, and allows for data to be shared across business functions and applications. It promotes
        standards of shared data models and integration patterns.
      </p>
      <p>
        Similarly, Reference Data refers to data that should be shared across the organization. It's often gathered from{" "}
        <strong>external sources</strong>, and it's used to provide context to the organization's functions and
        activities.
      </p>
      <p>How to get started:</p>
      <ul>
        <li>
          Plan for storage in a <strong>system of record</strong>
        </li>
        <li>
          Collect <strong>business entities</strong> and document data
        </li>
        <li>
          Set up a process for <strong>ongoing monitoring</strong>
        </li>
      </ul>
      <p>
        Master and Reference Data provide a common language and emphasize that data is a shared asset across the
        organization.
      </p>
      <p
        style={{
          fontSize: ".9em",
        }}
      >
        <a href="https://youtu.be/MJGENzVGP0w" target="_blank" rel="noreferrer">
          <em>Watch a video about this topic</em>
        </a>
      </p>

      <h2 id={contents[6].anchor}>{contents[6].text}</h2>
      <h5>
        Percentage of the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        : 10%
      </h5>
      <p>
        The data warehouse is a specific infrastructure element that provides data consumers, such as analysts and data
        scientists, with access to data that has been shaped to conform to business rules and is stored in an
        easy-to-query format.
      </p>
      <p>
        The data warehouse typically connects information from multiple “source-of-truth” transactional databases. The
        contents of a data warehouse have been restructured for speed and ease of querying. This serves to increase
        performance by partitioning, indexing, and decreasing the complexity of table joins.
      </p>
      <p>
        Business intelligence is typically represented by reports and dashboards that provide insight to business
        stakeholders.
      </p>
      <blockquote>
        <p>"The only sins that ever get addressed interrupt the flow of money."</p>
      </blockquote>
      <p>
        I really like this quote from business intelligence consultant{" "}
        <a
          href="https://www.dataengineeringpodcast.com/power-bi-business-intelligence-episode-154/"
          target="_blank"
          rel="noreferrer"
        >
          Rob Collie
        </a>{" "}
        because it illustrates the fact that business intelligence will often reveal all the data quality issues that
        persist in a business. Just because a data quality problem isn't interrupting the cash flow, that doesn't mean
        it isn't insidiously impeding the organization's strategic objectives.
      </p>
      <p>
        This is why it is so important to start Data Strategy efforts by creating governance and promoting data quality
        that then will flow into effective advanced analytics like business intelligence and data science projects.
      </p>
      <p>How to get started with Data Warehousing:</p>
      <ul>
        <li>First, design with the end in mind, then build and deliver in agile sprints</li>
        <li>Second, aggregate and optimize at the end of the implementation process</li>
        <li>
          Third, promote self-service access to data through transparent communication of metadata and education for
          data consumers
        </li>
      </ul>
      <p>How to get started with Business Intelligence:</p>
      <ul>
        <li>Similarly, the first step is to design with the end in mind, then build and deliver in agile sprints</li>
        <li>Second, aggregate and optimize at the end of the implementation process</li>
        <li>
          Third, promote self-service access to data through transparent communication of metadata and education for
          data consumers
        </li>
      </ul>
      <p>
        These elements of Data Strategy sit toward the top of the Aiken Pyramid, where we are starting to get into
        activities such as advanced analytics and machine learning that might be one step more complicated than BI.
      </p>
      <p
        style={{
          fontSize: ".9em",
        }}
      >
        <a href="https://youtu.be/SjQ1T1Pb7m4" target="_blank" rel="noreferrer">
          <em>Watch a video about this topic</em>
        </a>
      </p>

      <h2 id={contents[7].anchor}>{contents[7].text}</h2>
      <p>
        Like Maslow's hierarchy of needs, Data Science actualization cannot be attained without first achieving the
        physiological and safety needs of <strong>Data Governance</strong>, <strong>Data Quality</strong>, and{" "}
        <strong>Data Architecture</strong> at the foundational levels of the{" "}
        <Link to="/resources/aiken-pyramid" target="_blank">
          Aiken Pyramid
        </Link>
        . Success in this area will set you up to pursue capabilities such as <strong>Data Modeling</strong>,{" "}
        <strong>Master & Reference Data Management</strong>, and{" "}
        <strong>Data Warehousing & Business Intelligence</strong>.
      </p>
      <p>
        Understanding Data Strategy transforms you from a data consumer into an empowered advocate for better Data
        Management practices. You may continue to deepen your authority in this field by becoming recognized as a{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          Certified Data Management Professional (CDMP)
        </Link>
        . The knowledge gained from reading the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>{" "}
        can help you set up a robust Data Strategy for your team.
      </p>
      <p>
        To that end, you may also be interested in the{" "}
        <Link to="/products/data-strategy-workbook" target="_blank">
          Data Strategy Workbook
        </Link>
        , which contains 20 exercises to help your organization accelerate the development of its Data Strategy
        capabilities. The six Data Management capabilities discussed in this article are also featured in our{" "}
        <Link to="/products/poster" target="_blank">
          Foundations of Data Strategy Poster
        </Link>
        , which can be a helpful tool for keeping this information top of mind.
      </p>
    </>
  );
};
