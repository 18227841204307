import { useCallback } from "react";
import { formatCurrency, formatCurrencySimple } from "../../helpers/utils";

export default function MultiSelect({ PRODUCT, OPTIONS, selectValues, setSelectValues, setPrice }) {
  const showLabel = PRODUCT === "Data Strategist Mug" || OPTIONS.length > 2;

  const handlePriceChange = useCallback(
    (price) => {
      setPrice(price);
    },
    [setPrice]
  );

  return (
    <>
      {OPTIONS[0]?.content.length > 1 && (
        <label htmlFor="options" style={{ display: OPTIONS.length === 1 ? "flex" : "block", gap: 8, width: "100%" }}>
          <p style={{ fontWeight: 800 }}>Options:</p>
          {OPTIONS.map((optionGroup, idx) => (
            <div
              key={idx}
              style={{
                display: showLabel ? "flex" : "block",
                gap: 8,
                marginBottom: 4,
                width: "100%",
                height: 25.195,
              }}
            >
              {showLabel && (
                <label
                  htmlFor={optionGroup.optionsLabel}
                  style={{ fontSize: ".9em", color: "rgb(48, 49, 61)", fontWeight: 900, minWidth: 40 }}
                >
                  {optionGroup.optionsLabel.charAt(0).toUpperCase() + optionGroup.optionsLabel.slice(1)}
                  <span style={{ fontWeight: 400 }}>:</span>
                </label>
              )}
              <select
                key={idx}
                name={optionGroup.optionsLabel}
                style={{ width: "100%", height: 25.195 }}
                value={selectValues[idx]}
                onChange={(e) => {
                  const opt = optionGroup.content.find((opt) => opt.label === e.target.value);
                  setSelectValues((prevSelectValues) => {
                    const updatedSelectValues = [...prevSelectValues];
                    updatedSelectValues[idx] = opt.label;
                    return updatedSelectValues;
                  });
                  if (opt.price) handlePriceChange(opt.price);
                }}
              >
                {optionGroup.content.map((opt) => (
                  <option key={opt.label} value={opt.label}>
                    {opt.displayText}{" "}
                    {opt.price &&
                      `(${
                        PRODUCT === "Data Strategist Mug" ? formatCurrency(opt.price) : formatCurrencySimple(opt.price)
                      })`}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </label>
      )}
    </>
  );
}
