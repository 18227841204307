const checkoutFormMessages = [
  { type: "membership", message: "Information about next steps" },
  { type: "sendinblue", message: "Your email course" },
  { type: "download", message: "Your digital download" },
  { type: "physical", message: "Information about your product" },
  { type: "service", message: "Information about next steps" },
  { type: "bundle", message: "Your product bundle" },
  { type: "course", message: "Information about next steps" },
];

export default checkoutFormMessages;
