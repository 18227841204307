import { useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import NewsletterSignUp from "./NewsletterSignUp";
import NewsletterArchive from "./NewsletterArchive";

import CustomHelmet from "../../components/CustomHelmet";
import NavButtons from "../../components/nav/navbar/NavButtons";

const navbuttons = [
  {
    id: "sign-up",
    label: "Sign Up",
    path: "/newsletter",
  },
  {
    id: "archive",
    label: "Archive",
    path: "/newsletter/archive",
  },
];

const NewsletterPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description="Data Strategy Professionals offers engaging monthly events focused on helping data practitioners save time and ace the Certified Data Management Professional (CDMP) exam."
        name="Newsletter"
        link="/newsletter"
        img="https://www.nicolejaneway.com/wp-content/uploads/2021/04/diego-ph-fIq0tET6llw-unsplash_square.jpg"
        alt="hand holding lightbulb"
      />

      <div className="home-layout">
        <div
          style={{
            height: "2em",
            width: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <h1 className="title">Newsletter</h1>
          <NavButtons navbuttons={navbuttons} />
        </div>

        <Switch>
          <Route exact path="/newsletter">
            <NewsletterSignUp />
          </Route>

          <Route exact path="/newsletter/archive">
            <NewsletterArchive />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default NewsletterPage;
