import { Link } from "react-router-dom";
import products from "../../data/products";

export default function DSPproducts({ products: productList }) {
  const addOns = products.filter((product) => productList.includes(product.name));

  return (
    <div className="teal-box" style={{ maxWidth: "2000px", margin: "auto" }}>
      <div className="center-text-box">
        <h4
          style={{
            fontSize: "1.2em",
            marginBottom: "1em",
            fontWeight: "900",
            textAlign: "center",
          }}
        >
          You May Also Be Interested
        </h4>
        <div className="image-display-tri">
          {addOns.map((product) => (
            <figure>
              <Link to={product.link} target="_blank" rel="noreferrer">
                <img src={product.img} alt={product.alt} style={{ width: "20vw" }} />
              </Link>
              <figcaption style={{ textAlign: "center" }}>
                <Link to={product.link} target="_blank" rel="noreferrer">
                  {product.name}
                </Link>
              </figcaption>
            </figure>
          ))}
        </div>
      </div>
    </div>
  );
}
