import { useState } from "react";

import EmailForm from "./FooterEmailForm";

export default function FooterNewsletterSignUp() {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [validEmail, setValidEmail] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    fetch("/.netlify/functions/sendinblue-newsletter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        emailList: 36,
        source: "footer newsletter signup",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setSubmitted(true);
  };

  return (
    <div
      style={{
        width: 400,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 4,
        marginRight: 120,
      }}
    >
      <p style={{ width: 95, textAlign: "center", fontSize: "1.05em", marginRight: 20 }}>
        Subscribe to our newsletter:
      </p>

      {submitted ? (
        <p style={{ fontWeight: 900, paddingLeft: 40, width: 254.594, color: "#4f4e4e" }}>Thanks!</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
            }}
          >
            <EmailForm setEmail={setEmail} setValidEmail={setValidEmail} />
            <button
              className="btn btn-nav"
              type="submit"
              disabled={!validEmail}
              style={{ padding: "10px 10px", height: 42.391 }}
            >
              Sign Up
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
