import inputStyles from "./inputStyles";

type NameFormProps = {
  setFirstName: (firstName: string) => void;
};

const NameFormContainer: React.VFC<NameFormProps> = ({ setFirstName }) => {
  return (
    <div style={{ marginBottom: 16 }}>
      <label htmlFor={"firstName"}>
        <p style={inputStyles.label}>
          <strong>First Name</strong>
        </p>
      </label>
      <input
        type="text"
        id="firstName"
        onBlur={(e) => setFirstName(e.target.value)}
        className="field checkout-form-input"
      />
    </div>
  );
};

export default NameFormContainer;
