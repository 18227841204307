import { useState, createContext } from "react";

const LayoutContext = createContext();

function LayoutProvider(props) {
  const [navHeight, setNavHeight] = useState();

  const value = { navHeight, setNavHeight };

  return <LayoutContext.Provider value={value}>{props.children}</LayoutContext.Provider>;
}

export { LayoutContext, LayoutProvider };
