import { Link } from "react-router-dom";

import riyadh from "../img/blog/ksa/riyadh.webp";
import desert from "../img/blog/ksa/desert.webp";
import mecca from "../img/blog/ksa/mecca.webp";
import neom from "../img/blog/ksa/neom.webp";

import Post from "../components/blog/Post";
import Contents from "../components/blog/Contents";

const POSTNAME = "ksa";

const KSA: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default KSA;

const Rest = () => {
  const contents: Content[] = [
    { anchor: "impressive-economic-growth", text: "Impressive Economic Growth" },
    { anchor: "data-management-standards", text: "Data Management Standards" },
    { anchor: "achieving-compliance", text: "Achieving Compliance" },
    { anchor: "conclusion", text: "Conclusion" },
  ];
  return (
    <>
      <figure>
        <img src={riyadh} alt="Riyadh" />
        <figcaption>
          Photo by{" "}
          <a href="https://unsplash.com/@konevi" target="_blank" rel="noreferrer">
            ekrem osmanoglu
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/" target="_blank" rel="noreferrer">
            Unsplash
          </a>
        </figcaption>
      </figure>
      <p>
        The Kingdom of Saudi Arabia (KSA) achieved remarkable economic growth in recent years. The International
        Monetary Fund (IMF) recognized the country as the{" "}
        <a
          href="https://www.imf.org/en/News/Articles/2023/06/06/saudi-arabia-staff-concluding-statement-of-the-2023-article-iv-mission"
          target="_blank"
          rel="noreferrer"
        >
          <strong>fastest growing G20 economy</strong>
        </a>{" "}
        in 2022 with an overall expansion of 8.7% last year.
      </p>
      <p>
        Against this backdrop, the government of KSA aims to introduce a new era of{" "}
        <strong>efficiency and transparency</strong> through the establishment of a{" "}
        <strong>National Data Management Office (</strong>
        <a href="https://sdaia.gov.sa/en/Sectors/NDMO/Pages/default.aspx" target="_blank" rel="noreferrer">
          <strong>NDMO</strong>
        </a>
        <strong>)</strong>. All Saudi public entities have been asked to comply with NDMO requirements for collection,
        processing, storage, and transfer of personal data (which refers to “[a]ny element of data, alone or in
        connection with other available data, that would enable the identification of a Saudi citizen”).
      </p>
      <p>
        The government's efforts to diversify the economy, coupled with <strong>strategic initiatives</strong> including{" "}
        <a href="https://www.vision2030.gov.sa/" target="_blank" rel="noreferrer">
          <strong>Vision 2030</strong>
        </a>{" "}
        and the establishment of NDMO, have paved the way for positive developments across various sectors. The
        combination of these ambitious policies and innovative strategies has positioned Saudi Arabia as a regional
        economic powerhouse.
      </p>
      <p>
        This article is designed to help you learn about <strong>NDMO's Data Management Standards</strong> so that you
        can understand how to apply Data Management best practices to your organization.
      </p>

      <Contents contents={contents} />

      <p>
        Before describing the Data Management Standards in more detail, let's set the scene by talking about the extent
        and character of economic growth in KSA. We will then explore the role of Data Management in building efficient,
        enduring institutions. Finally, we'll discuss steps to achieving compliance with NDMO's guidelines.
      </p>
      <h2 id="impressive-economic-growth">Impressive Economic Growth</h2>
      <p>
        Economic development in KSA is on a strong trajectory. The momentum of the past year continues into 2023.
        According to an{" "}
        <a
          href="https://www.imf.org/en/News/Articles/2023/06/06/saudi-arabia-staff-concluding-statement-of-the-2023-article-iv-mission"
          target="_blank"
          rel="noreferrer"
        >
          IMF report
        </a>{" "}
        published June 7, 2023:
      </p>
      <blockquote>
        The Saudi economy is booming, spurred by high oil prices, a strong pick up in private investment and reform
        implementation… Implementation of the impressive structural reform agenda will help generate a strong, inclusive
        and more sustainable growth.
      </blockquote>
      <p>
        These changes are part of{" "}
        <a href="https://www.vision2030.gov.sa/" target="_blank" rel="noreferrer">
          Vision 2030
        </a>
        , a nation-wide effort to <strong>delink the economy from overreliance on oil exports</strong>. Implementation
        of Vision 2030 projects have helped spur <strong>innovation, entrepreneurship, and foreign investment</strong>.
      </p>
      {/* <p>
            Vision 2030 has been criticized as a <strong>dangerous smokescreen</strong> for social issues such as an{" "}
            <a href="https://www.mdpi.com/2227-9032/11/1/74" target="_blank" rel="noreferrer">
              unusually high rate of domestic violence
            </a>
            , human rights abuses like the use of{" "}
            <a href="https://news.un.org/en/story/2022/11/1130917" target="_blank" rel="noreferrer">
              capital punishment in response to drug-related offenses
            </a>
            , and international scandals such as the{" "}
            <a
              href="https://www.aljazeera.com/news/2018/11/17/cia-says-saudi-crown-prince-ordered-khashoggis-murder-reports"
              target="_blank"
              rel="noreferrer"
            >
              2018 killing of Washington Post journalist Jamal Khashoggi
            </a>
            , which the CIA claims occurred on the orders of Crown Prince bin Salman, among other concerns highlighted
            by{" "}
            <a
              href="https://www.amnesty.org/en/location/middle-east-and-north-africa/saudi-arabia/report-saudi-arabia/"
              target="_blank"
              rel="noreferrer"
            >
              various investigations
            </a>
            .
          </p> */}
      <p>
        {/* <span>Nonetheless, </span> */}
        Saudi Arabia's determination to <strong>shift from an oil-dependent economy to a diversified one</strong> is
        yielding tangible results, particularly within sectors like technology, tourism, and renewable energy. Growth in
        business outside the oil industry is{" "}
        <a
          href="https://www.imf.org/en/News/Articles/2023/06/06/saudi-arabia-staff-concluding-statement-of-the-2023-article-iv-mission"
          target="_blank"
          rel="noreferrer"
        >
          expected to average 5%
        </a>{" "}
        in 2023. Moreover, the objectives set forward under Vision 2030 have propelled progress on digitalization, the
        regulatory and business environment, female labor force participation, and private sector investment. The{" "}
        <a
          href="https://www.wipo.int/edocs/pubdocs/en/wipo-pub-2000-2022-en-main-report-global-innovation-index-2022-15th-edition.pdf"
          target="_blank"
          rel="noreferrer"
        >
          2022 Global Innovation Index
        </a>{" "}
        ranked Saudi Arabia 51st globally, a jump from its previous position of 66th, which serves to highlight the
        impact of these ambitious programs.
      </p>
      <p>
        As we navigate 2023, the Kingdom of Saudi Arabia has benefited from increased prosperity, partly due to these
        ambitious aims. The concerted effort to diversify the economy and put thoughtful governance into place has
        yielded positive outcomes. Through its commitment to growth, innovation, and internationalization, Saudi Arabia
        is poised to continue its journey toward becoming a <strong>dynamic and diversified global economy</strong>.
      </p>
      <figure>
        <img src={mecca} alt="buildings" />
        <figcaption>
          Photo by{" "}
          <a
            href="https://unsplash.com/@hydngallery?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            target="_blank"
            rel="noreferrer"
          >
            Haidan
          </a>{" "}
          on{" "}
          <a
            href="https://unsplash.com/photos/IOBIgKmjm1Y?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            target="_blank"
            rel="noreferrer"
          >
            Unsplash
          </a>
        </figcaption>
      </figure>

      <h2 id="data-management-standards">Data Management Standards</h2>
      <p>
        In setting up the National Data Management Office, Saudi Arabia plans to encourage the development of{" "}
        <strong>resilient, long lasting institutions</strong>. The NDMO has put forward standards in keeping with best
        practices in Data Management and Data Protection. Many of the requirements are based on the{" "}
        <em>Data Management Body of Knowledge (</em>
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        <em>)</em> by{" "}
        <a href="https://www.dama.org/" target="_blank" rel="noreferrer">
          DAMA International
        </a>
        . The NDMO intends to steward Data Management activities across public entities.
      </p>
      <p>
        The{" "}
        <a href="https://sdaia.gov.sa/en/SDAIA/about/Documents/PoliciesEN.pdf" target="_blank" rel="noreferrer">
          <strong>National Data Management and Personal Data Protection Standards</strong>
        </a>{" "}
        document outlines <strong>15 standards for Data Management and Data Protection</strong>. If you want to learn
        more about the 15 standards, you can{" "}
        <Link to="/landing/ksa-data-standards" target="_blank" rel="noreferrer">
          download a pdf
        </Link>{" "}
        containing the <strong>definition, goals, and actions</strong> associated with each. Many of these standards
        will look familiar to anyone who's read the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        .
      </p>
      <br />
      <ol>
        <li>
          <strong>Data Governance</strong>:&nbsp; authority and control over the planning and implementation of an
          organization's data management practices. This standard is directly linked to the organization's business
          strategy.
        </li>
        <li>
          <strong>Data Catalog and Metadata</strong>:&nbsp; focuses on establishing access to high quality integrated
          metadata. Effective access to metadata could be achieved with the help of a Data Catalog tool.
        </li>
        <li>
          <strong>Data Quality</strong>:&nbsp; enhancement of the quality of organizational data in order to ensure data
          is fit for consumption.
        </li>
        <li>
          <strong>Data Operations</strong>:&nbsp; aims to maximize the value of data throughout its lifecycle by
          focusing on design, implementation, and support for data storage.
        </li>
        <li>
          <strong>Document and Content Management</strong>:&nbsp; management of the data lifecycle of content stored
          outside of traditional relational databases.
        </li>
        <li>
          <strong>Data Architecture and Modeling</strong>:&nbsp; establishment of formal data structures and data flow
          channels to ensure end-to-end data processing within and across departments of an organization.
        </li>
        <li>
          <strong>Reference and Master Data Management</strong>:&nbsp; provides a common point of reference for all
          essential data elements by linking them to a single master data store.
        </li>
        <li>
          <strong>Business Intelligence and Analytics</strong>:&nbsp; focuses on analyzing the organization's data
          records to extract insights and draw conclusions about the information uncovered.
        </li>
        <li>
          <strong>Data Sharing and Interoperability</strong>:&nbsp; handles collection of data from various sources and
          to establish communication between various databases and applications. This standard covers data sharing
          practices for standardized exchange of data between departments within an organization.
        </li>
        <li>
          <strong>Data Value Realization</strong>:&nbsp; continual evaluation of data assets as they relate to business
          goals.
        </li>
        <li>
          <strong>Open Data</strong>:&nbsp; focuses on enhancing transparency, accelerate innovation, and fostering
          economic growth by making data available in the public domain.
        </li>
        <li>
          <strong>Freedom of Information</strong>:&nbsp; focuses on providing access to governmental information,
          describing the processes for access, and offering an appeal mechanism in the event of disputes.
        </li>
        <li>
          <strong>Data Classification</strong>:&nbsp; involves the categorization of data so that it may be used
          efficiently.
        </li>
        <li>
          <strong>Personal Data Protection</strong>:&nbsp; deals with the proper handling and non-disclosure of personal
          information.
        </li>
        <li>
          <strong>Data Security and Protection</strong>:&nbsp; under the mandate of the Saudi National Cybersecurity
          Authority, this standard focuses on the processes, people, and technology designed to protect the
          organization's data, including authorized access to data and safeguarding against unauthorized disclosure.
        </li>
      </ol>
      <figure>
        <img src={neom} alt="man walking in desert" />
        <figcaption>
          Photo by{" "}
          <a
            href="https://unsplash.com/@neom?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            target="_blank"
            rel="noreferrer"
          >
            NEOM
          </a>{" "}
          on{" "}
          <a
            href="https://unsplash.com/photos/kirkdJn_jEk?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            target="_blank"
            rel="noreferrer"
          >
            Unsplash
          </a>
        </figcaption>
      </figure>
      <h2 id="achieving-compliance">Achieving Compliance</h2>
      <p>
        Each year, public entities within Saudi Arabia are responsible for conducting a compliance audit and submitting
        the report to NDMO during the third quarter of that year. NDMO reviews and consolidates all reports and
        publishes results at the organizational, sector, and whole-of-government level.
      </p>
      <p>
        A public entity is considered to be “[a]ny independent governmental or public entity or affiliates thereof in
        the Kingdom of Saudi Arabia.” It also includes “any company [that] runs, operates or maintains public utilities
        or national infrastructure, or renders public service related to management of these public utilities or
        national infrastructure.”
      </p>
      <p>
        There are two outcomes to the NDMO compliance audit, either a pass or a fail grade. A pass grade is 100%
        compliance while a fail grade is constituted by either partial or no compliance.
      </p>
      <p>
        Within each organization, the annual compliance exercise should be led by the Chief Data Officer, supported by
        the other Data Management and Personal Data Protection Office roles.
      </p>
      <p>Here are some key Data Management roles that should be involved in this initiative:</p>
      <ul>
        <li>
          <strong>Chief Data Officer</strong>:&nbsp; responsible for overseeing an organization's data management
          strategy, ensuring data quality, and leveraging data assets to drive business value and innovation
        </li>
        <li>
          <strong>Data Architect</strong>:&nbsp; designs and creates the data infrastructure and framework, ensuring
          data solutions are scalable, reliable, and optimized to meet the organization's specific needs and goals
        </li>
        <li>
          <strong>Database Administrator</strong>:&nbsp; manages and maintains the organization's databases, ensuring
          data integrity, security, and availability while optimizing performance and facilitating backup and recovery
          processes
        </li>
        <li>
          <strong>Data Integration Architect</strong>:&nbsp; designs and implements strategies to integrate, centralize,
          and harmonize data from various sources, ensuring seamless data flow and consistency across the organization's
          systems
        </li>
        <li>
          <strong>Data Quality Analyst</strong>:&nbsp; assesses, cleans, and monitors data to ensure accuracy,
          reliability, and consistency, thereby supporting informed decision-making and upholding data integrity within
          an organization
        </li>
        <li>
          <strong>Metadata Specialist</strong>:&nbsp; manages and curates metadata to ensure accurate data
          classification, organization, and discoverability, facilitating the efficient use and understanding of an
          organization's data assets
        </li>
        <li>
          <strong>Business Intelligence Program Manager</strong>:&nbsp; oversees the strategy and execution of business
          intelligence initiatives, ensuring that data-driven insights are effectively harnessed to inform business
          decisions and drive organizational growth
        </li>
      </ul>
      <p>
        As public entities embark on the project of annual compliance, it's not just about meeting standards but
        encapsulating a broader vision where data emerges as a catalyst for innovation, growth, and transparency within
        Saudi Arabia. Every stakeholder has a pivotal role to play in this transformative journey.
      </p>
      <figure>
        <img src={desert} alt="desert" />
        <figcaption>
          Photo by{" "}
          <a
            href="https://unsplash.com/@jeremiahdelmar?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            target="_blank"
            rel="noreferrer"
          >
            Jeremiah Del Mar
          </a>{" "}
          on{" "}
          <a
            href="https://unsplash.com/photos/NjZPpFQlTPs?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            target="_blank"
            rel="noreferrer"
          >
            Unsplash
          </a>
        </figcaption>
      </figure>

      <h2 id="conclusion">Conclusion</h2>
      <p>
        The NDMO Data Standards reflect a remarkable effort to enshrine best practices of Data Management across
        organizations throughout the country. In developing these standards, the NDMO took into account guidance from
        leading institutions in this field, such as{" "}
        <a href="http://dama.org" target="_blank" rel="noreferrer">
          DAMA International
        </a>
        . If you want to read more about the definition, goals, and activities associated with each standard,{" "}
        <Link to="/landing/ksa-data-standards" target="_blank" rel="noreferrer">
          check out this report
        </Link>
        .
      </p>
      <p>
        To prepare for annual compliance, many Saudi individuals are finding it helpful to attain accreditation as a{" "}
        <strong>Certified Data Management Professional (</strong>
        <a href="https://www.datastrategypros.com/resources/cdmp" target="_blank" rel="noreferrer">
          <strong>CDMP</strong>
        </a>
        <strong>)</strong>. The CDMP exam from DAMA-I serves as a valuable signal of knowledge spanning the fourteen
        topic areas of the <em>Data Management Body of Knowledge</em> (
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        ).
      </p>
      <p>
        At Data Strategy Professionals, our primary objective is to help practitioners prepare for this valuable
        credential. We offer a{" "}
        <Link to="/products/cdmp-study-plan" target="_blank" rel="noreferrer">
          study plan
        </Link>
        ,{" "}
        <Link to="/products/cdmp-fundamentals-notes" target="_blank" rel="noreferrer">
          notes
        </Link>
        , and{" "}
        <Link to="/products/cdmp-question-sets" target="_blank" rel="noreferrer">
          practice questions
        </Link>{" "}
        for the test.
      </p>
    </>
  );
};
