import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { CheckoutContext } from "../../contexts/CheckoutContext";

import { handleProductPurchase, handleFreeTrialClick } from "../../helpers/checkoutUtils";

interface BuyNowButtonProps {
  margins?: string;
  buttonWidth?: number;
  checkoutFunction?: any;
  checkoutLink?: string | null;
  learnMore?: string | null;
  paymentName?: paymentName | null;
  freeTrial?: boolean;
}

const BuyNowButton: React.FC<BuyNowButtonProps> = ({
  margins = "4em",
  buttonWidth = 200,
  learnMore = null,
  paymentName = null,
  freeTrial = false,
}) => {
  const history = useHistory();
  const checkoutContext = useContext(CheckoutContext) as checkoutContext;
  const { setStep, setConfirmationTable } = checkoutContext.checkout;

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center", gap: 40, margin: margins + " auto" }}>
        <button
          className="btn btn-accent"
          style={{ width: buttonWidth, fontWeight: 900 }}
          onClick={(e) => handleProductPurchase(e, paymentName, history, setStep)}
        >
          Buy Now
        </button>
        {learnMore && (
          <button
            className="btn btn-trustpilot"
            style={{ width: buttonWidth }}
            onClick={() => window.open(learnMore, "_blank")}
          >
            Learn More
          </button>
        )}
        {freeTrial && (
          <button
            className="btn btn-trustpilot"
            style={{ width: buttonWidth }}
            onClick={() => handleFreeTrialClick(paymentName, history, setConfirmationTable, setStep)}
          >
            Free Trial
          </button>
        )}
      </div>
    </div>
  );
};

export default BuyNowButton;
