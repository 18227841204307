import { useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { CheckoutContext } from "../../contexts/CheckoutContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function StripeProvider({ firstName, lastName, email, discountCode, company, children, setFadeLoader }) {
  const checkoutContext = useContext(CheckoutContext);
  const { confirmationTable, clientSecret, setClientSecret, setPaymentIntentId } = checkoutContext.checkout;

  useEffect(() => {
    setFadeLoader(true)
    fetch("/.netlify/functions/handle-payment-intent", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        paymentsTable: confirmationTable.filter((row) => row.selected),
        discountCode,
        company,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        setPaymentIntentId(data.id);
        setFadeLoader(false)
      })
      .catch((error) => {
        setFadeLoader(false)
        console.error("Error:", error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
          }}
        >
          {children}
        </Elements>
      )}
    </>
  );
}
