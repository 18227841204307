import Carousel from "react-multi-carousel";
import { useMediaQuery } from "@mui/material";

import ProductCardContainer from "../../components/product-card/ProductCardContainer";
import CustomButtons from "../../components/carousel/CustomButtons";

import { productSlider } from "../../helpers/carouselStyles";

import productsList from "../../data/products";

const products = productsList.filter((product) => product.showOnHomepage);

const BestSellers: React.FC = () => {
  const desktop = useMediaQuery("(min-width:1024px)");
  const bestSellers: productType[] = [
    products.find((productObj) => productObj.name === "CDMP Fundamentals Bundle"),
    products.find((productObj) => productObj.name === "CDMP Crash Course"),
    products.find((productObj) => productObj.name === "CDMP Tutoring"),
    products.find((productObj) => productObj.name === "Specialist Bundle"),
    products.find((productObj) => productObj.name === "CAP Bundle"),
    products.find((productObj) => productObj.name === "Data Management Master Class"),
  ];

  return (
    <div
      className="homepage"
      style={{
        margin: "0px auto",
        background: "#f9f9f9",
        maxWidth: "100%",
        overflow: desktop ? "" : "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "1.8em",
          borderBottom: "2px solid #dbdbdb",
          textAlign: "center",
          position: "relative",
          zIndex: 0,
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "1.3em",
            marginTop: "11px",
            background: "#f9f9f9",
            zIndex: 1,
            display: "inline-block",
            padding: "0 20px",
            fontFamily: "Raleway",
          }}
          id="best-sellers"
        >
          <strong>Best Sellers</strong>
        </p>
      </div>
      <div className="carousel-container" style={{ position: "relative", padding: "0px 12px" }}>
        <Carousel
          responsive={productSlider}
          swipeable={true}
          draggable={true}
          ssr={true}
          keyBoardControl={true}
          infinite={true}
          arrows={false}
          renderButtonGroupOutside={true}
          // @ts-ignore
          customButtonGroup={<CustomButtons />}
        >
          {bestSellers.map((productObj, idx) => {
            return (
              <div
                style={{
                  margin: "0px 10px",
                  height: "calc(100% - 40px)",
                }}
                key={idx}
              >
                <ProductCardContainer key={productObj.name} productObj={productObj} idx={idx} decorate={false} />
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default BestSellers;
