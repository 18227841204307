import { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import Post from "../components/blog/Post";
import PostImage from "../components/blog/PostImage";
import CDMPLevelsModal from "../components/CDMPLevelsModal";
import CDMPQuickStart from "../components/blog/quick-start/CDMPQuickStart";

import chapters from "../img/blog/training/chapters.webp";
import trainingFull from "../img/blog/training/training-full.webp";

const POSTNAME = "cdmp-training";

const styles = {
  imageContainer: {
    maxWidth: 1000,
    margin: "3em 0",
    display: "flex",
    justifyContent: "center",
  },
  image: {
    maxWidth: 900,
  },
  subtitleContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: 8,
    marginTop: "3em",
  },
  subtitleIcon: {
    height: "1em",
    width: "1em",
    borderRadius: "50%",
  },
  subtitleText: {
    margin: 0,
  },
  subSubtitleText: {
    marginTop: "1.5em",
  },
};

const CDMPTraining: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default CDMPTraining;

const Rest = () => {
  const [showModal, setShowModal] = useState(false);

  const desktop = useMediaQuery("(min-width: 900px)");

  return (
    <>
      {showModal && <CDMPLevelsModal setShowModal={setShowModal} />}
      <CDMPQuickStart />
      <PostImage postName={POSTNAME} />

      <p>
        An effective approach to studying for the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP
        </Link>{" "}
        should support a future test taker to first <a href="#intake">intake</a>, then <a href="#process">process</a>,
        then <a href="#practice">practice</a> applying information from the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        , so they can eventually <a href="#ace-the-exam">ace the test</a>. By systematically meeting the test taker's
        needs at each step of this process, an effective study approach significantly increases the chances of the test
        taker meeting their goals, such as attaining recognition as a{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          CDMP Master
        </span>
        .
      </p>

      {desktop && (
        <div style={styles.imageContainer}>
          <img src={trainingFull} alt="training diagram" style={styles.image} className="box-shadow" />
        </div>
      )}

      <div style={styles.subtitleContainer} id="intake">
        <span style={{ ...styles.subtitleIcon, backgroundColor: "#d8e4e6" }} />
        <h2 style={styles.subtitleText}>Intake New Information</h2>
      </div>

      <p>
        If you're just reading the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        , that may not be enough to help you internalize the concepts for test day (much less the rest of your career as
        a data practitioner). Your study approach should provide a structured way to take in new information and{" "}
        <strong>optimize your test performance</strong>. It's best if you find a way to{" "}
        <strong>introduce key concepts</strong> in a way that makes them feel <strong>clear and manageable</strong>.
      </p>
      <p>
        When someone who really understands the materials explains it in a friendly and approachable manner, it can help
        you{" "}
        <strong>
          see the content of the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>{" "}
          in a new way and make connections
        </strong>{" "}
        between ideas that were previously confusing or overwhelming. Therefore, it's important to find a study approach
        that utilizes content outside the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>{" "}
        as part of a <strong>tried and true methodology</strong> to help you attain success on the exam.
      </p>
      <figure>
        <img
          src={chapters}
          alt="seven chapters on CDMP Specialist Exams comprise 70% of CDMP Fundamentals Exam content"
        />
        <figcaption>
          The seven chapters that are options for the{" "}
          <Link to="/resources/cdmp-specialist-exam" target="_blank">
            CDMP Specialist Exams
          </Link>{" "}
          comprise 70% of{" "}
          <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
            CDMP Fundamentals Exam
          </Link>{" "}
          content
        </figcaption>
      </figure>
      <p>
        As you study for the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        , we recommend that you use the{" "}
        <a target="_blank" rel="noreferrer" href="https://en.wikipedia.org/wiki/Pareto_principle">
          <strong>Pareto principle</strong>
        </a>{" "}
        to prioritize where you direct your attention while studying. Consider structuring your schedule so you{" "}
        <strong>spend more time on the concepts with a higher likelihood of ending up on the exam</strong>. This
        strategy is also referred to as{" "}
        <a target="_blank" rel="noreferrer" href="https://www.verywellmind.com/what-is-selective-attention-2795022">
          <strong>selective attention</strong>
        </a>
        . After reviewing the list above, be sure to dedicate the most time and attention to the chapters which will be
        11% or 10% of the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        .
      </p>
      <h4 style={styles.subSubtitleText}>Study tips</h4>
      <ul>
        <li>
          Don't just read the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>
        </li>
        <li>Find a study aid that makes complex concepts feel clear and manageable</li>
        <li>
          Make sure you're incorporating auxiliary study materials to ensure you have a full understanding of the
          material; check out additional reading materials such as{" "}
          <Link to="/resources/cdmp-specialist-exam" target="_blank">
            these books
          </Link>{" "}
          and{" "}
          <Link to="/resources" target="_blank">
            articles
          </Link>{" "}
          as well as{" "}
          <a href="https://www.youtube.com/@datastrategypros" target="_blank" rel="noreferrer">
            videos
          </a>{" "}
          to get a new perspective on{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>{" "}
          content
        </li>
        <li>
          Keep study sessions short and focused on key concepts; consider the{" "}
          <Link to="/resources/pomodoro-technique" target="_blank">
            pomodoro technique
          </Link>
        </li>
      </ul>
      <h4 style={styles.subSubtitleText}>Relevant concepts from learning science</h4>
      <ul>
        <li>
          <a target="_blank" rel="noreferrer" href="https://en.wikipedia.org/wiki/Cognitive_load">
            <strong>Cognitive load</strong>
          </a>
          :&nbsp; this theory describes the amount of strain on a person's mental faculties such as working memory.
          Techniques like summarization aim to reduce the cognitive load of learning new material in order to optimize
          the learner's ability to recall content and attain top performance on a test or at work.
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nsuworks.nova.edu/cgi/viewcontent.cgi?article=1013&amp;context=fdla-journal"
          >
            <strong>Microlearning</strong>
          </a>
          :&nbsp; splitting complex information into discrete{" "}
          <a target="_blank" rel="noreferrer" href="https://en.wikipedia.org//wiki/Chunking_(psychology)">
            chunks
          </a>{" "}
          helps to make it easier for a learner to process and retain new ideas
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://en.wikipedia.org/wiki/Pareto_principle">
            <strong>Pareto principle</strong>
          </a>
          :&nbsp; also known as the 80/20 principle, the Pareto principle describes how for many systems, 80% of the
          outcomes for something come from only 20% of the inputs. This is extremely true for the{" "}
          <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
            CDMP Fundamentals Exam
          </Link>
          , where 70% of the content is drawn from the seven chapters that are featured in the{" "}
          <Link to="/resources/cdmp-specialist-exam" target="_blank">
            CDMP Specialist Exams
          </Link>
          . Leveraging the Pareto Principle, a learner should absolutely spend the majority of their effort studying
          those seven chapters.
        </li>
      </ul>

      <div style={styles.subtitleContainer} id="process">
        <span style={{ ...styles.subtitleIcon, backgroundColor: "#cee1df" }} />
        <h2 style={styles.subtitleText}>Process Information</h2>
      </div>
      <p>
        Revisiting key concepts is crucial for long-term retention. Following a structured schedule to study and review
        the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>{" "}
        can help you attain multiple exposures to the material at optimal{" "}
        <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8759977/" target="_blank" rel="noreferrer">
          spaced repetition
        </a>{" "}
        intervals. Because the book is so long (500+ pages), you need to set up a schedule to{" "}
        <Link to="/products/cdmp-study-plan" target="_blank">
          study and review
        </Link>{" "}
        the most important concepts for the exam. Following a schedule will help you to avoid the{" "}
        <a href="https://en.wikipedia.org/wiki/Cognitive_load" target="_blank" rel="noreferrer">
          cognitive load
        </a>{" "}
        that can come from trying to remember too many things at once, a common problem with cramming that leads to less
        effective learning outcomes.
      </p>
      <p>
        Give yourself <strong>enough time</strong> so you don't feel overwhelmed by the amount of material you're
        studying and reviewing at one time – we recommend 90 days, but we also offer an immediate access version of our{" "}
        <Link to="/products/cdmp-study-plan" target="_blank">
          Study Plan
        </Link>{" "}
        for practitioners who may have more experience and wish to get ready for the exam in a shorter period of time.
      </p>
      <p>
        Be sure to develop your own concise summaries of information. Summarization supports{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://nsuworks.nova.edu/cgi/viewcontent.cgi?article=1013&amp;context=fdla-journal"
        >
          microlearning
        </a>
        , splitting complex information into discrete{" "}
        <a target="_blank" rel="noreferrer" href="https://en.wikipedia.org//wiki/Chunking_(psychology)">
          chunks
        </a>
        , which makes it easier to process and retain new ideas. You can then put these summaries on{" "}
        <strong>flashcards</strong> and use{" "}
        <a href="https://en.wikipedia.org/wiki/Testing_effect" target="_blank" rel="noreferrer">
          active recall
        </a>{" "}
        as a way to <strong>quickly reinforce your memory</strong> of key concepts.
      </p>
      <p>
        The strategy of studying by teaching what you're learning to another person is called the{" "}
        <a
          href="https://www.colorado.edu/artssciences-advising/resource-library/life-skills/the-feynman-technique-in-academic-coaching"
          target="_blank"
          rel="noreferrer"
        >
          Feynman technique
        </a>
        , named for the Nobel Prize-winning physicist{" "}
        <a href="https://en.wikipedia.org/wiki/Richard_Feynman" target="_blank" rel="noreferrer">
          {" "}
          Richard Feynman
        </a>
        . As you teach what you learn to another person (maybe a study buddy you found through the CDMP Study Groups on{" "}
        <a href="https://www.facebook.com/groups/346145433213551" target="_blank" rel="noreferrer">
          Facebook
        </a>{" "}
        or{" "}
        <a href="https://www.linkedin.com/groups/12965811/" target="_blank" rel="noreferrer">
          LinkedIn
        </a>
        ) make sure you can explain key concepts using straightforward, simplified vocabulary.
      </p>
      <p>
        All too often, we experience the{" "}
        <a href="https://en.wikipedia.org/wiki/Illusion_of_explanatory_depth" target="_blank" rel="noreferrer">
          illusion of explanatory depth
        </a>
        . We may be tempted to use overly broad or complex language to disguise from ourselves the fact that we do not
        truly understand a concept. By breaking things down into simple ideas and straightforward concepts a
        five-year-old could understand, we challenge our understanding and reveal the areas where our knowledge could be
        improved.
      </p>
      <h4 style={styles.subSubtitleText}>Study tips</h4>
      <ul>
        <li>
          Build a{" "}
          <Link to="/products/cdmp-study-plan" target="_blank">
            study and review
          </Link>{" "}
          schedule to learn new concepts while you revisit the material you've already studied
        </li>
        <li>
          Challenge yourself with active recall methods such as flashcards to reinforce your memory of key concepts
        </li>
        <li>
          Make sure you can summarize key concepts using straightforward, decomplexified vocabulary and try teaching
          what you're learning to another person to avoid falling victim to the{" "}
          <a href="https://en.wikipedia.org/wiki/Illusion_of_explanatory_depth" target="_blank" rel="noreferrer">
            illusion of explanatory depth
          </a>
        </li>
        <li>
          Utilize study groups and discussion sessions to support other learners; you may be interested in joining one
          of the Data Strategy Professionals'{" "}
          <Link to="/#community" target="_blank">
            communities
          </Link>{" "}
          or attending one of our upcoming Discussion Group{" "}
          <Link to="/events" target="_blank">
            events
          </Link>
        </li>
      </ul>
      <h4 style={styles.subSubtitleText}>Relevant concepts from learning science</h4>
      <ul>
        <li>
          <a target="_blank" rel="noreferrer" href="https://en.wikipedia.org/wiki/Spaced_repetition">
            <strong>Spaced repetition</strong>
          </a>
          :&nbsp; also known as{" "}
          <a target="_blank" rel="noreferrer" href="https://en.wikipedia.org/wiki/Distributed_practice">
            distributed learning
          </a>
          , this technique describes a structured approach to learning by spacing study sessions apart over time. Note
          that short learning sessions over a longer period of time are more potent than large blocks of time spent
          studying. As a learner's recall and understanding of a concept improves, the time interval between review
          sessions may increase (e.g., moving review sessions from daily to once a week to once a month).
        </li>
        <li>
          <a href="https://learning.subwiki.org/wiki/Feynman_technique" target="_blank" rel="noreferrer">
            Feynman technique
          </a>
          :&nbsp; this four-step process involves selecting and writing about a concept, attempting to teach it to
          another person, revisiting the source material to fill knowledge gaps, then simplifying your explanation to
          make it clearer
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/Illusion_of_explanatory_depth" target="_blank" rel="noreferrer">
            Illusion of explanatory depth
          </a>
          :&nbsp; a cognitive bias where someone believes they understand how something works better than they do
        </li>
      </ul>

      <div style={styles.subtitleContainer} id="practice">
        <span style={{ ...styles.subtitleIcon, backgroundColor: "#c3ddd8" }} />
        <h2 style={styles.subtitleText}>Test Your Knowledge</h2>
      </div>
      <p>
        Active recall (i.e., testing yourself) is an effective way to learn. Over time, you'll improve your ability to
        instinctively recognize correct answers. Active recall not only improves long-term retention of information, it
        also helps you identify areas where you may need to improve and helps you feel more comfortable when it comes
        time to take the exam.
      </p>
      <h4 style={styles.subSubtitleText}>Study tips</h4>
      <ul>
        <li>
          Utilize{" "}
          <Link to="/resources/free-cdmp-practice-questions" target="_blank">
            practice questions
          </Link>{" "}
          in the style of the target exam
        </li>
        <li>Create a log of questions that you missed or were confused by</li>
        <li>Seek out advice from others about common mistakes</li>
      </ul>
      <h4 style={styles.subSubtitleText}>Relevant concepts from learning science</h4>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://courses.lumenlearning.com/waymaker-psychology/chapter/reading-retrieval/"
          >
            Retrieval practice
          </a>
          :&nbsp; also known as{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.researchgate.net/publication/51797070_Test-Enhanced_Learning_in_the_Classroom_Long-Term_Improvements_From_Quizzing"
          >
            test-enhanced learning
          </a>{" "}
          or{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://web.colby.edu/memoryandlanguagelab/files/2012/07/Jacoby-Wahlheim-Coane-2010-JEPLMC.pdf"
          >
            recognition memory
          </a>
          , this concept describes how repeated testing of learners' ability to recognize correct answers from amongst
          multiple options has been found to “enhance recognition memory performance beyond that produced by repeated
          study”
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://en.wikipedia.org/wiki/Gamification_of_learning">
            <strong>Gamification</strong>
          </a>
          :&nbsp; the idea of bringing game design principles such as level progression and points into educational
          design to motivate learner progress; while the{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://en.wikipedia.org/wiki/Gamification_of_learning#Effectiveness"
          >
            effectiveness
          </a>{" "}
          of gamification is still unclear, you might find it's a helpful way to keep yourself motivated to study
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="http://uweb.cas.usf.edu/~drohrer/pdfs/Taylor&amp;Rohrer2010ACP.pdf">
            <strong>Interleaving</strong>
          </a>
          :&nbsp; in contrast to the typical method of teaching related ideas one after another, interleaving avoids any
          two related questions being posed consecutively. This approach encourages learners to think about the problem
          “on the basis of the problem itself” rather than relying on prior knowledge about the type of problem they'll
          encounter next to help them to select the correct answer.
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.proquest.com/docview/1646385613/fulltextPDF/240655B6C76A4E6APQ/1?accountid=40858&amp;sourcetype=Scholarly%20Journals"
          >
            <strong>Feedback</strong>
          </a>
          :&nbsp; corrective feedback on practice multiple choice questions can help to correct mistakes more reliably
          than when testers try to learn without feedback
        </li>
      </ul>

      <div style={styles.subtitleContainer} id="ace-the-exam">
        <span style={{ ...styles.subtitleIcon, backgroundColor: "#b3d1cc" }} />
        <h2 style={styles.subtitleText}>Ace the Exam!</h2>
      </div>
      <p>
        An effective{" "}
        <Link to="/resources/cdmp" target="_blank">
          CDMP Fundamentals Exam
        </Link>{" "}
        training course needs to support learners to iteratively study and test their knowledge of the key concepts on
        which they'll be examined. Following evidence-based study methods will help data practitioners to maximize their
        exam performance. If you haven't already, be sure to check out our{" "}
        <Link to="/products/cdmp-fundamentals-bundle" target="_blank">
          CDMP Fundamentals Bundle
        </Link>{" "}
        for a comprehensive, evidence-based set of study tools to help you{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          attain your goal score
        </span>
        . Good luck!!
      </p>
    </>
  );
};
