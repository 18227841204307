import { Link } from "react-router-dom";

const CDMPStudyProgramOfferings: React.VFC = () => {
  return (
    <div
      style={{
        maxWidth: 1000,
        width: "100%",
      }}
    >
      <ul style={{ marginBottom: ".2em" }}>
        <li>
          <Link to="/products/cdmp-study-plan" target="_blank">
            90-day Study Plan
          </Link>{" "}
          for the Certified Data Management Professional (
          <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
            CDMP
          </Link>
          ) Exam
        </li>
        <li>
          <Link to="/products/cdmp-fundamentals-notes" target="_blank">
            CDMP Fundamentals Notes
          </Link>
        </li>
        <li>
          All three{" "}
          <Link to="/products/cdmp-question-sets" target="_blank">
            CDMP Question Sets
          </Link>{" "}
          (150 total practice questions)
        </li>
        <li>
          Access to video lessons for each chapter of the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>{" "}
          tested on the{" "}
          <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
            CDMP
          </Link>{" "}
          exam
        </li>
        <li>
          <Link to="/products/cdmp-exam-readiness-check" target="_blank">
            CDMP Exam Readiness Check
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default CDMPStudyProgramOfferings;
