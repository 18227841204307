import Bills from "../img/people/nicole_bills.webp";
import Shaulis from "../img/people/jon_shaulis.webp";
import Johnson from "../img/people/jeff_johnson.webp";
import Lisel from "../img/people/lisel_engelbrecht.webp";
import Rajeev from "../img/people/rajeev_dhingra.webp";
import Nupur from "../img/people/nupur_gandhi.webp";

const journeys = [
  {
    id: "Jon",
    img: Shaulis,
    name: "Jon Shaulis",
    title: "Senior Principal Enterprise Data Architect",
    content: `Jon was first made aware of the CDMP exam when he began interviewing for an Enterprise Data Architect position.
      One of the "nice to haves" listed was a <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam'>CDMP credential</a>
      as the organization maintains awareness of industry best practices and directional shifts in IT.
      This includes keeping up with publications from DAMA, Gartner, Forester, and others.<br/><br/>
      After the interview process, Jon was hired by a sub-contractor for an evaluation period prior to
      being onboarded by the primary contractor. During that time, Jon earned the CDMP credential to boost his chances of
      getting brought on to the team. He got the job.<br /><br />
      Jon is confident the CDMP has made a significant impact on his career. "Coming from a technical first background,
      it's easy to be too deep in the woods to see past the trees," he admits. "The DAMA framework takes an enterprise view
      of the data landscape and describes best practices and insights that can help an organization improve its data maturity."
      Jon has written a <a href='https://jonshaulis.com/index.php/2019/11/26/hurray-i-earned-my-cdmp/' target='_blank' rel='noreferrer'>
      thorough post</a> documenting his experience with the exam.`,
  },
  {
    id: "Nupur",
    img: Nupur,
    name: "Nupur Gandhi",
    title: "Senior Business Data Consultant",
    content:
      "Nupur holds both an MBA with a concentration in Marketing and a Masters in Business Analytics and Project Management.  As a lead Data Steward, she spearheaded Salesforce reporting across US and Canada, using Tableau to track KPIs. For the last several years as a Senior Business Data Consultant at The Hartford, Nurpur has supported several Data Management functions, including driving Data Quality initiatives, building out Data Governance, and enabling source-to-target data mapping.<br/><br/>She is working towards taking the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam'>CDMP Fundamentals Exam</a> and has found the <a href='https://www.datastrategypros.com/products/cdmp-study-plan'>CDMP Study Plan</a> to be helpful.  She is seeking to better understand all the data domains from the <a href='https://dama-ps.org/' target='_blank' rel='noreferrer'><em>DMBOK</em></a> to develop holistic knowledge of the entire data lifecycle.<br/><br/>Additionally, Nupur serves on the Board of <a href='https://damanewengland.org/' target='_blank' rel='noreferrer'>DAMA New England</a> as VP of Online Services.  Through the <a href='https://damanewengland.org/Podcasts' target='_blank' rel='noreferrer'>Data Perspectives podcast</a>, Nipur interviews industry experts and data practitioners to provide real life examples, practical insights, and approaches to overcoming barriers to implementing optimized Data Strategy.",
  },
  {
    id: "Jeff",
    img: Johnson,
    name: "Jeff Johnson",
    title: "Director of Data Governance",
    content:
      "Jeff first became a CDMP Practitioner in 2018. This was around the time he joined the Board of Directors for the <a href='https://dama-ps.org/' target='_blank' rel='noreferrer'>Data Management Association (DAMA) Puget Sound chapter</a>.<br/><br/>With 20+ years of Data Management experience, Jeff often refers to the <a href='http://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><i>Data Management Body of Knowledge (DMBOK)</i></a> for best practices. This has proven useful across his career working for companies such as Starbucks, Experian Information Solutions, Sila Solutions Group, and others.<br/><br/>As Jeff looked to renew his certification, he discovered the <a href='https://www.facebook.com/groups/346145433213551/' target='_blank' rel='noreferrer'>CDMP Study Group</a> in 2021. In addition, he purchased the <a href='https://www.datastrategypros.com/products/cdmp-study-plan'>CDMP Study Plan</a>. Following the recommended study schedule, Jeff scored at the highest tier of the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam'>CDMP Fundamentals Exam.</a><br/><br/>With this certification, Jeff continues to serve on the Board of Directors for <a href='https://dama-ps.org/' target='_blank' rel='noreferrer'>DAMA Puget Sound</a> with a team of committed members focused on growing the chapter's presence in the Puget Sound area.",
  },
  {
    id: "Lisel",
    img: Lisel,
    name: "Lisel Engelbrecht",
    title: "Data Executive Financial Services and Data & AI Community Builder",
    content: `Lisel is a Data Executive in the Financial Services Industry. 
      She has spent most of her career involved in large scale, data-related projects in the manufacturing, retail, ecommerce, operations, 
      and financial services industries in South Africa.<br/><br/>
      Lisel has searched constantly for ways to solidify and improve her knowledge of data. 
      She stumbled upon the <a href='https://dama.org.za/' target='_blank' rel='noreferrer'>DAMA Southern Africa chapter</a> in 2010,
      then subsequently achieved recognition as a CDMP Practitioner. 
      She re-certified in 2016 and again in 2021. Her most recent certification was supported by following the content provided by 
      <a href='https://www.facebook.com/groups/346145433213551/' target='_blank' rel='noreferrer'>CDMP Study Group</a>
      and DAMA Southern Africa.<br/><br/>
      Lisel speaks three languages fluently: 1) Afrikaans – Lisel explains, "this is my mother tongue.  It's absolutely brilliant and deep;"
      2) English – "this is my official language for communication and learning;"
      3) Data – "this is my professional language."`,
  },
  {
    id: "Rajeev",
    img: Rajeev,
    name: "Rajeev Dhingra",
    title: "Risk Data Professional",
    content: `Rajeev is currently working as Risk Data Professional at ANZ Bank in Australia.
      His current role entails working on risk data architecture, data lineage, process mapping, and Data Quality dashboards.<br/><br/>
      Rajeev has spent his career conducting data-driven projects in the banking and financial services industries in Singapore and Australia.
      He has worked with Citigroup, Barclays, UOB, and ANZ across risk, finance, and retail internet banking domains.<br/><br/>
      Rajeev is working toward recognition as a Certified Data Management Professional (CDMP)
      by following the excellent resources provided through the
      <a href='https://www.facebook.com/groups/346145433213551/' target='_blank' rel='noreferrer'>CDMP Study Group</a>
      and DAMA's <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a>.
      The knowledge gained from these resources is contributing to how Rajeev thinks about and delivers his current work in Risk Data Management.<br/><br/>
      Rajeev is an active contributor to the <a href='https://www.facebook.com/groups/346145433213551/' target='_blank' rel='noreferrer'>CDMP Study Group</a>,
      which he has found to be a great way to improve his understanding of data principles and practices.
      For fun, Rajeev enjoys listening to music, watching Netflix, and playing cricket.`,
  },
  {
    id: "Nicole",
    img: Bills,
    name: "Nicole Janeway Bills",
    title: "Data Strategy Professionals Community Organizer",
    content: `Nicole offers a proven track record of applying Data Strategy and related disciplines to solve clients'
    most pressing challenges. She has worked as a Data Scientist and Project Manager for federal and commercial consulting teams.<br /> <br />
    In June 2020, she discovered the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam'>Certified Data Management Professional exam</a>.
    Excited about the opportunity to advance her career and deliver enhanced value to her clients, Nicole studied for a few months and passed
    the exam in October 2020. Along the way, she wrote about her experience for
    <a href='https://medium.com/towards-data-science/search?q=Nicole%20Janeway' target='_blank' rel='noreferrer'>Towards Data Science</a> and started the
    <a href='https://www.facebook.com/groups/346145433213551/' target='_blank' rel='noreferrer'>CDMP Study Group</a> on Facebook.
    Shortly after completing the test, Nicole was promoted from Staff Data Scientist to Digital Team Manager.
    <br /><br />Her business experience includes natural language processing, cloud computing, statistical testing, pricing analysis, ETL processes,
    and web and application development. She found the knowledge gained from the process of attaining the CDMP to be useful in her day-to-day work.`,
  },
];

export default journeys;
