import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const CustomButtonGroup = ({ next, previous }) => {
  return (
    <div
      className="custom-button-group"
      style={{ position: "absolute", left: 0, top: "50%", width: "100%", color: "#051f36" }}
    >
      <ArrowBackIos onClick={() => previous()} style={{ marginLeft: 16, fontSize: "1.9em", cursor: "pointer" }} />
      <ArrowForwardIos
        onClick={() => next()}
        style={{ position: "absolute", right: 8, fontSize: "1.9em", cursor: "pointer" }}
      />
    </div>
  );
};

export default CustomButtonGroup;
