import { useState, useEffect } from "react";

import intro from "../../img/products/data-privacy-exam-questions/cippe-intro.webp";
import q1 from "../../img/products/data-privacy-exam-questions/cippe-q1.webp";
import a1 from "../../img/products/data-privacy-exam-questions/cippe-a1.webp";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import CustomModal from "../../components/CustomModal";
import ExtendedDescription from "../../components/product-pages/ExtendedDescription";

const PRODUCT = "CIPP/E Question Sets";

export default function CIPPEExamQuestions() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        description={description}
        tealBox={tealBox}
        extendedDescription={extendedDescription}
        rest={rest(handleShowClick)}
      />
    </>
  );
}

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <div className="blog">
      <p>
        Prepare for the{" "}
        <a href="https://iapp.org/certify/cippe/" target="_blank" rel="noreferrer">
          Certified Information Privacy Professional/Europe exam
        </a>{" "}
        with these sets of 50 practice questions. These questions, developed by our team at Data Strategy Professionals,
        are based on the prep materials provided by{" "}
        <a href="https://iapp.org/" target="_blank" rel="noreferrer">
          IAPP
        </a>
        .
      </p>
      <br />
      <p>
        Immediately upon purchase, you'll receive a PDF document via email containing 1){" "}
        <strong>50 multiple choice questions</strong> and 2) <strong>answers section</strong> with a detailed
        explanation for each correct response.
      </p>
      <h4>Features</h4>
      <ul>
        <li>20 pages of question and answer content</li>
        <li>
          50 <strong>new</strong> questions for the CIPP/E Exam
        </li>
        <li>
          Detailed <strong>explanation</strong> for each answer
        </li>
        <li>
          Support for any questions about the product at{" "}
          <a href="mailto:support@datastrategypros.com?subject=CIPP%2FE%20Question%20Sets">
            support@datastrategypros.com
          </a>
        </li>
      </ul>
    </div>
  </div>
);

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>How to Learn Anything</h4>
    <div className="oath">
      <p>
        Research has shown{" "}
        <a href="http://pdf.poojaagarwal.com/McDaniel_Agarwal_etal_2011_JEP.pdf" target="_blank" rel="noreferrer">
          time
        </a>{" "}
        and{" "}
        <a href="http://pdf.poojaagarwal.com/Roediger_Agarwal_etal_2011_JEPA.pdf" target="_blank" rel="noreferrer">
          time again
        </a>{" "}
        that <strong>testing ourselves</strong> is one of the most effective ways to <strong>achieve mastery</strong>.
      </p>
      <p>
        So if you're really interested in becoming a <strong>Data Privacy expert</strong>, doing practice questions like
        these are among the best way to get there!
      </p>
      <p>
        This Question Set uses{" "}
        <a href="http://uweb.cas.usf.edu/~drohrer/pdfs/Taylor&Rohrer2010ACP.pdf" target="_blank" rel="noreferrer">
          interleaving
        </a>{" "}
        (i.e., mixed practice) to make the long-lasting effects of studying with these practice questions even stronger.
      </p>
      <p>
        You'll return to them throughout your test prep until you're <strong>totally ready</strong> to take the{" "}
        <a href="https://iapp.org/certify/cippe/" target="_blank" rel="noreferrer">
          CIPP/E exam
        </a>
        !
      </p>
    </div>
  </div>
);

const extendedDescription = (
  <div className="extended-description-container">
    <ExtendedDescription
      title="Frequently Asked Questions"
      description={
        <div className="extended-description faq">
          <p>
            <strong>Where did the CIPP/E Question Sets come from?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            The CIPP/E Question Sets were written in collaboration with our team at Data Strategy Professionals.
          </p>

          <p>
            <strong>What is included in my purchase of the CIPP/E Question Sets?</strong>
          </p>

          <p style={{ marginBottom: "1.25em" }}>
            The CIPP/E Question Sets includes an introduction with test preparation instructions as well as a detailed
            explanation for each answer.
          </p>
          <p>
            <strong>What else can I do to prep for the CIPP/E Exam?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Check out the{" "}
            <a href="https://www.facebook.com/groups/dataprivacystudygroup" target="_blank" rel="noreferrer">
              Data Privacy Study Group
            </a>{" "}
            for more information.
          </p>
        </div>
      }
    />
  </div>
);

const rest = (handleShowClick) => {
  <>
    <div className="white-box">
      <h4 className="box-title" style={{ textAlign: "center" }}>
        Photos
      </h4>
      <div className="image-display-tri">
        <figure>
          <img
            src={intro}
            alt="test prep"
            onClick={handleShowClick}
            style={{ maxHeight: "380px", cursor: "pointer" }}
          />
        </figure>

        <figure>
          <img
            src={q1}
            alt="questions"
            onClick={handleShowClick}
            style={{
              maxHeight: "380px",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
        </figure>

        <figure>
          <img src={a1} alt="answers" onClick={handleShowClick} style={{ maxHeight: "380px", cursor: "pointer" }} />
        </figure>
      </div>
    </div>
  </>;
};
