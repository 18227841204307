import { useEffect, useState, useContext } from "react";

import { CheckoutContext } from "../../../contexts/CheckoutContext";

import MessageProgressBar from "./MessageProgressBar";

type DiscountCodeProps = {
  showButton: boolean;
  setShowButton: (showButton: boolean) => void;
  showText: boolean;
  done: boolean;
  setDone: (done: boolean) => void;
};

const DiscountCode: React.VFC<DiscountCodeProps> = ({ showButton, setShowButton, showText, done, setDone }) => {
  const [value, setValue] = useState("");
  const [divHeight, setDivHeight] = useState(80);

  const checkoutContext = useContext(CheckoutContext);

  const { discountCodeInput, setDiscountCodeInput, discountValue, discountMessage } = checkoutContext.discount;

  useEffect(() => {
    if (showText) {
      setDivHeight(80);
    } else {
      setDivHeight(20);
    }
  }, [showText]);

  function handleSubmit(event) {
    event.preventDefault();
    setDiscountCodeInput(value);
  }

  useEffect(() => {
    if (discountCodeInput === "INCORRECT_CODE") {
      setValue("");
    }
  }, [discountCodeInput]);

  return (
    <>
      {!done && (
        <div style={{ minHeight: divHeight }}>
          {showButton ? (
            <div style={{ height: showText ? 58 : 20 }}>
              <p onClick={() => setShowButton(false)}>
                <span className="fake-link" style={{ fontWeight: 400, fontSize: ".9em" }}>
                  Add discount code
                </span>
              </p>
            </div>
          ) : discountValue ? (
            !done && (
              <div style={{ textAlign: "center", minHeight: 58 }}>
                <p style={{ paddingTop: 8 }}>
                  Nice! You're getting{" "}
                  <strong>
                    {discountValue > 1 ? "$" + discountValue / 100 : Math.round(discountValue * 100) + "%"}
                  </strong>{" "}
                  off this order
                </p>
                <MessageProgressBar setDone={setDone} />
              </div>
            )
          ) : (
            <form>
              <label
                htmlFor="discountCode"
                style={{
                  fontSize: ".9em",
                  color: "rgb(48, 49, 61)",
                }}
              >
                <span onClick={() => setShowButton(true)}>Discount code</span>{" "}
                {discountMessage && <span style={{ fontWeight: 400 }}>— {discountMessage}</span>}
              </label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 8,
                  height: 38,
                  marginTop: -4,
                  marginBottom: 20,
                  fontSize: ".9em",
                }}
              >
                <input
                  className="field checkout-form-input"
                  type="text"
                  id="discountCode"
                  onChange={(e) => setValue(e.target.value)}
                  style={{ marginBottom: 0, height: 36, fontSize: ".95em" }}
                  value={value}
                  placeholder="Add discount code"
                />
                <button
                  className="btn btn-trustpilot"
                  disabled={!value}
                  style={{ padding: 10, height: 36 }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      )}
    </>
  );
};

export default DiscountCode;
