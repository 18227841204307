import { Link } from "react-router-dom";

import Post from "../components/blog/Post";
import PostImage from "../components/blog/PostImage";
import Contents from "../components/blog/Contents";

const POSTNAME = "data-driven";

const DataDriven: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default DataDriven;

const Rest = () => {
  const contents: Content[] = [
    {
      anchor: "challenges",
      text: "Challenges of Becoming Data-Driven",
    },
    {
      anchor: "examples",
      text: "Examples of Data-Driven Organizations",
    },
    {
      anchor: "culture",
      text: "Cultivating a Data-Driven Culture",
    },
    {
      anchor: "conclusion",
      text: "Conclusion",
    },
  ];

  return (
    <>
      <p>
        While some organizations possess a high level of data literacy that supports data-driven decision-making, many
        organizations struggle with logistical challenges and fail to reach their potential in terms of using data to
        generate value.
      </p>

      <PostImage postName={POSTNAME} />
      <Contents contents={contents} />

      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <p>
        <a target="_blank" rel="noreferrer" href="https://online.hbs.edu/blog/post/data-driven-decision-making">
          Data-driven decision-making
        </a>{" "}
        has increasingly defined organizational success over the last decades:
      </p>
      <ul>
        <li>
          MIT Sloan's{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://sloanreview.mit.edu/article/big-data-analytics-and-the-path-from-insights-to-value/"
          >
            2010 survey
          </a>{" "}
          of over 3,000 executives found that "
          <strong>top-performing organizations use analytics five times more than lower performers</strong>"
        </li>
        <li>
          A 2011{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://deliverypdf.ssrn.com/delivery.php?ID=100104064008117096066127091029083126060049030043069035029003104010111027119005089097056102018016038120029023078069015111086095105084013078067065071077100072085089087002059024110101029003099007024086103088069104114095073122089068010026069094070103082017&amp;EXT=pdf&amp;INDEX=TRUE"
          >
            study
          </a>{" "}
          surveyed 330 firms about their business practices and found that data-driven decision-making was correlated
          with 5-6% increased output and productivity relative to firms not using data-driven decision-making
        </li>
        <li>
          The World Economic Forum (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.weforum.org/agenda/2018/10/the-8-major-forces-shaping-the-future-of-the-global-economy/"
          >
            WEF
          </a>
          ) highlighted the newfound dominance of data-driven tech companies amongst the top five publicly traded
          companies between 2013-2018
        </li>
        <li>
          Management consulting firm{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/mckinsey%20analytics/our%20insights/achieving%20business%20impact%20with%20data/achieving-business-impact-with-data_final.ashx"
          >
            McKinsey &amp; Company
          </a>{" "}
          observed in 2018 that their less data-driven clients had a weaker understanding of their business
          opportunities and generated less "insight-based value" from their data than their more data-driven clients
        </li>
        <li>
          A 2020{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.researchgate.net/profile/Demetris-Vrontis/publication/348176781_Does_data-driven_culture_impact_innovation_and_performance_of_a_firm_An_empirical_examination/links/607837d0881fa114b4033d4d/Does-data-driven-culture-impact-innovation-and-performance-of-a-firm-An-empirical-examination.pdf"
          >
            study
          </a>{" "}
          exploring the relationship between data use and innovation proposed that "
          <strong>the secret of a firm's success lies in how it can scientifically analyze customer data</strong>"
        </li>
      </ul>
      <p>
        Of course, becoming data-driven is easier said than done. The{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.wavestone.com/app/uploads/2023/10/WAVESTONE-Data-Transformation-Becoming-a-Data-driven-Organization.pdf"
        >
          2023 Data and Analytics Leadership Executive Survey
        </a>{" "}
        from consulting firm{" "}
        <a target="_blank" rel="noreferrer" href="https://www.wavestone.com/en/">
          Wavestone
        </a>{" "}
        asked executives from 116 Fortune 1000 companies about their business practices. The results highlight how "
        <strong>becoming data-driven is a long and difficult journey</strong>" primarily due to people and process
        issues such as: leadership receptivity to new ideas, the alignment of team skills with business objectives, and
        communications.
      </p>

      <h2 id={contents[1].anchor}>{contents[1].text}</h2>
      <p>
        Two examples of high performing organizations that excel at making data-driven decisions are Netflix and Google.{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://netflixtechblog.com/analytics-at-netflix-who-we-are-and-what-we-do-7d9c08fe6965"
        >
          Data Analysts
        </a>{" "}
        at Netflix approach data as a tool "to inform a wide range of questions" related to the priorities of their
        department. For each of Netflix's six verticals, the Data Analytics team is "given{" "}
        <strong>freedom to choose their projects</strong> and [is] <strong>responsible for prioritizing</strong> the
        ones that will have the most business impact" in an effort to "<strong>leverage their unique skills</strong> to
        make Netflix better."
      </p>
      <p>
        Meanwhile,{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.mckinsey.com/industries/technology-media-and-telecommunications/our-insights/learning-from-googles-digital-culture"
        >
          Google
        </a>{" "}
        annually collects data from all employees to understand "what a great culture would include:{" "}
        <strong>innovation and autonomy, forward thinking, teamwork</strong>." Programs are then re-designed later in
        the year to address all areas in which feedback was poor. Claims are also expected to have "data to back [them]
        up," epitomized by Google's{" "}
        <a target="_blank" rel="noreferrer" href="https://hbr.org/2013/12/how-google-sold-its-engineers-on-management">
          Project Oxygen
        </a>{" "}
        which involved research into the efficacy of managers at Google after engineers shared skepticism about their
        managers adding value to the company.
      </p>
      <p>
        Netflix and Google can prioritize empowering their staff with high levels of autonomy to maximize data-driven
        decision-making because they've largely solved the basic people and process barriers highlighted in{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.wavestone.com/app/uploads/2023/10/WAVESTONE-Data-Transformation-Becoming-a-Data-driven-Organization.pdf"
        >
          Wavestone's report
        </a>
        . For the majority of organizations, one activity that could be beneficial is to undertake an{" "}
        <strong>organization-wide program to enhance data literacy</strong>.
      </p>
      <p>
        Gartner's 2022{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.gartner.com/en/newsroom/press-releases/2022-04-20-gartner-survey-finds-successful-cdos-link-data-and-analytics-to-prioritized-and-measured-business-outcomes"
        >
          Chief Data Officer Survey
        </a>{" "}
        highlighted poor data literacy as being amongst the top challenges for CDOs, prompting Gartner to create a
        simple and practical{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.gartner.com/en/newsroom/press-releases/2022-04-20-gartner-survey-finds-successful-cdos-link-data-and-analytics-to-prioritized-and-measured-business-outcomes"
        >
          guide to data literacy
        </a>
        :
      </p>
      <ol>
        <li>
          <strong>Baseline assessment</strong> — establish where the gaps are in data literacy such as an understanding
          of basic statistics and what outputs key systems produce
        </li>
        <li>
          <strong>Training program</strong> — find staff already confident with data, identify communication barriers
          stopping data-driven decision-making, and trial a proof-of-concept workshop;{" "}
          <a target="_blank" rel="noreferrer" href="https://www.datacamp.com/courses/introduction-to-data-literacy">
            online courses
          </a>{" "}
          and other existing guidance can also be referenced
        </li>
        <li>
          <strong>Leadership role modeling</strong> — data-driven decision-making should permeate through business
          meetings and be visible to all staff
        </li>
        <li>
          <strong>Measure initiative effectiveness</strong> — measure program outcomes against quantitative success
          criteria such as the proportion of staff who've passed a certification, collect staff feedback, and support
          staff to immediately start applying what they've learned
        </li>
      </ol>
      <p>
        Fostering data affinity will likely be on the list of projects the Data Management function should undertake to
        encourage data-driven decision-making. Organizations must also surmount the common people and process issues
        that hinder data-driven decision-making. To start on this journey,{" "}
        <a href="https://online.hbs.edu/blog/post/data-driven-decision-making" target="_blank" rel="noreferrer">
          Harvard Business Review
        </a>{" "}
        suggest that data practitioners "
        <strong>start small, benchmarking your performance, documenting everything, and adjusting as you go</strong>
        ." You may be interested in reading Asana's{" "}
        <a href="https://asana.com/resources/data-driven-decision-making" target="_blank" rel="noreferrer">
          step-by-step advice
        </a>{" "}
        on how to make more data-driven decisions.
      </p>

      <h2 id={contents[2].anchor}>{contents[2].text}</h2>
      <p>
        As we started discussing in our mid-March Newsletter, a data-driven culture is essential to enabling{" "}
        <a target="_blank" rel="noreferrer" href="https://online.hbs.edu/blog/post/data-driven-decision-making">
          data-driven decision-making
        </a>
        . While there are different definitions for a data-driven culture (as discussed in these sources:{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.mckinsey.com/capabilities/quantumblack/our-insights/why-data-culture-matters"
        >
          1
        </a>
        ,{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.forbes.com/sites/brentdykes/2019/03/28/the-four-key-pillars-to-fostering-a-data-driven-culture/?sh=3f5330b47d90"
        >
          2
        </a>
        ,{" "}
        <a target="_blank" rel="noreferrer" href="https://cloud.google.com/resources/building-a-data-driven-culture">
          3
        </a>
        ), we broadly define it as an{" "}
        <strong>active organization-wide coordination of data practices to support data-driven decision-making</strong>.
      </p>
      <p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.mckinsey.com/industries/technology-media-and-telecommunications/our-insights/learning-from-googles-digital-culture"
        >
          Google
        </a>{" "}
        has invested heavily into developing a data-driven culture. Their annual{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://qz.com/97731/inside-googles-culture-of-relentless-self-surveying"
        >
          Googlegeist survey
        </a>{" "}
        collects data from all employees on "what a great culture would include:{" "}
        <strong>innovation and autonomy, forward thinking, teamwork</strong>". Programs are then re-designed to address
        all areas in which feedback was poor. Claims made by Google employees are also expected to have "data to back
        [them] up". Google's{" "}
        <a target="_blank" rel="noreferrer" href="https://hbr.org/2013/12/how-google-sold-its-engineers-on-management">
          Project Oxygen
        </a>{" "}
        empirically researched the impact of managers at Google after engineers questioned the value of managers.
        Results showed that<strong> </strong>more highly rated managers{" "}
        <a target="_blank" rel="noreferrer" href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3470734">
          increased happiness
        </a>{" "}
        and{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.researchgate.net/publication/323741744_The_impact_of_employee_turnover_and_turnover_volatility_on_labor_productivity_a_flexible_non-linear_approach"
        >
          reduced turnover
        </a>{" "}
        amongst their teams, and helped to identify the characteristics of high performing managers to inform training
        for lower performing managers.
      </p>
      <p>
        But not all organizations are in a position yet to build data into the "DNA of [their] culture" like Google has
        done. Only 24% of consulting firm{" "}
        <a target="_blank" rel="noreferrer" href="https://www.wavestone.com/en/">
          Wavestone's
        </a>{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.wavestone.com/app/uploads/2023/10/WAVESTONE-Data-Transformation-Becoming-a-Data-driven-Organization.pdf"
        >
          2023 Data and Analytics Leadership Executive Survey
        </a>{" "}
        respondents saw themselves as data-driven while only 21% believe they've developed a data culture. Amongst the
        key conclusions drawn was that "data transformation and becoming data-driven are about
        <strong> helping everyone use data</strong>." One of the key steps towards everyone using data is high
        organization-wide data literacy.
      </p>
      <p>
        Gartner's 2022{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.gartner.com/en/newsroom/press-releases/2022-04-20-gartner-survey-finds-successful-cdos-link-data-and-analytics-to-prioritized-and-measured-business-outcomes"
        >
          Chief Data Officer Survey
        </a>{" "}
        highlighted poor data literacy as being amongst the top challenges for CDOs, prompting Gartner to create a
        practical{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.gartner.com/en/newsroom/press-releases/2022-04-20-gartner-survey-finds-successful-cdos-link-data-and-analytics-to-prioritized-and-measured-business-outcomes"
        >
          guide to data literacy
        </a>
        :
      </p>
      <ol>
        <li>
          <strong>Baseline assessment</strong> — establish where the gaps are in data literacy such as an understanding
          of basic statistics and what outputs key systems produce
        </li>
        <li>
          <strong>Training program</strong> — find staff already confident with data, identify communication barriers
          stopping data-driven decision-making, and trial a proof-of-concept workshop;{" "}
          <a target="_blank" rel="noreferrer" href="https://www.datacamp.com/courses/introduction-to-data-literacy">
            online courses
          </a>{" "}
          and other existing guidance can also be used
        </li>
        <li>
          <strong>Leadership role modeling</strong> — data-driven decision-making should permeate through business
          meetings and be visible to all staff
        </li>
        <li>
          <strong>Measure initiative effectiveness</strong> — measure program outcomes against quantitative success
          criteria such as the proportion of staff who've passed a certification, collect staff feedback, and support
          staff to immediately start applying what they've learned
        </li>
      </ol>
      <p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.mckinsey.com/capabilities/quantumblack/our-insights/why-data-culture-matters"
        >
          McKinsey &amp; Company
        </a>{" "}
        put it well:
        <blockquote>
          "Culture can be a compounding problem or a compounding solution... The technology [of data analytics], after
          all, is amazing. Imagine how far it can go with a culture to match."
        </blockquote>
        You may be interested in McKinsey &amp; Company's{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.mckinsey.com/capabilities/quantumblack/our-insights/why-data-culture-matters"
        >
          Why Data Culture Matters
        </a>{" "}
        for insights from leading cross-industry executives.
      </p>

      <h2 id={contents[3].anchor}>{contents[3].text}</h2>
      <p>
        An organization that is more committed to data-driven decision-making will generate significantly more value
        than a comparable organization that uses data less effectively. Learning from high performing data-driven
        organizations can be useful for finding evidence-based practices to adopt or improve within one's own
        organization.
      </p>
      <p>
        Becoming data-driven first requires an organization to assess the current state of its data practices through a
        Data Management Maturity Assessment (
        <Link to="/resources/data-management-maturity-assessment-dmma" target="_blank">
          DMMA
        </Link>
        ). An assessment will allow your organization to identify technical, people, and process issues that can be
        addressed.
      </p>
      <p>
        For more help with Data Strategy best practices, we recommend checking out our{" "}
        <Link to="/resources/data-strategy-best-practices" target="_blank">
          article on this topic
        </Link>
        , as well as our{" "}
        <Link to="/products/data-strategy-workbook" target="_blank">
          Data Strategy Workbook
        </Link>{" "}
        and{" "}
        <Link to="/products/document-checklist" target="_blank">
          Document Checklist
        </Link>
        .
      </p>
    </>
  );
};
