import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { CheckoutContext } from "../../contexts/CheckoutContext";

import { handleFreeTrialClick } from "../../helpers/checkoutUtils";

export default function FreeTrial({ product }) {
  const desktop = useMediaQuery("(min-width: 1200px)");

  const history = useHistory();
  const checkoutContext = useContext(CheckoutContext) as checkoutContext;
  const { setStep, setConfirmationTable } = checkoutContext.checkout;

  return (
    <div className="gray-box" style={{ padding: "30px 40px 32px 40px" }}>
      <div className="center-text-box">
        <h4 className="box-title" style={{ textAlign: "center", maxWidth: 1400 }}>
          Want to Check it out First?
        </h4>
        <div
          style={{
            display: "flex",
            flexDirection: desktop ? "row" : "column",
            justifyContent: "space-between",
            marginBottom: "1em",
            alignItems: "center",
          }}
        >
          <div>
            <p>
              Get the <strong>first seven days</strong> of the {product} delivered straight to your inbox for free.
            </p>
            <p>
              This is a great way to <strong>experience the course</strong> and decide if it's a good fit for you.
            </p>
          </div>

          <button
            className="btn btn-accent"
            style={{
              width: 200,
              height: 37,
              padding: "10px 20px",
              margin: "4px 0",
              marginTop: !desktop ? 10 : 0,
            }}
            onClick={() => {
              const paymentName = product.toLowerCase().replace(/\//g, "").replace(/ /g, "-");
              handleFreeTrialClick(paymentName, history, setConfirmationTable, setStep);
            }}
          >
            Free Trial
          </button>
        </div>
      </div>
    </div>
  );
}
