import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import fb from "../../img/icons/color-facebook-128.webp";
import li from "../../img/icons/color-linkedin-128.webp";
import med from "../../img/icons/color-medium-128.webp";
import twit from "../../img/icons/color-twitter-128.webp";
import yt from "../../img/icons/color-youtube-128.webp";
import gh from "../../img/icons/color-github-icon.webp";
import em from "../../img/icons/color-email-128.webp";

import FooterNewsletterSignUp from "./FooterNewsletterSignUp.js";

export default function Footer() {
  const desktop = useMediaQuery("(min-width: 960px)");

  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className="footer-container">
      <div
        style={{
          maxWidth: 2000,
          width: "100%",
          display: "flex",
          justifyContent: desktop ? "space-between" : "center",
        }}
      >
        <div
          style={{
            display: "flex",
            marginLeft: desktop ? 40 : 0,
            flexDirection: "column",
            alignItems: desktop ? "flex-start" : "center",
          }}
        >
          <p>© 2021 - {year} Data Strategy Professionals</p>
          <div style={{ justifyContent: "center" }}>
            <a href="https://www.facebook.com/DataStrategyPros" target="_blank" rel="noreferrer noopener">
              <img src={fb} alt="facebook" />
            </a>
            <a href="https://twitter.com/Nicole_Janeway" target="_blank" rel="noreferrer noopener">
              <img src={twit} alt="twitter" />
            </a>
            <a href="https://github.com/DataStrategyPros" target="_blank" rel="noreferrer noopener">
              <img src={gh} alt="github" />
            </a>
            <a href="https://nicolejaneway.medium.com/" target="_blank" rel="noreferrer noopener">
              <img src={med} alt="medium" />
            </a>
            <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer noopener">
              <img src={li} alt="linkedin" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCO6JE24WY82TKabcGI8mA0Q"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={yt} alt="youtube" />
            </a>
            <a href="http://eepurl.com/hhFVsX" target="_blank" rel="noreferrer noopener">
              <img src={em} alt="email" />
            </a>
          </div>
          <div className="tandc" style={{ display: "flex" }}>
            <Link to="/terms-and-conditions">
              <p style={{ paddingRight: "20px", width: 150, textAlign: "right" }}>Terms and Conditions</p>
            </Link>
            <p style={{ paddingRight: "20px" }}> | </p>
            <Link to="/code-of-conduct">
              <p style={{ color: "inherit", width: 100, textAlign: "left" }}>Code of Conduct</p>
            </Link>
          </div>
        </div>
        {desktop && <FooterNewsletterSignUp />}
      </div>
    </div>
  );
}
