import React, { useContext } from "react";
import { CheckoutContext } from "../../../contexts/CheckoutContext";
import { formatCurrency } from "../../../helpers/utils";

const ListPrice: React.VFC = () => {
  const checkoutContext = useContext(CheckoutContext);
  const { displayPrice } = checkoutContext.price;

  return (
    <>
      {displayPrice && (
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <p style={{ fontSize: ".9em" }}>Price</p>
          <p style={{ fontSize: "1.5em" }} data-testid="listPrice">
            {formatCurrency(displayPrice)}
          </p>
        </div>
      )}
    </>
  );
};

export default ListPrice;
