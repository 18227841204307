import { Link } from "react-router-dom";
import Post from "../components/blog/Post";

import cheers from "../img/products/membership/cheers_rec.webp";
import boardroom from "../img/blog/boardroom.webp";
import groupAtTable from "../img/blog/group-at-table.webp";

const POSTNAME = "steward";

const Steward: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default Steward;

const Rest = () => {
  return (
    <>
      <p>
        Set your Data Management team up for success by selecting the proper job title that conveys their
        responsibilities and importance. While organizations differ in the functions they assign to each role, the three
        job titles of <a href="#owner">Data Owner</a>, <a href="#steward">Data Steward</a>, and{" "}
        <a href="#trustee">Data Trustee</a> have a distinct history and associated connotations.
      </p>
      <figure>
        <img src={cheers} alt="group of people giving a cheer" />
        <figcaption>
          Photo by{" "}
          <a href="https://www.pexels.com/@fauxels/" target="_blank" rel="noreferrer">
            fauxels
          </a>{" "}
          on{" "}
          <a
            href="https://www.pexels.com/photo/photo-of-people-holding-each-other-s-hands-3184433/"
            target="_blank"
            rel="noreferrer"
          >
            Pexels
          </a>
        </figcaption>
      </figure>
      <h2 id="owner">Data Owner</h2>
      <p>
        A Data Owner has primary accountability and responsibility for a specific data asset. This job title conveys a
        clear and simple mandate: <em>own the data</em> — got it.
      </p>
      <p>
        However, many organizations are moving away from the role of Data Owner because of its association with a less
        than satisfactory level of{" "}
        <Link to="data-management-maturity-assessment-dmma" target="_blank">
          Data Management maturity
        </Link>
        .
      </p>
      <p>
        At an organization with low data maturity, Data Governance takes place, though it has not yet been formalized.
        At this stage, rather than providing clear roles and responsibilities, an organization will end up with data
        owners by virtue of necessity. The unofficial role of data owner evolves in order to get data-related work done.
      </p>

      <div className="teal-box blog" style={{ maxWidth: 1000 }}>
        <p>
          "The appropriate use of Data Owner as a term came up during a data maturity workshop I ran today. The
          consensus from the group was perhaps it wasn't the best language to use for this particular organization for a
          variety of reasons. What was positive to see though was it generated a conversation and suggestions on how to
          name roles were starting to come from the business — which should help with adoption and staff engagement in
          the future with whatever is chosen 🤞"
        </p>
        <p style={{ textAlign: "right", marginBottom: 0 }}>
          – <strong>Craig March</strong>,
          <br />
          Senior Data Business Partner
        </p>
      </div>

      <p>
        Moreover, the job title Data Owner is problematic because it seems to imply there should be one single Data
        Owner per data asset. Given the highly collaborative nature of the modern data team, this is not always a
        realistic or useful implication. After all, data is owned by the organization, not a specific individual.
      </p>

      <div className="teal-box blog" style={{ maxWidth: 1000 }}>
        <p>
          “The idea that we can say that data can be owned by one person is a classic example of how broken things can
          be. That idea of Data Ownership being a necessary dependency for Data Management and Data Governance
          success... is indicative of some of the problems we've got in this space... Data ownership is not working and
          it was never able to work for any data that was shared outside of one system.”
        </p>
        <p style={{ textAlign: "right", marginBottom: 0 }}>
          – <strong>Malcolm Hawker</strong>,<br />
          Head of Data Strategy at Profisee
          <br />
          via{" "}
          <a
            href="https://profisee.com/podcast/cdo-matters-ep-40-the-top-data-trends-of-2023/"
            target="_blank"
            rel="noreferrer"
          >
            CDO Matters podcast
          </a>
        </p>
      </div>

      <p>
        Not everyone holds the viewpoint that Data Owner is a job title to avoid. In the words of Data & Performance
        Management Consultant Samantha Magnus, "'Owner' to me applies accountability which is important. Just like in
        the OKR framework, there are owners for the objectives and key results."
      </p>

      <div className="teal-box blog" style={{ maxWidth: 1000 }}>
        <p>
          "I think the alternative view stems mostly from a place of accountability. In the corporate environment I work
          in, I find that culturally, we're accustomed to the concept that ownership is singular, or at best held within
          a single group. While that concept is certainly merited, it's often not conducive for [working with] data...
          It seems to overly emphasize who's the problem, instead of what's the problem and how we can collectively
          solve for it. Data is simply too pervasive for singular ownership most times!"
        </p>
        <p style={{ textAlign: "right", marginBottom: 0 }}>
          – <strong>Alvin Ng</strong>,<br />
          Data Audit Professional
        </p>
      </div>

      <p>
        Given the baggage associated with this term, you may instead consider the job titles{" "}
        <a href="#steward">Data Steward</a> or <a href="#trustee">Data Trustee</a> in order to avoid the negative
        connotations associated with Data Owner.
      </p>

      <figure>
        <img src={boardroom} alt="group of people in a boardroom" />
        <figcaption>
          Photo by{" "}
          <a href="https://www.pexels.com/@divinetechygirl/" target="_blank" rel="noreferrer">
            Christina Morillo
          </a>{" "}
          on{" "}
          <a
            href="https://www.pexels.com/photo/group-of-people-on-a-conference-room-1181406/"
            target="_blank"
            rel="noreferrer"
          >
            Pexels
          </a>
        </figcaption>
      </figure>
      <h2 id="steward">Data Steward</h2>
      <p>
        A Data Steward is responsible for carrying out Data Management policies and procedures. The role is focused on
        the day-to-day maintenance of data assets.
      </p>
      <p>
        The term Data Stewardship arose in the 2000s to encompass responsibility for implementing Data Governance and
        Metadata Management. Similar to the origin story of the <a href="#owner">Data Owner</a> role, the Data Steward
        role is often initially an informal one. Data Stewards are often identified by the organization as possessing
        the capability and maturity to act as a steward of the data assets within their business unit or data domain.
      </p>
      <p>
        Data Steward is an effective replacement of the old <a href="#owner">Data Owner</a> role. However, this title
        lacks some of the gravitas connoted by “owner.” Where a stronger sense of authority is required to execute the
        job, the title <a href="#trustee">Data Trustee</a> may be considered instead.
      </p>

      <div className="teal-box blog" style={{ maxWidth: 1000 }}>
        <p>
          "IMHO... Calling the person a 'Data Owner' implies exactly the opposite of what it means to steward data and
          conflicts with what most organizations are trying to accomplish. There are different levels of stewards —
          operational (i.e., within a business function), tactical (i.e., across business functions), and even strategic
          (<em>sometimes</em>).
        </p>{" "}
        <p>
          "I agree that there has to be formal accountability at all levels but by calling people 'owners,' it implies
          that the data is theirs (they <em>own</em> it) rather than the true fact that they are taking care of it for
          somebody else (the definition of stewardship) — the organization."
        </p>
        <p style={{ textAlign: "right", marginBottom: 0 }}>
          – <strong>Robert Seiner</strong>,
          <br />
          author of{" "}
          <a href="https://amzn.to/4adQ4Lv" target="_blank" rel="noreferrer">
            <em>Non-Invasive Data Governance</em>
          </a>
        </p>
      </div>

      <div className="teal-box blog" style={{ maxWidth: 1000 }}>
        <p>
          "I prefer the term 'Data Guardian.' I think the term 'Steward' seems like a minor role (in the traditional
          sense of the word). I've seen others use 'Curator,' which is better, but seems to imply that data is old in a
          museum. Since I've been focusing on Data Privacy, Security, and Compliance, I'm thinking that 'Guardian' is
          better. Not policing, but guarding data."
        </p>
        <p style={{ textAlign: "right", marginBottom: 0 }}>
          – <strong>Karen Lopez</strong>,<br />
          Data Management Expert
        </p>
      </div>

      <h2 id="trustee">Data Trustee</h2>
      <p>
        A Data Trustee holds the ultimate responsibility for one or more data assets. This role harkens back to the
        ultimate “buck-stops-here” authority of having dedicated Data Owners, without the troublesome connotations
        discussed above.
      </p>

      <figure>
        <img src={groupAtTable} alt="group reviewing a document" />
        <figcaption>
          Photo by{" "}
          <a href="https://www.shutterstock.com/g/LightField+Studios" target="_blank" rel="noreferrer">
            LightField Studios
          </a>{" "}
          on{" "}
          <a
            href="https://www.shutterstock.com/image-photo/selective-focus-businessmen-looking-documents-near-1552665332"
            target="_blank"
            rel="noreferrer"
          >
            ShutterStock
          </a>
        </figcaption>
      </figure>

      <p>
        Depending on the organization, the job Data Trustee may be given additional responsibilities, such as ensuring
        adherence to certain policies or upholding standards related to Data Governance and/or Data Privacy.
      </p>
      <p>
        Ultimately, the Data Trustee is responsible for any risks of non-compliance with Data Management standards
        related to their portfolio. They would be the one to initiate remediation steps in the event of a breach of
        policy within their domain.
      </p>

      <div className="teal-box blog" style={{ maxWidth: 1000 }}>
        <p>
          "The debate between 'Data Owner' and 'Data Steward' reflects the evolving nature of data management. I see
          that effective data governance is more about fostering accountability and collaboration than defining rigid
          ownership structures.
        </p>

        <p>
          "Ultimately, the right title depends on your organizational context and data maturity. But from my
          perspective, 'Data Steward' is a better fit for highlighting that the role is not about ownership but about
          responsibility, collaboration, and trust in managing data for the benefit of all."
        </p>
        <p style={{ textAlign: "right", marginBottom: 0 }}>
          – <strong>Alan Russell</strong>,<br />
          Director Analytics & Data Governance
        </p>
      </div>

      <h2 id="conclusion">Conclusion</h2>
      <p>
        If you're responsible for setting up an operating framework for Data Management, you can make up whatever job
        titles you like. Don't be afraid to get creative to suit the culture of your organization. For example, Data
        Management expert Karen Lopez prefers the term "Data Guardian" to "Data Steward."
      </p>
      <p>
        When it comes time to attract talent, you will have to reckon with the perceptions of the potential candidates,
        which will be highly influenced by role title. Making the right choice can help convey the responsibility and
        importance of the role. For this reason, we recommend creating the position of{" "}
        <a href="#steward">Data Steward</a> and, if appropriate, <a href="#trustee">Data Trustee</a>, while avoiding the
        title <a href="#owner">Data Owner</a>.
      </p>
    </>
  );
};
