import CheckAnimation from "./CheckAnimation";
import SourceFormContainer from "./SourceFormContainer";

const ThanksAnimation: React.VFC<SourceFormProps> = ({ firstName, email }) => {
  return (
    <>
      <CheckAnimation />
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: 40 }}>
        <h1 style={{ textAlign: "center" }}>Thank you!</h1>
        <p style={{ textAlign: "center" }}>We'll be in touch 😊</p>
      </div>
      <SourceFormContainer firstName={firstName} email={email} />
    </>
  );
};

export default ThanksAnimation;
