import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../../components/product-pages/MultiPriceProductPage";

import CustomModal from "../../../components/CustomModal";
import BuyNowButton from "../../../components/product-pages/BuyNowButton";
import VisualDescription from "../../../components/product-pages/VisualDescription";
import ExtendedDescriptionContainer from "../../../components/product-pages/ExtendedDescriptionContainer";
import ProductTestimonials from "../../../components/product-testimonials/ProductTestimonials";
import DSEVideoSummaryCarousel from "../../../components/product-pages/DSEVideoSummaryCarousel";
import AdditionalWays from "../../../components/product-pages/AdditionalWays";
import DSEFreeTrial from "./DSEFreeTrial";
import DSESyllabus from "./DSESyllabus";

import testimonials from "../../../data/testimonials";

const PRODUCT = "Data Management Master Class";

export default function DSECourse() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const [showPDF, setShowPDF] = useState(false);

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  function handleShowPDF() {
    setShowPDF(!showPDF);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      {showPDF && <DSESyllabus handleShowClick={handleShowPDF} />}

      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        description={Description(handleShowPDF)}
        tealBox={tealBox}
        extendedDescription={extendedDescription()}
        rest={rest(handleShowPDF)}
      />
    </>
  );
}

function Syllabus({ handleShowPDF }) {
  return (
    <p>
      <span className="fake-link" onClick={handleShowPDF}>
        Click to view syllabus
      </span>
    </p>
  );
}

const Description = (handleShowPDF) => {
  return (
    <div className="gray-box" id="product-description">
      <h4>Description</h4>
      <VisualDescription product={PRODUCT} />
      <Syllabus handleShowPDF={handleShowPDF} />
    </div>
  );
};

const tealBox = <AdditionalWays />;

const extendedDescription = () => {
  const items = [
    {
      title: "Learn More",
      description: (
        <div className="extended-description">
          <p>
            Advance your understanding of <strong>Data Management</strong> with our comprehensive course. Through
            thoughtfully crafted <strong>video lessons</strong>, you'll learn how to navigate{" "}
            <strong>19 Data Management</strong> topics.
          </p>
          <p>
            Each of the <strong>230+ lessons</strong> in this <strong>self-paced course</strong> includes video and text
            content to help you fully comprehend each concept. The chapters wrap up with{" "}
            <strong>additional resources</strong>, <strong>case studies</strong>, and{" "}
            <strong>practice questions</strong>.
          </p>

          <p style={{ marginBottom: 0 }}>Key features:</p>
          <ul>
            <li>230 video lessons across 19 chapters, each covering a crucial aspect of Data Management</li>
            <li>Links to additional resources to further your knowledge</li>
            <li>Practice questions to challenge your knowledge of each chapter</li>
          </ul>
        </div>
      ),
    },
    {
      title: "Frequently Asked Questions",
      description: (
        <div className="extended-description faq">
          <p>
            <strong>Who is this course for?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            This course is for anyone who wants to learn more about Data Management. It is suitable for beginners and
            advanced practitioners alike. The thorough curriculum will make you a more effective data practitioner with
            broad knowledge spanning the data lifecycle. Each chapter will contribute to your mastery of essential Data
            Management principles. At the conclusion of the course, you will be adept at implementing Data Management
            best practices.
          </p>
          <p>
            <strong>What is included in the course?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Each chapter includes video and text content to help you fully comprehend each topic. The chapters wrap up
            with <strong>additional resources</strong>, <strong>case studies</strong>, and{" "}
            <strong>practice questions</strong>.
          </p>

          <p>
            <strong>How long will it take to complete the course?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>The course is self-paced and can be completed in about 10 hours.</p>

          <p>
            <strong>Is this course suitable preparation for the CDMP exam?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Yes, the course aims to cover all of the material tested on the{" "}
            <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
              CDMP Fundamentals Exam
            </Link>
            . However, the course goes beyond the CDMP to represent modern best practices. In order to prepare
            effectively for the CDMP Fundamentals Exam, we recommend pairing this course with the{" "}
            <Link to="/products/cdmp-fundamentals-bundle" target="_blank">
              CDMP Fundamentals Bundle
            </Link>
            .
          </p>
        </div>
      ),
    },
  ];
  return <ExtendedDescriptionContainer items={items} />;
};

const rest = (handleShowPDF) => {
  const testimonialsToShow = testimonials.filter((testimonial) =>
    testimonial.id.includes("data-management-master-class")
  );

  return (
    <>
      <DSEFreeTrial />
      <div className="white-box">
        <DSEVideoSummaryCarousel crashCourse={false} />
        <div className="center-text-box">
          <div className="three-col contents">
            <ol start={0}>
              <li>Introduction & Welcome</li>
              <li>Data Management</li>
              <li>Data Management Maturity Assessment</li>
              <li>Data Valuation</li>
              <li>Data Governance</li>
              <li>Data Architecture</li>
              <li>Data Modeling</li>
              <li>Data Quality</li>
              <li>Metadata Management</li>
              <li>Data Storage & Operations</li>
              <li>Cloud Governance</li>
              <li>Data Security</li>
              <li>Data Integration & Interoperability</li>
              <li>Master & Reference Data</li>
              <li>Document & Content Management</li>
              <li>Data Warehousing & Business Intelligence</li>
              <li>Advanced Analytics & Data Science</li>
              <li>Data Ethics</li>
              <li>Data Privacy</li>
              <li>Change Management</li>
              <li>Wrap Up & Next Steps</li>
            </ol>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Syllabus handleShowPDF={handleShowPDF} />
          </div>
        </div>
      </div>

      <ProductTestimonials testimonialsToShow={testimonialsToShow} />
      <BuyNowButton paymentName={"data-management-master-class"} freeTrial />
    </>
  );
};
