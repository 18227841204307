import { Link } from "react-router-dom";

import Post from "../components/blog/Post";
import Contents from "../components/blog/Contents";
import BestCertifications from "../components/blog/BestCertifications";
import CustomModal from "../components/CustomModal";

import { useModal } from "../helpers/utils";

const POSTNAME = "analyst";

const DataAnalyst: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default DataAnalyst;

const Rest = () => {
  const { showModal, image, alt, handleShowClick } = useModal();

  const contents: Content[] = [
    { anchor: "data-analyst", text: "Data Analyst" },
    { anchor: "data-scientist", text: "Data Scientist" },
    { anchor: "machine-learning-engineer", text: "Machine Learning Engineer" },
    { anchor: "ml-ops", text: "ML Ops" },
    { anchor: "conclusion", text: "Conclusion" },
  ];

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <figure>
        <img src="https://cdn-images-1.medium.com/max/1600/1*JczrH-rvHY7Ce5wm60M8Gw.webp" alt="runners" />
        <figcaption>
          Photo by{" "}
          <a href="https://www.pexels.com/@olly" target="_blank" rel="noreferrer">
            Andrea Piacquadio
          </a>{" "}
          on{" "}
          <a
            href="https://www.pexels.com/photo/strong-sportsmen-ready-for-running-on-stadium-3764011/"
            target="_blank"
            rel="noreferrer"
          >
            Pexels
          </a>
        </figcaption>
      </figure>
      <p>
        This article uses the metaphor of a track team to differentiate between the role of a{" "}
        <a href="#data-analyst">Data Analyst</a>, <a href="#data-scientist">Data Scientist</a>, and{" "}
        <a href="#machine-learning-engineer">Machine Learning Engineer</a>. We'll start with the idea that conducting a
        Data Science project is similar to running a relay race. Hopefully, this analogy will help you make more
        informed choices around your education, job applications, and project staffing.
      </p>
      <p>
        If you're interested in pursuing a career in one of these areas, we strongly recommend checking out our report
        on the{" "}
        <Link to="/landing/best-certifications" target="_blank">
          best certifications
        </Link>{" "}
        spanning seven data-related domains. One of our favorites is the Certified Analytics Professional (
        <Link to="/resources/certified-analytics-professional" target="_blank">
          CAP
        </Link>
        ) certification, which is a great way for Data Analysts to advance their skills. (If you're just getting
        started, you can check out our CAP Study Groups on{" "}
        <a href="https://www.facebook.com/groups/certifiedanalyticsprofessionals/" target="_blank" rel="noreferrer">
          Facebook
        </a>{" "}
        and{" "}
        <a href="https://www.linkedin.com/groups/13026365/" target="_blank" rel="noreferrer">
          LinkedIn
        </a>{" "}
        and our{" "}
        <Link to="/products/#cap" target="_blank">
          study materials
        </Link>{" "}
        to help you prepare for the CAP or aCAP exam).
      </p>

      <Contents contents={contents} />

      <h2 id="data-analyst">🔵 Data Analyst</h2>
      <p>
        The Data Analyst is capable of taking data from the “starting line” (i.e., pulling data from storage), doing
        data cleaning and processing, and creating a final product like a dashboard or report. The Data Analyst may also
        be responsible for transforming data for use by a Data Scientist, a hand-off that we'll explore in a moment.
      </p>
      <figure>
        <img src="https://cdn-images-1.medium.com/max/1600/1*wrCzL_4ex52qdBKL9yGXYA.webp" alt="track" />
        <figcaption>The Data Analyst is capable of running half a lap</figcaption>
      </figure>
      <p>You might say that the Data Analyst is very capable of running the first part of the race, but no further.</p>

      <h2 id="data-scientist">🔴 Data Scientist</h2>
      <p>
        The Data Scientist has all the skills of the Data Analyst, though they might be less well-versed in dashboarding
        and perhaps a bit rusty at report writing. The Data Scientist can run further than the data analyst, though, in
        terms of their ability to apply statistical methodologies to create complex data products.
      </p>
      <figure>
        <img src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*MLGY6amEeNMXbEvL8GUHsA.png" alt="track" />
        <figcaption>The Data Scientist is capable of running the full lap…</figcaption>
      </figure>
      <p>
        The Data Scientist is capable of racing the entire lap. That means they have the skills required to query data,
        explore features to assess predictive power, select an appropriate crop of models for training and testing,
        conduct hyperparameter tuning, and ultimately arrive at a statistics-powered model that provides business value
        through classification or prediction. However, if an organization loads its Data Scientist with all these
        responsibilities — from data ingest through data modeling — the Data Scientist won't be able to run as well as
        if he or she were asked to run only the second part of the race, focused on the data modeling.
      </p>
      <figure>
        <img src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*SwRWf-BiB4EcegmzxeVN2A.png" alt="track" />
        <figcaption>
          …the Data Scientist will run faster if only tasked with running the second half of the relay
        </figcaption>
      </figure>

      <p>
        Overall, the team's performance will improve if a business analyst conducts the querying and data cleaning
        steps, allowing the Data Scientist to focus on statistical modeling.
      </p>
      <p>
        If you want to read more about Data Science, check out our article{" "}
        <Link to="/resources/data-science-for-leaders" target="_blank">
          An Introduction to Data Science for Technology Leaders
        </Link>
        .
      </p>

      <BestCertifications />

      <h2 id="machine-learning-engineer">🔶 Machine Learning Engineer</h2>
      <p>
        The Machine Learning Engineer could be thought of as the team's secret weapon. You might conceptualize the MLE
        as the person designing track shoes that empower the other runners to race at top speeds.
      </p>
      <figure>
        <img src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*F2EREhuqM6lpAYX7y6whfw.png" alt="track" />
        <figcaption>
          The Machine Learning Engineer is a versatile player, capable of developing advanced methodologies
        </figcaption>
      </figure>
      <p>
        The Machine Learning Engineer may also be focused on bringing state-of-the-art solutions to the Data Science
        team. For example, an MLE may be more focused on deep learning techniques compared to a Data Scientist's
        classical statistical approach.
      </p>

      <p>
        Increasingly, the distinction between these positions is blurring, as statistics becomes the domain of
        easy-to-implement packages in Python and R. Don't get me wrong — a fundamental understanding of statistical
        testing remains paramount in this career field. However, with growing frequency, the enterprise data scientist
        is asked to execute models powered by deep learning. This refers to the field of Data Science enabled by
        GPU-based computing, where typical models include neural networks like CNNs, RNNs, LSTMs, and transformers.
      </p>
      <p>
        Machine learning researchers at companies such as{" "}
        <a href="https://research.google/teams/brain/" target="_blank" rel="noreferrer">
          Google Brain
        </a>
        ,{" "}
        <a href="https://openai.com/" target="_blank" rel="noreferrer">
          OpenAI
        </a>
        , and{" "}
        <a href="https://deepmind.com/" target="_blank" rel="noreferrer">
          Deep Mind
        </a>{" "}
        design new algorithmic approaches to advance toward state-of-the-art performance on specific use cases and,
        ultimately, the goal of building artificial general intelligence.
      </p>
      <h2 id="ml-ops">🚌 ML Ops</h2>
      <p>
        Another job title related to Data Science is MLOps. This refers to the responsibility of productionizing a model
        —in other words, creating a version of the model that is accessible to end users. MLOps is focused on creating a
        robust pipeline from data ingest, through preprocessing, to model inference (i.e., use in the real world to make
        classifications or predictions). This role's responsibilities are closely related to those of the DevOps
        practitioner in software development.
      </p>
      <figure>
        <img src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*gWOmpfpyJ7PyGKc3c08hLA.png" alt="track" />
        <figcaption>MLOps is the bus driver, responsible for getting everyone to the track meet</figcaption>
      </figure>
      <h2 id="conclusion">Conclusion</h2>
      <p>
        In this post, we explored the job titles of Data Analyst, Data Scientist, and a few positions related to machine
        learning using the metaphor of a track team. The <a href="#data-analyst">Data Analyst</a> might start off the
        relay, before passing cleaned data to the <a href="#data-scientist">Data Scientist</a> for modeling. The{" "}
        <a href="#machine-learning-engineer">Machine Learning Engineer</a> is like an experienced coach, specialized in
        deep learning. Finally, the <a href="#ml-ops">MLOps practitioner</a> is like the bus driver responsible for
        getting the team to the track meet.
      </p>
      <p>
        To learn more about other job functions, check out our article,{" "}
        <Link to="/resources/data-steward" target="_blank">
          Data Owner vs. Data Steward vs. Data Trustee
        </Link>
        .
      </p>
    </>
  );
};
