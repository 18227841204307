import navbar from "../../../data/navbar";

import navStyles from "../navStyles";

const styles: { [key: string]: React.CSSProperties } = {
  navbarMenu: {
    position: "relative",
    textAlign: "right",
    fontSize: "16px",
    padding: "0.2rem 0.2rem",
    lineHeight: 0,
    backgroundColor: "white",
    cursor: "pointer",
    borderRadius: "0.25rem 0 0.25rem 0.25rem",
    zIndex: 1000,
    whiteSpace: "nowrap",
  },
};

const NavItemBurger = ({ hamburgerIsActive, setHamburgerIsActive, itemName }) => {
  const navItem = navbar.find((item) => item.name === itemName);

  return (
    <div
      // hides navbar if display is less than 1000px
      id="nav-item-burger"
      style={{
        position: "absolute",
        zIndex: 1000,
        width: navItem.width,
      }}
      onMouseLeave={() => setHamburgerIsActive(false)}
    >
      {hamburgerIsActive && navItem.items.length > 0 && (
        <div
          style={{
            ...styles.navbarMenu,
            width: navItem.width,
            right: navItem.width - 115,
            top: -4,
          }}
          className="box-shadow"
        >
          <>
            {navbar
              .find((item) => item.name === itemName)
              .items.map((item, idx) => {
                return (
                  <NavBurgerItem
                    hamburgerIsActive={hamburgerIsActive}
                    setHamburgerIsActive={setHamburgerIsActive}
                    key={idx}
                    item={item.name}
                    path={item.path}
                  />
                );
              })}
          </>
        </div>
      )}
    </div>
  );
};

type NavBurgerItemProps = {
  hamburgerIsActive: boolean;
  setHamburgerIsActive: (value: boolean) => void;
  item: string;
  path: string;
};

const NavBurgerItem: React.VFC<NavBurgerItemProps> = ({ hamburgerIsActive, setHamburgerIsActive, item, path }) => {
  return (
    <p
      className="resources-menu-item"
      onClick={() => {
        window.open(`https://www.datastrategypros.com${path}`, "_self");
        setHamburgerIsActive(false);
      }}
      style={navStyles.navbarItem}
    >
      {item}
    </p>
  );
};

export default NavItemBurger;
