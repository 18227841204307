import BasePerson from "./BasePerson";

import specialistPeople from "../../data/people/specialistPeople";

const SpecialistExamPerson: React.FC<{ personName: string }> = ({ personName }) => {
  const specialistPerson: SpecialistPerson = specialistPeople.find((person) => person.name === personName) ?? {
    name: "",
    exam: "",
    link: "",
  };

  const { exam, link } = specialistPerson;

  return <BasePerson personName={personName} link={link} lineTwo={exam} italics={false} />;
};

export default SpecialistExamPerson;
