import VisualDescription from "./VisualDescription";

export default function BundleTealBox({ PRODUCT }) {
  return (
    <>
      <div className="teal-box cdmp" id="product-testimonials">
        <h4>You'll receive:</h4>
        <VisualDescription product={PRODUCT} />
      </div>
    </>
  );
}
