import { useState, createContext } from "react";

const defaultCheckoutContext: checkoutContext = {
  taxes: {
    calculateShippingAndTaxes: false,
    setCalculateShippingAndTaxes: () => {},
    taxCalcDone: false,
    setTaxCalcDone: () => {},
    errorCalculatingShippingAndTaxes: false,
    setErrorCalculatingShippingAndTaxes: () => {},
  },
  discount: {
    discountCodeInput: null,
    setDiscountCodeInput: () => {},
    discountValue: null,
    setDiscountValue: () => {},
    discountAmount: null,
    setDiscountAmount: () => {},
    discountMessage: null,
    setDiscountMessage: () => {},
  },
  checkout: {
    step: 0,
    setStep: () => {},
    paymentIntentId: null,
    setPaymentIntentId: () => {},
    clientSecret: null,
    setClientSecret: () => {},
    confirmationTable: null,
    setConfirmationTable: () => {},
    finalPaymentArray: null,
    setFinalPaymentArray: () => {},
  },
  purchasesAndAddons: {
    pastPurchases: [],
    setPastPurchases: () => {},
    potentialAddOns: [],
    setPotentialAddOns: () => {},
  },
  price: {
    displayPrice: undefined,
    setDisplayPrice: () => {},
  },
};

const CheckoutContext = createContext<checkoutContext>(defaultCheckoutContext);

function CheckoutProvider(props) {
  const [step, setStep] = useState<number>(0);
  const [displayPrice, setDisplayPrice] = useState<number>();

  const [shippingAmt, setShippingAmt] = useState<number>();
  const [taxCalcDone, setTaxCalcDone] = useState<boolean>(true);
  const [calculateShippingAndTaxes, setCalculateShippingAndTaxes] = useState<boolean>(false);
  const [errorCalculatingShippingAndTaxes, setErrorCalculatingShippingAndTaxes] = useState<boolean>(false);

  const [discountCodeInput, setDiscountCodeInput] = useState<string | null>(null);
  const [discountValue, setDiscountValue] = useState<number | null>(null);
  const [discountAmount, setDiscountAmount] = useState<number | null>(null);
  const [discountMessage, setDiscountMessage] = useState<string | null>(null);

  const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const [pastPurchases, setPastPurchases] = useState<paymentName[]>(null);
  const [addOnsStatus, setAddOnsStatus] = useState([false, false, false, false]); // todo: remove
  const [potentialAddOns, setPotentialAddOns] = useState<paymentName[]>(null); // todo: maybe paymentName[][]
  const [selectedAddOns, setSelectedAddOns] = useState(null); // todo: remove
  const [confirmationTable, setConfirmationTable] = useState<confirmationTableRow[] | null>(null);
  const [finalPaymentArray, setFinalPaymentArray] = useState<any | null>(null);

  const checkoutValues = {
    taxes: {
      shippingAmt,
      setShippingAmt,
      calculateShippingAndTaxes,
      setCalculateShippingAndTaxes,
      taxCalcDone,
      setTaxCalcDone,
      errorCalculatingShippingAndTaxes,
      setErrorCalculatingShippingAndTaxes,
    },
    discount: {
      discountCodeInput,
      setDiscountCodeInput,
      discountValue,
      setDiscountValue,
      discountAmount,
      setDiscountAmount,
      discountMessage,
      setDiscountMessage,
    },
    checkout: {
      step,
      setStep,
      paymentIntentId,
      setPaymentIntentId,
      clientSecret,
      setClientSecret,
      confirmationTable,
      setConfirmationTable,
      finalPaymentArray,
      setFinalPaymentArray,
    },
    purchasesAndAddons: {
      pastPurchases,
      setPastPurchases,
      addOnsStatus,
      setAddOnsStatus,
      potentialAddOns,
      setPotentialAddOns,
      selectedAddOns,
      setSelectedAddOns,
    },
    price: {
      displayPrice,
      setDisplayPrice,
    },
  };

  return <CheckoutContext.Provider value={checkoutValues}>{props.children}</CheckoutContext.Provider>;
}

export { CheckoutContext, CheckoutProvider };
