import { useState } from "react";
import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import VisualDescription from "../../components/product-pages/VisualDescription";
import TestimonialBox from "../../components/product-pages/TestimonialBox";
import CDMPLevelsModal from "../../components/CDMPLevelsModal";

import testimonials from "../../data/testimonials";

const PRODUCT = "Specialist Question Sets";

export default function SpecialistQuestions() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && <CDMPLevelsModal setShowModal={setShowModal} />}
      <MultiPriceProductPage PRODUCT={PRODUCT} description={description(setShowModal)} tealBox={tealBox} rest={rest} />
    </>
  );
}
const description = (setShowModal) => {
  return (
    <div className="gray-box" id="product-description">
      <h4>Description</h4>
      <div className="blog">
        <p>
          Progress your understanding of Data Management with these sets of <strong>50 practice questions</strong> that
          focus on <strong>each of the seven chapters</strong> of the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK v2 Revised</em>
          </a>{" "}
          tested on the{" "}
          <Link to="/resources/cdmp-specialist-exam" target="_blank">
            Specialist Exams
          </Link>
          . These Question Sets contain a mix of questions from the{" "}
          <Link to="/products/cdmp-question-sets" target="_blank">
            CDMP Question Sets
          </Link>{" "}
          and new questions developed to enhance your preparedness for the Specialist Exams.
        </p>
        <p>
          Immediately upon purchase, you'll receive information about how to sign into our Training Site to access the
          Question Set in an exam simulator for unlimited practice. Upon completion of each mock exam, you can review
          your score along with an explanation for each question and reference to the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK v2 Revised</em>
          </a>{" "}
          (chapter and section) where relevant.
        </p>
        <p>
          <Link to="/resources/cdmp-specialist-exam" target="_blank">
            CDMP Specialist Exams
          </Link>{" "}
          offer data practitioners the opportunity to advance their credentials and knowledge of specific Data Strategy
          topics. Successful completion of two Specialist Exams is required in order to attain recognition at the{" "}
          <span className="fake-link-1" onClick={() => setShowModal(true)}>
            Practitioner or Master level
          </span>
          .
        </p>
        <p>
          To further develop your understanding of these topics, don't miss our{" "}
          <Link to="/products/specialist-guides" target="_blank">
            Specialist Guides
          </Link>
          .
        </p>
        <p>
          <i>
            Note: this product is available as part of the{" "}
            <Link to="/products/specialist-bundle" target="_blank">
              Specialist Bundle
            </Link>
          </i>
        </p>
      </div>
    </div>
  );
};

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials" style={{ marginBottom: 0, paddingBottom: 0 }}>
    <h4>Features</h4>
    <VisualDescription product={PRODUCT} />
  </div>
);

const rest = (
  <TestimonialBox
    testimonialsToShow={testimonials.filter((testimonial) => testimonial.id.includes("specialist-question-sets"))}
  />
);
