import { Link } from "react-router-dom";

import { formatDate } from "../../../helpers/utils";

const EventBanner: React.VFC<{ upcomingEvent: eventDetails }> = ({ upcomingEvent }) => {
  return (
    <div
      style={{
        background: "#072845",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        textWrap: "balance",
        padding: "0 10px",
        zIndex: 998,
      }}
    >
      <p
        id="sale"
        style={{
          textAlign: "center",
          padding: ".75em 0 .75em 0",
          fontSize: "1.1em",
        }}
      >
        Join us for{" "}
        <Link to="/events" style={{ color: "white" }}>
          <span dangerouslySetInnerHTML={{ __html: upcomingEvent.name }} />
        </Link>{" "}
        on{" "}
        {upcomingEvent.start.toLocaleDateString("en-us", {
          weekday: "short",
          month: "short",
          day: "numeric",
        })}{" "}
        at {formatDate(upcomingEvent.start)} ET.{" "}
        <a href={upcomingEvent.link} target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
          Sign up
        </a>
        .
      </p>
    </div>
  );
};

export default EventBanner;
