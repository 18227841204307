import { useState } from "react";

import NameForm from "../../components/sign-up-forms/NameFormContainer";
import EmailForm from "../../components/sign-up-forms/EmailFormContainer";

import ThanksAnimation from "../../components/sign-up-forms/ThanksAnimation";

import payments from "../../data/payments";

const payment = payments.find((payment) => payment.name === "data-management-master-class-free-trial");

const DMMACourseSignup: React.FC<{ blog: boolean }> = ({ blog = true }) => {
  const [firstName, setFirstName] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    fetch("/.netlify/functions/sendinblue-email-list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        firstName,
        emailList: 120,
        source: "DMMA Mini-Course",
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .catch((err) => {
        console.log("error:", err);
        return { statusCode: 400, body: JSON.stringify({ error: err.message }) };
      });

    fetch("/.netlify/functions/add-to-free-course", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        email,
        payment,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setSubmitted(true);
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .catch((err) => {
        console.log("error:", err);
        return { statusCode: 400, body: JSON.stringify({ error: err.message }) };
      });
  };

  return (
    <div className="teal-box" style={{ maxWidth: "1000px", margin: blog ? "57.6px 0px" : "auto", height: 382.586 }}>
      {!submitted ? (
        <>
          <h4 className="box-title" style={{ textAlign: "center", fontSize: "1.2em", marginBottom: "12px" }}>
            <strong>{blog ? "DMMA Mini-Course" : "Want to Give it a Try?"}</strong>
          </h4>
          <div style={{ textAlign: "center", maxWidth: 600, margin: "auto" }}>
            <p style={{ textWrap: "balance" }}>
              Sign up for our <strong>free 14-lesson video course</strong> about Data Management Maturity Assessments
              {!blog && ", chapter 2 of the Data Management Master Class"}.
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div style={{ maxWidth: 400, margin: "1.25em auto" }}>
              <NameForm setFirstName={setFirstName} />
              <EmailForm setEmail={setEmail} setValidEmail={setValidEmail} />
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <button
                disabled={!firstName || !validEmail}
                className="btn btn-accent"
                type="submit"
                style={{
                  margin: "auto",
                  width: 250,
                }}
              >
                Sign up
              </button>
            </div>
          </form>
        </>
      ) : (
        <ThanksAnimation firstName={firstName} email={email} />
      )}
    </div>
  );
};

export default DMMACourseSignup;
