import { useEffect, useRef } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import checkmark from "../../assets/lotties/checkmark.json";

const CheckAnimation: React.VFC<{ small?: boolean }> = ({ small = false }) => {
  const lottieRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      lottieRef.current.pause();
    }, 2300);
  }, []);

  return (
    <div style={{ height: 120, display: "flex", justifyContent: "center" }}>
      <Player
        autoplay
        loop={false}
        src={checkmark}
        style={{ height: small ? 52 : 96, width: small ? 52 : 96 }}
        ref={lottieRef}
      />
    </div>
  );
};

export default CheckAnimation;
