export default function TestimonialCard({ testimonial, fontSize = 1, quotes = true, productPage = false }) {
  const testimonialText = productPage ? testimonial.plainText : testimonial.content;

  const colors = ["#1a5e52", "#1a485e", "#2b769a", "#6f8ce8", "#13334f", "#5f7496"];

  return (
    <div className="visual-description-item">
      <p
        className="testimonial-text"
        dangerouslySetInnerHTML={{
          __html: quotes ? `"${testimonialText}"` : testimonialText,
        }}
        style={{ fontSize: `${fontSize}em`, position: "relative" }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <div>
          <p style={{ textAlign: "right" }}>
            — <span>{testimonial.name}</span>
            {testimonial.title && ", "}
          </p>
          <p
            style={{
              textAlign: "right",
              fontStyle: "italic",
            }}
          >
            {testimonial.title}
          </p>
        </div>
        {testimonial.img ? (
          <img className="testimonial-img" src={testimonial.img} alt="" />
        ) : (
          <div
            style={{
              background: colors[Math.floor(Math.random() * colors.length)],
              objectFit: "cover",
              borderRadius: "50%",
              marginTop: -60,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="testimonial-img"
          >
            <p style={{ color: "white", fontSize: "1.8em", fontWeight: 600 }}>{testimonial.name[0]}</p>
          </div>
        )}
      </div>
    </div>
  );
}
