import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import cdmp from "../../img/logos/logo_cdmp.webp";
import dmbok from "../../img/grps/dmbok_sq.webp";
import li from "../../img/grps/buildings.webp";
import cap from "../../img/grps/cap.webp";
import cds from "../../img/grps/cds.webp";
import dp from "../../img/grps/dp.webp";
import React from "react";

const OurCommunity: React.VFC = () => {
  const desktop = useMediaQuery("(min-width:640px)");

  return (
    <div
      className="white-box"
      style={{
        padding: "0",
        margin: "0px auto",
        width: "85%",
        background: "none",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "1.8em",
          borderBottom: "2px solid #dbdbdb",
          textAlign: "center",
          position: "relative",
          zIndex: 0,
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "1.3em",
            marginTop: "11px",
            background: "#f9f9f9",
            zIndex: 1,
            display: "inline-block",
            padding: "0 20px",
            fontFamily: "Raleway",
          }}
          id="community"
        >
          <strong>Our Community</strong>
        </p>
      </div>

      {!desktop && (
        <div>
          <br />
        </div>
      )}

      {desktop && (
        <>
          <div
            className="image-display-tri"
            style={{
              gap: "40px",
              justifyContent: "space-between",
              marginTop: "50px",
            }}
          >
            <figure>
              <a href="https://www.facebook.com/groups/cdmpstudygroup" target="_blank" rel="noreferrer">
                <div style={{ overflow: "hidden", position: "relative" }}>
                  <img src={cdmp} alt="hand holding lightbulb" className="zoom-on-hover" />
                </div>
              </a>
              <figcaption style={{ textAlign: "center" }}>
                <a href="https://www.facebook.com/groups/cdmpstudygroup" target="_blank" rel="noreferrer">
                  CDMP Study Group (Facebook)
                </a>
                <p>Data Management</p>
              </figcaption>
            </figure>

            <figure>
              <a href="https://www.linkedin.com/groups/12965811/" target="_blank" rel="noreferrer">
                <div style={{ overflow: "hidden", position: "relative" }}>
                  <img
                    src={dmbok}
                    alt="Data Management Body of Knowledge"
                    className="zoom-on-hover"
                    style={{ height: "100%" }}
                  />
                </div>
              </a>
              <figcaption style={{ textAlign: "center" }}>
                <a href="https://www.linkedin.com/groups/12965811/" target="_blank" rel="noreferrer">
                  CDMP Study Group (LinkedIn)
                </a>
                <p>Data Management</p>
              </figcaption>
            </figure>

            <figure>
              <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer">
                <div style={{ overflow: "hidden", position: "relative" }}>
                  <img src={li} alt="buildings" className="zoom-on-hover" />
                </div>
              </a>
              <figcaption style={{ textAlign: "center" }}>
                <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer">
                  Data Strategy Professionals on LinkedIn
                </a>
              </figcaption>
            </figure>
          </div>
          <br />
          <br />
        </>
      )}

      <div className="center-text-box">
        <p>
          The best credential for Data Strategists is an{" "}
          <a rel="noreferrer noopener" href="http://amzn.to/32oK8hH" target="_blank">
            open book
          </a>{" "}
          exam. The <Link to="/resources/cdmp-fundamentals-exam">Certified Data Management Professional exam</Link> is
          wonderfully practical in its approach to testing Data Management fundamentals. In the{" "}
          <a rel="noreferrer noopener" href="https://www.facebook.com/groups/cdmpstudygroup/" target="_blank">
            CDMP Study Group
          </a>
          , we cover a different chapter of the{" "}
          <a rel="noreferrer noopener" href="http://amzn.to/32oK8hH" target="_blank">
            <em>Data Management Body of Knowledge </em>
          </a>
          each month. We also offer a{" "}
          <a href="https://www.linkedin.com/groups/12965811/" target="_blank">
            community on LinkedIn
          </a>{" "}
          for folks preparing for the CDMP exams.
        </p>
        <br />
        <p>
          Moreover, the{" "}
          <a rel="noreferrer noopener" href="https://www.linkedin.com/groups/13951141" target="_blank">
            Data Strategy Professionals
          </a>{" "}
          group on LinkedIn offers useful resources on how to tackle the opportunities and challenges associated with
          Data Management.
        </p>
        <br />
        <br />
      </div>

      {desktop && (
        <>
          <div
            className="image-display-tri"
            style={{
              justifyContent: "space-evenly",
              marginTop: 30,
            }}
          >
            <figure>
              <a
                href="https://www.facebook.com/groups/certifiedanalyticsprofessionals/"
                target="_blank"
                rel="noreferrer"
              >
                <div style={{ overflow: "hidden", position: "relative" }}>
                  <img src={cap} alt="buildings" className="zoom-on-hover" />
                </div>
              </a>
              <figcaption style={{ textAlign: "center" }}>
                <a
                  href="https://www.facebook.com/groups/certifiedanalyticsprofessionals/"
                  target="_blank"
                  rel="noreferrer"
                >
                  CAP Study Group (Facebook)
                </a>
                <p>Data Analytics</p>
              </figcaption>
            </figure>

            <figure>
              <a href="https://www.facebook.com/groups/dataprivacystudygroup/" target="_blank" rel="noreferrer">
                <div style={{ overflow: "hidden", position: "relative" }}>
                  <img src={dp} alt="people studying" className="zoom-on-hover" />
                </div>
              </a>
              <figcaption style={{ textAlign: "center" }}>
                <a href="https://www.facebook.com/groups/dataprivacystudygroup/" target="_blank" rel="noreferrer">
                  Data Privacy Study Group (Facebook)
                </a>
              </figcaption>
            </figure>
          </div>
          <br />
          <br />
        </>
      )}

      <div className="center-text-box">
        <p>
          The Certified Analytics Professional (CAP) Study Group on{" "}
          <a href="https://www.facebook.com/groups/dataprivacystudygroup/" target="_blank" rel="noreferrer">
            Facebook
          </a>{" "}
          or{" "}
          <a href="https://www.linkedin.com/groups/13026365/" target="_blank" rel="noreferrer">
            Linkedin
          </a>{" "}
          offers a chance for experienced Data Analysts to prepare for the{" "}
          <a
            href="https://nicolejaneway.medium.com/certified-analytics-professional-exam-a8c63b5a5268"
            target="_blank"
            rel="noreferrer"
          >
            INFORMS certification
          </a>{" "}
          alongside their peers. The{" "}
          <a
            href="https://nicolejaneway.medium.com/certified-analytics-professional-exam-a8c63b5a5268"
            target="_blank"
            rel="noreferrer"
          >
            CAP exam
          </a>{" "}
          provides an independent verification of the technical expertise needed for top performance at a data-driven
          organization. The certification is vendor-neutral, so it's a great foundation for any role involving data
          analytics.
        </p>
        <br />

        <p>
          Data Privacy represents an area of utmost importance. Data practitioners looking to enhance their
          understanding of key ideas and landmark regulation in this area should look no further than the{" "}
          <a href="https://www.facebook.com/groups/dataprivacystudygroup/" target="_blank" rel="noreferrer">
            Data Privacy Study Group
          </a>
          . We help our community members prepare for the certifications offered by the{" "}
          <a href="https://iapp.org/" target="_blank" rel="noreferrer">
            International Association of Privacy Professionals (IAPP)
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default OurCommunity;
