import { useState, useContext, useEffect } from "react";
import { ArrowForwardIos } from "@mui/icons-material";

import { CheckoutContext } from "../../../contexts/CheckoutContext";
import ConfirmationTable from "./ConfirmationTable";

import { updateConfirmationTable } from "../../../helpers/checkoutUtils";

export default function ConfirmationPage({ step, setStep, email, address }) {
  const checkoutContext = useContext(CheckoutContext);
  const { confirmationTable, setConfirmationTable, paymentIntentId } = checkoutContext.checkout;
  const { setTaxCalcDone } = checkoutContext.taxes;

  const [showButton, setShowButton] = useState(true);
  const [activeRows, setActiveRows] = useState(5);

  useEffect(() => {
    let maxIndex = -1; // Initialize with -1 to indicate no match found
    for (let i = 0; i < confirmationTable.length; i++) {
      if (confirmationTable[i].selected === true) {
        maxIndex = i; // Update maxIndex if a true value is found
      }
    }
    if (activeRows < maxIndex) {
      setActiveRows(maxIndex);
    }
    if (maxIndex === confirmationTable.length) {
      setShowButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    if (activeRows + 3 < confirmationTable.length) {
      setActiveRows(activeRows + 3);
    } else {
      setActiveRows(confirmationTable.length);
      setShowButton(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (confirmationTable.filter((row) => row.selected).some((row) => row.type === "physical") && !address) {
      setStep(2);
    } else {
      fetch("/.netlify/functions/handle-payment-intent", {
        method: "POST",
        body: JSON.stringify({
          email: email,
          paymentIntentId,
          address: address?.address,
          paymentsTable: confirmationTable.filter((row) => row.selected),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const paymentsTable = data.paymentsTable;
          setConfirmationTable(updateConfirmationTable(confirmationTable, paymentsTable));
          setTaxCalcDone(true);
          setStep(3);
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
        <p
          style={{
            textAlign: "left",
            fontWeight: 900,
            fontSize: "1.01em",
            marginBottom: 4,
          }}
        >
          Add related products
        </p>
        <button
          className="btn btn-accent"
          style={{
            fontSize: "0.8em",
            borderRadius: 16,
            height: 28,
            marginTop: -2,
            marginBottom: 8,
            display: "flex",
            alignItems: "center",
            gap: 6,
          }}
          onClick={handleSubmit}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {"Continue "}
            <ArrowForwardIos sx={{ fontSize: "0.8em" }} />
            <ArrowForwardIos sx={{ fontSize: "0.8em", marginLeft: -0.5 }} />
          </div>
        </button>
      </div>
      <ConfirmationTable activeRows={activeRows} step={step} />
      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: 8 }}>
        {showButton && (
          <button
            className="btn btn-trustpilot"
            style={{
              fontSize: "0.8em",
              borderRadius: 16,
              height: 28,
              paddingTop: 6,
            }}
            onClick={handleClick}
          >
            Load more
          </button>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 30 }}>
          <button
            className="btn btn-accent"
            type="submit"
            style={{
              width: "200px",
              padding: "10px 20px",
              margin: "4px 0",
            }}
          >
            Continue
          </button>
        </div>
      </form>
    </>
  );
}
