import advice from "../../data/advice";
import groupLinks from "../../data/groupLinks";

import fb from "../../img/icons/color-facebook-128.webp";
import li from "../../img/icons/color-linkedin-128.webp";

import Person from "../person/Person";
import SpecialistExamPerson from "../person/SpecialistExamPerson";

const styles = {
  buttonStyle: {
    width: "1em",
    height: "1em",
    marginLeft: ".5em",
    cursor: "pointer",
  },
};

const Advice: React.FC<{ id: string }> = ({ id }) => {
  const adviceObj: Advice = advice.find((advice) => advice.id === id);
  const { group, article, text, personName } = adviceObj;

  function handleClick(button: "linkedin" | "facebook") {
    const linkObj: LinkObj = groupLinks.find((linkObj) => linkObj.name === group);

    if (button === "linkedin") {
      window.open(linkObj.linkedIn);
    } else if (button === "facebook") {
      window.open(linkObj.facebook);
    }
  }

  return (
    <div className="teal-box blog">
      <h4
        style={{
          marginBottom: ".8em",
          fontSize: "1.2em",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Advice from {group} Study Group
        <img src={li} alt="linkedin" style={styles.buttonStyle} onClick={() => handleClick("linkedin")} />
        <img src={fb} alt="facebook" style={styles.buttonStyle} onClick={() => handleClick("facebook")} />
      </h4>

      <div className="blog" dangerouslySetInnerHTML={{ __html: text }} />
      {article === "Specialist" ? <SpecialistExamPerson personName={personName} /> : <Person personName={personName} />}
    </div>
  );
};

export default Advice;
