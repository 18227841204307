const BasePerson: React.FC<{
  personName: string;
  link: string;
  lineTwo?: string | null;
  italics: boolean;
  img?: string | null;
}> = ({ personName, link, lineTwo = null, italics, img = null }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "1em" }}>
      <div>
        <p style={{ textAlign: "right", marginBottom: "0px" }}>
          –{" "}
          {link ? (
            <a href={link} target="_blank" rel="noreferrer">
              {personName}
            </a>
          ) : (
            <span style={{ fontWeight: 900 }}>{personName}</span>
          )}
          {lineTwo && <span>,</span>}
        </p>
        {lineTwo && (
          <p style={{ textAlign: "right", marginBottom: "0px", fontStyle: italics ? "italic" : "normal" }}>{lineTwo}</p>
        )}
      </div>
      {img && <img className="testimonial-img" src={img} alt={personName} />}
    </div>
  );
};

export default BasePerson;
