import validator from "validator";

export default function FooterEmailForm({ setEmail, showLabel = true, setValidEmail }) {
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setValidEmail(true);
      setEmail(email);
    } else if (!validator.isEmail(email)) {
      setValidEmail(false);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <label htmlFor={"userEmail"}>
        {showLabel && (
          <p
            style={{
              fontSize: ".9em",
              color: "#959393",
              fontWeight: 900,
              position: "absolute",
              top: -18,
            }}
          >
            Email
          </p>
        )}
      </label>
      <input type="text" id="userEmail" onKeyUp={(e) => validateEmail(e)} className="field checkout-form-input" />
    </div>
  );
}
