import { useState } from "react";
import { Link } from "react-router-dom";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import CDMPLevelsModal from "../components/CDMPLevelsModal";
import Post from "../components/blog/Post";
import PostImage from "../components/blog/PostImage";
import QuestionCard from "../components/blog/QuestionCard";
import CDMPQuickStart from "../components/blog/quick-start/CDMPQuickStart";

import levels from "../img/blog/levels_simplified.webp";
import esl from "../img/blog/esl.webp";

import cdmpChapters from "../data/cdmpChapters";
import cdmpPracticeQuestions from "../data/cdmpPracticeQuestions";

import { createRows } from "../helpers/utils";

const POSTNAME = "cdmp-practice-questions";

export default function CDMPPracticeQuestions() {
  return <Post postName={POSTNAME} rest={<Rest />} />;
}

const Rest = () => {
  const [showModal, setShowModal] = useState(false);
  const rows = createRows(cdmpChapters);

  // const contents: Content[] = [
  //   { anchor: "intro", text: "Introduction to the CDMP Exams" },
  //   { anchor: "intro-fundamentals", text: "Introduction to the CDMP Fundamentals Exam" },
  //   { anchor: "practice-questions-fundamentals", text: "CDMP Fundamentals Exam Practice Questions" },
  //   { anchor: "intro-specialist", text: "Introduction to the CDMP Specialist Exams" },
  //   {
  //     anchor: "practice-questions-specialist",
  //     text: "CDMP Specialist Exam Practice Questions",
  //     subContents: [
  //       { anchor: "dg", text: "Data Governance" },
  //       { anchor: "dmd", text: "Data Modeling & Design" },
  //       { anchor: "dii", text: "Data Integration & Interoperability" },
  //       { anchor: "mdm", text: "Master & Reference Data Management" },
  //       { anchor: "mm", text: "Metadata Management" },
  //       { anchor: "dwbi", text: "Data Warehousing & Business Intelligence" },
  //       { anchor: "dq", text: "Data Quality" },
  //     ],
  //   },
  //   { anchor: "conclusion", text: "Conclusion" },
  // ];

  return (
    <>
      {showModal && <CDMPLevelsModal setShowModal={setShowModal} />}
      <CDMPQuickStart />
      <div className="blog">
        <p>
          In this article, we explain the format of the{" "}
          <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
            CDMP Fundamentals Exam
          </Link>{" "}
          and{" "}
          <Link to="/resources/cdmp-specialist-exam" target="_blank">
            CDMP Specialist Exam
          </Link>{" "}
          from{" "}
          <a href="https://www.dama.org/" target="_blank" rel="noreferrer">
            DAMA-I
          </a>
          . We'll also provide some <strong>free practice questions</strong> for these exams. The CDMP can be a valuable
          addition to your resume, and familiarity with the way the test is structured and the kind of questions it asks
          will go a long way toward attaining your goal score. Hopefully, this writeup helps you gauge whether pursuing
          CDMP is a good step toward your goals and assess your readiness to sit for accreditation.
        </p>

        <PostImage postName={POSTNAME} />

        {/* TODO */}
        <h4>Contents</h4>
        <ul>
          <li>
            <a href="#intro">Introduction to the CDMP Exams</a>
          </li>
          <li>
            <a href="#intro-fundamentals">Introduction to the CDMP Fundamentals Exam</a>
          </li>
          <li>
            <a href="#practice-questions-fundamentals">CDMP Fundamentals Exam Practice Questions</a>
          </li>
          <li>
            <a href="#intro-specialist">Introduction to the CDMP Specialist Exam</a>
          </li>
          <li>
            <a href="#practice-questions-specialist">CDMP Specialist Exam Practice Questions</a>
            <ul>
              <li>
                <a href="#dg">Data Governance</a>
              </li>
              <li>
                <a href="#dmd">Data Modeling & Design</a>
              </li>
              <li>
                <a href="#dii">Data Integration & Interoperability</a>
              </li>
              <li>
                <a href="#mdm">Master & Reference Data Management</a>
              </li>
              <li>
                <a href="#mm">Metadata Management</a>
              </li>
              <li>
                <a href="#dwbi">Data Warehousing & Business Intelligence</a>
              </li>
              <li>
                <a href="#dq">Data Quality</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#conclusion">Conclusion</a>
          </li>
          <li>
            <a href="#related-posts">Related Posts</a>
          </li>
        </ul>

        <h2 id="intro">Introduction to the CDMP Exams</h2>
        <p>There are three levels of the CDMP accreditation:</p>
        <figure>
          <img src={levels} alt="diagram showing the three levels of the CDMP" />
          <figcaption>
            Technically, there is a fourth level, but there are only{" "}
            <a href="https://www.dama.org/team/cdmp-fellows" target="_blank" rel="noreferrer">
              three CDMP Fellows
            </a>{" "}
            in the world, so we're not going to worry about that one.
          </figcaption>
        </figure>

        <p>
          To attain recognition as a <strong>CDMP Associate</strong>, you must take the{" "}
          <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
            CDMP Fundamentals Exam
          </Link>{" "}
          and achieve a <strong>score of 60%+</strong>. To attain recognition as a <strong>CDMP Practitioner</strong>,
          you must take the Fundamentals Exam and two{" "}
          <Link to="/resources/cdmp-specialist-exam" target="_blank">
            Specialist Exams
          </Link>{" "}
          and <strong>score 70%+</strong> on each exam. To attain the <strong>CDMP Master</strong> level accreditation,
          you must take the Fundamentals Exam and two Specialist Exams and <strong>score 80%+</strong> on each exam.
        </p>
        <p>
          All CDMP exams contain <strong>100 multiple choice questions</strong>. All questions have{" "}
          <strong>five answer choices</strong> with a <strong>single correct answer</strong>. It's very important to get
          through all 100 questions in the allotted time. Because there is no penalty for an incorrect guess, you should
          be sure to <strong>answer every question</strong>, even if it's just your best guess. Once you reach the end
          of the test, use the remaining time to <strong>look up answers</strong> in the book.
        </p>
        <p>
          As a native English speaker, you'll have <strong>90 minutes to take the exam</strong>. If you are an English
          as a second language person, you should register for the <strong>ESL version</strong> of the exam, and you'll
          then receive an <strong>additional 20 minutes</strong> to take the test.
        </p>

        <figure>
          <a href="http://cdmp.info/exams/" target="_blank" rel="noreferrer">
            <img src={esl} alt="screenshot of cdmp website showing how to sign up for the esl version of the exam" />
          </a>
          <figcaption>
            If it applies to you, select ESL from the drop down to purchase the English as a second language version of
            the exam.
          </figcaption>
        </figure>

        <h4 id="intro-fundamentals" style={{ marginTop: "3em" }}>
          Introduction to the CDMP Fundamentals Exam
        </h4>
        <p>
          The CDMP Fundamentals Exam is based on the <strong>first 14 chapters</strong> of the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>{" "}
          from{" "}
          <a href="https://www.dama.org/" target="_blank" rel="noreferrer">
            DAMA-I
          </a>
          . Preparing for this exam instills a broad understanding of the{" "}
          <Link to="/resources/foundations-of-data-strategy" target="_blank">
            Data Management
          </Link>{" "}
          discipline, with a particular focus on certain domains.
        </p>
        <p>
          In terms of the composition of exam, 60% of content comes directly from the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>
          . 20% of questions address familiar concepts but may use different words than the book. And finally, 20% of
          questions are practical, multidisciplinary, and/or require the test-taker to apply knowledge from the{" "}
          <em>DMBOK</em> to new situations. To more effectively answer these challenging questions, we recommend the{" "}
          <Link to="/products/data-management-master-class" target="_blank">
            Data Management Master Class
          </Link>{" "}
          as a resource to bridge this knowledge gap.
        </p>

        <p>
          The percentage of questions for each chapter of the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>{" "}
          is listed below with the options for the Specialist Exams highlighted blue.
        </p>

        <TableContainer sx={{ mt: 4, mb: 4, maxWidth: 1000 }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#efefef" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 900, minWidth: 180, p: 1 }}>Chapter</TableCell>
                <TableCell sx={{ fontWeight: 900, p: 1 }}>Percentage of questions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow
                    key={row.number}
                    sx={{
                      backgroundColor: row.specialist ? "#dee8e9" : "auto",
                    }}
                  >
                    <TableCell sx={{ p: 1 }}>
                      {row.title} (ch. {row.number})
                    </TableCell>
                    <TableCell sx={{ p: 1 }}>{row.percent}%</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <p>
          If you're short on time, we recommend that you{" "}
          <strong>focus on the seven chapters that are options for the Specialist Exams</strong>. These chapters
          collectively comprise 70% of Fundamentals Exam test questions.
        </p>
        <p>In the next section, we'll take a look at some practice questions.</p>
        <br />
      </div>
      <h4 id="practice-questions-fundamentals">CDMP Fundamentals Exam Practice Questions</h4>
      {cdmpPracticeQuestions.slice(0, 5).map((question) => (
        <QuestionCard question={question} />
      ))}
      <br />
      <p>
        Hopefully, these practice questions helped you get a sense of how the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>{" "}
        is designed. For every question, you'll want to select the single best answer from the five options. Be sure to
        answer every question in a timely manner so you don't run out of time and leave questions unanswered. Remember,
        you only need to score a 60% to pass at the{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          Associate level
        </span>
        . If you want to continue to the{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          Practitioner or Master level
        </span>
        , you'll need to score at least 70% or 80% respectively. For more practice questions, check out our{" "}
        <Link to="/products/cdmp-question-sets" target="_blank">
          CDMP Question Sets
        </Link>
        .
      </p>
      <h4 id="intro-specialist" style={{ marginTop: "3em" }}>
        Introduction to the CDMP Specialist Exams
      </h4>
      <p>
        In order to attain recognition at the{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          Practitioner or Master level
        </span>
        , you'll need to get a target score of 70%+ or 80%+ on two Specialist Exams. Here's the list of options:
      </p>
      <TableContainer sx={{ mt: 4, mb: 4, maxWidth: 1000 }}>
        <Table>
          <TableBody>
            {rows
              .filter((row) => row.specialist)
              .map((row) => {
                return (
                  <TableRow
                    key={row.number}
                    sx={{
                      backgroundColor: row.specialist ? "#dee8e9" : "auto",
                    }}
                  >
                    <TableCell sx={{ p: 1 }}>
                      <p style={{ margin: 0 }}>
                        <Link to={`/resources/cdmp-specialist-exam#${row.short}`} target="_blank" rel="noreferrer">
                          {row.title}
                        </Link>{" "}
                        (ch. {row.number}) — {row.description}
                      </p>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <p>
        As is the case for the Fundamentals Exam, the Specialist Exams are roughly 60% content from the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        , 20% content from{" "}
        <a href="https://www.dama.org/cpages/books-referenced-in-dama-dmbok" target="_blank" rel="noreferrer">
          works cited in the <em>DMBOK</em>
        </a>
        , and 20% questions that are designed to test your practical experience as a data practitioner. Keep reading to
        the next section for some practice questions.
      </p>

      <h4 id="practice-questions-specialist" style={{ marginTop: "3em" }}>
        CDMP Specialist Exam Practice Questions
      </h4>
      <h6 id="dg"> Data Governance</h6>
      <QuestionCard question={cdmpPracticeQuestions[5]} />

      <h6 id="dmd" style={{ marginTop: "2em" }}>
        {" "}
        Data Modeling & Design
      </h6>
      <QuestionCard question={cdmpPracticeQuestions[6]} />

      <h6 id="dii" style={{ marginTop: "2em" }}>
        Data Integration & Interoperability
      </h6>
      <QuestionCard question={cdmpPracticeQuestions[7]} />

      <h6 id="mdm" style={{ marginTop: "2em" }}>
        Master & Reference Data Management
      </h6>
      <QuestionCard question={cdmpPracticeQuestions[8]} />

      <h6 id="dwbi" style={{ marginTop: "2em" }}>
        Data Warehousing & Business Intelligence
      </h6>
      <QuestionCard question={cdmpPracticeQuestions[9]} />

      <h6 id="mm" style={{ marginTop: "2em" }}>
        Metadata Management
      </h6>
      <QuestionCard question={cdmpPracticeQuestions[10]} />

      <h6 id="dq" style={{ marginTop: "2em" }}>
        Data Quality
      </h6>
      <QuestionCard question={cdmpPracticeQuestions[11]} />

      <p>
        So hopefully, this section gave you a better idea of how the{" "}
        <Link to="/resources/cdmp-specialist-exam" target="_blank">
          Specialist Exams
        </Link>{" "}
        are formatted and the kinds of questions they contain. Don't forget that you should plan to score a 70%+ on two
        Specialist Exams if you're aiming for the{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          Practitioner
        </span>{" "}
        designation or 80%+ on the two tests if you're shooting for the{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          Master
        </span>{" "}
        designation. For more practice questions, check out our{" "}
        <Link to="/products/specialist-question-sets" target="_blank">
          Specialist Question Sets
        </Link>
        .
      </p>
      <h2>Conclusion</h2>
      <p>
        As you can see from these examples, the CDMP exams are pretty straightforward. There are no trick questions and
        most of the material is drawn from the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        . To prepare for the Specialist Exams, you may want to check out some of the auxiliary reading from{" "}
        <Link to="/resources/cdmp-specialist-exam" target="_blank">
          this list
        </Link>
        .
      </p>
      <p>
        Attaining recognition as a{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          CDMP Associate
        </span>{" "}
        is a very helpful way to demonstrate your knowledge of Data Management best practices, key ideas, and common
        terminology. Continue to advance your skills by sitting for the{" "}
        <Link to="/resources/cdmp-specialist-exam" target="_blank">
          Specialist Exams
        </Link>{" "}
        and achieving the{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          CDMP Practitioner
        </span>{" "}
        or{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          Master
        </span>{" "}
        level accreditation.
      </p>
      <p>
        If you feel you would benefit from more practice questions, check out our{" "}
        <Link to="/products/cdmp-question-sets" target="_blank">
          CDMP Question Sets
        </Link>
        , which contain an explanation and reference for each correct answer. If you're unsure whether you're ready to
        sit for the exam, the{" "}
        <Link to="/products/cdmp-exam-readiness-check" target="_blank">
          CDMP Exam Readiness Check
        </Link>{" "}
        can help you find out. You may also be interested in exploring how the CDMP interacts with your goals through a
        conversation with one of our{" "}
        <Link to="/products/career-coaching" target="_blank">
          Career Coaches
        </Link>
        .
      </p>
      <p>
        Good luck, and don't hesitate to send us an email at{" "}
        <a href="mailto:info@datastrategypros.com">info@datastrategypros.com</a> to share your feedback.
      </p>
    </>
  );
};
