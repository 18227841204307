import tshirt from "../../../img/products/tshirt/tshirt_crop.webp";
import pdf from "../../../img/products/enterprise/enterprise-study-program.webp";
import email from "../../../img/products/enterprise/enterprise-email.webp";
import calendar from "../../../img/products/enterprise/enterprise-calendar.webp";

import enterpriseStyles from "../../../helpers/enterpriseStyles";

const CDMPStudyProgramBox: React.VFC = () => {
  const IMAGE_HEIGHT = 120;
  const body = `Hi there,
  
I thought you might be interested in this Data Strategy training: https://datastrategypros.com/landing/cdmp-study-program

Here's a document that the Data Strategy Professionals team put together to explain the program:  https://mcusercontent.com/c164c67d8c9591e3b056f39b1/files/1f04a5d8-0378-da0e-6b76-a250816919d1/Enterprise_Study_Program.pdf

If you have more questions, you can email their team at info@datastrategypros.com

Hope you'll consider this training for our team!

Best,
[yournamehere]`;

  const link =
    "mailto:colleagues@example.com" +
    "?cc=info@datastrategypros.com" +
    "&subject=" +
    encodeURIComponent("Data Strategy training") +
    "&body=" +
    encodeURIComponent(body);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ ...enterpriseStyles.enterpriseBox, gridTemplateColumns: "repeat(4, 220px)" }}>
        <div style={enterpriseStyles.boxTitle}>
          <p className="title product-title">Learn More and Share</p>
        </div>
        <a href="mailto:info@datastrategypros.com?subject=Enterprise%20Study%20Program">
          <img
            src={tshirt}
            alt="woman wearing a t-shirt"
            style={{
              height: IMAGE_HEIGHT,
            }}
            className="box-shadow"
          />
        </a>
        <a
          href="https://mcusercontent.com/c164c67d8c9591e3b056f39b1/files/1f04a5d8-0378-da0e-6b76-a250816919d1/Enterprise_Study_Program.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <img src={pdf} style={{ height: IMAGE_HEIGHT }} alt="pdf cover" className="box-shadow" />
        </a>
        <a href={link}>
          <img src={email} style={{ height: IMAGE_HEIGHT }} alt="email" className="box-shadow" />
        </a>
        <a href="https://tidycal.com/datastrategypros/enterprise">
          <img src={calendar} style={{ height: IMAGE_HEIGHT }} alt="calendar" className="box-shadow" />
        </a>
        <div>
          <p>Questions? Email our team at</p>
          <p>
            <a href="mailto:info@datastrategypros.com?subject=Enterprise%20Study%20Program">
              info@datastrategypros.com
            </a>
          </p>
        </div>
        <p>
          Download a{" "}
          <a
            href="https://mcusercontent.com/c164c67d8c9591e3b056f39b1/files/1f04a5d8-0378-da0e-6b76-a250816919d1/Enterprise_Study_Program.pdf"
            target="_blank"
            rel="noreferrer"
          >
            PDF
          </a>{" "}
          explaining the contents of the program
        </p>
        <p>
          Draft an <a href={link}>email</a> to your colleagues about the program
        </p>
        <p>
          <a href="https://tidycal.com/datastrategypros/enterprise" target="_blank" rel="noreferrer">
            Schedule a call
          </a>{" "}
          with our team to discuss in more detail
        </p>
      </div>
    </div>
  );
};

export default CDMPStudyProgramBox;
