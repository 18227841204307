import { useEffect, useState, useContext } from "react";

import ListPrice from "./ListPrice";
import DiscountCode from "./DiscountCode";
import CheckoutMessages from "./CheckoutMessages";

import { CheckoutContext } from "../../../contexts/CheckoutContext";

import ProductImage from "../../../components/checkout/ProductImage";
import MultiSelect from "../../../components/checkout/MultiSelect";

import payments from "../../../data/payments";
import products from "../../../data/products";

import { getSelectedOptions, getPrice } from "../../../helpers/checkoutUtils";

const CheckoutLeftSide: React.VFC<{
  step: number;
  showMultiple: boolean;
  showProductImage: boolean;
}> = ({ step, showMultiple, showProductImage }) => {
  const checkoutContext = useContext(CheckoutContext);
  const { confirmationTable, setConfirmationTable } = checkoutContext.checkout;
  const { setDisplayPrice } = checkoutContext.price;

  const ROW = confirmationTable[0];

  const payment = payments.find((payment) => payment.name === ROW.paymentName);
  const product = products.find((product) => product.name === payment.product);

  const [selectValues, setSelectValues] = useState<emailListsName[] | string[]>(
    // @ts-ignore
    ROW.selectedOptions.map((option) => option.label)
  );

  const [showText, setShowText] = useState(true); // REMOVE??
  const [showButton, setShowButton] = useState(true);
  const [done, setDone] = useState(false);

  useEffect(() => {
    setDone(false);
  }, []);

  useEffect(() => {
    if (confirmationTable) {
      const updatedRow = {
        ...ROW,
        selectedOptions: getSelectedOptions(payment, selectValues),
        price: getPrice(payment, getSelectedOptions(payment, selectValues)),
      };

      const table = confirmationTable.map((row, idx) => (idx === 0 ? updatedRow : row));

      setConfirmationTable(table);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValues]);

  useEffect(() => {
    if (ROW?.metadata?.taxCode === "txcd_99999999" && step === 1) setShowText(false);
    if (step === 2) setShowText(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <div id="product-left-side">
      <ProductImage ROW={ROW} product={product} showProductImage={showProductImage} />
      {showMultiple ? (
        <>
          {step !== 4 && !done && (
            <DiscountCode
              showButton={showButton}
              setShowButton={setShowButton}
              showText={showText}
              done={done}
              setDone={setDone}
            />
          )}
        </>
      ) : (
        <>
          {payment.options[0]?.content.length > 1 && step < 3 && (
            <form style={{ marginBottom: 14 }}>
              <MultiSelect
                PRODUCT={payment.product}
                OPTIONS={payment.options}
                selectValues={selectValues}
                setSelectValues={setSelectValues}
                setPrice={setDisplayPrice}
              />
            </form>
          )}
          {!ROW.paymentName.includes("free-trial") && (
            <div style={{ height: done ? 60 : 100, paddingBottom: 20 }}>
              <ListPrice />
              {step !== 4 && (
                <DiscountCode
                  showButton={showButton}
                  setShowButton={setShowButton}
                  showText={showText}
                  done={done}
                  setDone={setDone}
                />
              )}
            </div>
          )}
          {step < 2 && <CheckoutMessages />}
        </>
      )}
    </div>
  );
};

export default CheckoutLeftSide;
