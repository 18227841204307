const IntellectualProperty: React.FC = () => {
  return (
    <div style={{ marginBottom: "2em" }}>
      <h4>Intellectual Property and Appropriate Use</h4>
      <p>
        Ownership of intellectual property associated with the company website, synchronous training, products,
        services, and social media content is retained by Data Strategy Professionals. Without exception, these
        materials may not be used, reproduced, distributed, or shared outside their authorized context without prior
        written consent.
      </p>
    </div>
  );
};

export default IntellectualProperty;
