import { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

import email0 from "../../img/products/data-privacy-study-plan/emails/day1.1.webp";
import email1 from "../../img/products/data-privacy-study-plan/emails/day1.2.webp";
import email2 from "../../img/products/data-privacy-study-plan/emails/day2.1.webp";
import email3 from "../../img/products/data-privacy-study-plan/emails/day2.2.webp";
import email4 from "../../img/products/data-privacy-study-plan/emails/day3.1.webp";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import CustomModal from "../../components/CustomModal";
import SubtleTestimonialCardFlip from "../../components/testimonial-flip-card/SubtleTestimonialCardFlip";
import FreeTrial from "../../components/product-pages/FreeTrial";
import BuyNowButton from "../../components/product-pages/BuyNowButton";
import ExtendedDescription from "../../components/product-pages/ExtendedDescription";

import testimonials from "../../data/testimonials";

import { responsive } from "../../helpers/carouselStyles";

const PRODUCT = "CIPP/E Study Plan";

export default function CIPPEStudyPlan() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        description={description}
        tealBox={tealBox}
        extendedDescription={extendedDescription}
        rest={rest(handleShowClick)}
      />
    </>
  );
}

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <div className="blog">
      <p>
        The{" "}
        <a href="https://iapp.org/certify/cippe/" target="_blank" rel="noreferrer">
          Certified Information Privacy Professional/Europe exam
        </a>{" "}
        covers a significant amount of content, so why not let <strong>daily emails</strong> guide your studies? Prepare
        for the <strong>CIPP/E</strong> from{" "}
        <a href="https://iapp.org/" target="_blank" rel="noreferrer">
          IAPP
        </a>{" "}
        with this 90-day Study Plan that offers comprehensive instruction on <strong>GDPR</strong>.
      </p>

      <h4>Features</h4>
      <ul>
        <li>
          90 days of <strong>daily emails</strong> on the most important content for the CIPP/E exam
        </li>

        <li>
          Key <strong>concepts</strong> and <strong>vocabulary</strong> for each topic
        </li>

        <li>
          Thoughtful <strong>extra resources</strong> to elevate your career as a Data Privacy professional
        </li>

        <li>
          Support for <strong>any questions</strong> about the Study Plan at{" "}
          <a href="mailto:support@datastrategypros.com?subject=CAP%20Question%20Sets">support@datastrategypros.com</a>
        </li>
      </ul>
    </div>
  </div>
);

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>What People are Saying</h4>
    <SubtleTestimonialCardFlip
      testimonial={testimonials.find((testimonial) => testimonial.id === "cippe-study-plan-002")}
      productPage
      fontSize={1.05}
    />
  </div>
);

const extendedDescription = (
  <div className="extended-description-container">
    <ExtendedDescription
      description={
        <div className="extended-description">
          <p>
            Maximize your <strong>time, energy, and motivation</strong> to become a Data Privacy expert. Accelerate your
            journey to attain this valuable credential – get all the information you need delivered straight to your
            inbox. Our scientifically-backed approach leverages the <strong>80/20 method</strong> to save you time and
            as you prepare for this challenging exam.
          </p>

          <p>
            At the end of 90 days, you'll be <strong>totally ready</strong> to ace the test and earn recognition as a
            master of <strong>Europe's Data Privacy regime</strong>.
          </p>
          <p>
            On a different timeline to complete the CIPP/E? No problem! Select <strong>Immediate Access</strong> to
            receive all the emails of the CIPP/E Study Plan at one time.
          </p>

          <p>
            Don't miss our{" "}
            <Link to="/products/cippe-exam-questions" target="_blank">
              CIPP/E Question Sets
            </Link>{" "}
            for additional practice with multiple choice questions in the style of the test.
          </p>
        </div>
      }
    />
    <ExtendedDescription
      title="Frequently Asked Questions"
      description={
        <div className="extended-description faq">
          <p>
            <strong>Why do people love the CIPP/E Study Plan?</strong>
          </p>

          <p style={{ marginBottom: "1.25em" }}>
            The Study Plan is an exhaustive course on the concepts you need to understand for the CIPP/E exam. The email
            series, which is composed of 105 emails, comprehensively covers each study topic in the{" "}
            <a href="https://iapp.org/certify/get-certified/cippe/" target="_blank" rel="noreferrer">
              IAPP Body of Knowledge
            </a>
            , reconceptualizing the content in a thorough, reader-friendly, and memorable format. After using the Study
            Plan to guide your review, we're confident you'll feel well prepared for test day.
          </p>

          <p>
            <strong>What's included in the CIPP/E Study Plan?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            The Study Plan is comprised of 105 emails to walk you through all the content required for the exam as
            described in the{" "}
            <a href="https://iapp.org/certify/get-certified/cippe/" target="_blank" rel="noreferrer">
              IAPP Body of Knowledge
            </a>{" "}
            for the exam. Furthermore, it provides extra resources to advance your career as a Data Privacy
            professional. Finally, the series includes a set of study tips to improve your effectiveness at preparing
            for this exam or any future certifications.
          </p>
        </div>
      }
    />
  </div>
);

const rest = (handleShowClick) => {
  return (
    <>
      <FreeTrial product={PRODUCT} />

      <div className="white-box carousel-container">
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Screenshots
        </h4>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          keyBoardControl={true}
          infinite={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          // @ts-ignore
          dotListclassName="custom-dot-list-style"
          itemclassName="carousel-item"
          shouldResetAutoplay={false}
        >
          <img src={email0} alt="study plan email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          <img src={email1} alt="study plan email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          <img src={email2} alt="study plan email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          <img src={email3} alt="study plan email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          <img src={email4} alt="study plan email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
        </Carousel>
      </div>

      <BuyNowButton margins="60px" paymentName={"cippe-study-plan"} freeTrial={true} />
    </>
  );
};
