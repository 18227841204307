import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import enterprise from "../img/products/membership/enterprise.webp";

export default function EnterpriseBanner() {
  const showTablet = useMediaQuery("(min-width:660px)");
  const showDesktop = useMediaQuery("(min-width:900px)");

  return (
    <Link to="/enterprise">
      <div className="product-banner" style={{ height: 180 }}>
        <div style={{ width: "calc((100vw - 2000px)/2)" }} />
        <img src={enterprise} alt="smiling Data Strategists" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "40px 20px 40px 20px",
            color: "#363636",
            justifyContent: "center",
          }}
        >
          <p style={{ fontSize: showDesktop ? "1.3em" : "1em", marginBottom: ".5em" }}>
            Do you want to improve <strong>Data Strategy</strong> at your organization?
          </p>
          {showTablet && (
            <>
              <p style={{ fontSize: "1.2em", marginBottom: ".2em" }}>
                Check out our{" "}
                <span className="fake-link">
                  <strong>Enterprise offerings</strong>
                </span>
              </p>
              {showDesktop && (
                <p>
                  Get your team speaking the same language when it comes to the management of your valuable data assets.
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </Link>
  );
}
