import { formatCurrency, formatCurrencySimple } from "../../helpers/utils";
import cardStyles from "./cardStyles";

export default function ProductCard({ productObj }) {
  return (
    <>
      <div style={cardStyles.imageContainer}>
        <img
          className="zoom-on-hover-subtle"
          src={productObj.img}
          alt={productObj.alt}
          style={{ objectFit: "cover", width: "100%" }}
        />
      </div>
      <div className="card-content" style={cardStyles.cardContent}>
        <p className="title card-title">
          {productObj.name}{" "}
          {productObj.price && (
            <span style={{ fontFamily: "Roboto", fontWeight: 400 }}>
              ({productObj.price % 100 ? formatCurrency(productObj.price) : formatCurrencySimple(productObj.price)}
              {productObj.unit && `${" "} ${productObj.unit}`})
            </span>
          )}
        </p>
        <div className="content" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          <p dangerouslySetInnerHTML={{ __html: productObj.description }} />
          <br />
          <br />
        </div>
      </div>

      <button className="btn btn-accent full-width bottom">More Details</button>
    </>
  );
}
