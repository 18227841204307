import EmailForm from "./EmailForm";

import inputStyles from "./inputStyles";

const EmailFormContainer: React.VFC<EmailFormProps> = ({ setEmail, setValidEmail }) => {
  return (
    <div>
      <label htmlFor={"userEmail"}>
        <p style={inputStyles.label}>
          <strong>Email</strong>
        </p>
      </label>
      <EmailForm setEmail={setEmail} setValidEmail={setValidEmail} />
      <br />
    </div>
  );
};

export default EmailFormContainer;
