import { excelDateToDate } from "../../helpers/utils";

import newsletters from "../../data/newsletters";

const NewsletterArchive: React.VFC = () => {
  return (
    <div className="two-col" style={{ marginBottom: "1em" }}>
      {newsletters.map((newsletter) => (
        <p key={newsletter.date}>
          <a href={newsletter.link} target="_blank" rel="noreferrer">
            {newsletter.title}
          </a>{" "}
          – {excelDateToDate(newsletter.date)}
        </p>
      ))}
    </div>
  );
};

export default NewsletterArchive;
