import { useState, useEffect, useContext } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";

import { CheckoutContext } from "../../contexts/CheckoutContext";

import ConfirmationTable from "./confirmation/ConfirmationTable";

import messages from "../../data/messages/checkoutFormMessages";
import payments from "../../data/payments";
import BackButton from "../../components/BackButton";

export default function CheckoutForm({ step, setStep, address, email, setFadeLoader, showMultiple, freeTrial }) {
  const stripe = useStripe();
  const elements = useElements();

  const checkoutContext = useContext(CheckoutContext);
  const { confirmationTable, setFinalPaymentArray } = checkoutContext.checkout;
  const { potentialAddOns } = checkoutContext.purchasesAndAddons;

  const payment = payments.find((payment) => payment.name === confirmationTable[0].paymentName);

  const [isLoading, setIsLoading] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [error, setError] = useState("");

  const message = messages.find((message) => message.type === payment.type).message;

  function handleChange(e) {
    setError("");
    if (e.complete) {
      setDisableSubmit(false);
    }
  }

  useEffect(() => {
    setFadeLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    if (!stripe || !elements) {
      // disable form submission until Stripe.js has loaded
      return;
    }

    setFinalPaymentArray(confirmationTable.filter((row) => row.selected).map((row) => row.paymentName));

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
        shipping: address,
      },
      redirect: "if_required",
    });

    if (result.error) {
      setError(result.error.message);
      setDisableSubmit(true);
    } else {
      setStep(4);
    }
  };

  return (
    <>
      {!isLoading && (
        <>
          {showMultiple && (
            <div style={{ marginBottom: 40 }}>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 900,
                    fontSize: "1.01em",
                    marginBottom: 4,
                  }}
                >
                  Review your order
                </p>
                <BackButton handleClick={() => (potentialAddOns ? setStep(1) : setStep(0))} />
              </div>
              <ConfirmationTable step={step} />
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
            <p
              style={{
                textAlign: "left",
                fontWeight: 900,
                fontSize: "1.01em",
              }}
            >
              Enter your {step === 2 ? "shipping details" : freeTrial ? "information" : "payment details"}
            </p>
            {!showMultiple && <BackButton handleClick={() => (potentialAddOns ? setStep(1) : setStep(0))} />}
          </div>
          <p style={{ fontSize: "0.9em", marginBottom: "16px", color: "#6d6e78" }}>
            One-time payment for{" "}
            {showMultiple
              ? "your order. Details"
              : `${
                  payments.find((payment) => payment.name === confirmationTable[0].paymentName).product
                }. ${message}`}{" "}
            will be sent to the email address: <strong>{email}</strong>.{" "}
            {/* {payment.type !== "physical" && (
            <span>
              If you need to change your email address,{" "}
              <span className="fake-link" onClick={() => setStep(0)}>
                please return to the start of the checkout process
              </span>
              .
            </span>
          )} */}
          </p>
        </>
      )}

      <form onSubmit={handleSubmit} style={{ marginTop: "16px", marginBottom: "2em" }}>
        <PaymentElement onReady={() => setIsLoading(false)} onChange={(e) => handleChange(e)} />
        {error && (
          <div
            style={{
              marginTop: 20,
              padding: 10,
              fontSize: 14,
              backgroundColor: "#bdd8da",
              color: "#011220",
              border: "1px solid #959393",
            }}
          >
            {error}
          </div>
        )}
        {!isLoading && (
          <>
            <br />
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: 20 }}>
              <button
                className="btn btn-accent"
                type="submit"
                style={{
                  width: "200px",
                  padding: "10px 20px",
                }}
                disabled={disableSubmit}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </form>
    </>
  );
}
