import { useContext } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { LayoutContext } from "../../../contexts/LayoutContext";

import HeroButton from "./HeroButton";
import HeroPeople from "./HeroPeople";

const Hero: React.VFC = () => {
  const { navHeight } = useContext(LayoutContext);
  const desktop = useMediaQuery("(min-width:600px) and (min-height: 920px)");

  return (
    <>
      <div
        style={{
          position: "relative",
          height: `calc(100vh - ${navHeight}px - 170px)`,
          minHeight: `calc(670px - ${navHeight}px)`,
          maxHeight: 1500,
          display: "flex",
          justifyContent: "center",
          minWidth: 300,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            height: `calc(100vh - ${navHeight}px - 150px)`,
            minHeight: `calc(700px - ${navHeight}px)`,
            maxHeight: 1500,
            width: "100%",
            zIndex: 2,
            maxWidth: 2000,
          }}
        >
          <HeroPeople />
          <div
            style={{
              position: "absolute",
              top: desktop ? "16%" : 35,
              left: "10%",
              color: "#051f36",
              textShadow: "-2px 2px 2px rgba(0, 0, 0, 0.2)",
              zIndex: 1000,
              maxWidth: "90%",
              overflow: "hidden",
            }}
          >
            <p
              style={{
                fontSize: desktop ? "5em" : "4em",
                fontFamily: "Permanent Marker",
                maxWidth: 600,
                lineHeight: 0.8,
              }}
            >
              Ace your certifications
            </p>
            <p style={{ fontSize: desktop ? "2.4em" : "1.1em", marginTop: 20, marginLeft: desktop ? 20 : 0 }}>
              and save time
            </p>
            <p
              style={{
                fontSize: desktop ? "2.4em" : "1.4em",
                marginTop: -4,
                marginLeft: desktop ? 30 : 0,
              }}
            >
              with quality{" "}
              <Link to="/products" style={{ borderBottomColor: "#fe7f78", borderBottomWidth: 4 }} id="product">
                study materials
              </Link>
            </p>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: desktop ? "10%" : "5%",
              left: "10%",
              width: "50vw",
              color: "white",
            }}
          >
            <HeroButton link="/start-here" text="Get Certified" />
            <HeroButton link="/advance-your-career" text="Advance your Career" />
            <HeroButton link="/enterprise" text="Training at your Office" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
