import testimonials from "../../../data/testimonials";

import NewTestimonialFlipCard from "../../../components/new-testimonial-flip-card/NewTestimonialFlipCard";

export default function CDMPTestimonials() {
  let testimonialsToShow = [];

  testimonialsToShow.push(
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-002"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-001"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-001"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-notes-001"),
    testimonials.find((testimonial) => testimonial.id === "general-003"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-000")
  );

  return (
    <div>
      <div
        className="homepage"
        style={{
          padding: "20px 40px 20px 40px",
          margin: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "2.2em",
            borderBottom: "2px solid #dbdbdb",
            textAlign: "center",
            position: "relative",
            zIndex: 0,
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontSize: "1.8em",
              marginTop: "11px",
              background: "#e9eff1",
              zIndex: 1,
              display: "inline-block",
              padding: "0 20px",
              fontFamily: "Raleway",
            }}
            id="testimonials"
          >
            <strong>What People are Saying</strong>
          </p>
        </div>
        <div className="center-text-box" style={{ marginTop: 40 }}>
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            {testimonialsToShow.map((testimonial) => (
              <NewTestimonialFlipCard testimonial={testimonial} frontFontSize={1.9} fontSize={1.1} />
            ))}
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}
