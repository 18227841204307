import { Link } from "react-router-dom";

import levels from "../img/cdmp/levels.webp";
// import idea from "../img/blog/idea.webp";

import Post from "../components/blog/Post";
import PostImage from "../components/blog/PostImage";
import Contents from "../components/blog/Contents";
import BestCertifications from "../components/blog/BestCertifications";
import CDMPQuickStart from "../components/blog/quick-start/CDMPQuickStart";
import Advice from "../components/blog/Advice";

const POSTNAME = "cdmp";

const CDMP: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default CDMP;

const Rest: React.FC = () => {
  const contents: Content[] = [
    {
      anchor: "about",
      text: "About the CDMP Fundamentals Exam",
    },
    {
      anchor: "content",
      text: "What's tested on the CDMP",
    },
    {
      anchor: "prep",
      text: "How to prepare for CDMP",
    },
    {
      anchor: "motivation",
      text: "Why you should take the CDMP",
    },
    {
      anchor: "conclusion",
      text: "Conclusion",
    },
  ];

  return (
    <>
      <CDMPQuickStart />
      <p>
        All the hype around data usecases illustrates the gaps that most organizations have in terms of{" "}
        <Link to="/resources/foundations-of-data-strategy" target="_blank">
          managing their data
        </Link>{" "}
        as a strategic asset. Enter the Certified Data Management Professional (
        <a href="https://cdmp.info/" target="_blank" rel="noreferrer">
          CDMP
        </a>
        ) exam from Data Management Association International (
        <a href="https://dama.org/" target="_blank" rel="noreferrer">
          DAMA-I
        </a>
        ), the{" "}
        <Link to="/landing/best-certifications" target="_blank">
          best certification
        </Link>{" "}
        for data practitioners. This article outlines the <strong>hows and whys</strong> of gaining the valuable CDMP
        accreditation, which lays the groundwork for effective leadership in Data Strategy.
      </p>
      <PostImage postName={POSTNAME} />
      <Contents contents={contents} />
      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <p>
        Training for the{" "}
        <a href="https://cdmp.info/" target="_blank" rel="noreferrer">
          CDMP Fundamentals Exam
        </a>{" "}
        confers expertise across 14 Data Management domains. Data Management is a crucial discipline that spans{" "}
        <strong>end-to-end management of the data lifecycle</strong> through effective{" "}
        <Link to="/resources/foundations-of-data-strategy#data-governance" target="_blank">
          Data Governance
        </Link>{" "}
        and respect for{" "}
        <Link to="/resources/oath" target="_blank">
          Data Ethics
        </Link>
        .
      </p>
      <p>
        The test is <strong>open book</strong>, but the <strong>100 questions</strong> on the exam must be completed
        within <strong>90 minutes</strong> — not a lot of time to be looking things up. Therefore, it's important to be
        extremely familiar with the reference material, the 590-page{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>Data Management Body of Knowledge (DMBOK)</em>
        </a>
        .
      </p>
      <p>
        When you schedule the exam ($300), DAMA provides 200 practice questions that are pretty reflective of the
        difficulty of the actual exam. As a further resource, we offer{" "}
        <Link to="/products/cdmp-question-sets" target="_blank">
          CDMP Question Sets
        </Link>{" "}
        that include an explanation and reference for each correct answer. You can also check out{" "}
        <Link to="/resources/cdmp-practice-questions" target="_blank">
          this article
        </Link>
        , which provides more information about the structure of the exam.
      </p>
      <Advice id="cdmp-004" />

      <p>
        You'll take the exam in an online test-taking platform while monitored via webcam ($11 proctoring fee). The
        format is <strong>multiple choice — choose the single correct option out of five</strong>.
        {/* You can mark questions and come back to them.  */}
        At the conclusion of the test, you will receive immediate feedback on your score.
      </p>

      <p>
        <strong>Anything over 60% is considered passing</strong>. This is just fine if you're interested in attaining
        your CDMP Associate and moving along. If you're interested in the advanced tiers of CDMP certification, you'll
        have to pass with a 70% (CDMP Practitioner) or 80% (CDMP Master).{" "}
        {/* To get certified at the highest level,{" "}
        <a href="https://www.dama.org/team/cdmp-fellows" target="_blank" rel="noreferrer">
          CDMP Fellow
        </a>
        , you'll need to attain the Master certification and also demonstrate industry experience and contribution to
        the field.  */}
        These advanced certifications also require passing two{" "}
        <Link to="/resources/cdmp-specialist-exam" target="_blank">
          Specialist Exams
        </Link>
        .
      </p>
      <figure>
        <img
          src={levels}
          alt="CDMP certification levels"
          // onClick={handleShowClick}
        />
        <figcaption>
          via{" "}
          <a href="https://cdmp.info/about/" target="_blank" rel="noreferrer">
            DAMA
          </a>
        </figcaption>
      </figure>
      <p>
        This brings me to my final point, which is about why — purely from a <strong>career advancement</strong>{" "}
        standpoint — you should choose to put yourself through the studying and exam-taking process for CDMP:&nbsp;
        certification from DAMA is associated with <strong>high-end positions</strong> in leadership, management, and
        data architecture. Increasingly, enterprise roles and federal contracts related to data management are
        requesting CDMP certification. You may be interested in{" "}
        <a href="https://jonshaulis.com/index.php/2019/11/26/hurray-i-earned-my-cdmp/" target="_blank" rel="noreferrer">
          this blog post
        </a>{" "}
        that provides more detail about one data practitioner's experience getting certified and analyzing job
        opportunities that require CDMP accreditation. We also offer a{" "}
        <Link to="/products/career-coaching" target="_blank">
          Career Coaching
        </Link>{" "}
        service if you want to discuss how the CDMP fits into your career goals.
      </p>
      <Advice id="cdmp-005" />

      <p style={{ marginTop: "2em" }}>
        <strong>Pros</strong>:
      </p>
      <ul>
        <li>Provides well-rounded knowledge of Data Management</li>
        <li>Open book test means less time spent on rote memorization</li>
        <li>Four tiers for different levels of data practitioners</li>
        <li>60% score requirement to pass lowest level of certification</li>
        <li>Associated with elite roles</li>
        <li>
          Provides membership to{" "}
          <a href="https://www.dama.org/cpages/home" target="_blank" rel="noreferrer">
            DAMA International
          </a>
        </li>
        <li>
          $311 exam fee is cheaper than other data-related certifications from{" "}
          <a href="https://learn.microsoft.com/en-us/credentials/" target="_blank" rel="noreferrer">
            Microsoft
          </a>{" "}
          and{" "}
          <a href="https://www.opengroup.org/certifications" target="_blank" rel="noreferrer">
            The Open Group
          </a>
        </li>
      </ul>
      <p>
        <strong>Cons</strong>:
      </p>
      <ul>
        <li>
          DAMA is not backed by a major tech company (e.g., Amazon, Google, Microsoft) that is actively pushing
          marketing efforts and driving brand recognition for the CDMP — this means that the credential is likely to be
          recognized as valuable mainly among individuals who are already familiar with Data Management
        </li>
        <li>
          $311 exam fee is relatively expensive compared to{" "}
          <a
            href="https://nicolejaneway.medium.com/how-to-ace-the-aws-cloud-practitioner-certification-with-minimal-effort-39f10f43146"
            target="_blank"
            rel="noreferrer"
          >
            AWS Cloud Practitioner cert
          </a>{" "}
          ($100) or{" "}
          <a href="https://cloud.google.com/certification" target="_blank" rel="noreferrer">
            GCP certs
          </a>{" "}
          ($200)
        </li>
      </ul>
      <p>
        <strong>Alternatives</strong>:
      </p>
      <ul>
        <li>
          <em>Microsoft Certified Solutions Associate</em> (
          <a href="https://www.microsoft.com/en-us/learning/mcsa-certification.aspx" target="_blank" rel="noreferrer">
            MCSA
          </a>
          ) — modularized certifications focusing on various Microsoft products ($330+)
        </li>
        <li>
          <em>Microsoft Certified Solutions Expert</em> (
          <a href="https://www.microsoft.com/en-us/learning/mcse-certification.aspx" target="_blank" rel="noreferrer">
            MCSE
          </a>
          ) — builds on the MCSA with integrated certifications on topics such as{" "}
          <a
            href="https://www.microsoft.com/en-us/learning/mcse-core-infrastructure.aspx"
            target="_blank"
            rel="noreferrer"
          >
            Core Infrastructure
          </a>
          ,{" "}
          <a
            href="https://www.microsoft.com/en-us/learning/mcse-data-management-analytics.aspx"
            target="_blank"
            rel="noreferrer"
          >
            Data Management &amp; Analytics
          </a>
          , and{" "}
          <a
            href="https://www.microsoft.com/en-us/learning/mcse-productivity-certification.aspx"
            target="_blank"
            rel="noreferrer"
          >
            Productivity
          </a>{" "}
          ($495+)
        </li>
        <li>
          <em>The Open Group Architecture Framework</em> (
          <a
            href="https://www.opengroup.org/certifications/togaf-individual-certification"
            target="_blank"
            rel="noreferrer"
          >
            TOGAF
          </a>
          ) — various levels of certification on high-level framework for software development and enterprise
          architecture methodology ($550+)
        </li>
        <li>
          <em>Scaled Agile Framework</em> (
          <a href="https://www.scaledagile.com/certification-basics/" target="_blank" rel="noreferrer">
            SAFe
          </a>
          ) — role-based certifications for software engineering teams ($995)
        </li>
      </ul>
      <BestCertifications />
      {/* <p>
        <Link to="/landing/best-certifications" target="_blank">
          See more of our top picks for data-related certifications
        </Link>
      </p>

      <figure>
        <img src={idea} alt="" />
        <figcaption>
          Photo by{" "}
          <a href="https://unsplash.com/photos/1K6IQsQbizI" target="_blank" rel="noreferrer">
            Franki Chamaki
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/photos/1K6IQsQbizI" target="_blank" rel="noreferrer">
            Unsplash
          </a>
          .&nbsp; The signage is a trademark of{" "}
          <a href="https://www.hivery.com/" target="_blank" rel="noreferrer">
            Hivery
          </a>
          , a company that leverages AI for the retail industry.
        </figcaption>
      </figure> */}
      <h2 id={contents[1].anchor}>{contents[1].text}</h2>
      <p>The CDMP covers 14 topics, listed below in order of priority:</p>
      <ul>
        <li>
          <strong>Data Governance</strong> (11%):&nbsp; practices and processes to ensure formal management of data
          assets.{" "}
          <Link to="/resources/foundations-of-data-strategy#data-governance" target="_blank">
            Read more
          </Link>
          .
        </li>
        <li>
          <strong>Data Quality</strong> (11%):&nbsp; assuring data is fit for consumption based on its accuracy,
          completeness, consistency, integrity, reasonability, timeliness, uniqueness/deduplication, validity, and
          accessibility.{" "}
          <Link to="/resources/foundations-of-data-strategy#data-quality" target="_blank">
            Read more
          </Link>
          .
        </li>
        <li>
          <strong>Data Modeling & Design</strong> (11%):&nbsp; translation of business needs into technical
          specifications.{" "}
          <Link to="/resources/foundations-of-data-strategy#data-modeling" target="_blank">
            Read more
          </Link>
          .
        </li>
        <li>
          <strong>Metadata Management</strong> (11%):&nbsp; managing data about data, including definitions, structures,
          and classifications.
        </li>
        <li>
          <strong>Master and Reference Data Management</strong> (10%):&nbsp; reference data is information used to
          categorize other data found in a database or information that is solely for relating data in a database to
          information beyond the boundaries of the organization. Master reference data refers to information that is
          shared across a number of systems within the organization.{" "}
          <Link to="/resources/foundations-of-data-strategy#master-and-reference-data" target="_blank">
            Read more
          </Link>
          .
        </li>
        <li>
          <strong>Data Warehousing & Business Intelligence</strong> (10%):&nbsp; a data warehouse stores information
          from operational systems (as well as other data resources, potentially) in a way that is optimized to support
          decision-making processes. Business intelligence refers to the use of technology to gather and analyze data,
          then translate it into useful information.{" "}
          <Link to="/resources/warehouse" target="_blank">
            Read more
          </Link>
          .
        </li>
        <li>
          <strong>Data Architecture</strong> (6%):&nbsp; specifications to describe existing state, define data
          requirements, guide data integration, and control data assets, according to the organization's Data Strategy.{" "}
          <Link to="/resources/foundations-of-data-strategy#data-architecture" target="_blank">
            Read more
          </Link>
          .
        </li>
        <li>
          <strong>Data Storage & Operations</strong> (6%):&nbsp; characterization of hardware or software that holds,
          deletes, backs up, organizes, and secures an organization's information.
        </li>
        <li>
          <strong>Data Security</strong> (6%):&nbsp; implementation of policies and procedures to ensure people and
          things take the right actions with data and information assets, even in the presence of malicious inputs.{" "}
          <a href="https://owasp.org/www-project-data-security-top-10/" target="_blank" rel="noreferrer">
            Read more
          </a>
          .
        </li>
        <li>
          <strong>Data Integration & Interoperability</strong> (6%):&nbsp; use of technical and business processes to
          merge data from different sources, with the goal of readily and efficiently providing access to valuable
          information.
        </li>
        <li>
          <strong>Document & Content Management</strong> (6%):&nbsp; technologies, methods, and tools used to organize
          and store an organization's documents.
        </li>
        <li>
          <strong>Data Management</strong> (2%):&nbsp; end-to-end management of data, including collection, control,
          protection, delivery, and enhancement.{" "}
          <Link to="/resources/foundations-of-data-strategy" target="_blank">
            Read more
          </Link>
          .
        </li>
        <li>
          <strong>Data Ethics</strong> (2%):&nbsp; code of conduct encompassing data handling, algorithms, and other
          practices to ensure that data is used appropriately in a moral context.{" "}
          <Link to="/resources/oath" target="_blank">
            Read more
          </Link>
          .
        </li>
        <li>
          <strong>Big Data</strong> (2%):&nbsp; extremely large datasets, often composed of various structured,
          unstructured, and semi-structured data types.{" "}
          <Link to="/resources/data-science-for-technology-leaders" target="_blank">
            Read more
          </Link>
          .
        </li>
      </ul>
      <p>
        One more thing to know about the composition of the exam is that 60% of content comes directly from the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        . 20% of questions address familiar concepts but may use different words than the book. And finally, 20% of
        questions are practical, multidisciplinary, and/or require the test-taker to apply knowledge from the{" "}
        <em>DMBOK</em> to new situations.
      </p>
      {/* <blockquote style={{ marginTop: 57.6, marginBottom: 57.6 }}>
        <p>
          "The very existence of an organization can be threatened by poor quality data"
          <br />—{" "}
          <a href="https://scholar.google.com/citations?user=bJoq2dEAAAAJ&hl=en" target="_blank" rel="noreferrer">
            Joe Peppard
          </a>
          , Principal Research Scientist at MIT Sloan School of Management
        </p>
      </blockquote> */}
      <Advice id="cdmp-000" />

      <h2 id={contents[2].anchor}>{contents[2].text}</h2>
      <p>
        Given that CDMP is an open book test, to study for the exam, all that's needed to prepare is DAMA's{" "}
        <em>Data Management Body of Knowledge</em> (
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        ). The latest version is the <strong>v2 Revised Edition</strong>, and the exam has been updated as of{" "}
        <strong>November 2024</strong> to reflect the{" "}
        <a href="https://www.dama.org/cpages/changes-to-dama-dmbok-2" target="_blank" rel="noreferrer">
          small changes
        </a>{" "}
        in this version relative to the <em>DMBOK</em> v2.
      </p>
      <p>
        The exam contains <strong>100 multiple choice questions</strong> to be answered in <strong>90 minutes</strong>.
        Take a look at{" "}
        <Link to="/resources/cdmp-practice-questions#practice-questions-fundamentals" target="_blank">
          this post
        </Link>{" "}
        to review some sample questions.
      </p>
      <p>
        To prepare for the test, you'll need to review the first 14 chapters of the <em>DMBOK</em>, which come to around{" "}
        <strong>500 pages</strong>. We recommend focusing your studies on the <strong>seven chapters</strong> that are
        options for the{" "}
        <Link to="/resources/cdmp-specialist-exam" target="_blank">
          {" "}
          Specialist Exams
        </Link>{" "}
        because these chapters actually comprise <strong>70% of the questions</strong> on the test. Check out{" "}
        <Link to="/resources/cdmp-training-course" target="_blank">
          this article
        </Link>{" "}
        for more study tips for each phase of your CDMP journey.
      </p>
      <p>
        You may also be interested in Data Strategy Professionals'{" "}
        <Link to="/products/cdmp-study-plan" target="_blank">
          90-day Study Plan
        </Link>
        . This includes a <strong>study and review schedule</strong> to keep you on track with your reading. The
        scientifically-backed study approach leverages the 80/20 principle to help you focus on the most important
        information. The{" "}
        <Link to="/products/cdmp-study-plan" target="_blank">
          Study Plan
        </Link>{" "}
        also includes <strong>vocabulary and key concepts</strong> for each chapter. And because becoming a CDMP is
        about more than a test score, the plan includes{" "}
        <strong>external resources such as thought-provoking articles, case studies, and interview questions</strong> to
        prepare you for Data Management in the real world setting. If you're on a different timeline to achieve Data
        Strategy mastery through the CDMP, that's no problem! You can select "Immediate Access" to receive access to the
        full{" "}
        <Link to="/products/cdmp-study-plan" target="_blank">
          CDMP Study Plan
        </Link>{" "}
        so you can review it at your own pace.
      </p>
      <p>
        We also offer the{" "}
        <Link to="/products/cdmp-fundamentals-notes" target="_blank">
          CDMP Fundamentals Notes
        </Link>{" "}
        that provide a condensed version of the <em>DMBOK</em> to help you review the material quickly. Our{" "}
        <Link to="/products/cdmp-question-sets" target="_blank">
          Question Sets
        </Link>{" "}
        and{" "}
        <Link to="/products/cdmp-exam-readiness-check" target="_blank">
          Exam Preparedness Check
        </Link>{" "}
        ensure you're ready to effortlessly ace the test. You can pick up all our study materials at once through our{" "}
        <Link to="/products/cdmp-fundamentals-bundle" target="_blank">
          CDMP Fundamentals Bundle
        </Link>
        .
      </p>
      <div style={{ maxWidth: 1000, margin: "3em 0" }}>
        <div className="video-box" style={{ margin: "1em auto" }}>
          <iframe
            src="https://www.youtube.com/embed/7_rugpo3a7c?si=1MKLxiKrCPQq3DZn"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            frameBorder="0"
            height="315"
          />
        </div>
      </div>
      <h4>Study tips</h4>
      <p>
        📘 <strong>Buy the</strong>{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <strong>
            <em>DMBOK</em>
          </strong>
        </a>{" "}
        <strong>right now</strong>. The exam is <strong>open book</strong>, and the <em>DMBOK</em> is legitimately super
        useful as a reference for Data Management work. The <em>DMBOK</em> occupies a prime position on my desk. As a
        consultant, I frequently found myself referencing my highlights and sticky notes to address clients' questions.
        In terms of how to study <em>DMBOK</em>, one test taker recommended 4–6 hours per weekend for 8–10 weeks.
        Another approach could be reading a couple pages each morning and evening. However you tackle it, make sure
        you're incorporating{" "}
        <a
          href="https://medium.com/@pruthvikumar.123/using-spaced-repetition-to-supercharge-your-programming-skills-a1b355934f61"
          target="_blank"
          rel="noreferrer"
        >
          spaced repetition
        </a>{" "}
        into your studying methodology. We provide more information about study skills in{" "}
        <Link to="/resources/cdmp-training-course" target="_blank">
          this article
        </Link>
        .
      </p>
      <p>
        💸 <strong>Buy the</strong>{" "}
        <a href="https://cdmp.info/about/" target="_blank" rel="noreferrer">
          <strong>CDMP exam</strong>
        </a>{" "}
        <strong>when you feel ready to take the test</strong>. With the move to the Brightspace platform in 2024, DAMA
        is now limiting test-takers to 12 months of eligibility after purchase of the exam. The purchase grants aspiring
        certificants access to a <strong>test bank of 200 questions</strong> that simulate the real exam.
      </p>
      <p>
        🔖{" "}
        <strong>
          Highlight and sticky note the <em>DMBOK</em>
        </strong>
        . Okay, this one's obvious if you have a physical copy of the book. You'll definitely want to use a highlighter
        and sticky notes to indicate key concepts. I put <strong>all sticky notes on one side of the book</strong> for
        easy referencing. I also recommend orienting your writing sideways on the tabs so they don't stick out of the
        book as far.
      </p>
      {/* <p>
        ❗️<strong>Indicate the start of each chapter</strong>. Use wide sticky notes or notes of a specific color to
        mark the start of each chapter. This was invaluable during the test to quickly find content associated with a
        specific topic.
      </p> */}
      <p>
        🔨 <strong>Work with the chapter framework</strong>. All the chapters of the <em>DMBOK</em> follow the structure{" "}
        <em>
          Introduction, Activities, Tools, Techniques, Implementation Guidelines, Governance, Works Cited / Recommended.
        </em>{" "}
        Focus your studying on the <strong>overview and technical sections</strong> that get more play on the exam
        relative to implementation and organizational sections.
      </p>
      <p>
        💙 <strong>Read in order then review by priority</strong>. The topics of the <em>DMBOK</em> are arranged in an
        intuitive order — the sequence you might assess these topics during a Data Management engagement. However, the
        proportion of each of the 14 topics tested on the exam ranges from 11% for foundational areas such as Data
        Governance to 2% for more esoteric topics such as Big Data. On your second reading of the <em>DMBOK</em>, I
        recommend studying in <strong>priority order</strong>.
      </p>
      <p>
        ❓ <strong>Use the official practice test extensively</strong>. You will see a handful of the questions provided
        by DAMA again when you take the exam. If you are aiming to optimize your score (i.e., because you are interested
        in continuing to the{" "}
        <Link to="/resources/cdmp-specialist-exam" target="_blank">
          Master-level
        </Link>{" "}
        credential), you should retake the practice test until you are consistently scoring 100%. That way, when you see
        one of these questions on the exam, you can answer it quickly, saving you time that you can invest in
        researching the more challenging questions.
      </p>
      <p>
        💻 <strong>Plan to use the ebook for the exam</strong>. Certain questions will ask about exact wording from the{" "}
        <em>DMBOK</em>. To maximize your score, you need to use the ebook copy of the <em>DMBOK</em> on the exam. If you
        have a MacBook, we recommend{" "}
        <a href="https://pdfexpert.com/" target="_blank" rel="noreferrer">
          PDF Expert
        </a>
        . If you have a different operating system, your best option is{" "}
        <a href="https://get.adobe.com/reader/" target="_blank" rel="noreferrer">
          Adobe Reader
        </a>
        . Both tools are free and effective ways to search the book using <code>ctrl + f</code> as you're taking the
        exam.
      </p>
      <p style={{ marginBottom: "3em" }}>
        ⭐️{" "}
        <strong>
          Join the CDMP Study Group on{" "}
          <a href="https://www.facebook.com/groups/346145433213551/" target="_blank" rel="noreferrer">
            Facebook
          </a>{" "}
          or{" "}
          <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer">
            LinkedIn
          </a>
        </strong>
        . Use this community to compare notes, ask questions, and find study partners.
      </p>
      <Advice id="cdmp-001" />

      {/* <figure>
            <img src={crossedArms} alt="man with crossed arms" className="box-shadow" />
            <figcaption>
              Photo by{" "}
              <a href="https://unsplash.com/@milancsizmadia" target="_blank" rel="noreferrer">
                Milan Csizmadia
              </a>{" "}
              on{" "}
              <a href="https://unsplash.com/photos/rCa8as1KkU0" target="_blank" rel="noreferrer">
                Unsplash
              </a>
            </figcaption>
          </figure> */}
      <h2 id="motivation">Why you should take the CDMP</h2>
      <p>Still not convinced of the importance of Data Management fundamentals?</p>
      <blockquote>
        <p>"Successwise, you're better off being good at two complementary skills than being excellent at one"</p>
      </blockquote>
      <p>
        Scott Adams, author and creator of the Dilbert comics,{" "}
        <a href="https://amzn.to/32AZik6" target="_blank" rel="noreferrer">
          offers the idea
        </a>{" "}
        that “every skill you acquire doubles your odds of success.” He acknowledges this may be somewhat of an
        oversimplification — “obviously some skills are more valuable than others, and the twelfth skill you acquire
        might have less value than each of the first eleven” — but the point is that sometimes <strong>it's</strong>{" "}
        <strong>better to go wide</strong> than to go deep.
      </p>
      <p>
        Setting aside the relative magnitude of the benefit (
        <em>because I seriously doubt it's 2x per skill… thank you, law of diminishing marginal returns</em>
        ), it seems unquestionable that broadening your skillset can lead to more significant gains relative to toiling
        away at learning one specific skill. In a nutshell, this is why I think it's important for a data scientist to
        learn Data Strategy.
      </p>
      <p>
        Generally speaking, <strong>having diversity in your skillset allows you to:</strong>
      </p>
      <ol>
        <li>
          <strong>Problem solve</strong> more effectively by drawing on cross-disciplinary learnings
        </li>
        <li>
          <strong>Communicate</strong> better with your teammates from other specialties
        </li>
        <li>
          <strong>Get your foot in the door</strong> in terms of gaining access to new projects
        </li>
      </ol>
      <p>
        Understanding{" "}
        <strong>
          Data Strategy transforms you from being a data consumer into an empowered data advocate at your organization
        </strong>
        . It's worth putting up with all the tongue twister acronyms in order to deepen your appreciation for the
        end-to-end knowledge generating process.
      </p>
      <Advice id="cdmp-002" />

      <p>
        Taking the CDMP is extremely straightforward and beneficial for technology professionals. There are no trick
        questions, the test is open book, and it costs only $311. Hopefully, this writeup has gotten you thinking about
        how attaining recognition as a CDMP would <strong>advance your career</strong>. For example:
      </p>
      <ul style={{ marginTop: "1em" }}>
        <li>You're looking for a new job related to data</li>
        <li>
          You're a data practitioner looking to move into a leadership role that spans multiple phases of the data
          lifecycle
        </li>
        <li>
          You're a consultant seeking a credential to show your client that you possess a deep understanding of Data
          Management
        </li>
        <li>
          You're working in a data-related job and you want to develop an end-to-end perspective on data-related topics
        </li>
        <li>You're someone who is driven to learn useful things!</li>
      </ul>
      <p>
        With each passing day, organizations produce more data than ever before. This creates{" "}
        <strong>significant opportunities and massive risks</strong>. A strong Data Strategy can empower experimentation
        that promotes comparative advantage. A weak Data Strategy can jeopardize an organization's very existence,
        putting customers, stakeholders, and employees at risk.
      </p>
      <p>
        Professionals who can manage <strong>data as a strategic asset</strong> are in high demand. The CDMP is the{" "}
        <strong>only certification</strong> on end-to-end Data Strategy capabilities.
      </p>
      <Advice id="cdmp-003" />

      <h2 id="conclusion">Conclusion</h2>
      <p>
        The CDMP is extremely relevant in the workplace and the{" "}
        <Link to="/resources/data-management-job-projections" target="_blank">
          marketplace
        </Link>
        . The credential is associated with high-level <strong>leadership</strong> positions. It's also{" "}
        <strong>recognized by potential clients</strong> across the commercial and governmental spheres as indicating
        deep knowledge of Data Management. And if you're just setting out on your data-related career journey, the CDMP
        is a great place to <strong>get started</strong>.
      </p>
      <p>Study tips, one more time:</p>
      <ol>
        <li>
          Buy the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>{" "}
          for the open book exam
        </li>
        <li>
          Buy the{" "}
          <a href="https://cdmp.info/about/" target="_blank" rel="noreferrer">
            CDMP exam
          </a>{" "}
          to gain access to 200 practice questions
        </li>
        <li>
          Highlight and put sticky notes in the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>
        </li>
        <li>
          Work with the chapter framework, focusing on <em>Introduction, Activities, Tools,</em> and <em>Techniques</em>
        </li>
        <li>Read the chapters in order then review by priority</li>
        <li>Use the official practice test extensively</li>
        <li>
          Plan to use the{" "}
          <a href="https://technicspub.com/dmbok2/" target="_blank" rel="noreferrer">
            ebook
          </a>{" "}
          for the exam
        </li>
        <li>
          Join the CDMP Study Group on{" "}
          <a href="https://www.facebook.com/groups/346145433213551/" target="_blank" rel="noreferrer">
            Facebook
          </a>{" "}
          or{" "}
          <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer">
            LinkedIn
          </a>
        </li>
      </ol>
    </>
  );
};
