import { Link, useHistory } from "react-router-dom";

import ProductCard from "./ProductCard";
import { BestValueIcon, NewIcon, UpdatedIcon } from "./ProductIcons";

import cardStyles from "./cardStyles";

type ProductCardContainerProps = {
  productObj: productType;
  idx: number;
  decorate?: boolean;
};

const ProductCardContainer: React.FC<ProductCardContainerProps> = ({ productObj, decorate = true }) => {
  const history = useHistory();
  return (
    <div
      className="card"
      style={decorate && productObj.bestValue ? cardStyles.card : cardStyles.defaultCard}
      onClick={() => history.push(productObj.link)}
    >
      {decorate && (
        <>
          {productObj.bestValue && <BestValueIcon />}
          {productObj.new && <NewIcon />}
          {productObj.updated && <UpdatedIcon />}
        </>
      )}
      <ProductCard productObj={productObj} />
    </div>
  );
};

export default ProductCardContainer;
