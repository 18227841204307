import React from "react";
import enterpriseStyles from "../../../helpers/enterpriseStyles";

const SmallGroupFAQs: React.VFC = () => {
  return (
    <div style={enterpriseStyles.container}>
      <p className="title product-title">Frequently Asked Questions</p>
      <p>
        <strong>How are the study materials delivered?</strong>
      </p>
      <p>
        We will provide access to the practice questions in a group-friendly format via{" "}
        <a href="https://www.polleverywhere.com/" target="_blank" rel="noreferrer">
          Poll Everywhere
        </a>
        . The introductory videos and practice questions are hosted on our <strong>Training Site</strong>, which
        participants will receive access to for the duration of their cohort. The recommended study schedule is provided
        by email.
      </p>
      <br />
      <p>
        <strong>What is the recommended duration of the study group?</strong>
      </p>
      <p>
        We typically recommend an 8-week study process. That said, we are happy to adjust the schedule to meet the needs
        of your group.
      </p>
      <br />
      <p>
        <strong>How does the pricing work?</strong>
      </p>
      <p>The price of the study materials is $280 per cohort.</p>
      <br />
      <p>
        <strong>Can Data Strategy Professionals run the study group?</strong>
      </p>
      <p>Yes, we are happy to facilitate your study group at a rate of $185 per hour.</p>
    </div>
  );
};

export default SmallGroupFAQs;
