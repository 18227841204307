import TeamCard from "../../components/about/TeamCard";
import team from "../../data/team";

import { Grid } from "@mui/material";

const Team: React.FC = () => {
  return (
    <>
      <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "stretch", pt: 1 }}>
        {team.map((member) => (
          <Grid item xs={12} sm={12} md={6} lg={4} mb={3}>
            <TeamCard teamMember={member} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Team;
