import { useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";

import BootcampFAQs from "./BootcampFAQs";
import CDMPrationale from "../cdmp-study-program/CDMPrationale";
import BootcampTestimonials from "./BootcampTestimonials";

import { responsive } from "../../../helpers/carouselStyles";

import smiling from "../../../img/products/membership/smiling_sq.webp";
import cdmp1 from "../../../img/products/enterprise/cdmp1.webp";
import cdmp2 from "../../../img/products/enterprise/cdmp2.webp";
import cdmp3 from "../../../img/products/enterprise/cdmp3.webp";
import cdmp4 from "../../../img/products/enterprise/cdmp4.webp";
import cdmp5 from "../../../img/products/enterprise/cdmp5.webp";
import cdmp6 from "../../../img/products/enterprise/cdmp6.webp";
import cdmp7 from "../../../img/products/enterprise/cdmp7.webp";
import cdmp8 from "../../../img/products/enterprise/cdmp8.webp";

import GetInTouch from "../../../components/GetInTouch";
import CustomHelmet from "../../../components/CustomHelmet";
import EightyDivider from "../../../components/EightyDivider";

const EnterpriseBootcamp: React.VFC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const carouselImgs = [];

  carouselImgs.push(cdmp1, cdmp2, cdmp3, cdmp4, cdmp5, cdmp6, cdmp8);

  return (
    <>
      <CustomHelmet
        description="CDMP Bootcamp"
        name="CDMP Bootcamp"
        link="/landing/data-strategy-bootcamp"
        img={smiling}
        alt="person writing exam questions"
      />

      <div className="landing-page" style={{ minHeight: "88vh" }}>
        <div className="landing-page-inner">
          <Link to="/enterprise">
            <span className="landing-page-close-icon">x</span>
          </Link>
          <p className="title landing-page-title">Two Day CDMP Bootcamp</p>
          <div className="landing-page-divider" style={{ marginTop: "-1.7em" }} />
          <p style={{ fontSize: "1.3em", margin: "-0.9em 0 1.5em 0" }}>
            <strong>Save time</strong> and help your team members advance their knowledge of Data Strategy with{" "}
            <strong>custom training</strong> from Data Strategy Professionals.
          </p>
          <div className="blog" style={{ fontSize: "1.15em" }}>
            <p>
              Our <strong>CDMP Bootcamp</strong> for teams provides an accelerated option to facilitate study of each of
              the 14 chapters of the{" "}
              <em>
                <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                  DMBOK
                </a>
              </em>{" "}
              tested on the{" "}
              <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
                CDMP Fundamentals Exam
              </Link>
              . The Two Day CDMP Bootcamp can be virtual, hybrid, or in-person.
            </p>
          </div>
          <GetInTouch />
          <br />

          <div className="carousel-container" style={{ margin: "3em auto 16px auto" }}>
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              ssr={true}
              keyBoardControl={true}
              infinite={true}
              arrows={true}
            >
              {carouselImgs.map((source, idx) => {
                return <img src={source} alt="participants of DAMA Georgia CDMP Bootcamp" key={idx} />;
              })}
            </Carousel>
          </div>

          <div className="blog">
            <figcaption style={{ textAlign: "center", marginBottom: "3em" }}>
              Photos of{" "}
              <a href="https://dama-ga.org/" target="_blank" rel="noreferrer">
                DAMA Georgia
              </a>{" "}
              CDMP Bootcamp from '23 and '24
            </figcaption>
          </div>
          <EightyDivider />
          <BootcampFAQs />
          <EightyDivider />
          <BootcampTestimonials />
          <EightyDivider />
          <CDMPrationale />
        </div>
      </div>
    </>
  );
};

export default EnterpriseBootcamp;
