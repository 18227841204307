import ProductTestimonialCardFlip from "./ProductTestimonialCardFlip";

export default function ProductTestimonials({ testimonialsToShow, color = "#dee8e9", showTitle = true }) {
  return (
    <div className="teal-box" style={{ background: color }}>
      {showTitle && <h4 className="box-title">What People are Saying</h4>}
      <div
        className="center-text-box"
        style={{ marginTop: 20, display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "stretch" }}
      >
        {testimonialsToShow.map((testimonial) => (
          <ProductTestimonialCardFlip
            testimonial={testimonial}
            frontFontSize={1.8}
            fontSize={1.1}
            key={testimonial.id}
          />
        ))}
      </div>
    </div>
  );
}
