import TestimonialCard from "../../../components/testimonial-flip-card/TestimonialCard";

import enterpriseStyles from "../../../helpers/enterpriseStyles";

import testimonials from "../../../data/testimonials";

const SmallGroupTestimonials: React.FC = () => {
  const testimonialsToShow = [
    testimonials.find((testimonial) => testimonial.id === "general-001"),
    testimonials.find((testimonial) => testimonial.id === "general-005"),
    testimonials.find((testimonial) => testimonial.id === "general-004"),
  ];

  return (
    <div style={{ ...enterpriseStyles.container, marginBottom: "2em" }}>
      <p className="title product-title">Testimonials</p>
      {testimonialsToShow.map((testimonial) => (
        <TestimonialCard key={testimonial.id} testimonial={testimonial} productPage />
      ))}
    </div>
  );
};

export default SmallGroupTestimonials;
