import Carousel from "react-multi-carousel";
import { useMediaQuery } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import Card from "../../../components/Card";

import journeys from "../../../data/journeys";

import { nonresponsive } from "../../../helpers/carouselStyles";

export default function CDMPJourneys() {
  const desktop = useMediaQuery("(min-width: 768px)");

  return (
    <>
      <div
        className="teal-box homepage carousel-container"
        style={{
          maxWidth: 2000,
          position: "relative",
          background: "none",
          border: "5px solid white",
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "1.8em",
            marginTop: "12px",
            fontFamily: "Raleway",
          }}
          id="journeys"
        >
          <strong>Data Strategy Journeys</strong>
        </p>
        <br />
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={nonresponsive}
          ssr={true}
          keyBoardControl={true}
          infinite={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          renderButtonGroupOutside={true}
          arrows={false}
          customButtonGroup={desktop ? <CustomButtonGroup /> : <> </>}
          itemclassName="carousel-item"
          shouldResetAutoplay={false}
        >
          {journeys.map((journey) => (
            <Card key={journey.id} person={journey} color="none" />
          ))}
        </Carousel>
        <br />
      </div>
    </>
  );
}

const CustomButtonGroup = ({ next, previous }) => {
  return (
    <div
      className="custom-button-group"
      style={{ position: "absolute", left: 0, top: "50%", width: "100%", color: "#051f36" }}
    >
      <ArrowBackIos onClick={() => previous()} style={{ marginLeft: 20, fontSize: "1.9em", cursor: "pointer" }} />
      <ArrowForwardIos
        onClick={() => next()}
        style={{ position: "absolute", right: 12, fontSize: "1.9em", cursor: "pointer" }}
      />
    </div>
  );
};
