import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import CustomHelmet from "../../components/CustomHelmet";
import CheckAnimation from "../../components/sign-up-forms/CheckAnimation";

const ThankYou: React.VFC = () => {
  const [feedback, setFeedback] = useState("");
  const [responseOk, setResponseOk] = useState(false);
  const [showError, setShowError] = useState(false);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const stars = parseInt(queryParams.get("stars"));
  const email = queryParams.get("email");

  async function handleSubmit() {
    const data = {
      records: [
        {
          fields: {
            Feedback: feedback,
            Email: email,
            Stars: stars,
          },
        },
      ],
    };

    const response = await fetch("/.netlify/functions/suggestion-airtable", {
      method: "POST",
      body: JSON.stringify(data),
    });

    if (response.ok) {
      setResponseOk(true);
      setFeedback(""); // Clear the textarea
    } else {
      setShowError(true);
    }
  }

  return (
    <>
      <CustomHelmet
        description="Data Strategy Professionals offers engaging monthly events focused on helping data practitioners save time and ace the Certified Data Management Professional (CDMP) exam."
        name="Thank you!"
        link="/thank-you"
        img="https://www.nicolejaneway.com/wp-content/uploads/2021/04/diego-ph-fIq0tET6llw-unsplash_square.jpg"
        alt="hand holding lightbulb"
      />

      <div className="home-layout">
        <span style={{ display: "flex", alignItems: "flex-end", gap: 8, marginBottom: 20 }}>
          <h1 className="title">We appreciate your input!</h1>
        </span>
        <div style={{ width: 400, minHeight: 180 }}>
          {!responseOk ? (
            <>
              <p>Let us know your thoughts:</p>
              <div style={{ display: "flex", flexDirection: "column", width: 400, gap: 8 }}>
                <textarea style={{ height: 100 }} value={feedback} onChange={(e) => setFeedback(e.target.value)} />
                <button
                  className="btn btn-accent"
                  style={{ width: "fit-content", alignSelf: "flex-end" }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </>
          ) : (
            <CheckAnimation />
          )}
        </div>
        {showError && <p>There was an error submitting your feedback. Please try again later.</p>}
      </div>
    </>
  );
};

export default ThankYou;
