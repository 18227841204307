import { useState } from "react";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";

import email0 from "../../img/products/cap-study-plan/emails/day1.2.webp";
import email1 from "../../img/products/cap-study-plan/emails/day2.1.webp";
import email2 from "../../img/products/cap-study-plan/emails/day2.2.webp";
import email3 from "../../img/products/cap-study-plan/emails/day3.1.webp";
import email4 from "../../img/products/cap-study-plan/emails/day8.webp";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import CustomModal from "../../components/CustomModal";
import SubtleTestimonialCardFlip from "../../components/testimonial-flip-card/SubtleTestimonialCardFlip";
import ExtendedDescription from "../../components/product-pages/ExtendedDescription";
import BuyNowButton from "../../components/product-pages/BuyNowButton";
import FreeTrial from "../../components/product-pages/FreeTrial";

import { responsive } from "../../helpers/carouselStyles";

import testimonials from "../../data/testimonials";

const PRODUCT = "CAP Study Plan";

export default function CAPStudyPlan() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);
    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}

      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        tealBox={tealBox}
        extendedDescription={extendedDescription}
        rest={rest(handleShowClick)}
      />
    </>
  );
}

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>What People are Saying</h4>
    <div style={{ marginTop: "2em" }}>
      <SubtleTestimonialCardFlip
        productPage
        testimonial={testimonials.find((testimonial) => testimonial.id === "cap-study-plan-000")}
      />
    </div>
  </div>
);

const extendedDescription = (
  <div className="extended-description-container">
    <ExtendedDescription
      description={
        <div className="extended-description">
          <p>
            Maximize your <strong>time, energy, and motivation</strong> to attain this valuable credential with a{" "}
            <strong>step-by-step</strong> study schedule, <strong>learning objective summaries</strong>,{" "}
            <strong>explanations of challenging concepts</strong>, and extra resources in the form of{" "}
            <strong>videos and articles</strong> to help you remember each vocabulary word.
          </p>
          <p>
            Our science-backed approach to tackling the CAP exam restructures the content of the{" "}
            <strong>official Study Guide</strong> from{" "}
            <a href="https://www.informs.org/" target="_blank" rel="noreferrer">
              INFORMS
            </a>{" "}
            to leverage the <strong>80/20 principle</strong>.
          </p>
          <h4>Features</h4>
          <ul>
            <li>
              <strong>Daily emails</strong> to guide your study of the <strong>CAP Study Guide</strong> from{" "}
              <a href="https://www.informs.org/" target="_blank" rel="noreferrer">
                INFORMS
              </a>
            </li>

            <li>
              Key <strong>concepts</strong> and <strong>vocabulary</strong> explained in a memorable and human-friendly
              way
            </li>

            <li>
              <strong>Practice questions</strong>, answers, and explanations for <strong>every chapter</strong>
            </li>

            <li>
              Support for <strong>any questions</strong> about the course at{" "}
              <a href="mailto:support@datastrategypros.com?subject=CAP%20Question%20Sets">
                support@datastrategypros.com
              </a>
            </li>
          </ul>
        </div>
      }
    />

    <ExtendedDescription
      title="Frequently Asked Questions"
      description={
        <div className="extended-description faq">
          <p>
            <strong>Why do people love the CAP Study Plan?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            This series of 75+ emails guides your study of the content recommended by{" "}
            <a href="https://www.informs.org/" target="_blank" rel="noreferrer">
              INFORMS
            </a>{" "}
            for CAP test takers. Our daily study schedule significantly builds on what's provided in the official Study
            Guide via key takeaways, reader-friendly vocabulary explanations, a breakdown of practice questions per
            chapter, detailed explanations for the correct answers, and resources to advance your career as a Data
            Analyst.
          </p>

          <p>
            <strong>How exactly does the CAP Study Plan improve on the official CAP Study Guide?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            First, we restructured the content of the Study Guide to leverage the 80/20 principle. This means you stay
            focused on the content that is the most important for passing the exam. For each of the seven domains on the
            exam, we offer lesson objective summaries and video and/or article links for each technical vocabulary term.
            Whether you're an auditory or visual learner, we want to be sure you don't get lost. Finally, we wrap up
            each domain with a knowledge check to ensure you're understanding what you're reading. In summary, the daily
            emails of the CAP Study Plan will save you time and ensure you stay on track to complete the exam
            successfully in 65 days.
          </p>

          <p>
            <strong>What's included in the CAP Study Plan?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            The comprehensive email course includes 75+ emails, 21 practice questions, and video and articles to explain
            each vocabulary term. We've added trusted resources to help you remember what you're reading and advance
            your understanding of Data Analytics. Plus, we include nine science-backed study tips so you can get the
            most out of the time you spend studying for this test or any future certification.
          </p>

          <p>
            <strong>
              Is the CAP Study Plan good preparation for the{" "}
              <a href="https://www.certifiedanalytics.org/acap" target="_blank" rel="noreferrer">
                aCAP
              </a>{" "}
              exam?
            </strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Yes!! The Study Plan is excellent preparation for the{" "}
            <a href="https://www.certifiedanalytics.org/acap" target="_blank" rel="noreferrer">
              aCAP exam
            </a>
            , which is the version of the test suitable for new entrants to the field of Data Analytics. The aCAP
            focuses on foundational skills such as data interpretation, data visualization, and introductory analytical
            methods.
          </p>

          <p>
            <strong>Where did the questions included in the CAP Study Plan come from?</strong>
          </p>

          <p style={{ marginBottom: "1.25em" }}>
            In the CAP Study Plan, we use the{" "}
            <a href="https://www.certifiedanalytics.org/sample-test" target="_blank" rel="noreferrer">
              official practice questions
            </a>{" "}
            provided by INFORMS. If you feel you would benefit from additional, new, never-before-seen practice
            questions, you can purchase them{" "}
            <Link to="/products/cap-exam-questions" target="_blank" rel="noreferrer">
              here
            </Link>
            .
          </p>
        </div>
      }
    />
  </div>
);

const rest = (handleShowClick) => (
  <>
    <FreeTrial product={PRODUCT} />

    <div className="white-box carousel-container">
      <h4 className="box-title" style={{ textAlign: "center" }}>
        Screenshots
      </h4>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        keyBoardControl={true}
        infinite={true}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        // @ts-ignore
        dotListclassName="custom-dot-list-style"
        itemclassName="carousel-item"
        shouldResetAutoplay={false}
      >
        <img src={email0} alt="study plan email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
        <img src={email1} alt="study plan email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
        <img src={email2} alt="study plan email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
        <img src={email3} alt="study plan email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
        <img src={email4} alt="study plan email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
      </Carousel>
    </div>

    <BuyNowButton margins="60px" paymentName={"cap-study-plan"} freeTrial={true} />
  </>
);
