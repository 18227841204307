import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material";

import Post from "../components/blog/Post";

import charter from "../data/charter";
import PostImage from "../components/blog/PostImage";

const POSTNAME = "data-governance-charter";

const styles = {
  bulletList: {
    marginTop: 0,
    marginBottom: 0,
  },
  numericOutline1: {
    marginTop: 2,
    marginLeft: 52,
    marginBottom: 0,
  },
  numericOutline2: {
    marginTop: 2,
    marginLeft: 86,
    marginBottom: 0,
  },
};

const Charter = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default Charter;

const Rest = () => {
  return (
    <>
      <p>
        A Data Governance Charter is a document that outlines the core strategy and process related to the exercise of
        control and authority over an organization's data assets. In this article, we will discuss the standard
        structure and elements of a Data Governance Charter so that you can craft this document to support your
        organization's{" "}
        <Link to="/resources/foundations-of-data-strategy" target="_blank">
          Data Strategy
        </Link>
        .
      </p>
      <p>
        For more help with your organization's Data Strategy documents, check out our{" "}
        <Link to="/landing/document-drafting-and-review">Document Drafting & Review</Link> service.
      </p>

      <PostImage postName={POSTNAME} />

      <h4>Contents</h4>
      <ul style={styles.bulletList}>
        <li>
          <a href="#about">About the Data Governance Charter</a>
        </li>
        <li>
          <a href="#sample">Sample Document</a>
        </li>
      </ul>
      <ol style={styles.numericOutline1}>
        <li>
          <a href="#executive-summary">Executive Summary</a>
        </li>
        <li>
          <a href="#intro">Introduction</a>
        </li>
      </ol>
      <ol type="a" style={styles.numericOutline2}>
        <li>
          <a href="#problem-statement">Problem Statement</a>
        </li>
        <li>
          <a href="#scope">Scope</a>
        </li>
      </ol>
      <ol style={styles.numericOutline1} start={3}>
        <li>
          <a href="#guiding-principles">Guiding Principles</a>
        </li>
        <li>
          <a href="#goals">Goals</a>
        </li>
      </ol>
      <ol type="a" style={styles.numericOutline2}>
        <li>
          <a href="#accessible">Accessible</a>
        </li>
        <li>
          <a href="#understandable">Understandable</a>
        </li>
        <li>
          <a href="#trustworthy">Trustworthy</a>
        </li>
        <li>
          <a href="#interoperable">Interoperable</a>
        </li>
        <li>
          <a href="#secure">Secure</a>
        </li>
      </ol>
      <ol style={styles.numericOutline1} start={5}>
        <li>
          <a href="#implementation">Implementation</a>
        </li>
        <li>
          <a href="#operating-framework">Operating Framework</a>
        </li>
      </ol>
      <ol type="a" style={styles.numericOutline2}>
        <li>
          <a href="#dgsc">Data Governance Steering Committee (DGSC)</a>
        </li>
        <li>
          <a href="#dgpo">Data Governance Program Office (DGPO)</a>
        </li>
        <li>
          <a href="#data-steward-council">Data Steward Council</a>
        </li>
      </ol>
      <ol style={styles.numericOutline1} start={7}>
        <li>
          <a href="#metrics">Metrics</a>
        </li>
        <li>
          <a href="#conclusion">Conclusion</a>
        </li>
      </ol>
      <ul style={styles.bulletList}>
        <li>
          <a href="#sources">Sources</a>
        </li>
        <li>
          <a href="#next-steps">Summary and Next Steps</a>
        </li>
        <li>
          <a href="#related-posts">Related Posts</a>
        </li>
      </ul>

      <h2 id="about">About the Data Governance Charter</h2>
      <p>
        According to the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        , a Data Governance Charter "[i]dentifies the business drivers, vision, mission, and principles for data
        governance, including readiness assessment, internal process discovery, and current issues or success criteria"
        (ch. 3, section 2.5 Develop Data Governance Strategy). Data Governance refers to "exercise of authority and
        control (planning, monitoring, and enforcement) over the management of data assets" (ch. 3, section 1
        Introduction). Overall, this document is a helpful resource for setting up many{" "}
        <Link to="/resources/data-strategy-best-practices" target="_blank">
          Data Strategy best practices
        </Link>
        .
      </p>
      <h2 id="sample">Sample Document</h2>
      <TableContainer>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
            border: "1px solid #dbdbdb",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 900, minWidth: 180, paddingBottom: 0, fontSize: 17 }}>Text</TableCell>
              <TableCell
                sx={{ fontWeight: 900, minWidth: 180, backgroundColor: "#dbdbdb", paddingBottom: 0, fontSize: 17 }}
              >
                Notes
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {charter.map((section) => (
              <TableRow key={section.id}>
                <TableCell
                  dangerouslySetInnerHTML={{ __html: section.text }}
                  style={{ width: "66%", paddingBottom: 0 }}
                />
                <TableCell
                  sx={{ backgroundColor: "#dbdbdb", verticalAlign: "top" }}
                  dangerouslySetInnerHTML={{ __html: section.notes }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h2 id="sources">Sources</h2>
      <p>
        Here are several publicly available Data Governance Charters that we reviewed to inform our sample document:
      </p>
      <ul>
        <li>
          <a
            href="https://media.defense.gov/2020/Oct/08/2002514180/-1/-1/0/DOD-DATA-STRATEGY.PDF"
            target="_blank"
            rel="noreferrer"
          >
            US Department of Defense
          </a>{" "}
          – personal favorite; best executive summary
        </li>
        <li>
          <a
            href="https://www.canada.ca/en/department-national-defence/corporate/reports-publications/data-governance.html"
            target="_blank"
            rel="noreferrer"
          >
            Department of National Defence and Canadian Armed Forces
          </a>{" "}
          – best summaries throughout
        </li>
        <li>
          <a href="https://data.wisc.edu/data-governance/" target="_blank" rel="noreferrer">
            University of Wisconsin-Madison
          </a>
        </li>
        <li>
          <a
            href="https://www.uc.edu/content/dam/uc/infosec/docs/policies/Data_Governance_and_Classification_Policy_9.1.1.pdf"
            target="_blank"
            rel="noreferrer"
          >
            University of Cincinnati
          </a>
        </li>
        <li>
          <a href="https://policies.usask.ca/documents/data-governance-framework.pdf" target="_blank" rel="noreferrer">
            University of Saskatchewan
          </a>
        </li>
        <li>
          <a
            href="https://cdn.web.uta.edu/-/media/project/website/administration/university-analytics/documents/uta_dg_charter.ashx"
            target="_blank"
            rel="noreferrer"
          >
            University of Texas Arlington
          </a>
        </li>
        <li>
          <a
            href="https://data.cityofrochester.gov/documents/RochesterNY::city-of-rochester-data-governance-charter/explore"
            target="_blank"
            rel="noreferrer"
          >
            City of Rochester
          </a>
        </li>
        <li>
          <a href="https://data.baltimorecity.gov/pages/data-governance" target="_blank" rel="noreferrer">
            City of Baltimore
          </a>
        </li>
      </ul>

      <h2 id="next-steps">Summary and Next Steps</h2>
      <p>
        The Data Governance Charter provides high-level information about Data Governance strategy in a single document.
      </p>
      <p>At minimum, the Charter should contain the following sections:</p>
      <ul>
        <li>Introduction with Mission Statement</li>
        <li>
          Some form of summarization (e.g., an Executive Summary at the beginning, Key Takeaways at the end of each
          section, and/or a Conclusion at the end)
        </li>
        <li>Vision, Scope, and Guiding Principles</li>
        <li>Process for Implementation</li>
        <li>Roles and Responsibilities</li>
      </ul>
      <p>
        A strong Charter will also include information about business drivers, current issues, target future state,
        success criteria, business strategy alignment, and principles for Data Governance.
      </p>
      <p>You may also wish to include the following sections:</p>
      <ul>
        <li>Definitions</li>
        <li>References</li>
        <li>Considerations</li>
        <li>Contributors</li>
      </ul>
      <p>
        The Charter should be shared with the organization as part of training initiatives and through the Data
        Governance site on the organization’s intranet, along with the Implementation Roadmap, Data Policies and
        Standards, descriptions of Roles and Responsibilities, program announcements, trainings, contact information for
        program leaders, and other relevant information such as Data Governance wins and associated pizza parties 🍕
      </p>
      <p>
        After developing an effective Data Governance Charter, an organization may choose to turn its attention to
        producing supporting documents, such as building out an Operating Framework, developing a Roadmap and
        Implementation Strategy, or creating an Operations Plan. On a more tactical level, it may be useful to create a
        Business Glossary, Data Governance Scorecard, or Communication Plan. These deliverables will help the
        organization prepare to implement the Data Governance strategy.
      </p>
      <p>
        For more information about documents related to Data Governance, we recommend checking out the{" "}
        <Link to="/products/document-checklist" target="_blank">
          Document Checklist
        </Link>
        . If you need assistance developing these documents, Data Strategy Professionals can help! Check out our{" "}
        <Link to="/landing/document-drafting-and-review" target="_blank">
          Document Drafting & Review
        </Link>{" "}
        service.
      </p>
    </>
  );
};
