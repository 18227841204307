import TestimonialCard from "../testimonial-flip-card/TestimonialCard";

export default function TestimonialBox({ testimonialsToShow, color = "#dee8e9" }) {
  return (
    <div className="teal-box" style={{ backgroundColor: color, paddingBottom: "2.5em" }}>
      <div className="center-text-box">
        <h4 className="box-title" style={{ textAlign: "center" }}>
          What People are Saying
        </h4>
        {testimonialsToShow.map((testimonial) => (
          <TestimonialCard key={testimonial.id} testimonial={testimonial} quotes={true} productPage={true} />
        ))}
      </div>
    </div>
  );
}
