import SubtleTestimonialCardFlip from "../testimonial-flip-card/SubtleTestimonialCardFlip";
import testimonials from "../../data/testimonials";

const WhatPeopleAreSaying: React.FC<{ selectedTestimonials: string[] }> = ({ selectedTestimonials }) => {
  return (
    <div className="teal-box cdmp" id="product-testimonials">
      <h4>What People Are Saying</h4>
      <div style={{ marginTop: "1em" }}>
        {selectedTestimonials.map((testimonial) => (
          <SubtleTestimonialCardFlip
            testimonial={testimonials.find((t) => t.id === testimonial)}
            productPage
            fontSize={1.05}
          />
        ))}
      </div>
    </div>
  );
};

export default WhatPeopleAreSaying;
