import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";

import CustomHelmet from "../../components/CustomHelmet";

import logo from "../../img/helmet/products/logo_cdmp.png";

import examTaker from "../../img/user-stories/exam-taker.webp";
import gdpr from "../../img/products/data-privacy-exam-questions/cippe-qs.webp";
import logoBids from "../../img/user-stories/logo_bids.webp";

const styles: { [key: string]: React.CSSProperties } = {
  videoModal: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    zIndex: 998,
    background: "#5a5a5a80",
  },
  image: {
    height: 200,
    width: "100%",
    objectFit: "cover",
    marginBottom: 20,
    cursor: "pointer",
  },
  container: {
    width: "95%",
    margin: "auto",
    fontSize: "1.1em",
  },
  text: {
    margin: "1em 0 .2em 0",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    margin: "auto",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 40,
    gap: 8,
  },
  button: {
    width: 280,
    fontSize: "1.1em",
    padding: "16px 20px",
  },
  mobileButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
  },
};

const GetCertified: React.VFC = () => {
  const desktop = useMediaQuery("(min-width:960px)");
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [video, setVideo] = useState("");

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description="Data Strategy Professionals provides expertly crafted test prep materials for DAMA's Certified Data Management Professional (CDMP) exam as well as other data-related training."
        name="Get Certified"
        link="/get-certified"
        img={logo}
        alt="person writing exam questions"
      />
      {openVideoModal && (
        <div style={styles.videoModal} onClick={() => setOpenVideoModal(false)}>
          <dialog className="products-popup box-shadow">
            <span className="close-icon" onClick={() => setOpenVideoModal(false)}>
              x
            </span>
            <div className="video-box" style={{ margin: "1em 1em", width: "90vw" }}>
              <iframe
                src={video}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                frameBorder="0"
                height="315"
              />
            </div>
          </dialog>
        </div>
      )}
      <div className="home-layout" style={{ minHeight: "70vh" }}>
        <h1 className="title">Get Certified</h1>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <div className="product-page-divider" style={{ marginBottom: 30 }}>
              <p className="product-page-divider-title">
                <strong>Data Strategy</strong>
              </p>
            </div>
            <div style={styles.container}>
              <img
                src={examTaker}
                alt="person writing exam questions"
                style={styles.image}
                onClick={() => history.push("/products/#cdmp-fundamentals")}
              />

              <p>
                The best certification for Data Strategists is the{" "}
                <Link to="/resources/cdmp-fundamentals-exam">Certified Data Management Professional (CDMP)</Link> from{" "}
                <a href="https://www.dama.org/" target="_blank" rel="noreferrer">
                  DAMA
                </a>
                .
              </p>
              <p style={styles.text}>
                Becoming recognized as a CDMP will help you gain valuable knowledge across 14 knowledge areas of Data
                Management.
              </p>
              <p style={styles.text}>
                Save time while studying the{" "}
                <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                  <em>Data Management Body of Knowledge (DMBOK)</em>
                </a>{" "}
                with our <Link to="/products/cdmp-fundamentals-bundle">CDMP Fundamentals Bundle</Link>.
              </p>
            </div>
            {!desktop && (
              <div style={styles.mobileButtonContainer}>
                <button
                  className="btn btn-accent"
                  style={styles.button}
                  onClick={() => history.push("/products/#cdmp-fundamentals")}
                >
                  Learn more
                </button>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="product-page-divider" style={{ marginBottom: 30 }}>
              <p className="product-page-divider-title">
                <strong>Data Privacy</strong>
              </p>
            </div>

            <div style={styles.container}>
              <img
                src={gdpr}
                alt="person writing exam questions"
                style={styles.image}
                onClick={() => history.push("/products/#data-privacy")}
              />

              <p>
                <a href="https://iapp.org/" target="_blank" rel="noreferrer">
                  International Association of Privacy Professionals (IAPP)
                </a>{" "}
                offers certifications for ambitious data practitioners interested in Data Privacy.
              </p>
              <p style={styles.text}>
                These exams are challenging, which is why they are viewed as highly valuable across the world.
              </p>
              <p style={styles.text}>
                We provide training for the{" "}
                <a href="https://iapp.org/certify/cippe/" target="_blank" rel="noreferrer">
                  Certified Information Privacy Professional Exam / Europe (CIPPE)
                </a>{" "}
                through a comprehensive <Link to="/products/cippe-study-plan">Study Plan</Link> and{" "}
                <Link to="/products/cippe-exam-questions">practice questions</Link>.
              </p>
            </div>
            {!desktop && (
              <div style={styles.mobileButtonContainer}>
                <button
                  className="btn btn-accent"
                  style={styles.button}
                  onClick={() => history.push("/products/#data-privacy")}
                >
                  Learn more
                </button>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="product-page-divider" style={{ marginBottom: 30 }}>
              <p className="product-page-divider-title">
                <strong>Data Analytics & Data Science</strong>
              </p>
            </div>

            <div style={styles.container}>
              <img
                src={logoBids}
                alt="hands stacked over a table"
                style={styles.image}
                onClick={() => history.push("/products/#cap")}
              />

              <p>
                Data Analysts would benefit from adding the{" "}
                <Link to="/resources/certified-analytics-professional">Certified Analytics Professional (CAP)</Link>{" "}
                designation from{" "}
                <a href="https://www.informs.org/" target="_blank" rel="noreferrer">
                  INFORMS
                </a>{" "}
                to their resume in order to stand out in the workplace or the job market.
              </p>
              <p style={styles.text}>
                Given the challenging nature of the test, we have designed a{" "}
                <Link to="/products/cap-study-plan">65-day Study Plan</Link> to help guide you every step of the way.
              </p>
              <p style={styles.text}>
                We also provide additional <Link to="/products/cap-exam-questions">practice questions</Link> to test
                your knowledge of this field.
              </p>
            </div>

            {!desktop && (
              <div style={styles.mobileButtonContainer}>
                <button className="btn btn-accent" style={styles.button} onClick={() => history.push("/products/#cap")}>
                  Learn more
                </button>
              </div>
            )}
          </Grid>
        </Grid>
        {desktop && (
          <>
            <Grid container spacing={4} sx={{ marginBottom: 5 }}>
              <Grid item xs={12} md={4}>
                <div style={styles.buttonContainer}>
                  <button
                    className="btn btn-accent"
                    style={styles.button}
                    onClick={() => history.push("/products/#cdmp-fundamentals")}
                  >
                    Learn more
                  </button>
                  <button
                    className="btn btn-trustpilot"
                    onClick={() => {
                      setOpenVideoModal(true);
                      setVideo("https://www.youtube.com/embed/SfQ4J0QKxHc");
                    }}
                    id="btn-purchase"
                    style={styles.button}
                  >
                    Watch the video
                  </button>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={styles.buttonContainer}>
                  <button
                    className="btn btn-accent"
                    style={styles.button}
                    onClick={() => history.push("/products/#data-privacy")}
                  >
                    Learn more
                  </button>
                  <button
                    className="btn btn-trustpilot"
                    id="btn-purchase"
                    style={styles.button}
                    onClick={() => window.open("/resources/cippe-update-october-2023", "_blank")}
                  >
                    Read the article
                  </button>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={styles.buttonContainer}>
                  <button
                    className="btn btn-accent"
                    style={styles.button}
                    onClick={() => history.push("/products/#cap")}
                  >
                    Learn more
                  </button>
                  <button
                    className="btn btn-trustpilot"
                    onClick={() => {
                      setOpenVideoModal(true);
                      setVideo("https://www.youtube.com/embed/MP3_ZBFosZ4");
                    }}
                    id="btn-purchase"
                    style={styles.button}
                  >
                    Watch the video
                  </button>
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

export default GetCertified;
