const Contents: React.FC<{ contents: Content[] }> = ({ contents }) => {
  return (
    <>
      <h4>Contents</h4>
      <ul>
        {contents.map((content) => (
          <li key={content.anchor}>
            <a href={`#${content.anchor}`}>{content.text}</a>
          </li>
        ))}
        <li>
          <a href="#related-posts">Related Posts</a>
        </li>
      </ul>
    </>
  );
};

export default Contents;
