export default function CompanyForm({ setCompany, setCompanyIsBlank }) {
  return (
    <div style={{ width: "100%" }}>
      <label htmlFor={"userCompany"}>
        <p style={{ fontSize: "13px", color: "rgb(48, 49, 61)" }}>
          <strong>Company</strong>
        </p>
      </label>
      <input
        type="text"
        id="userCompany"
        onBlur={(e) => setCompany(e.target.value)}
        onChange={() => setCompanyIsBlank(false)}
        className="field checkout-form-input"
      ></input>
      <br />
    </div>
  );
}
