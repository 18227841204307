import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials.js";

import testimonials from "../../data/testimonials";

const PRODUCT = "Data Science Bundle";

export default function DSBundle() {
  return <MultiPriceProductPage PRODUCT={PRODUCT} description={description} tealBox={tealBox} rest={rest()} />;
}

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <div className="blog">
      <p>
        Prepare for a career in Data Science with this{" "}
        <Link to="/products/data-science-email-bootcamp">12-week email course</Link> as well as{" "}
        <Link to="/products/cds-question-sets">100 practice questions</Link> to challenge your knowledge of Data
        Science.
      </p>
      <p>
        The{" "}
        <Link to="/products/data-science-email-bootcamp" target="_blank">
          Data Science Bootcamp
        </Link>{" "}
        offers a comprehensive guide to entering the field of Data Science. We feature a different topic each week,
        including an overview of the Data Science field, statistics, Python, machine learning, and sample projects.
      </p>
      <p>
        You will also receive both{" "}
        <Link to="/products/cap-question-sets" target="_blank">
          Data Science Question Sets
        </Link>{" "}
        which each contain 50 distinct, never-before-seen questions, developed by our team at Data Strategy
        Professionals. In the answers section of each document, we provide a detailed explanation for each question.
      </p>
    </div>
  </div>
);

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>You'll receive:</h4>
    <div className="blog">
      <ul>
        <li>
          <strong>12-week email bootcamp</strong> to guide your preparation a career in Data Science
        </li>
        <li>
          <strong>100 practice questions</strong> to test your knowledge
        </li>
        <li>
          Support for any questions at{" "}
          <a href="mailto:support@datastrategypros.com?subject=Data%20Science%20Product%20Bundle">
            support@datastrategypros.com
          </a>
        </li>
      </ul>
    </div>
  </div>
);

const rest = () => {
  const testimonialsToShow = testimonials.filter((testimonial) => testimonial.id.includes("bids-email-bootcamp"));
  return <ProductTestimonials testimonialsToShow={testimonialsToShow} />;
};
