import CustomModal from "../CustomModal";

import { useModal } from "../../helpers/utils";

const LeadMagnetScreenshots: React.FC<LeadMagnetScreenshotsProps> = ({ images }) => {
  const { showModal, image, alt, handleShowClick } = useModal();

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}

      <h4 className="box-title" style={{ fontSize: "1.2em", textAlign: "center" }}>
        Screenshots
      </h4>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "3em",
          marginBottom: 70,
        }}
      >
        {images.map((imgObj, idx) => (
          <img
            src={imgObj.img}
            alt={imgObj.alt}
            onClick={handleShowClick}
            style={{ cursor: "pointer" }}
            className="box-shadow"
            key={idx}
          />
        ))}
      </div>
    </>
  );
};

export default LeadMagnetScreenshots;
