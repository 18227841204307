import ProductCardBasic from "../../../components/product-card/ProductCardContainer";
import productsList from "../../../data/products";

const products = productsList.filter((product) => product.showOnHomepage);

export default function BestSellers() {
  return (
    <div
      className="white-box homepage"
      style={{
        padding: "0px 40px",
        margin: "0px auto",
        background: "#e9eff1",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "2.2em",
          borderBottom: "2px solid #dbdbdb",
          textAlign: "center",
          position: "relative",
          zIndex: 0,
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "1.8em",
            marginTop: "11px",
            background: "#e9eff1",
            zIndex: 1,
            display: "inline-block",
            padding: "0 20px",
            fontFamily: "Raleway",
          }}
          id="best-sellers"
        >
          <strong>Best Sellers</strong>
        </p>
      </div>
      <div className="product-catalog special" style={{ marginTop: 42 }}>
        {products
          .filter((productObj) => productObj.name.includes("CDMP"))
          .slice(0, 4)
          .map((productObj, idx) => {
            return <ProductCardBasic key={productObj.name} productObj={productObj} idx={idx} decorate={false} />;
          })}
      </div>
    </div>
  );
}
