import quote from "../../img/icons/quote.webp";

const NewTestimonialCard = ({ testimonial, fontSize = 1, quotes = true }) => {
  return (
    <>
      <div className="custom-popover w-100 px-2">
        <div className="popover-content w-100">
          <span className="popover-tip" />
          <div className="px-2 py-5" style={{ display: "flex", alignItems: "start" }}>
            <img src={quote} alt="quote" style={{ maxWidth: "8%", marginRight: 8, marginTop: -20 }} />
            <p
              dangerouslySetInnerHTML={{
                __html: quotes ? `"${testimonial.content}"` : testimonial.content,
              }}
              style={{ fontSize: `${fontSize}em`, position: "relative", width: "85%" }}
            />
          </div>
        </div>
      </div>
      <div className="profile-wrapper">
        {testimonial.img && <img className="profile-pic" src={testimonial.img} alt="" />}

        <div className="profile-content" style={{ display: "flex", alignItems: "start" }}>
          <div className="profile-name">{testimonial?.name}</div>
          <div className="profile-detail">{testimonial?.title}</div>
        </div>
      </div>
    </>
  );
};

export default NewTestimonialCard;
