import { useState, useEffect } from "react";
import { LinearProgress } from "@mui/material";

const MessageProgressBar: React.VFC<{ setDone: (done: boolean) => void }> = ({ setDone }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const timer = setInterval(() => {
      // @ts-ignore
      setProgress((oldProgress) => {
        if (oldProgress === 0) {
          return setDone(true);
        }
        return oldProgress - 10;
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <LinearProgress variant="determinate" value={progress} sx={progressStyle} />
    </div>
  );
};

const progressStyle = {
  width: "100%",
  color: "#dbdbdb",
  backgroundColor: "#c1c1c1",
  "& .MuiLinearProgress-colorPrimary": {
    backgroundColor: "#c1c1c1",
  },
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: "#dbdbdb",
  },
};

export default MessageProgressBar;
