import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";

import scr0 from "../../img/products/poster/scr0.webp";
import scr1 from "../../img/products/poster/scr1.webp";
import scr2 from "../../img/products/poster/scr2.webp";

const PRODUCT = "Foundations of Data Strategy Poster";

const Poster: React.FC<{}> = () => {
  return <MultiPriceProductPage PRODUCT={PRODUCT} description={description} tealBox={tealBox} rest={rest} />;
};

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <div className="blog">
      <p>
        This charming poster covers the focus areas of Data Governance, Data Quality, Data Architecture and Modeling,
        Master and Reference Data, and Data Warehousing and Business Intelligence from the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        .
      </p>
      <p>
        The poster is a stylish accent for your home or office and a great way to keep your Data Strategy knowledge
        fresh for the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP exam
        </Link>
        !
      </p>

      <h4>Features</h4>
      <ul>
        <li>Available in either 18 x 24 inch (46 x 61 cm) or 24 x 36 inch (61 x 91 cm)</li>
        <li>Printed on 175 gsm fine art paper with a matte finish</li>
        <li>
          12 instructional topics based on the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>
        </li>
      </ul>
    </div>
  </div>
);

const tealBox = (
  <div className="teal-box" id="product-testimonials">
    <h4>Other Physical Products</h4>
    <div className="blog">
      <p>
        We recognize that your work moves mountains, which is why we've chosen to represent your contributions with this
        classic mountain design on our{" "}
        <Link to="/products/t-shirt" target="_blank">
          Data Strategist T-Shirt
        </Link>
        .
      </p>
      <p>
        Also, check out our{" "}
        <Link to="/products/mug" target="_blank">
          Data Strategist Mug
        </Link>{" "}
        to proudly highlight your role as a data practitioner while enjoying your morning tea or coffee.
      </p>
    </div>
  </div>
);

const rest = (
  <div className="white-box">
    <h4 className="box-title" style={{ textAlign: "center" }}>
      Screenshots
    </h4>
    <div className="image-display-tri">
      <img
        src={scr0}
        alt="poster screenshot"
        // onClick={handleShowClick}
        // style={{ cursor: "pointer" }}
      />
      <img
        src={scr1}
        alt="poster screenshot"
        // onClick={handleShowClick}
        // style={{ cursor: "pointer" }}
      />
      <img
        src={scr2}
        alt="poster screenshot"
        // onClick={handleShowClick}
        // style={{ cursor: "pointer" }}
      />
    </div>
  </div>
);

export default Poster;
