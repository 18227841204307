import { Link } from "react-router-dom";

const AdditionalWays: React.VFC = () => {
  return (
    <div className="teal-box cdmp" id="product-testimonials">
      <h4>Additional ways to learn Data Strategy</h4>
      <div className="blog">
        <p>
          Be sure to check out the{" "}
          <Link to="/products/data-strategy-workbook" target="_blank">
            Data Strategy Workbook
          </Link>{" "}
          for a thought-provoking series of exercises to improve your organization's Data Management capabilities.
        </p>

        <p>
          Don't miss our{" "}
          <Link to="/products/document-checklist" target="_blank">
            Data Strategy Document Checklist
          </Link>{" "}
          for a comprehensive list of documents needed to improve your organization's Data Management capabilities.
        </p>
        <p>
          We also offer the <Link to="/products/cdmp-fundamentals-bundle">CDMP Fundamentals Bundle</Link> to help you
          prepare for the <Link to="/resources/cdmp-fundamentals-exam">CDMP Fundamentals Exam</Link> based on the{" "}
          <em>Data Management Body of Knowledge</em> (
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>
          ).
        </p>
      </div>
    </div>
  );
};

export default AdditionalWays;
