import { Link } from "react-router-dom";

import BestCertifications from "../components/blog/BestCertifications";
import Post from "../components/blog/Post";

const POSTNAME = "aiken";

const Aiken: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default Aiken;

const Rest = () => {
  return (
    <>
      <p>All too often, data initiatives fail.</p>
      <p>
        Business leadership optimistically pursues a solution that involves advanced machine learning without first
        developing a solid understanding of the health of the organization's data supply chain.
      </p>
      <p>
        The Aiken Pyramid is a framework that aims to direct data-related efforts. It lays out a dozen aspects of{" "}
        <Link to="/resources/foundations-of-data-strategy" target="_blank">
          Data Strategy
        </Link>{" "}
        in a hierarchy that is easy to understand and communicate across stakeholder groups.
      </p>
      <figure>
        <img src="https://miro.medium.com/v2/resize:fit:720/0*FMFU31e48Ig4cghB" alt="Aiken Pyramid" />
        <figcaption>
          The Aiken pyramid is illustrated as a pyramid with five levels, each representing a different aspect of data
          management, via{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>
        </figcaption>
      </figure>
      <p>
        This article will teach you how to use the Aiken Pyramid to prioritize your work as a data practitioner. It's a
        handy tool for visually communicating your efforts to anyone at your organization. Mastering this methodology
        will help you advocate for investment in foundational capabilities, such as Data Governance, Data Quality, and
        Metadata, that will enable the success of advanced analytics down the road.
      </p>
      <p>
        📖 For more ideas to improve Data Strategy at your organization, be sure to check out our{" "}
        <Link to="/products/data-strategy-workbook" target="_blank">
          Data Strategy Workbook
        </Link>
        , which contains 20 exercises to advance the capabilities of your team across all the Data Management
        disciplines discussed in this article and more!
      </p>
      <h2 id="background">Background</h2>
      <p>
        The framework is named for{" "}
        <a href="http://anythingawesome.com/wikipedia.html" target="_blank" rel="noreferrer">
          Peter Aiken
        </a>
        , a Data Strategist, author, and professor at Virginia Commonwealth University with over thirty years of
        experience. His books include{" "}
        <a href="https://amzn.to/3q9nPLE" target="_blank" rel="noreferrer">
          <em>The Case For The Chief Data Officer</em>
        </a>
        ,{" "}
        <a href="https://amzn.to/425aHVc" target="_blank" rel="noreferrer">
          <em>Monetizing Data Management</em>
        </a>
        <em>,</em> and{" "}
        <a href="https://amzn.to/3lEf9rY" target="_blank" rel="noreferrer">
          <em>Data Literacy</em>
        </a>
        . He has provided consulting services to notable organizations, such as Deutsche Bank, Nokia, and the US
        Department of Defense. Moreover, Aiken currently serves as the President of{" "}
        <a href="https://www.dama.org/" target="_blank" rel="noreferrer">
          DAMA
        </a>
        , the organization that administers the Certified Data Management Professional (
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP
        </Link>
        ) exam.
      </p>
      <figure>
        <img
          src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*PqID05P-VguXO6OUlDeErQ.jpeg"
          alt="person playing jenga"
        />
        <figcaption>
          Photo by{" "}
          <a
            href="https://unsplash.com/de/@johnmoeses?utm_source=medium&amp;utm_medium=referral"
            target="_blank"
            rel="noreferrer"
          >
            John Moeses Bauan
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/?utm_source=medium&amp;utm_medium=referral" target="_blank" rel="noreferrer">
            Unsplash
          </a>
        </figcaption>
      </figure>
      <h2 id="rationale">Rationale</h2>
      <p>
        The Aiken Pyramid outlines how twelve core Data Strategy concepts build upon each other. Just like a tower of
        Jenga blocks will crumble if enough foundational pieces are pulled out, Data Strategy initiatives cannot proceed
        successfully if there are gaping holes in the pyramid.
      </p>
      <p>The concepts in the Aiken Pyramid are described in detail below.</p>
      <h2 id="level-zero">Level Zero</h2>
      <p>
        <strong>Data Governance</strong>: at the base of the Pyramid, this topic helps the organization align their data
        assets to business objectives. By setting up clear rules, processes, and structures to manage data assets, the
        organization will hardwire data-centricity into day-to-day operations.
      </p>

      <BestCertifications />

      <h2 id="level-one">Level One</h2>
      <p>
        <strong>Data Architecture</strong>: this crucial discipline is concerned with creating an enterprise-wide view
        of how all data assets are collected, processed, stored, and distributed. A well-designed data architecture
        ensures everyone in the organization is on the same page when it comes to the complete picture of data assets.
        Data Architects use data flow diagrams and enterprise data models to flexibly showcase different views of the
        organization's Data Architecture.
      </p>
      <p>
        <strong>Data Quality</strong>: high quality data is data that is accurate, consistent, complete, reliable,
        relevant, and timely. These characteristics add value to business operations and reduce risks. It is crucial to
        ensure that data is of adequate quality or else risk poor decisions and significant security concerns. Gartner
        has estimated the{" "}
        <a
          href="https://www.gartner.com/smarterwithgartner/how-to-create-a-business-case-for-data-quality-improvement"
          target="_blank"
          rel="noreferrer"
        >
          annual cost
        </a>{" "}
        of low quality data at $12.9M USD.
      </p>
      <blockquote>
        <p>
          “Where There is Data Smoke, There is Business Fire” —{" "}
          <a href="https://medium.com/u/768ab8c3f1eb" target="_blank" rel="noreferrer">
            Tom Redman
          </a>
        </p>
      </blockquote>
      <p>
        <strong>Metadata</strong>: this field represents the tagging of data assets with relevant categorical
        information. High quality metadata helps guarantee that data is accurate, fit for purpose, timely, and
        consistent. Metadata represents a critical initial step toward the implementation of efficient data systems and
        processes.
      </p>
      <h2 id="level-two">Level Two</h2>
      <p>
        <strong>Data Security</strong>: refers to the safeguards that an organization implements to guard against
        unauthorized access, use, disclosure, and alteration of its data and information systems. Security breaches can
        be irreversibly catastrophic for organizations and their customers. During the third quarter of 2022,{" "}
        <a
          href="https://www.statista.com/statistics/1307426/number-of-data-breaches-worldwide/"
          target="_blank"
          rel="noreferrer"
        >
          approximately 15 million data records
        </a>{" "}
        were exposed globally via data breaches.
      </p>
      <p>
        <strong>Data Storage &amp; Operations</strong>: represents the design, implementation, and support of stored
        data to maximize its value. A Data Strategist may be responsible for choosing the appropriate storage and
        operational systems, as well as designing processes to ensure that data is properly managed and maintained
        throughout its lifecycle
      </p>
      <p>
        <strong>Data Modeling</strong>: organizing data for storage and operations. The process begins with the
        development of the conceptual data model, which could resemble a business glossary. Next, the modeler proceeds
        to create a logical data model, which transforms the data assets according to the chosen schema. Finally, the
        modeler creates a physical data model that details exactly how data will be stored. A good data model ensures
        that the database is highly performant and accessible in a way that is meaningful and valuable for the
        organization.
      </p>

      <h2 id="level-three">Level Three</h2>
      <p>
        <strong>Data Integration &amp; Interoperability</strong>: describes the processes related to the movement and
        consolidation of data within and between data stores, applications, and organizations. It requires that the
        systems and devices involved use common standards and protocols for data exchange. In addition, the data in
        itself must be structured in a way that it can easily be shared and understood.
      </p>

      <figure>
        <img
          src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*6wpnWNPSKnSVc6u_3Waoyg.png"
          alt="person working on a dashboard"
        />
        <figcaption>
          Photo by{" "}
          <a href="https://unsplash.com/@campaign_creators" target="_blank" rel="noreferrer">
            Campaign creators
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/photos/pypeCEaJeZY" target="_blank" rel="noreferrer">
            Unsplash
          </a>
        </figcaption>
      </figure>

      <h2 id="level-four">Level Four</h2>
      <p>
        <strong>Data Warehousing &amp; Business Intelligence</strong>: examines the process of storing and managing
        large amounts of structured data for the purpose of analytics. Business Intelligence (BI) refers to the tools
        and processes used to analyze data and make informed decisions. Data Strategists can fit Data Warehousing &amp;
        BI into the overall data strategy of an organization by defining goals and processes, identifying important data
        and metrics, and aligning these efforts with the needs of the organization.
      </p>
      <p>
        <strong>Reference &amp; Master Data</strong>: refers to the core data that is used to support an organization's
        business processes and decision-making. Master Data is the fundamental information that underpins an
        organization's operational procedures and decision-making. Reference data is data that is utilized as a
        benchmark. Data Strategists may work to integrate reference and master data with other types of data, such as
        transactional data, to provide a more complete and accurate picture of the organization and its operations.
      </p>
      <p>
        <strong>Document &amp; Content Management</strong>: refers to the organization, storage, and retrieval of
        electronic and physical documents and other forms of content. Developing policies and procedures for creating,
        storing, and accessing documents helps to ensure that the right people have access to the right information at
        the right time, and can also support compliance with relevant regulations and standards.
      </p>

      <h2 id="level-five">Level Five</h2>
      <p>
        <strong>Data Science</strong>: involves the use of various techniques and tools to extract insights and
        knowledge from large quantities of data.{" "}
        <Link to="/resources/data-science-for-leaders" target="_blank">
          Data Science
        </Link>{" "}
        may involve querying data, exploring features to assess predictive power, and selecting statistical models.
        Ultimately, the process results in a statistics-powered model that provides business value through
        classification or prediction.
      </p>
      <blockquote>
        <p>“Information is the oil of the 21st century, and analytics is the combustion engine.” — Peter Sondergaard</p>
      </blockquote>
      <h2 id="conclusion">
        <strong>Conclusion</strong>
      </h2>
      <p>
        Without proper planning, your organization will fail to optimize its Data Management capabilities. Following the
        hierarchy of the Aiken Pyramid is essential to ensuring your business can capitalize on its comparative
        advantage and avoid potentially catastrophic risks. This model ensures that the entire data ecosystem is healthy
        and able to support higher level functions that drive business value.
      </p>
      <p>
        Check out our{" "}
        <Link to="/enterprise" target="_blank">
          Enterprise
        </Link>{" "}
        offerings for more information about our in-person and virtual training options for organizations.
      </p>
    </>
  );
};
