import { useState } from "react";

import ReactCardFlip from "react-card-flip";

import NewTestimonialCardSimplified from "./NewTestimonialCardSimplified";
import NewTestimonialCard from "./NewTestimonialCard";

export default function NewTestimonialFlipCard({ testimonial, frontFontSize, fontSize }) {
  const [isFlipped, setIsFlipped] = useState(false);
  return (
    <div
      className="flex-item-testimonial"
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
      style={{ marginBottom: 40 }}
    >
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <NewTestimonialCardSimplified key={testimonial.id} testimonial={testimonial} frontFontSize={frontFontSize} />
        <NewTestimonialCard key={testimonial.id} testimonial={testimonial} fontSize={fontSize} />
      </ReactCardFlip>
    </div>
  );
}
