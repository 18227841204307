const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const nonresponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const customResponsiveness = {
  desktop: {
    breakpoint: { max: 6000, min: 1600 },
    items: 5,
    slidesToSlide: 5,
  },
  tablet: {
    breakpoint: { max: 1600, min: 640 },
    items: 4,
    slidesToSlide: 4,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 3,
    slidesToSlide: 3,
  },
};

const productSlider = {
  largeDesktop: {
    breakpoint: { max: 5000, min: 1600 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1600, min: 800 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const relatedPostsCarousel = {
  ...responsive,
  mobile: { ...responsive.mobile, breakpoint: { max: 500, min: 0 } },
};

export { responsive, nonresponsive, customResponsiveness, productSlider, relatedPostsCarousel };
