import { Link } from "react-router-dom";

import enterpriseStyles from "../../../helpers/enterpriseStyles";

const CDMPrationale: React.VFC = () => {
  return (
    <div style={enterpriseStyles.container}>
      <p className="title product-title">
        Why should your team members take the Certified Data Management Professional (CDMP) Exam?
      </p>
      <div className="blog">
        <p>
          <Link to="/resources/foundations-of-data-strategy" target="_blank">
            Learn to manage data as a strategic asset
          </Link>{" "}
          with the Certified Data Management Professional (
          <a href="https://cdmp.info/" target="_blank" rel="noreferrer">
            CDMP
          </a>
          ) exam from Data Management Association International (
          <a href="https://dama.org/" target="_blank" rel="noreferrer">
            DAMA
          </a>
          ). The CDMP is the <strong>best certification for Data Strategy</strong>, a crucial discipline that spans{" "}
          <strong>end-to-end management of the data lifecycle</strong> as well as associated aspects of{" "}
          <Link to="/resources/foundations-of-data-strategy" target="_blank">
            data governance
          </Link>{" "}
          and key considerations of{" "}
          <Link to="/oath" target="_blank">
            data ethics
          </Link>
          .
        </p>
        <p>
          The{" "}
          <a href="https://cdmp.info/" target="_blank" rel="noreferrer">
            CDMP Fundamentals Exam
          </a>{" "}
          confers expertise across <strong>14 key areas</strong> related to Data Strategy. The test is{" "}
          <strong>open book</strong>, but the <strong>100 questions</strong> on the exam must be completed within{" "}
          <strong>90 minutes</strong> — not a lot of time to be looking things up. Therefore, it's important to be
          extremely familiar with the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            reference material
          </a>
          .
        </p>
        <p>
          The CDMP is a <strong>valuable credential</strong> for Data Governance specialists, Data Quality engineers,
          Data Architects, Database Administrators, IT Professionals, Data Stewards, Data Analysts, Data Scientists, and
          really <strong>anyone who works with data</strong> or wants to understand how to use data more effectively.
        </p>
      </div>
    </div>
  );
};

export default CDMPrationale;
