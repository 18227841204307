const navbar = [
  {
    name: "Start Here",
    width: 160,
    items: [],
  },
  {
    name: "About",
    width: 160,
    items: [
      {
        name: "Team",
        path: "/about/team",
      },
      {
        name: "Testimonials",
        path: "/about/testimonials",
      },
      {
        name: "Partners",
        path: "/about/partners",
      },
      {
        name: "Media",
        path: "/about/media",
      },
      {
        name: "Contact",
        path: "/about/contact",
      },
    ],
  },
  {
    name: "Enterprise",
    width: 280,
    items: [
      {
        name: "Two Day CDMP Bootcamp",
        path: "/landing/data-strategy-bootcamp",
      },
      {
        name: "CDMP Study Program",
        path: "/landing/cdmp-study-program",
      },
      {
        name: "Document Drafting & Review",
        path: "/landing/document-drafting-and-review",
      },
      {
        name: "Data Strategy Workbook",
        path: "/products/data-strategy-workbook",
      },
      {
        name: "Data Strategy Document Checklist",
        path: "/products/document-checklist",
      },
    ],
  },
  {
    name: "Events",
    width: 160,
    items: [
      {
        name: "Upcoming Events",
        path: "/events",
      },
      {
        name: "Past Events",
        path: "/events/past",
      },
    ],
  },
  {
    name: "Resources",
    width: 160,
    items: [
      {
        name: "All",
        path: "/resources?topic=all",
      },
      {
        name: "Data Strategy",
        path: "/resources?topic=data-strategy",
      },
      {
        name: "Data Privacy",
        path: "/resources?topic=data-privacy",
      },
      {
        name: "Data Analytics",
        path: "/resources?topic=data-analytics",
      },
      {
        name: "Data Science",
        path: "/resources?topic=data-science",
      },
      {
        name: "Career Services",
        path: "/resources?topic=career-services",
      },
    ],
  },
  {
    name: "Newsletter",
    width: 160,
    items: [
      {
        name: "Sign Up",
        path: "/newsletter",
      },
      {
        name: "Archive",
        path: "/newsletter/archive",
      },
    ],
  },
];

export default navbar;
