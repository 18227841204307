import { useMediaQuery } from "@mui/material";

import person1 from "../../../img/hero-people/1.webp";
import person2 from "../../../img/hero-people/2.webp";
import person3 from "../../../img/hero-people/3.webp";
import person4 from "../../../img/hero-people/4.webp";
import person5 from "../../../img/hero-people/5.webp";
import person6 from "../../../img/hero-people/6.webp";
import person7 from "../../../img/hero-people/7.webp";
import person8 from "../../../img/hero-people/8.webp";
import person9 from "../../../img/hero-people/9.webp";
import person10 from "../../../img/hero-people/10.webp";
import person11 from "../../../img/hero-people/11.webp";
import person12 from "../../../img/hero-people/12.webp";
import person13 from "../../../img/hero-people/13.webp";
import person14 from "../../../img/hero-people/14.webp";
import person15 from "../../../img/hero-people/15.webp";
import person16 from "../../../img/hero-people/16.webp";

import Carousel from "../../../components/carousel/FadeCarousel";

import { shuffle } from "../../../helpers/utils";

const HeroPeople = () => {
  const lrgDesktop = useMediaQuery("(min-width: 1600px)");
  const desktop = useMediaQuery("(min-width: 1200px)");
  const smDesktop = useMediaQuery("(min-width: 1000px)");
  const tablet = useMediaQuery("(min-width: 640px)");
  const tallScreen = useMediaQuery("(min-height: 920px)");

  const randoms = [
    person3,
    person4,
    person5,
    person6,
    person7,
    person8,
    person10,
    person11,
    person12,
    person13,
    person14,
    person15,
  ];

  const peopleToShow = [person1, person2, person9, person16];
  peopleToShow.push(...shuffle(randoms));

  return (
    <>
      {(desktop || tablet) && (
        <div
          className="carousel-container"
          style={{
            position: "absolute",
            top: smDesktop ? "15%" : "none",
            bottom: tablet ? "5%" : "none",
            right: desktop ? "15%" : smDesktop ? "5%" : "-5%",
            width: lrgDesktop ? 500 : 400,
            height: lrgDesktop ? 500 : 400,
          }}
        >
          {smDesktop ? (
            <div
              style={{
                position: "absolute",
                backgroundColor: "#82aea7",
                height: tallScreen ? 500 : 400,
                width: lrgDesktop ? 400 : 350,
                borderRadius: 60,
                zIndex: 1,
              }}
            />
          ) : (
            <div
              style={{ position: "absolute", backgroundColor: "#82aea7", height: 400, width: 400, borderRadius: "50%" }}
            />
          )}
          <Carousel>
            {peopleToShow.map((person) =>
              smDesktop ? (
                <div style={{ height: 500, width: lrgDesktop ? 400 : 350, position: "relative" }} key={person}>
                  <img
                    src={person}
                    alt="person"
                    style={{
                      height: tallScreen ? 500 : 400,
                      width: lrgDesktop ? 400 : 350,
                      borderRadius: 60,
                      position: "absolute",
                      objectFit: "cover",
                      left: 0,
                      top: 0,
                      padding: 0,
                    }}
                  />
                </div>
              ) : (
                <div style={{ height: 400, width: 400, position: "relative" }}>
                  <img
                    src={person}
                    alt="person"
                    style={{
                      position: "absolute",
                      height: 400,
                      width: 400,
                      objectFit: "cover",
                      borderRadius: "50%",
                      padding: 0,
                    }}
                  />
                </div>
              )
            )}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default HeroPeople;
