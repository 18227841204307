const Jobs: React.FC = () => {
  return (
    <div>
      <ul>
        <li>
          <a
            href="https://docs.google.com/document/d/1wsVpqnuKShLzvpzPRz1tcGp9PIbcN56p-kR2SBA03vU/edit"
            target="_blank"
            rel="noreferrer"
          >
            CAP Product Lead
          </a>{" "}
          – <em>posted Dec 11, 2024; remote</em>
        </li>
        <li>
          <a
            href="https://docs.google.com/document/d/1sf9B3eZDww1LAx8_lPHa6lpGf4IMO9Jw3Tfdjf7tq3Q/edit"
            target="_blank"
            rel="noreferrer"
          >
            Communications and Customer Service Manager
          </a>{" "}
          – <em>posted Dec 11, 2024; remote</em>
        </li>
      </ul>
    </div>
  );
};

export default Jobs;
