export default function AdditionalProducts({ followOns }) {
  return (
    <div style={{ width: "100%" }}>
      <h4
        style={{
          fontSize: "1.2em",
          marginBottom: "1em",
          fontWeight: "900",
          textAlign: "center",
        }}
      >
        You May Also Be Interested
      </h4>
      <div className="image-display-tri">
        {followOns.map((product) => (
          <figure key={product.name}>
            <a href={`https://www.datastrategypros.com${product.link}`} target="_blank" rel="noreferrer">
              <img src={product.img} alt={product.alt} style={{ width: "15vw" }} />
            </a>
            <figcaption style={{ textAlign: "center", maxWidth: "15vw" }}>
              <a href={`https://www.datastrategypros.com${product.link}`} target="_blank" rel="noreferrer">
                {product.name}
              </a>
            </figcaption>
          </figure>
        ))}
      </div>
    </div>
  );
}
