import Carousel from "react-multi-carousel";
import { useMediaQuery } from "@mui/material";

import Card from "../../components/Card";

import journeys from "../../data/journeys";

import { nonresponsive } from "../../helpers/carouselStyles";

import CustomButtonGroup from "../../components/carousel/CustomButtonGroup";

const Journeys: React.VFC = () => {
  const desktop = useMediaQuery("(min-width: 768px)");

  return (
    <>
      <div className="teal-box homepage carousel-container" style={{ maxWidth: 2000, position: "relative" }}>
        <p
          style={{
            textAlign: "center",
            fontSize: "1.3em",
            marginTop: "12px",
            fontFamily: "Raleway",
          }}
          id="journeys"
        >
          <strong>Data Strategy Journeys</strong>
        </p>
        <br />
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={nonresponsive}
          ssr={true}
          keyBoardControl={true}
          infinite={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          renderButtonGroupOutside={true}
          arrows={false}
          // @ts-ignore
          customButtonGroup={desktop ? <CustomButtonGroup /> : <> </>}
          // @ts-ignore
          itemclassName="carousel-item"
          shouldResetAutoplay={false}
        >
          {journeys.map((journey) => (
            <Card key={journey.id} person={journey} color="#dee8e9" />
          ))}
        </Carousel>
        <br />
      </div>
    </>
  );
};

export default Journeys;
