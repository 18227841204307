import Shaulis from "../../img/people/jon_shaulis.webp";

const ShaulisTealBox = () => {
  return (
    <div className="teal-box">
      <div className="center-text-box oath" style={{ paddingTop: 20, paddingBottom: 10, zIndex: 1 }}>
        <p style={{ zIndex: 1 }}>
          "Since achieving my CDMP, hundreds of people have reached out to network, collaborate, comment, learn, and
          embolden their careers as data professionals. Nicole Janeway Bills of{" "}
          <strong>Data Strategy Professionals</strong> has become a pinnacle in this community. She has done so much
          more than just start a{" "}
          <a href="https://www.facebook.com/groups/346145433213551/" target="_blank" rel="noreferrer">
            study group
          </a>
          , she has also compiled helpful resources and responded back to others in the community who have questions or
          need support."
        </p>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <p style={{ textAlign: "right" }}>
            —{" "}
            <a
              href="https://jonshaulis.com/index.php/2019/11/26/hurray-i-earned-my-cdmp/#Study_plan"
              target="_blank"
              rel="noreferrer"
            >
              Jon Shaulis
            </a>
            ,<br />
            <em>Enterprise Data Architect</em>
          </p>
          <img style={{ borderRadius: "50%", width: 60, height: 60, marginLeft: 20 }} src={Shaulis} alt="Jon Shaulis" />
        </div>
      </div>
    </div>
  );
};

export default ShaulisTealBox;
