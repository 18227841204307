import DSPproducts from "./DSPproducts";

export default function ProductsModal({ setShowProductsPopup, products }) {
  const handleClose = () => {
    setShowProductsPopup(false);
  };

  return (
    <div style={{ width: "100vw", height: "100vh", position: "fixed", top: 0, zIndex: 998 }} onClick={handleClose}>
      <dialog className="products-popup box-shadow">
        <span className="close-icon" onClick={handleClose}>
          x
        </span>
        <DSPproducts products={products} />
      </dialog>
    </div>
  );
}
