import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";

import CustomModal from "../../components/CustomModal";
import BuyNowButton from "../../components/product-pages/BuyNowButton";
import ExtendedDescriptionContainer from "../../components/product-pages/ExtendedDescriptionContainer";

import gdpr0 from "../../img/products/courses/gdpr0.webp";
import gdpr1 from "../../img/products/courses/gdpr1.webp";
import gdpr2 from "../../img/products/courses/gdpr2.webp";

const PRODUCT = "GDPR Essentials Course";

export default function GDPRCourse() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        tealBox={tealBox}
        extendedDescription={extendedDescription()}
        rest={rest(handleShowClick)}
      />
    </>
  );
}

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>Additional ways to learn Data Privacy</h4>
    <div className="blog">
      <p>
        Be sure to check out the{" "}
        <Link to="/products/cippe-bundle" target="_blank">
          CIPP/E Bundle
        </Link>{" "}
        for our best selling study materials for{" "}
        <a href="https://iapp.org/" target="_blank" rel="noreferrer">
          IAPP
        </a>
        's{" "}
        <a href="https://iapp.org/train/cippe-training/" target="_blank" rel="noreferrer">
          CIPP/E exam
        </a>
        .
      </p>

      <p>
        Don't miss our{" "}
        <Link to="/products/gdpr-mind-maps" target="_blank">
          GDPR Mind Maps and Checklists
        </Link>{" "}
        for compelling visualizations of these topics.
      </p>
      <p>
        Be sure to consider our other{" "}
        <Link to="/enterprise" target="_blank">
          Enterprise offerings
        </Link>{" "}
        to ensure your team is using the same vocabulary when it comes to the management of your valuable data assets.
      </p>
    </div>
  </div>
);

const extendedDescription = () => {
  const items = [
    {
      title: "Learn More",
      description: (
        <div className="extended-description">
          <p>
            Each of the <strong>87 lessons</strong> in this <strong>self-paced course</strong> includes video and text
            content to help you fully comprehend each concept.
          </p>
          <p>
            The course is suitable for beginners and advanced practitioners alike. The thorough curriculum will make you
            a more effective data privacy practitioner.
          </p>
          <p>
            At the conclusion of the course, you will have a complete understanding of GDPR and how it impacts your
            work. The course is also excellent preparation for the{" "}
            <a href="https://iapp.org/train/cippe-training/" target="_blank" rel="noreferrer">
              CIPP/E exam
            </a>{" "}
            from{" "}
            <a href="https://iapp.org/" target="_blank" rel="noreferrer">
              IAPP
            </a>
            .
          </p>

          <p>Key features:</p>
          <ul>
            <li>87 video lessons across 18 chapters</li>
            <li>Links to additional resources to further your knowledge of GDPR</li>
            <li>
              Support for any questions at
              <a href="mailto:support@datastrategypros.com?subject=GDPR%20Essentials%20Course">
                support@datastrategypros.com
              </a>
            </li>
          </ul>
        </div>
      ),
    },
  ];
  return <ExtendedDescriptionContainer items={items} />;
};

const rest = (handleShowClick) => (
  <>
    <div className="white-box">
      <h4 className="box-title" style={{ textAlign: "center" }}>
        Screenshots
      </h4>
      <div className="image-display-tri">
        <img src={gdpr0} alt="GDPR Essentials Course" onClick={handleShowClick} className="screenshot" />
        <img src={gdpr1} alt="GDPR Essentials Course" onClick={handleShowClick} className="screenshot" />
        <img src={gdpr2} alt="GDPR Essentials Course" onClick={handleShowClick} className="screenshot" />
      </div>
    </div>

    <BuyNowButton margins="60px" checkoutLink={`/products/data-management-master-class/checkout`} />

    <div className="teal-box" style={{ margin: "20px 0 20px 0" }}>
      <h4 className="box-title" style={{ textAlign: "center" }}>
        Contents
      </h4>
      <div className="center-text-box">
        <div className="three-col contents">
          <ol>
            <li>Introduction to European Data Protection</li>
            <li>Data Protection Concepts</li>
            <li>Data Subjects' Rights</li>
            <li>Security of Personal Data</li>
            <li>Information Provision Obligations</li>
            <li>Accountability Requirements</li>
            <li>International Data Transfers</li>
            <li>Data Processing Principles </li>
            <li>Lawful Processing Criteria</li>
            <li>Employment Relationship</li>
            <li>Direct Marketing</li>
            <li>Internet Technology and Communications</li>
            <li>Surveillance Activities</li>
            <li>Supervision and enforcement</li>
            <li>Consequences for GDPR violations</li>
            <li>Territorial and Material Scope of the General Data Protection Regulation</li>
            <li>European Union Institutions</li>
            <li>Legislative Framework</li>
          </ol>
        </div>
      </div>
    </div>

    {/* <div className="gray-box">
      <div className="center-text-box">
        <h4 className="box-title">What People are Saying</h4>
      </div>
    </div> */}
  </>
);
