import posts from "../../data/posts";

const PostImage: React.FC<{ postName: postNickname }> = ({ postName }) => {
  const post = posts.find((p) => p.nickname === postName);

  return (
    <figure>
      <img src={post.image.img} alt={post.image.alt} className="box-shadow" />
      {post.image.photog && post.image.source && (
        <figcaption>
          {post.image.text && (
            <>
              <span dangerouslySetInnerHTML={{ __html: post.image.text }} /> <span>&nbsp; </span>
            </>
          )}
          <span>
            Photo by{" "}
            <a href={post.image.photogLink} target="_blank" rel="noreferrer">
              {post.image.photog}
            </a>{" "}
            on{" "}
            <a href={post.image.sourceLink} target="_blank" rel="noreferrer">
              {post.image.source}
            </a>
          </span>
          {post.image.text && <span>.</span>}
        </figcaption>
      )}
    </figure>
  );
};

export default PostImage;
