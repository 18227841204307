import { Link } from "react-router-dom";

const CDMPStudyProgramNextSteps: React.VFC = () => {
  return (
    <>
      <div style={{ maxWidth: 1100 }}>
        <p className="title product-title">Next Steps</p>
        <div className="blog">
          <p>
            After purchasing the <strong>CDMP Study Program</strong>, you will receive an email with instructions on how
            to provide the list of participants.
          </p>
          <p>Within 24-48 hours, our team will email each participant to share the following study materials:</p>
          <ul>
            <li>
              <Link to="/products/cdmp-study-plan" target="_blank">
                CDMP Study Plan
              </Link>{" "}
              (90-day option by default*)
            </li>
            <li>
              <Link to="/products/cdmp-fundamentals-notes" target="_blank">
                CDMP Fundamentals Notes
              </Link>
            </li>
            <li>
              All three{" "}
              <Link to="/products/cdmp-question-sets" target="_blank">
                CDMP Question Sets
              </Link>{" "}
              (150 total practice questions)
            </li>
            <li>
              Access to video lessons for each chapter of the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>{" "}
              tested on the{" "}
              <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
                CDMP
              </Link>{" "}
              exam
            </li>
            <li>
              <Link to="/products/cdmp-exam-readiness-check" target="_blank">
                CDMP Exam Readiness Check
              </Link>
            </li>
          </ul>
          <p>
            * If desired, participants can switch from the 90-day Study Plan to the Immediate Access option to receive
            all the content at once.
          </p>
          <p>
            All participants receive support for their questions by emailing{" "}
            <a href="mailto:support@datastrategypros.com?subject=Enterprise%20Study%20Program">
              support@datastrategypros.com
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
};

export default CDMPStudyProgramNextSteps;
