const charter = [
  {
    id: 1,
    text: `
    <h4 id="executive-summary" style={{ fontSize: 15 }}>
    1. Executive Summary
    </h4>
    <p>
    This strategy describes the challenge and the opportunity facing [ORGANIZATION NAME] in terms of
    its effective management of data as an asset. The Data Governance Charter establishes the vision,
    guiding principles, scope and goals, essential processes, and integral roles and responsibilities
    relative to Data Governance, a crucial domain that will enhance the effective management of data
    assets at [ORGANIZATION NAME]. The problem statement and scope, stated below, define our
    organization’s first order problem and to whom it applies. The vision statement captures the
    future state of data. We will achieve its vision based on the guiding principles, driven by our
    goals and informed by our responsibilities.
    </p>`,
    notes: `
    <br />
    <p>
    Not every Data Governance Charter will contain an Executive Summary. However, we think it's a good
    idea to provide the bottom line up front.
    <a href="https://media.defense.gov/2020/Oct/08/2002514180/-1/-1/0/DOD-DATA-STRATEGY.PDF" target='_blank' rel='noreferrer'>This document</a>
    includes a great example of an Executive Summary.
    </p>`,
  },
  {
    id: 2,
    text: `
    <h4 id="intro" style={{ fontSize: 15 }}>
    2. Introduction
    </h4>
    <p>
    Data Governance is a key component of the data strategy at [ORGANIZATION NAME]. This discipline
    supports the business strategy by enhancing the effectiveness of operations and decision-making
    through well-considered policies and procedures to manage data as an asset. [ORGANIZATION NAME]
    leaders require data in order to achieve the mission of [BRIEF MISSION STATEMENT SUMMARY]. Team
    members at all levels require access to data that is accurate, timely, and secure. The Data
    Governance Charter is a supporting document that helps ensure data is treated as an asset to help
    achieve these goals.
    </p>
    <p>
    The capabilities required by [ORGANIZATION NAME] depend upon effective Data Management policies,
    standards, and practices. Achieving our mission will require that we integrate data from diverse
    sources, comply with all relevant regulatory standards, maintain the highest level of data
    security and privacy, coordinate data across the business, use analytic tools to achieve superior
    situational awareness, and leverage data to drive insights that allow the business to continue to
    avoid threats and grow.
    </p>
    <p>
    This document describes the problem and establishes the vision, guiding principles, scope and
    goals, essential processes, and integral roles and responsibilities relative to data. The problem
    statement and scope, stated below, define our organization’s first order problem and to whom it
    applies. The vision statement captures the future state of data. We will achieve its vision based
    on the guiding principles, driven by our goals, and informed by our responsibilities.
    </p>`,
    notes: `
    <br />
    <p>
    Designating your sections clearly with numbers or letters enables easy discussion. You could also
    consider using line numbers.</p>
    <p>
    You may choose to list some additional benefits of data strategy from
    <a href='https://medium.com/@willemkoenders/offensive-vs-defensive-data-strategy-do-you-really-need-to-choose-c04f0387dbc3' target='_blank' rel='noreferrer'>this article</a> about offensive
    and defensive capabilities.
    </p>
    `,
  },
  {
    id: 2.3,
    text: `
  <h4 id="problem-statement" style={{ fontSize: 15 }}>
    2a. Problem Statement
  </h4>
  <p>
    [ORGANIZATION NAME] must accelerate its progress toward becoming a data-centric organization.
    Enterprise-level Data Management will ensure that trusted, secured data is made available as
    appropriate throughout the organization. A failure to implement effective Data Management will
    place limits on our capability to take advantage of real time, usable, high quality data to
    support operations, data-driven decision-making, and otherwise add value to the organization.
  </p>
  <p>
  Moving forward, our software and hardware systems should be procured, designed, tested, operated,
  sustained, and upgraded with data interoperability as a key requirement. Currently, these gaps are
  bridged with unnecessary manual steps that introduce complexity, delay, and risk of error. Lack of
  interoperability constrains our ability to achieve our potential efficiency across all domains.
</p>
<p>
  We also must improve the organization's capabilities across all fields required for effective Data
  Management. [ORGANIZATION NAME] must broaden efforts to assess our current talent, recruit new
  data experts, and retain our workforce while establishing policies to ensure that data talent is
  cultivated. We must also increase data-related training and promote a culture of data literacy.
</p>`,
    notes: `
  <br/>
  <p>
  The problem statement sets the stage for the rest of the Data Governance Charter. Your strategic
  suggestions will address the issues outlined in this section.
  </p>
  <p>
  Make sure the Problem Statement is clear and actionable. In your organization, what specific
  challenges are you facing? What specific measures do you suggest taking to mitigate the problem?
  </p>`,
  },
  {
    id: 2.5,
    text: `
    <h4 id="scope" style={{ fontSize: 15 }}>
    2b. Scope
    </h4>
    <p>
      This Data Governance document applies to the entirety of [ORGANIZATION NAME] and its data on
      whichever systems that information resides.
    </p>`,
    notes: `<p>
    The scope of your Data Governance policies may vary; perhaps you’re developing a document that
    only applies to one department or to the whole organization. Be specific and descriptive about
    your intention.
    </p>`,
  },
  {
    id: 3,
    text: `
    <h4 id="guiding-principles" style={{ fontSize: 15 }}>
    3. Guiding Principles
  </h4>
  <ul>
    <li>Transparency:&nbsp; it should be clear how and when decisions are made and processes are created</li>
    <li>Metrics-driven:&nbsp; we should measure and report on how we are doing against our goals</li>
    <li>Consistency:&nbsp; all decisions should be applied consistently</li>
    <li>Stewardship:&nbsp; while formal stewardship roles exist, it is everyone's responsibility to protect
    the privacy, security, and confidentiality of our data as required</li>
    <li>Accountability:&nbsp; decisions and processes should be audited</li>
    <li>Agility:&nbsp; all processes should be able to adapt when appropriate</li>
    <li>Change Management:&nbsp; new processes will require a concerted effort in managing change among teams</li>
  </ul>`,
    notes: `
    <br/>
    <p>
    A set of guiding principles helps you and your audience remain grounded. This will ensure that if
    there are disagreements about the finer points in your Data Governance policy, you have
    established common ground from which to work.
  </p>
  <p>
    Additionally, including a variety of formats in your documents, like bulleted lists, breaks up
    long stretches of text, highlights important points, and helps your reader follow along.
  </p>`,
  },
  {
    id: 4,
    text: `
  <h4 id="goals" style={{ fontSize: 15 }}>
    4. Goals
  </h4>
  <p>
    A core tenet of our Data Strategy is the understanding that data is not an IT asset, but an
    essential organ in our day-to-day operations. Data is ubiquitous. It is critical that data is of
    high quality, accurate, complete, timely, protected, and trustworthy. As such, [ORGANIZATION NAME]
    makes data one of our strategic assets by establishing the following goals.
  </p>
  <p>Data should be:</p>
  <ul>
    <li><strong>Accessible</strong>:&nbsp; users can locate and retrieve the needed data</li>
    <li><strong>Understandable</strong>:&nbsp; users can find descriptions of data to recognize the content, context, and
      applicability
    </li>
    <li><strong>Trustworthy</strong>:&nbsp; users can be confident in all aspects of data for decision-making</li>
    <li><strong>Interoperable</strong>:&nbsp; users and producers have a common representation and comprehension of data</li>
    <li><strong>Secure</strong>:&nbsp; users know that data is protected from unauthorized use and manipulation</li>
  </ul>`,
    notes: `
  <br/>
  <p>
  In determining your goals for your Data Governance project, what problems are you aiming to
  address? What problems are solvable through policy and behavior changes? What do you want to
  achieve as a data-savvy organization that this framework will enable?
  </p>
  <p>
  In this example, we have provided some common goals for an organization, but feel free
  to be creative in order to meet your organization’s specific needs.
  </p>
  <p>
  It's important to explicitly articulate actionable objectives whenever possible.
  </p>`,
  },
  {
    id: 4.1,
    text: `
    <h4 id="accessible" style={{ fontSize: 15 }}>
    4a. Accessible
  </h4>
  <p>
    The goal of making data accessible enables authorized users to discover the existence of data that
    is of particular interest and to obtain the data they need when they need it, including
    having data automatically pushed to interested and authorized users. Data Stewards,
    Custodians, and Trustees are all responsible and obligated to identify, register, and expose their data in a way that makes
    it easily discoverable and accessible across the enterprise, and to external partners as
    appropriate. Accessibility requires that protective mechanisms (e.g., security controls) are in
    place for credentialed users to ensure that access is permitted in accordance with laws,
    regulations, and policies.
  </p>
  <p>We will know we have made progress on data accessibility when:</p>
  <ul>
    <li>Metadata standards are implemented, including location and access methods for shared data</li>
    <li>Common platforms and services create, retrieve, share, utilize, and manage data</li>
    <li>Data access and sharing is controlled through reusable APIs</li>
  </ul>`,
    notes: `<br/><p>Data accessibility enhances decision-making and operational efficiency by ensuring that
    authorized users can quickly access necessary information. It promotes innovation and
    fosters collaboration by making data readily available across the organization.</p>`,
  },
  {
    id: 4.3,
    text: `
    <h4 id="understandable">4b. Understandable</h4>
    <p>
      Understanding data is critical to enable enhanced, more accurate, and timely decision-making. The
      inability to aggregate, compare, and truly understand data adversely affects our teams’ abilities
      to react to change. Without a proper understanding of data, interpretation and analysis could be
      flawed, resulting in equally flawed outcomes. Bringing together business and technology and
      applying a data-centric approach enable massive amounts of data to be transformed into the
      insights needed to lead [ORGANIZATION NAME] more effectively and efficiently.
    </p>
    <p>We will know we have made progress on data understandability when:</p>
  <ul>
    <li>Data elements are aligned into a comprehensive data dictionary with a controlled, yet flexible,
      vocabulary and taxonomy</li>
    <li>We have established processes to create, align, implement, and manage business vocabularies,
      including enterprise standards</li>
    <li>All team members feel comfortable working with our databases and drawing conclusions from the
      information inside</li>
  </ul>`,
    notes: `
    <br/>
    <p>Ensuring data is understandable helps team members to make well-informed decisions.
    A comprehensive data dictionary and standardized business vocabulary can enhance understandability
    and help team members effectively engage with data, fostering a more data-literate organization,
    and improving overall decision-making processes.</p>`,
  },
  {
    id: 4.5,
    text: `
    <h4 id="trustworthy">4c. Trustworthy</h4>
    <p>
      It is vital for our team members and clients can trust the data we use to deliver value.
      Lacking confidence in the data may result in less timely decision-making or, consequently, no
      decision when one is warranted.
    </p>
    <p>We will know we have made progress on data trustworthiness when:</p>
    <ul>
      <li>Data has protection, lineage, and pedigree metadata throughout its lifecycle</li>
      <li>We execute Data Quality Management techniques to assess and enhance data quality</li>
      <li>We properly tag and maintain all appropriate data and records in accordance with established
        processes and policies</li>
    </ul>`,
    notes: `<br>
    <p>Establishing trust in data is essential for effective decision-making and the overall value that can be derived from data.
    Ensuring trustworthiness involves implementing robust data protection measures, maintaining thorough lineage information, 
    and continuously enhancing data quality through structured management techniques.
    </p>`,
  },
  {
    id: 4.7,
    text: `<h4 id="interoperable">4d. Interoperable</h4>
    <p>
      Properly exchanging data between systems and maintaining semantic understanding are critical for
      successful decision-making and operations across departments. Achieving interoperability using
      common data formats and machine-to-machine communications accelerates advanced algorithm
      development and provides a strategic advantage.
    </p>
    <p>We will know we have made progress on data interoperability when:</p>
    <ul>
      <li>All data assets are machine-readable</li>
      <li>We are able to rapidly mediate different data standards and formats without serious loss
        of fidelity or accuracy</li>
      <li>We have developed a data-tagging strategy and subsequent implementation plan to enable data
        interoperability</li>
    </ul>`,
    notes: `
    <br>
    <p>Interoperability entails standardization of data formats and enablement of seamless machine-to-machine communication.
    </p>`,
  },
  {
    id: 4.8,
    text: `<h4 id="secure">4e. Secure</h4>
    <p>
      Ensuring the security of data is crucial to maintaining the trust of stakeholders and protecting
      the organization against data breaches and other security threats. Data Security involves both
      physical and digital measures to safeguard data from unauthorized access, use, disclosure,
      disruption, modification, or destruction.
    </p>
    <p>
      Effective Data Security is implemented through a comprehensive set of policies, procedures, and
      technological controls. This includes, but is not limited to, the use of encryption, secure data
      storage solutions, rigorous access controls, and continuous monitoring of security practices.
    </p>
    <p>We will know we have made progress on data security when:</p>
    <ul>
      <li>There is a significant reduction in data-related security incidents year-over-year</li>
      <li>All sensitive data is encrypted both at rest and in transit</li>
      <li>Regular security audits and penetration tests are conducted and result in actionable
        improvements</li>
      <li>Secure awareness training is completed annually by all employees</li>
    </ul>
  `,
    notes: `
    <br/>
    <p>
    Establishing strong Data Security measures not only protects the organization's data assets but
    also reinforces the integrity and reliability of its Data Governance framework.
    </p>`,
  },
  {
    id: 5,
    text: `
    <h4 id="implementation">5. Implementation</h4>
  <p>
    Implementing the Data Governance Charter involves a series of strategic actions designed to align
    the organization's Data Management practices with its overarching goals. This process starts with
    the establishment of a dedicated Data Governance team and the development of an operational plan
    that outlines specific tasks, timelines, and responsibilities.
  </p>
  <p>
    The implementation phase includes the integration of Data Governance policies into everyday
    business processes, training staff on new data procedures and standards, and setting up an
    oversight mechanism to ensure compliance and continuous improvement.
  </p>
  <p>Critical steps in the implementation phase are:</p>
  <ul>
    <li>Formalizing the Data Governance structure and defining roles and responsibilities within the
      organization</li>
    <li>Developing and disseminating Data Governance policies and procedures</li>
    <li>Conducting training sessions for all stakeholders to ensure understanding and adherence to Data
      Governance practices</li>
    <li>Setting up monitoring systems to track compliance and effectiveness of Data Governance
      initiatives</li>
    <li>Regularly reviewing and updating the Data Governance framework to keep it relevant as
      organizational needs evolve</li>
  </ul>`,
    notes: `
  <br/>
  <p>
    Effective implementation of the Data Governance Charter is critical for ensuring that the
    guidelines and practices outlined in the document are brought to life and become part of the
    organizational culture. This not only enhances data quality and security but also supports better
    decision-making across the organization.
  </p>`,
  },
  {
    id: 6,
    text: `
    <h4 id="operating-framework">6. Operating Framework</h4>
    <p>
    Clearly defined roles and responsibilities are fundamental to the effective execution of Data
    Governance policies. This section delineates the key roles involved in Data Governance and
    outlines their specific responsibilities.
  </p>
  <ul>
    <li><strong>Data Governance Board</strong>:&nbsp; provides strategic direction, oversight, and
      high-level monitoring of Data Governance activities; approves Data Governance policies and
      resolves high-impact issues</li>
    <li><strong>Data Stewards</strong>:&nbsp; responsible for the management and fitness of data elements—both
      content and metadata; ensure the quality, accessibility, and protection of data</li>
    <li><strong>Data Custodians</strong>:&nbsp; handle the technical environment and are responsible for the
      safe custody, transport, and storage of data, as well as the implementation of business rules</li>
    <li><strong>Data Trustees</strong>:&nbsp; senior-level executives who own the data assets in their
      functional areas; they are accountable for the data quality and integrity within their domains</li>
    <li><strong>Data Users</strong>:&nbsp; recipients of data who rely on accurate, timely, and
      relevant data for decision-making; they must adhere to Data Governance policies and procedures</li>
  </ul>
  <p>
    Each role is vital for maintaining the lifecycle and integrity of the data within the
    organization, ensuring that data-related decisions support the overall strategic objectives and
    comply with relevant regulations and standards.
  </p>`,
    notes: `
  <br/>
  <p>
  The assignment of clear roles and responsibilities within Data Governance ensures accountability
  and establishes a structure for maintaining the quality and security of data throughout its
  lifecycle. This clarity supports the organization's Data Management and enhances compliance and
  operational effectiveness.
  </p>
  <p>To read more about the various roles listed here, check out 
  <a href="https://www.datastrategypros.com/resources/data-steward" target="_blank" rel="noreferrer">this article</a>.</p>`,
  },
  {
    id: 6.1,
    text: `
  <h4 id="dgsc" style={{ fontSize: 15 }}>6a. Data Governance Steering Committee (DGSC)</h4>
  <p>
    The Data Governance Steering Committee (DGSC) plays a crucial role in overseeing the Data
    Governance program. Composed of senior leaders from various departments, the DGSC ensures
    alignment between Data Governance initiatives and organizational strategies.
  </p>
  <p>Key responsibilities of the DGSC include:</p>
  <ul>
    <li>Setting priorities for Data Governance projects based on business needs and strategic goals</li>
    <li>Approving Data Governance policies and frameworks</li>
    <li>Resolving conflicts and issues that arise within Data Governance operations</li>
    <li>Monitoring the effectiveness of Data Governance practices and making recommendations for
      improvements</li>
    <li>Communicating the value and progress of Data Governance initiatives to stakeholders across the
      organization</li>
  </ul>
  <p>
    The DGSC meets regularly to review the status of Data Governance activities and to ensure that
    Data Governance efforts are productive and aligned with the overall business objectives.
  </p>
`,
    notes: `
  <br/>
  <p>
  The establishment of a DGSC is essential for the success of the Data Governance program, providing
  strategic leadership and ensuring that Data Governance aligns with and supports the broader goals
  of the organization.
  </p>`,
  },
  {
    id: 6.2,
    text: `
    <h4 id="dgpo">6b. Data Governance Program Office (DGPO)</h4>
    <p>
      The Data Governance Program Office (DGPO) is the operational arm of the Data Governance
      initiative. It provides the day-to-day management and support necessary to ensure the successful
      implementation of Data Governance policies and practices across the organization.
    </p>
    <p>Responsibilities of the DGPO include:</p>
    <ul>
      <li>Coordinating and supporting Data Governance activities and projects</li>
      <li>Maintaining and updating the Data Governance framework and documentation</li>
      <li>Facilitating communication between the Data Governance Steering Committee, Data Stewards, and
        other stakeholders</li>
      <li>Monitoring compliance with Data Governance policies and reporting on performance metrics</li>
      <li>Providing training and resources to enhance Data Governance understanding and practice within
        the organization</li>
    </ul>
    <p>
      The DGPO acts as a hub for all Data Governance-related issues, ensuring consistency and coherence
      in how Data Governance is applied throughout the organization.
    </p>`,
    notes: `
  <br/>
  <p>
    By managing the operational aspects of Data Governance, the DGPO plays a key role in sustaining
    and enhancing the organization's Data Governance capabilities. It ensures that Data Governance
    practices are effectively integrated into organizational processes and that the Data Governance
    program continues to evolve in response to changing needs and challenges.
  </p>`,
  },
  {
    id: 6.3,
    text: `<h4 id="data-steward-council" style={{ fontSize: 15 }}>6c. Data Steward Council</h4>
  <p>
    The Data Steward Council consists of Data Stewards from various departments within the
    organization. This council is a critical component of the Data Governance structure, tasked with
    ensuring consistent and effective management of data assets across different areas of the
    business.
  </p>
  <p>Key responsibilities of the Data Steward Council include:</p>
  <ul>
    <li>Developing and maintaining data policies, standards, and procedures to guide Data Management
      practices</li>
    <li>Ensuring data quality by implementing data quality standards and monitoring compliance across
      departments</li>
    <li>Facilitating data sharing and integration efforts to enhance interoperability and consistency of
      data use</li>
    <li>Resolving data-related issues and conflicts that arise within the organization</li>
    <li>Advocating for the importance of data accuracy, privacy, and security within their respective
      domains</li>
    <li>Collaborating with IT and business units to align data-related activities with organizational
      goals and strategies</li>
  </ul>
  <p>
    The council meets regularly to review data practices, discuss challenges, and share best practices
    to continually improve the management of the organization’s data.
  </p>`,
    notes: `
  <br/>
  <p>
  The Data Steward Council plays a pivotal role in the operationalization of Data Governance,
  bridging the gap between policy and practice. Their collaborative efforts ensure that data assets
  are effectively managed and leveraged to support the organization's strategic objectives.
  </p>`,
  },
  {
    id: 7,
    text: `
    <h4 id="metrics" style={{ fontSize: 15 }}>7. Metrics</h4>
  <p>
    Metrics are crucial for assessing the effectiveness of the Data Governance framework and ensuring
    accountability across the organization. They provide quantifiable data points that help measure
    success and identify areas for improvement.
  </p>
  <p>Key metrics for monitoring Data Governance include:</p>
  <ul>
    <li><strong>Data Quality:</strong>&nbsp; error rates, completeness, consistency, and reliability of data</li>
    <li><strong>Data Utilization:</strong>&nbsp; frequency of data access and usage patterns among various
      departments</li>
    <li><strong>Compliance:</strong>&nbsp; rate of adherence to data policies and standards, including privacy
      and security regulations</li>
    <li><strong>Data Management Maturity:</strong>&nbsp; progress in achieving higher levels of data
      management maturity, as outlined in the Data Management Maturity Assessment</li>
    <li><strong>User Satisfaction:</strong>&nbsp; feedback from users on the accessibility and usability of
      data</li>
    <li><strong>Operational Efficiency:</strong>&nbsp; improvement in operational efficiency and reduction in
      operational risks due to better Data Management</li>
  </ul>
  <p>
    Regularly reporting these metrics allows the organization to track its progress, justify
    investments in Data Governance, and guide future policy decisions.
  </p>`,
    notes: `
  <br/>
  <p>
    By continuously measuring and analyzing these metrics, the organization can ensure that its Data
    Governance initiatives are aligned with its strategic goals and are delivering tangible benefits.
  </p>
  <p>To read more about Data Management Maturity Assessments, please check out
  <a href='https://www.datastrategypros.com/resources/data-management-maturity-assessment-dmma' target='_blank' rel='noreferrer'>this comparison</a> of various models.</p>`,
  },
  {
    id: 8,
    text: `
  <h4 id="conclusion" style={{ fontSize: 15 }}>8. Conclusion</h4>
  <p>
    The Data Governance Charter serves as a foundational document that guides the organization in
    managing its data assets responsibly and effectively. It establishes a framework for Data
    Governance that aligns with the organization’s overall strategy and compliance requirements.
  </p>
  <p>
    By clearly defining the structure, roles, responsibilities, and metrics, the Charter ensures that
    all stakeholders understand their roles and the importance of proper Data Management. This
    alignment is critical for fostering a culture of data-driven decision making, which ultimately
    enhances operational efficiency, reduces risks, and drives innovation.
  </p>
  <p>
    As the organization evolves, so too should the Data Governance Charter. Regular reviews and
    updates will ensure that it remains relevant and continues to provide value in a changing business
    and technological landscape.
  </p>`,
    notes: `
  <br/>
  <p>
    The successful implementation of this Charter will require ongoing commitment and collaboration
    from all parties involved. It is the responsibility of everyone within the organization to uphold
    the principles set forth in this document to ensure the integrity and value of the organization's
    data assets.
  </p>`,
  },
];

export default charter;
