import { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Drafts, Event, LaptopChromebook, AutoAwesome } from "@mui/icons-material";

import CustomModal from "../../../components/CustomModal";

import intake from "../../../img/products/services/career-coaching/01_intake.webp";
import calendly from "../../../img/products/services/career-coaching/02_calendly.webp";
import zoom from "../../../img/products/services/career-coaching/03_zoom.webp";
import pdf from "../../../img/products/services/career-coaching/04_pdf.webp";

export default function OurProcess() {
  const tablet = useMediaQuery("(min-width: 850px)");
  const desktop = useMediaQuery("(min-width: 1024px)");

  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}

      {desktop ? (
        <div className="teal-box">
          <div className="center-text-box">
            <h4 className="box-title" style={{ textAlign: "center" }}>
              Our Process
            </h4>
            <div
              style={{
                marginTop: 30,
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                gap: 20,
                textAlign: "center",
                textWrap: "balance",
                marginBottom: 20,
              }}
            >
              <div>
                <div style={{ position: "relative", marginBottom: 4 }}>
                  <img src={intake} alt="intake" style={{ cursor: "pointer" }} onClick={handleShowClick} />
                  <Drafts style={{ fontSize: 50, position: "absolute", bottom: 12, right: 10 }} />
                </div>
                <p>Detailed intake process </p>
              </div>
              <div>
                <div style={{ marginBottom: 4, position: "relative" }}>
                  <img src={calendly} alt="calendly" style={{ cursor: "pointer" }} onClick={handleShowClick} />
                  <Event style={{ fontSize: 50, position: "absolute", bottom: 12, right: 10 }} />
                </div>
                <p>Schedule session with Career Coach</p>
              </div>
              <div>
                <div style={{ position: "relative", marginBottom: 4 }}>
                  <img src={zoom} alt="zoom" style={{ cursor: "pointer" }} onClick={handleShowClick} />
                  <LaptopChromebook style={{ fontSize: 50, position: "absolute", bottom: 12, right: 10 }} />
                </div>
                <p>Zoom call with Career Coach</p>
              </div>
              <div>
                <div style={{ position: "relative", marginBottom: 4 }}>
                  <img src={pdf} alt="pdf" style={{ cursor: "pointer" }} onClick={handleShowClick} />
                  <AutoAwesome style={{ fontSize: 50, position: "absolute", bottom: 12, right: 10 }} />
                </div>
                <p>Receive action-oriented summary</p>
              </div>
            </div>
            <div className="product-page-divider" style={{ margin: "0px 0px 40px 0px" }} />
            <div
              style={{
                marginTop: 20,
                display: "grid",
                gridTemplateColumns: "100px 1fr",
              }}
            >
              <Drafts style={{ fontSize: 40, marginTop: 2, marginBottom: 4 }} />
              <div className="blog">
                <h4>Intake Process</h4>
                <p>To get to know you better, we ask for information about:</p>
                <ul style={{ marginBottom: 4 }}>
                  <li>Current role and responsibilities</li>
                  <li>Future career goals</li>
                  <li>Challenges you face in achieving your career goals</li>
                </ul>
                <p>
                  We also ask that you upload your resume and provide the link to your LinkedIn profile. These job
                  search documents help provide context to the Career Coach to inform the conversation.
                </p>
                <p>
                  For a detailed review of your resume and Linkedin profile, check out our dedicated{" "}
                  <Link to="/products/resume-review" target="_blank">
                    Resume & LinkedIn Review
                  </Link>{" "}
                  service.
                </p>
                <div className="product-page-divider" style={{ marginBottom: 40 }} />
              </div>
              <Event style={{ fontSize: 40, marginTop: 2, marginBottom: 4 }} />
              <div className="blog">
                <h4>Schedule Session</h4>
                <p>
                  You will also receive a link to book your Career Coaching call via TidyCal, an online scheduling tool.
                </p>
                <p>
                  Don't see a time that works for you? No problem! We'll adapt to fit into your busy schedule. Don't
                  hesitate to reach out to{" "}
                  <a href="mailto:coaching@datastrategypros.com?subject=Career%20Coaching%20inquiry">
                    Customer Support
                  </a>{" "}
                  with a list of your preferred days and times, and we'll make something work.
                </p>
                <div className="product-page-divider" style={{ marginBottom: 40 }} />
              </div>
              <LaptopChromebook style={{ fontSize: 40, marginTop: 2, marginBottom: 4 }} />
              <div className="blog">
                <h4>Zoom Call</h4>
                <p>
                  During the call with the Career Coach, you'll discuss your current situation and career goals. The
                  Coach will come into the call equipped with suggestions to improve your prospects for your job search
                  or otherwise advance your goals.
                </p>
                <p>
                  Our Career Coach is ready to help you level up as a data practitioner. We'll discuss barriers blocking
                  you from achieving your full potential and ways you can tap into your unique strengths to achieve your
                  goals.
                </p>
                <p>
                  If you're seeking a new role, we'll also discuss the current state of the job market, in-demand skills
                  and certifications, and how you can best position yourself to stand apart from the competition. We'll
                  talk about available positions in your area or remote. And we'll discuss how to best leverage your
                  network to help find your next job.
                </p>
                <div className="product-page-divider" style={{ marginBottom: 40 }} />
              </div>
              <AutoAwesome style={{ fontSize: 40, marginTop: 2, marginBottom: 4 }} />
              <div className="blog" style={{ marginBottom: 0 }}>
                <h4>Results</h4>
                <p>
                  Within 1-2 business days, you'll receive a conversation recap, including a pdf with detailed notes
                  about your suggested next steps.
                </p>
                <p>
                  Every Career Coaching session results in a document with the following sections:
                  <ul style={{ marginBottom: 0 }}>
                    <li>
                      Goals, which outlines our understanding of your unique career goals and other relevant information
                    </li>
                    <li>Action items that describe the specific steps you can take to level-up your career</li>
                    <li>Recommended qualifications, a list of recommended skills and certifications</li>
                    <li>Jobs near you and/or remote jobs (based on your preferences)</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : tablet ? (
        <div className="teal-box">
          <div className="center-text-box">
            <h4 className="box-title" style={{ textAlign: "center" }}>
              Our Process
            </h4>
            <div
              style={{
                marginTop: 30,
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                textAlign: "center",
                textWrap: "balance",
                marginBottom: 20,
              }}
            >
              <div>
                <Drafts style={{ fontSize: 50, marginBottom: 4 }} />
                <p>Complete detailed intake process </p>
              </div>
              <div>
                <Event style={{ fontSize: 50, marginBottom: 4 }} />
                <p>Schedule session with Career Coach</p>
              </div>
              <div>
                <LaptopChromebook style={{ fontSize: 50, marginBottom: 4 }} />
                <p>Zoom call with Career Coach</p>
              </div>
              <div>
                <AutoAwesome style={{ fontSize: 50, marginBottom: 4 }} />
                {/* <p>Receive detailed, action-oriented next steps based on conversation</p> */}
                <p>Receive action-oriented summary</p>
              </div>
            </div>
            <div className="product-page-divider" style={{ margin: "0px 0px 40px 0px" }} />
            <div
              style={{
                marginTop: 20,
                display: "grid",
                gridTemplateColumns: "100px 1fr",
              }}
            >
              <Drafts style={{ fontSize: 40, marginTop: 2, marginBottom: 4 }} />
              <div className="blog">
                <h4>Intake Process</h4>
                <p>To get to know you better, we ask for information about:</p>
                <ul style={{ marginBottom: 4 }}>
                  <li>Current role and responsibilities</li>
                  <li>Future career goals</li>
                  <li>Challenges you face in achieving your career goals</li>
                </ul>
                <p>
                  We also ask that you upload your resume and provide the link to your LinkedIn profile. These job
                  search documents help provide context to the Career Coach to inform the conversation.
                </p>
                <p>
                  For a detailed review of your resume and Linkedin profile, check out our dedicated{" "}
                  <Link to="/products/resume-review" target="_blank">
                    Resume & LinkedIn Review
                  </Link>{" "}
                  service.
                </p>
                <div className="product-page-divider" style={{ marginBottom: 40 }} />
              </div>
              <Event style={{ fontSize: 40, marginTop: 2, marginBottom: 4 }} />
              <div className="blog">
                <h4>Schedule Session</h4>
                <p>
                  You will also receive a link to book your Career Coaching call. Don't see a time that works for you?
                  No problem! We'll adapt to fit into your busy schedule. Don't hesitate to reach out to{" "}
                  <a href="mailto:coaching@datastrategypros.com?subject=Career%20Coaching%20inquiry">
                    Customer Support
                  </a>{" "}
                  with a list of your preferred days and times, and we'll make something work.
                </p>
                <div className="product-page-divider" style={{ marginBottom: 40 }} />
              </div>
              <LaptopChromebook style={{ fontSize: 40, marginTop: 2, marginBottom: 4 }} />
              <div className="blog">
                <h4>Zoom Call</h4>
                <p>
                  During the call with the Career Coach, you'll discuss your current situation and career goals. The
                  Coach will come into the call equipped with suggestions to improve your prospects for your job search
                  or otherwise advance your goals.
                </p>
                <p>
                  Our Career Coach is ready to help you level up as a data practitioner. We'll discuss barriers blocking
                  you from achieving your full potential and ways you can tap into your unique strengths to achieve your
                  goals.
                </p>
                <p>
                  If you're seeking a new role, we'll also discuss the current state of the job market, in-demand skills
                  and certifications, and how you can best position yourself to stand apart from the competition. We'll
                  talk about available positions in your area or remote. And we'll discuss how to best leverage your
                  network to help find your next job.
                </p>
                <div className="product-page-divider" style={{ marginBottom: 40 }} />
              </div>
              <AutoAwesome style={{ fontSize: 40, marginTop: 2, marginBottom: 4 }} />
              <div className="blog" style={{ marginBottom: 0 }}>
                <h4>Results</h4>
                <p>
                  Within 1-2 business days, you'll receive a conversation recap, including a pdf with detailed notes
                  about your suggested next steps.
                </p>
                <p>
                  Every Career Coaching session results in a document with the following sections:
                  <ul style={{ marginBottom: 0 }}>
                    <li>
                      Goals, which outlines our understanding of your unique career goals and other relevant information
                    </li>
                    <li>Action items that describe the specific steps you can take to level-up your career</li>
                    <li>Recommended qualifications, a list of recommended skills and certifications</li>
                    <li>Jobs near you and/or remote jobs (based on your preferences)</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="teal-box">
          <div className="center-text-box" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h4 className="box-title" style={{ textAlign: "center" }}>
              Our Process
            </h4>
            <div style={{ height: "0.5em" }} />
            <div className="blog">
              <Drafts style={{ float: "left", marginRight: 8 }} />
              <h4>Intake Process</h4>
              <p>To get to know you better, we ask for information about:</p>
              <ul style={{ marginBottom: 4 }}>
                <li>Current role and responsibilities</li>
                <li>Future career goals</li>
                <li>Challenges you face in achieving your career goals</li>
              </ul>
              <p>
                We also ask that you upload your resume and provide the link to your LinkedIn profile. These job search
                documents help provide context to the Career Coach to inform the conversation.
              </p>
              <p>
                For a detailed review of your resume and Linkedin profile, check out our dedicated{" "}
                <Link to="/products/resume-review" target="_blank">
                  Resume & LinkedIn Review
                </Link>{" "}
                service.
              </p>
              <div style={{ height: "0.5em" }} />
              <Event style={{ float: "left", marginRight: 8 }} />
              <h4>Schedule Session</h4>
              <p>
                You will also receive a link to book your Career Coaching call. Don't see a time that works for you? No
                problem! We'll adapt to fit into your busy schedule. Don't hesitate to reach out to{" "}
                <a href="mailto:coaching@datastrategypros.com?subject=Career%20Coaching%20inquiry">Customer Support</a>{" "}
                with a list of your preferred days and times, and we'll make something work.
              </p>
              <div style={{ height: "0.5em" }} />
              <LaptopChromebook style={{ float: "left", marginRight: 8 }} />
              <h4>Zoom Call</h4>
              <p>
                During the call with the Career Coach, you'll discuss your current situation and career goals. The Coach
                will come into the call equipped with suggestions to improve your prospects for your job search or
                otherwise advance your goals.
              </p>
              <p>
                Our Career Coach is ready to help you level up as a data practitioner. We'll discuss barriers blocking
                you from achieving your full potential and ways you can tap into your unique strengths to achieve your
                goals.
              </p>
              <p>
                If you're seeking a new role, we'll also discuss the current state of the job market, in-demand skills
                and certifications, and how you can best position yourself to stand apart from the competition. We'll
                talk about available positions in your area or remote. And we'll discuss how to best leverage your
                network to help find your next job.
              </p>
              <div style={{ height: "0.5em" }} />
              <AutoAwesome style={{ float: "left", marginRight: 8 }} />
              <h4>Results</h4>
              <p>
                Within 1-2 business days, you'll receive a conversation recap, including a pdf with detailed notes about
                your suggested next steps.
              </p>
              <p>
                Every Career Coaching session results in a document with the following sections:
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    Goals, which outlines our understanding of your unique career goals and other relevant information
                  </li>
                  <li>Action items that describe the specific steps you can take to level-up your career</li>
                  <li>Recommended qualifications, a list of recommended skills and certifications</li>
                  <li>Jobs near you and/or remote jobs (based on your preferences)</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
