import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const BreakingAd: React.VFC = () => {
  const desktop = useMediaQuery("(min-width:640px)");

  return (
    <div className="teal-box" style={{ maxWidth: "1000px", marginTop: 57.6 }}>
      <h4
        style={{
          fontSize: "1.2em",
          marginBottom: "12px",
          textAlign: "center",
        }}
      >
        <Link to="/products/data-science-email-bootcamp">Data Science Email Bootcamp</Link>
      </h4>
      {desktop && (
        <div
          style={{
            float: "left",
            margin: "0px 30px 1em 0",
          }}
        >
          <Link to="/products/data-science-email-bootcamp">
            <img
              src="https://www.nicolejaneway.com/wp-content/uploads/2021/04/breaking_into_ds_square.webp"
              alt="hands stacked over table"
              style={{
                maxWidth: "215px",
                padding: "1em",
              }}
            />
          </Link>
        </div>
      )}
      <div className="blog" style={{ marginRight: 10 }}>
        <p>
          Email Bootcamp delivers the opportunity to learn Data Science through <strong>three to four emails</strong>{" "}
          each week on a different foundational topic. You'll gain access to an{" "}
          <strong>exclusive Facebook group</strong> to interact with other data professionals and get any of your
          questions related to the course content answered by an expert.
        </p>
        <p>
          Each week, you'll receive +4 Data Science emails containing: <strong>key resources</strong>,{" "}
          <strong>crucial concepts</strong>, <strong>midweek checkin</strong>, <strong>weekly wrapup</strong>, and
          <strong>bonus content</strong>.
        </p>
        <p>
          Topics in the bootcamp include <strong>Statistics</strong>, <strong>Python and core libraries</strong>,{" "}
          <strong>SQL</strong>, <strong>Data Strategy</strong>, <strong>Machine Learning</strong>, and{" "}
          <strong>Deep Learning</strong>.
        </p>
        <p>
          Overall, <Link to="/products/data-science-email-bootcamp">Data Science Email Bootcamp</Link> is an excellent
          resource for technology leaders to better understand this highly in-demand field.
        </p>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Link to="/products/data-science-email-bootcamp">
          <button
            className="btn btn-accent"
            style={{
              margin: "1em auto",
              width: 250,
            }}
          >
            Sign Up
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BreakingAd;
