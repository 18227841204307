import { Link } from "react-router-dom";
import QuickStart from "./QuickStart";

const CAPQuickStart = () => {
  return <QuickStart title="CAP Exam Quick Start" description={quickStartDescription} />;
};

export default CAPQuickStart;

const quickStartDescription = (
  <div className="blog" style={{ maxWidth: 930 }}>
    <p>
      The CAP is a challenging exam that evaluates technical skills, problem solving abilities, and familiarity with
      analytics concepts. The exam is 3 hours long and consists of 100 multiple choice questions.
    </p>

    <p>
      The CAP is delivered as online through the Prolydian test taking platform as well as at computer-based testing
      centers throughout the world. You are allowed one bathroom break during the exam, however you cannot return to any
      questions that you have already seen during test after your break.
    </p>

    <p>
      Note that the aCAP is the same exam as the CAP, and can be converted to the higher level credential once the{" "}
      <Link to="/resources/certified-analytics-professional#eligibility">eligibility requirements</Link> are met.
    </p>

    <p>
      Be sure to join the CAP Study Group on{" "}
      <a href="https://www.facebook.com/groups/certifiedanalyticsprofessionals/" target="_blank" rel="noreferrer">
        Facebook
      </a>{" "}
      or{" "}
      <a href="https://www.linkedin.com/groups/13026365/" target="_blank" rel="noreferrer">
        LinkedIn
      </a>
      .
    </p>
  </div>
);
