import { useState } from "react";
import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import VisualDescription from "../../components/product-pages/VisualDescription";
import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";
import CDMPLevelsModal from "../../components/CDMPLevelsModal";

import testimonials from "../../data/testimonials";

const PRODUCT = "Specialist Guides";

export default function SpecialistGuides() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && <CDMPLevelsModal setShowModal={setShowModal} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        description={description(setShowModal)}
        tealBox={tealBox}
        rest={rest()}
      />
    </>
  );
}

const description = (setShowModal) => {
  return (
    <div className="gray-box" id="product-description">
      <h4>Description</h4>
      <div className="blog">
        <p>
          Prepare for the{" "}
          <Link to="/resources/cdmp-specialist-exam" target="_blank">
            CDMP Specialist Exams
          </Link>{" "}
          with these 15+ page guides.
        </p>

        <p>Each guide contains:</p>
        <ul>
          <li>Introduction to the CDMP Specialist Exams</li>
          <li>Topic Summary</li>
          <li>Practice Questions & Answers</li>
          <li>Further Reading</li>
          <li>Real World Examples</li>
          <li>Best Practices</li>
          <li>Tips from CDMP Study Group</li>
          <li>Next Steps</li>
        </ul>
        <p>
          <Link to="/resources/cdmp-specialist-exam" target="_blank">
            CDMP Specialist Exams
          </Link>{" "}
          offer data practitioners the opportunity to advance their credentials and knowledge of specific Data Strategy
          topics. Successful completion of two Specialist Exams is required in order to attain recognition at the{" "}
          <span className="fake-link-1" onClick={() => setShowModal(true)}>
            Practitioner or Master level
          </span>
          . To further develop your understanding of these topics, don't miss our{" "}
          <Link to="/products/specialist-question-sets" target="_blank">
            Specialist Question Sets
          </Link>
          .
        </p>
        <p>
          <i>
            Note: this product is available as part of the{" "}
            <Link to="/products/specialist-bundle" target="_blank">
              Specialist Bundle
            </Link>
          </i>
        </p>
      </div>
    </div>
  );
};

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>Features</h4>
    <VisualDescription product={PRODUCT} />
  </div>
);

const rest = () => {
  const testimonialsToShow = testimonials.filter((testimonial) => testimonial.id.includes("specialist-exam-guide"));

  return <ProductTestimonials testimonialsToShow={testimonialsToShow} />;
};
