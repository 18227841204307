import { useState, useEffect } from "react";
import validator from "validator";

import CompanyForm from "./CompanyForm";
import NonDisclosureAgreement from "./NonDisclosureAgreement";

import { Grid, Checkbox } from "@mui/material";

import blockedEmails from "../../data/blockedEmails";
import payments from "../../data/payments";

import messages from "../../data/messages/checkoutMessages";

export default function CustomerForm({
  paymentName,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  setEmail,
  setCompany,
  companyIsBlank,
  setCompanyIsBlank,
  freeTrial,
  setStep,
}) {
  const payment = payments.find((payment) => payment.name === paymentName);

  const documentReview = paymentName === "document-review";

  const message = messages.find((message) => message.type === payment.type).message;

  const [validEmail, setValidEmail] = useState(false);
  const [checked, setChecked] = useState(!documentReview);
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    switch (payment.name) {
      case "enterprise-study-program":
        setValidated(validEmail && !companyIsBlank);
        break;
      case "document-review":
        setValidated(validEmail && checked);
        break;
      default:
        setValidated(validEmail);
        break;
    }
  }, [validEmail, firstName, lastName, companyIsBlank, payment.name, checked]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (freeTrial) {
      setStep(4);
    } else {
      setStep(0.5);
    }
  };

  function handleShowClick() {
    setShowModal(!showModal);
  }

  const validateEmail = (e) => {
    const email = e.target.value.toLowerCase();

    if (blockedEmails.some((blockedEmail) => email.includes(blockedEmail))) {
      setValidEmail(false);
    } else if (validator.isEmail(email)) {
      setValidEmail(true);
      setEmail(email);
    } else if (!validator.isEmail(email)) {
      setValidEmail(false);
    }
  };

  return (
    <>
      {showModal && <NonDisclosureAgreement handleShowClick={handleShowClick} />}
      <form onSubmit={handleSubmit}>
        <div>
          <p
            style={{
              fontSize: "0.9em",
              marginBottom: "16px",
            }}
          >
            Please be sure to enter the email where you would like to receive {message}.
          </p>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <label htmlFor={"firstName"}>
                  <p style={{ fontSize: "13px", color: "rgb(48, 49, 61)" }}>
                    <strong>First Name</strong>
                  </p>
                </label>
                <input
                  type="text"
                  id="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  className="field checkout-form-input"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label htmlFor={"lastName"}>
                  <p style={{ fontSize: "13px", color: "rgb(48, 49, 61)" }}>
                    <strong>Last Name</strong>
                  </p>
                </label>
                <input
                  type="text"
                  id="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                  className="field checkout-form-input"
                />
                <br />
              </Grid>
            </Grid>
          </div>
          <div style={{ width: "100%" }}>
            <label htmlFor={"userEmail"}>
              <p style={{ fontSize: "13px", color: "rgb(48, 49, 61)" }}>
                <strong>Email</strong>
              </p>
            </label>
            <input type="text" id="userEmail" onKeyUp={(e) => validateEmail(e)} className="field checkout-form-input" />
            <br />
          </div>{" "}
          {payment.name === "enterprise-study-program" && (
            <CompanyForm setCompany={setCompany} setCompanyIsBlank={setCompanyIsBlank} />
          )}
        </div>
        <br />
        {documentReview && (
          <div style={{ display: "flex", marginBottom: "2em", gap: 6, alignItems: "flex-start" }}>
            <Checkbox
              style={{ marginTop: -7, marginLeft: -10, color: "#a2a1a1", backgroundColor: "transparent" }}
              onChange={() => setChecked(!checked)}
              checked={checked}
              inputProps={{ "aria-label": "nondisclosure agreement" }}
              color="default"
            />
            <p style={{ fontSize: ".9em" }}>
              By checking this box, you acknowledge that both parties agree to maintain the confidentiality of all
              information shared during the Document Review process, in accordance with the terms of the{" "}
              <span className="fake-link" onClick={handleShowClick}>
                Non-Disclosure Agreement
              </span>
              .
            </p>
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="btn btn-accent"
            type="submit"
            style={{
              width: "200px",
              padding: "10px 20px",
              margin: "4px 0",
            }}
            disabled={!validated}
          >
            Continue
          </button>
        </div>
        <br />
        <br />
      </form>
    </>
  );
}
