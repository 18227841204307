const cardStyles: { [key: string]: React.CSSProperties } = {
  cardContainer: {
    textDecoration: "none",
    color: "inherit",
  },
  card: {
    outline: "5px solid #559086",
    outlineOffset: 20,
    borderRadius: "1px",
    position: "relative",
    cursor: "pointer",
  },
  defaultCard: {
    cursor: "pointer",
  },
  bookmark: {
    position: "absolute",
    color: "#559086",
    top: -37,
    left: -6,
    textAlign: "center",
    zIndex: 10,
    animationDelay: "0s",
  },
  verified: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    color: "#559086",
    top: 4,
    left: 4,
    textAlign: "center",
    zIndex: 10,
    animationDelay: "0s",
  },
  verifiedBackground: {
    position: "absolute",
    backgroundColor: "#559086",
    borderRadius: "50%",
    width: 60,
    height: 60,
    zIndex: 9,
  },
  bookmarkText: {
    fontSize: "1.2em",
    lineHeight: "0.9",
    fontWeight: 900,
    fontFamily: "Raleway",
    position: "absolute",
    color: "white",
    top: 24,
    left: "calc(50% - 40px)",
    maxWidth: 80,
  },
  verifiedText: {
    fontSize: "1.2em",
    lineHeight: "0.9",
    fontWeight: 900,
    fontFamily: "Raleway",
    position: "absolute",
    color: "white",
    zIndex: 11,
  },
  updatedText: {
    fontSize: "1em",
    lineHeight: "0.9",
    fontWeight: 900,
    fontFamily: "Raleway",
    position: "absolute",
    color: "white",
    zIndex: 11,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    border: "none",
    width: "100%",
    overflow: "hidden",
  },
  cardContent: {
    paddingBottom: "1em",
  },
};

export default cardStyles;
