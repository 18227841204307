import { Link } from "react-router-dom";

import Post from "../components/blog/Post";
import PostImage from "../components/blog/PostImage";
import CDMPQuickStart from "../components/blog/quick-start/CDMPQuickStart";

import fig57 from "../img/blog/fig57.webp";

const POSTNAME = "dmbokv2r";

const DMBOKv2R: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default DMBOKv2R;

const Rest: React.FC = () => {
  const contents: Content[] = [
    {
      anchor: "changes",
      text: "Key Changes",
    },
    {
      anchor: "dm",
      text: "Data Management (ch. 1)",
    },
    {
      anchor: "de",
      text: "Data Ethics (ch. 2)",
    },
    {
      anchor: "dg",
      text: "Data Governance (ch. 3)",
    },
    {
      anchor: "da",
      text: "Data Architecture (ch. 4)",
    },
    {
      anchor: "dmd",
      text: "Data Modeling & Design (ch. 5)",
    },
    {
      anchor: "dso",
      text: "Data Storage and Operations (ch. 6)",
    },
    { anchor: "ds", text: "Data Security (ch. 7)" },
    { anchor: "dii", text: "Data Integration and Interoperability (ch. 8)" },
    { anchor: "dcm", text: "Document and Content Management (ch. 9)" },
    { anchor: "mdm", text: "Master & Reference Data Management (ch. 10)" },
    { anchor: "dwbi", text: "Data Warehousing & Business Intelligence  (ch. 11)" },
    { anchor: "md", text: "Metadata Management (ch. 12)" },
    { anchor: "dq", text: "Data Quality Management (ch. 13)" },
    { anchor: "bd", text: "Big Data & Data Science (ch. 14)" },

    {
      anchor: "conclusion",
      text: "Conclusion",
    },
  ];

  return (
    <>
      <CDMPQuickStart />
      <p>
        If you're considering taking the{" "}
        <Link to="resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        , important updates to the{" "}
        <em>
          Data Management Body of Knowledge (
          <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            DMBOK
          </a>
          )
        </em>{" "}
        could impact your preparation. As of November 1, 2024, the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>{" "}
        is now based on the Revised edition of the <em>DMBOK</em>, which is available as a{" "}
        <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          hard copy
        </a>{" "}
        or{" "}
        <a href="https://technicspub.com/dmbok2/" target="_blank" rel="noreferrer">
          ebook
        </a>
        .
      </p>
      <p>
        In order to provide a more accurate and standardized Body of Knowledge for Data Management practitioners, the
        Data Management Association International (<a href="https://www.dama.org/cpages/home">DAMA-I</a>) updated the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>{" "}
        in response to feedback from members and volunteers. The edits in the Revised edition clarify key terminology
        and correct errors in the original v2.
      </p>
      <PostImage postName={POSTNAME} />
      <p>
        DAMA-I produced{" "}
        <a href="https://www.dama.org/cpages/changes-to-dama-dmbok-2" target="_blank" rel="noreferrer">
          this webpage
        </a>{" "}
        highlighting the changes made in the Revised edition. This blog post builds upon the list of changes by offering
        additional commentary and advice for prospective certificants preparing for the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals
        </Link>{" "}
        or{" "}
        <Link to="/resources/cdmp-specialist-exam" target="_blank">
          Specialist
        </Link>{" "}
        Exams.
      </p>
      <h4>Contents</h4>
      <ul>
        <li key={contents[0].anchor}>
          <a href={`#${contents[0].anchor}`}>{contents[0].text}</a>
          {contents.slice(1, 15).map((content) => (
            <li key={content.anchor}>
              <a href={`#${content.anchor}`}>{content.text}</a>
            </li>
          ))}
        </li>
        {contents.slice(15, 17).map((content) => (
          <li key={content.anchor}>
            <a href={`#${content.anchor}`}>{content.text}</a>
          </li>
        ))}
        <li>
          <a href="#related-posts">Related Posts</a>
        </li>
      </ul>

      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <p>At a high level, here are the most significant changes made in the Revised edition:</p>
      <ul>
        <li>
          Data Quality Management (ch. 13), which accounts for 11% of the exam, has been almost completely rewritten
        </li>
        <li>
          DAMA-I has updated the Context Diagrams for nearly all chapters to reflect modern terminology and practices
        </li>
        <li>Issues with diagrams have been corrected</li>
      </ul>

      <p>
        The rest of this article will offer a detailed look at some important updates to specific chapters of the{" "}
        <em>DMBOK</em> v2 Revised.
      </p>
      <h4 id={contents[1].anchor}>{contents[1].text}</h4>
      <p>
        Besides minor edits, such as merging bullet points and updating diagram labels, there were no major changes to
        this chapter.
      </p>
      <h4 id={contents[2].anchor}>{contents[2].text}</h4>
      <p>
        The authors updated the chapter's Context Diagram by introducing two new development activities: Identify and
        Remediate Gaps in Existing Practices and Implement Risk Mitigation. These changes emphasize a stronger focus on
        remediation, building on the previous version, which only mentioned addressing gaps.
      </p>
      <p>
        Customers have been added to the Consumers section, which previously consisted of only Employees, Executives,
        and Regulators. This addition shows that customers are now recognized as key stakeholders in data handling
        ethics.
      </p>
      <h4 id={contents[3].anchor}>{contents[3].text}</h4>
      <p>
        DAMA-I revised terminology and role definitions to reflect modern practices. They clarified titles such as Data
        Owner and Business Data Steward to improve understanding. A key change is the consistent capitalization of Data
        Governance throughout the text. Additionally, the terminology shifted from Data Governance program to Data
        Governance function, emphasizing its role as a sustained capability rather than a temporary initiative.
      </p>
      <p>
        The definition of a Data Owner now focuses on accountability, describing the role as a business person
        responsible for decisions about data within their domain. This replaces the previous characterization as a
        Business Data Steward with decision-making authority, highlighting strategic oversight over operational control.
        The text also redefines data as an "intangible asset," replacing "non-fungible asset" to align with legal and
        accounting standards and avoid associations with blockchain terminology.
      </p>
      <p>
        Changes in roles and responsibilities further highlight the evolution of governance practices. In section 2.4,
        references to the Chief Data Officer (CDO) have been replaced with the Data Governance function, shifting the
        focus from individual leadership to a collective organizational effort. Meanwhile, section 2.7, which addresses
        the development of goals, principles, and policies, introduces more precise terminology. Business Owners have
        been replaced by Data Owners, emphasizing data-specific governance, while Certified users and Certified data
        have been updated to Authorized users and Critical data, respectively, reflecting a broader and more practical
        approach to governance priorities.
      </p>
      <p>
        The updated escalation protocol in section 2.10 exemplifies a more targeted approach to resolving data
        governance issues. Previously, unresolved issues were escalated to corporate governance or management. Now, the
        text specifies escalation to the Data Governance Steering Committee, ensuring that experts with appropriate
        expertise handle critical decisions. Similarly, section 2.14 has clarified that Business Data Stewards, rather
        than generic Data Stewards, are responsible for maintaining business glossary content. Finally, the name for
        section 2.16 has been changed from Sponsor Data Asset Valuation to Define Data Asset Valuation Method,
        emphasizing structured methodologies over advocacy.
      </p>
      <h4 id={contents[4].anchor}>{contents[4].text}</h4>
      <p>
        DAMA-I corrected the Context Diagram by moving Manage Enterprise Requirements within Projects from a
        sub-activity under the Establish Enterprise Data Architecture planning activity to a standalone development
        activity.
      </p>

      <h4 id={contents[5].anchor}>{contents[5].text}</h4>
      <p>
        DAMA-I reworded and refined the activities required to build a data model in the updated Context Diagram. The
        Participants section now includes Data Consumers and Analysts, reflecting the growing involvement of less
        traditionally technical roles in what was once considered a highly technical activity. This change recognizes
        the blurring lines between roles in modern data practices, underlining collaboration across disciplines.
      </p>

      <p>
        Section 5.2, Data Modeling Metrics, explicitly mentions a Data Model Scorecard, replacing the more general
        reference to Data Model Validation in the previous version. This addition highlights a necessary shift toward
        standardized evaluation methods in Data Modeling.
      </p>
      <h4 id={contents[6].anchor}>{contents[6].text}</h4>
      <p>
        In this chapter, Figure 57 in the Federated Databases section has been corrected – swapping the labels for
        Tightly Coupled and Loosely Coupled databases. This change addresses an error that resulted in confusion for
        many readers.
      </p>
      <figure>
        <img src={fig57} alt="Figure 57" />
        <figcaption>
          Corrected Figure 57 in <em>DMBOK</em> v2 Revised
        </figcaption>
      </figure>
      <p>
        Furthermore, Participants section of the Context Diagram has been expanded to include two new roles: database
        technology specialist and data modeler, alongside the previously listed database administrator and data
        architect.
      </p>
      <p>
        This update highlights the broader skills needed to address complexities found in federated database
        environments. Database Administrators and Data Architects focus on system maintenance and high-level design, and
        Database Technology Specialists ensure expertise in the underlying technologies that enable federation.
        Similarly, Data Modelers are vital in designing the schemas and relationships needed for effective integration.
        Schema design is essential in tightly coupled systems with a unified schema. The Deliverables section of the
        Context Diagram now includes a Disaster Recovery Plan for the data within the Business Continuity Plan, which
        speaks to the need for detailed data recovery measures.
      </p>
      <p>
        The Deliverables section of the Context Diagram now includes a Disaster Recovery Plan for the data within the
        Business Continuity Plan, which speaks to the need for detailed data recovery measures.
      </p>
      <h4 id={contents[7].anchor}>{contents[7].text}</h4>
      <p>
        DAMA-I updated section 4.1, CRUD Matrix Usage, by removing execute as a specified function, which had been
        included in the previous edition. This change aligns the matrix with the traditional CRUD framework (Create,
        Read, Update, Delete) and simplifies its application. They also renamed section 3.2, replacing HTTPS with Web
        Page Security.
      </p>
      <h4 id={contents[8].anchor}>{contents[8].text}</h4>
      <p>
        In the Context Diagram, the Maintain DII Metadata activity was moved to the Implement and Monitor section,
        correcting its placement as an operational task. This adjustment aligns the activity with the objective of
        maintaining data integration metadata as an ongoing operation rather than a one-time task.
      </p>
      <p>
        The Participants section now includes the role of a Data Integration Developer, replacing the generic title
        Interface Developer from the previous version. The Consumers section now includes Customers and Partners,
        reflecting the reality that businesses increasingly exchange information with external organizations.
      </p>
      <p>
        Another noteworthy update is the inclusion of Data Scientists in the Consumers section. This addition highlights
        the evolving role of Data Scientists, emphasizing their involvement in supporting analytics and their growing
        need for data integration and operations knowledge.
      </p>
      <p>
        DAMA-I removed the duplicate Hub and Spoke Integration listing from the Techniques section, and they refined the
        Metrics section to provide more transparent and measurable ways to evaluate integration performance, such as
        Data Availability, Data Volumes and Speed, and Solution Costs and Complexity.
      </p>
      <h4 id={contents[9].anchor}>{contents[9].text}</h4>
      <p>
        DAMA-I updated the definition to emphasize controlling the capture, storage, access, and use of data and
        information stored primarily outside relational databases.
      </p>
      <p>
        E-discovery Approaches moved from Planning Activities to the Deliverables section. This change underscores the
        importance of defining clear E-discovery Approaches as company documents expand and litigation becomes more
        common. DAMA-I also updated the management of lifecycle operations to align the Context Diagram with the
        accompanying text.
      </p>

      <h4 id={contents[10].anchor}>{contents[10].text}</h4>
      <p>
        The authors updated this chapter's Context Diagram definition to shift its focus from managing shared data for
        organizational goals to managing reconciled and integrated data. This new emphasis prioritizes stewardship and
        semantic consistency, enabling enterprise-wide data sharing and treating data as a valuable asset. The updated
        goals highlight critical Data Quality Dimensions, such as Completeness, Consistency, and Currency.
      </p>
      <p>
        To reinforce this focus on quality, they included Data Exception Reports in the Deliverables section. They also
        underscored the role of stewardship by adding Subject Matter Experts to the Consumers section. Additionally,
        DAMA-I expanded the Metrics section by incorporating data ingestion, moving beyond the earlier emphasis on data
        consumption. These updates reflect a more structured and enterprise-wide approach to managing reference and
        master data effectively. DAMA-I added data exception reports to the Deliverables section to further reinforce
        the focus on quality. They also highlighted the importance of stewardship by including subject matter experts in
        the Consumers section.
      </p>

      <h4 id={contents[11].anchor}>{contents[11].text}</h4>
      <p>
        The updated Context Diagram now includes Reporting Strategy under Deliverables and adds a Subject Matter Expert
        to the Consumers section. DAMA-I revised the Tools section to specify Business Intelligence Tools, ensuring
        alignment with the rest of the text, unlike the previous version, which only referenced Analytic Applications.
        They also simplified the title of section 4.3 from Audit Data that can be Queried to Queriable Data.
      </p>

      <h4 id={contents[12].anchor}>{contents[12].text}</h4>
      <p>
        The updated Metadata Management Context Diagram broadens its definition, emphasizing capabilities to process,
        secure, and govern data, not just implementation and control. It also refines goals by expanding organizational
        understanding of business terms to include technical lineage.
      </p>

      <h4 id={contents[13].anchor}>{contents[13].text}</h4>
      <p>
        Chapter 13, now titled Data Quality Management, introduces substantial updates to enhance practicality and align
        with modern practices. The revised Context Diagram incorporates new activities, tools, techniques, and metrics
        to present a comprehensive approach to managing data quality. The improved Common Causes of Data Quality Issues,
        section 1.3.5, uses a clearer, more informative fishbone diagram. Table 29 expands the Data Quality Dimensions
        to include Currency, emphasizing the need to keep data current and relevant for decision-making.
      </p>
      <p>
        The chapter highlights the relationships between Data Quality and other knowledge areas, showing how
        quality-checked data supports activities across disciplines. It adds the role of a Data Curator to the Consumers
        section and corrects Figure 87 to accurately represent a Bi-Directional Metadata Architecture.
      </p>
      <p>
        The Inputs section now includes Data Sharing Agreements, Data Lineage, and Data Issues, focusing on data origins
        and cross-organizational sharing. The Activities section introduces Identification of Dimensions and Supporting
        Business Rules to align dimensions with business rules. It also makes the Develop and Deploy Data Quality
        Operations activity iterative, integrating the Shewhart/Deming Plan, Do, Check, Act cycle for continuous
        improvement.
      </p>
      <p>
        The Deliverables section includes Remediated and Certified Data, reflecting modern terminology. The Suppliers
        section adds Data Vendors, tying to the emphasis on Data Sharing Agreements. The Metrics section now features
        ROI, Data Quality Scorecards, and Data Quality Plan Progress, promoting project-based and financially sound data
        quality initiatives.
      </p>
      <p>
        The Tools section adds Business Rules Engines, Data Enrichment, and Incident Management tools, supporting
        iterative and operational improvements in Data Quality Operations. These updates make chapter 13 a more
        actionable and comprehensive resource for practitioners.
      </p>
      <h4 id={contents[14].anchor}>{contents[14].text}</h4>
      <p>
        DAMA-I revised the definition within the Context Diagram, shifting from collecting and analyzing data to uncover
        unknown insights to a broader focus on handling large datasets and using statistical analysis to find answers.
      </p>
      <p>
        The revised Goals section emphasizes enhancing the communication of model outputs to stakeholders and
        decision-makers while integrating organizational practices with established best practices in Data Management,
        Big Data, and Data Science.
      </p>
      <p>
        The Deliverables section has been updated to include Model Performance Results, reinforcing the focus on
        showcasing business value through measurable outcomes. The Consumers section includes Intermediaries,
        Stakeholders, and Customers, reflecting the increasing mainstream adoption of Big Data practices in real-world
        scenarios.
      </p>
      <p>
        The Techniques section introduces additional techniques, such as Columnar Compression and Analytic Modeling.
        Columnar Compression enhances data storage efficiency and speeds up query performance. Analytic Modeling
        supports advanced data exploration and decision-making, providing practical tools to maximize the value of Big
        Data initiatives.
      </p>

      <h4 id={contents[15].anchor}>{contents[15].text}</h4>
      <p>
        The updates to the <em>DMBOK</em> v2 Revised represent an upgrade to the previous version. However, the{" "}
        <em>DMBOK</em> v2 is still a relevant resource for CDMP exam preparation. Most changes in the Revised edition
        address minor inconsistencies and typos.
      </p>
      <p>
        The Revised edition reflects refined terminology and corrects past errors. In addition, it aligns the content
        with DAMA-I's understanding of{" "}
        <Link to="/resources/data-strategy-best-practices" target="_blank">
          contemporary best practices in Data Management
        </Link>
        . For anyone who has yet to take the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        , the Revised edition offers a clear advantage by incorporating improved Context Diagrams, updated role titles,
        and other corrections.
      </p>
      <p>
        If you already own the previous version, the decision to upgrade hinges on your exam plans and preparation
        strategy. Given that nearly every chapter has updated Context Diagrams and that{" "}
        <a href="#dq">Data Quality Management</a> – comprising 11% of the exam – has been extensively restructured, the
        new edition is a helpful resource for ensuring comprehensive exam readiness. By investing in the Revised
        edition, you'll be better equipped to earn a top score on the CDMP.
      </p>
    </>
  );
};
