type BestPracticeTopics = "Strategy" | "Technology" | "People" | "Process";

type BestPractice = {
  topic: BestPracticeTopics;
  nickname: string;
  bestPractice: string;
  description: string;
  sources: string;
};

const bestPractices: BestPractice[] = [
  {
    topic: "Strategy",
    nickname: "alignment",
    bestPractice: "Alignment of Data Strategy and Business Strategy",
    description:
      "drive coherent and strategic organizational growth by using data to support the organization's mission statement",
    sources: `<a href="https://www.forbes.com/sites/forbestechcouncil/2022/12/05/how-to-build-a-data-strategy-that-can-help-deliver-value-fast/" target="_blank" rel="noreferrer">Forbes Technology Council</a>,
    <a href="https://www.dataversity.net/data-strategy-best-practices/" target="_blank" rel="noreferrer">DATAVERSITY</a>,
    <a href="https://www.linkedin.com/pulse/what-best-practices-effective-data-strategy-nirav-raval/" target="_blank" rel="noreferrer">Nirav Raval</a>,
    <a href="https://medium.com/accelerated-analyst/crafting-a-successful-data-strategy-for-your-startup-expert-tips-and-best-practices-523e53beb28c" target="_blank" rel="noreferrer">Courtlin Holt-Nguyen</a>,
    <a href="https://www.analytics8.com/blog/7-elements-of-a-data-strategy/" target="_blank" rel="noreferrer">Analytics8</a>,
    <a href="https://aws.amazon.com/what-is/data-strategy/" target="_blank" rel="noreferrer">AWS</a>`,
  },
  {
    topic: "People",
    nickname: "cultural-change",
    bestPractice: "Cultural change to support Data Management",
    description: "change management is essential to data transformation",
    sources: `<a href="https://strategy.data.gov/practices/" target="_blank" rel="noreferrer">Federal Data Strategy</a>,
    <a href="https://www.dataversity.net/data-strategy-best-practices/" target="_blank" rel="noreferrer">DATAVERSITY</a>,
    <a href="https://www.linkedin.com/pulse/what-best-practices-effective-data-strategy-nirav-raval/" target="_blank" rel="noreferrer">Nirav Raval</a>,
    <a href="https://atlan.com/data-governance-trends/" target="_blank" rel="noreferrer">Atlan</a>,
    <a href="https://www.analytics8.com/blog/7-elements-of-a-data-strategy/" target="_blank" rel="noreferrer">Analytics8</a>,
    <a href="https://aws.amazon.com/what-is/data-strategy/" target="_blank" rel="noreferrer">AWS</a>`,
  },
  {
    topic: "Technology",
    nickname: "data-security",
    bestPractice: "Elevation of Data Security as a top priority",
    description: "the protection of critical information assets should be a foremost concern",
    sources: `<a href="https://strategy.data.gov/practices/" target="_blank" rel="noreferrer">Federal Data Strategy</a>,
    <a href="https://medium.com/accelerated-analyst/crafting-a-successful-data-strategy-for-your-startup-expert-tips-and-best-practices-523e53beb28c" target="_blank" rel="noreferrer">Courtlin Holt-Nguyen</a>,
    <a href="https://atlan.com/data-governance-trends/" target="_blank" rel="noreferrer">Atlan</a>`,
  },
  {
    topic: "People",
    nickname: "stakeholder-engagement",
    bestPractice: "Identification and engagement of stakeholders",
    description: "proactive communication with stakeholders will help secure support for Data Management initiatives",
    sources: `<a href="https://strategy.data.gov/practices/" target="_blank" rel="noreferrer">Federal Data Strategy</a>,
    <a href="https://www.forbes.com/sites/forbestechcouncil/2022/12/05/how-to-build-a-data-strategy-that-can-help-deliver-value-fast/" target="_blank" rel="noreferrer">Forbes Technology Council</a>,
    <a href="https://atlan.com/data-governance-trends/" target="_blank" rel="noreferrer">Atlan</a>`,
  },
  {
    topic: "Process",
    nickname: "documentation",
    bestPractice: "Documentation of standards and processes",
    description:
      "quality documentation promotes consistency, sustainability, and reliability in the use of data assets",
    sources: `<a href="https://strategy.data.gov/practices/" target="_blank" rel="noreferrer">Federal Data Strategy</a>,
    <a href="https://www.linkedin.com/pulse/what-best-practices-effective-data-strategy-nirav-raval/" target="_blank" rel="noreferrer">Nirav Raval</a>`,
  },
  {
    topic: "People",
    nickname: "recruitment-and-training",
    bestPractice: "Recruitment and training of team members",
    description: "foster a skilled and knowledgeable Data Management workforce",
    sources: `<a href="https://www.analytics8.com/blog/7-elements-of-a-data-strategy/" target="_blank" rel="noreferrer">Analytics</a>,
    <a href="https://aws.amazon.com/what-is/data-strategy/" target="_blank" rel="noreferrer">AWS</a>`,
  },
  {
    topic: "Technology",
    nickname: "data-architecture",
    bestPractice: "Data Architecture enhancement",
    description:
      "develop a better understanding of the enterprise data landscape to improve efficiency, scalability, and the overall management of data assets",
    sources: `<a href="https://www.analytics8.com/blog/7-elements-of-a-data-strategy/" target="_blank" rel="noreferrer">Analytics8</a>,
    <a href="https://aws.amazon.com/what-is/data-strategy/" target="_blank" rel="noreferrer">AWS</a>`,
  },
  {
    topic: "Strategy",
    nickname: "dmma",
    bestPractice: `Data Management Maturity Assessment (DMMA)`,
    description: "systematically evaluate and elevate the organization's data management capabilities",
    sources: `<a href="https://strategy.data.gov/practices/" target="_blank" rel="noreferrer">Federal Data Strategy</a>,
    <a href="https://www.analytics8.com/blog/7-elements-of-a-data-strategy/" target="_blank" rel="noreferrer">Analytics8</a>,
    <a href="https://aws.amazon.com/what-is/data-strategy/" target="_blank" rel="noreferrer">AWS</a>`,
  },
  {
    topic: "Strategy",
    nickname: "data-strategy-roadmap",
    bestPractice: "Data Strategy roadmap",
    description: "guide the organization's data strategy with a focus on alignment with business strategy",
    sources: `<a href="https://www.dataversity.net/data-strategy-best-practices/" target="_blank" rel="noreferrer">DATAVERSITY</a>,
    <a href="https://aws.amazon.com/what-is/data-strategy/" target="_blank" rel="noreferrer">AWS</a>`,
  },
  {
    topic: "Technology",
    nickname: "data-lakes",
    bestPractice: "Data Storage using data lakes",
    description: "data lakes are a cost-effective and scalable way to store and manage large volumes of data",
    sources: `<a href="https://www.linkedin.com/pulse/what-best-practices-effective-data-strategy-nirav-raval/" target="_blank" rel="noreferrer">Nirav Raval</a>,
    <a href="https://atlan.com/data-governance-trends/" target="_blank" rel="noreferrer">Atlan</a>`,
  },
  {
    topic: "Process",
    nickname: "data-quality",
    bestPractice: "Monitor KPIs",
    description:
      "regularly monitor and report on key performance indicators to ensure that the organization's data is being used effectively",
    sources: `<a href="https://www.analytics8.com/blog/7-elements-of-a-data-strategy/" target="_blank" rel="noreferrer">Analytics8</a>`,
  },
  {
    topic: "Technology",
    nickname: "ai-and-ml",
    bestPractice: "Integrate AI/ML",
    description: "leverage artificial intelligence and machine learning to improve Data Management efficiency",
    sources: `<a href="https://www.linkedin.com/pulse/what-best-practices-effective-data-strategy-nirav-raval/" target="_blank" rel="noreferrer">Nirav Raval</a>,
    <a href="https://atlan.com/data-governance-trends/" target="_blank" rel="noreferrer">Atlan</a>,
    <a href="https://aws.amazon.com/what-is/data-strategy/" target="_blank" rel="noreferrer">AWS</a>`,
  },
];

type Color = {
  topic: BestPracticeTopics;
  color: "#e6d0f2" | "#d4edbc" | "#c2e7ff" | "#ffe3aa";
};

const colors: Color[] = [
  { topic: "Strategy", color: "#e6d0f2" },
  { topic: "Technology", color: "#d4edbc" },
  { topic: "People", color: "#c2e7ff" },
  { topic: "Process", color: "#ffe3aa" },
];

export { bestPractices, colors };
