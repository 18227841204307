const specialistPeople = [
  {
    name: "Katie Reynolds",
    exam: "Data Governance and Data Warehousing & Business Intelligence",
  },
  {
    name: "Bjarte Tolleshaug",
    exam: "Data Governance",
  },
  {
    name: "Mayukh Datta Roy",
    exam: "Master & Reference Data Management and Data Quality",
  },
  {
    name: "Albert Chiwara",
    exam: "Metadata Management and Data Warehousing & Business Intelligence",
  },
  {
    name: "Jeff Johnson",
  },
  {
    name: "Katrina Waring Castillo",
    exam: "Master & Reference Data and Data Warehousing & Business Intelligence",
  },
  {
    name: "Ryan Balazs",
    exam: "Data Modeling & Design and Data Integration & Interoperability",
  },
  {
    name: "Mahendar Muthu",
    exam: "Metadata Management and Data Warehousing & Business Intelligence",
  },
  {
    name: "Peter Vennel",
    exam: "Data Warehousing & Business Intelligence and Data Quality",
  },
  {
    name: "Nicole Janeway Bills",
    exam: "Data Quality and Metadata Management",
  },
  {
    name: "Kathryn Lesh",
    exam: "Data Governance and Data Quality",
  },
];

export default specialistPeople;
