import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { useMediaQuery } from "@mui/material";

import scr0 from "../../img/products/data-strategy-workbook/scr0.webp";
import scr1 from "../../img/products/data-strategy-workbook/scr1.webp";
import scr2 from "../../img/products/data-strategy-workbook/scr2.webp";
import scr3 from "../../img/products/data-strategy-workbook/scr3.webp";
import scr4 from "../../img/products/data-strategy-workbook/scr4.webp";
import scr5 from "../../img/products/data-strategy-workbook/scr5.webp";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";

import CustomModal from "../../components/CustomModal";
import SubtleTestimonialCardFlip from "../../components/testimonial-flip-card/SubtleTestimonialCardFlip";
import ExtendedDescription from "../../components/product-pages/ExtendedDescription";

import { responsive } from "../../helpers/carouselStyles";

import testimonials from "../../data/testimonials";

const PRODUCT = "Data Strategy Workbook";

export default function DataStrategyWorkbook() {
  const desktop = useMediaQuery("(min-width:640px)");

  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        tealBox={tealBox}
        extendedDescription={extendedDescription}
        rest={rest(handleShowClick, desktop)}
      />
    </>
  );
}

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>What People are Saying</h4>

    <div style={{ marginTop: "1em" }}>
      <SubtleTestimonialCardFlip
        testimonial={testimonials.find((testimonial) => testimonial.id === "data-strategy-workbook-000")}
        productPage
        fontSize={1.05}
      />
    </div>
  </div>
);

const extendedDescription = (
  <div className="extended-description-container">
    <ExtendedDescription
      description={
        <div className="extended-description">
          <p>Build your organization's Data Strategy capabilities across the following topics:</p>
          <ol>
            <li>Data Management Fundamentals</li>
            <li>Data Management Maturity Assessment (DMMA)</li>
            <li>Technology</li>
            <li>Change Management</li>
          </ol>

          <p>
            These exercises can help you identify <strong>key objectives</strong> in your organization and highlight how
            data can help you achieve them. You will establish clear <strong>roles and responsibilities</strong> for
            Data Management and Data Governance functions, evaluate current data infrastructure and capabilities,
            identifying <strong>areas for improvement</strong>.
          </p>
          <p>
            This resource is a handy tool as you develop plans for{" "}
            <strong>collecting, analyzing, and using data</strong> to make better decisions and ultimately encourage a
            culture of data-driven decisions and a dedication to continual improvement with an{" "}
            <strong>organization-wide emphasis</strong> on Data Literacy.
          </p>

          <p>
            Be sure to consider our other{" "}
            <Link to="/enterprise" target="_blank">
              Enterprise offerings
            </Link>{" "}
            to ensure your team is using the same vocabulary when it comes to the management of your valuable data
            assets. Don't miss our{" "}
            <Link to="/products/document-checklist" target="_blank">
              Data Strategy Document Checklist
            </Link>{" "}
            for a comprehensive list of documents needed to improve your organization's Data Management capabilities. We
            also offer a{" "}
            <Link to="/landing/cdmp-study-program" target="_blank">
              CDMP Study Program
            </Link>{" "}
            that provides access to our best selling study materials as well as video lessons.
          </p>
          <p>
            Support for any questions at{" "}
            <a href="mailto:support@datastrategypros.com?subject=Data%20Strategy%20Workbook">
              support@datastrategypros.com
            </a>
            .
          </p>
        </div>
      }
    />
  </div>
);

const rest = (handleShowClick, desktop) => {
  const carouselImgs = [];

  carouselImgs.push(scr0, scr1, scr2, scr3, scr4, scr5);

  return (
    <>
      <div className="white-box carousel-container">
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Screenshots
        </h4>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          keyBoardControl={true}
          infinite={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          // @ts-ignore
          dotListclassName="custom-dot-list-style"
          itemclassName="carousel-item"
          shouldResetAutoplay={false}
        >
          {carouselImgs.map((image) => (
            <img
              src={image}
              alt="sample exercise"
              onClick={handleShowClick}
              style={{ cursor: "pointer" }}
              key={image}
            />
          ))}
        </Carousel>
      </div>

      <div className="teal-box" style={{ margin: "20px 0 20px 0" }}>
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Contents
        </h4>
        <div className="center-text-box">
          <div className="two-col contents" style={{ marginBottom: "1em" }}>
            <ul>
              <li>
                <strong>Getting Started</strong> with a current state assessment
              </li>
              <li>
                <strong>Data Valuation</strong> using multiple strategies to quantify the value of your data assets
              </li>
              <li>
                <strong>Dealing with Difficult Stakeholders</strong> by identifying common goals and creating solutions,
                not stop signs
              </li>
              <li>
                Four-part <strong>Data Management Maturity Assessment</strong>
              </li>
              <li>
                <strong>Cloud Governance</strong> to address security, privacy, data quality, and potential compliance
                issues
              </li>
              <li>
                Creating a vision statement for <strong>Change Management</strong>
              </li>
            </ul>
          </div>
          <p>
            ...and a <strong>dozen more</strong> exercises to improve your organization's Data Management capabilities!
          </p>
        </div>
      </div>
    </>
  );
};
