import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import TestimonialBox from "../../components/product-pages/TestimonialBox";

import testimonials from "../../data/testimonials";

const PRODUCT = "CIPP/E Bundle";

export default function CIPPEBundle() {
  return <MultiPriceProductPage PRODUCT={PRODUCT} description={description} rest={rest} />;
}

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <div className="blog">
      <p>
        Prepare for the{" "}
        <a href="https://iapp.org/certify/cippe/" target="_blank" rel="noreferrer">
          Certified Information Privacy Professional/Europe exam
        </a>{" "}
        with a comprehensive{" "}
        <Link to="/products/cippe-study-plan" target="_blank">
          90-day email course
        </Link>{" "}
        as well as{" "}
        <Link to="/products/cippe-exam-questions" target="_blank">
          100 practice questions
        </Link>{" "}
        to challenge your knowledge of GDPR.
      </p>
      <p>
        The{" "}
        <Link to="/products/cippe-study-plan" target="_blank">
          CIPP/E Study Plan
        </Link>{" "}
        provides a thorough introduction to the concepts tested on the CIPP/E exam through daily emails.
      </p>
      <p>
        You will also receive both{" "}
        <Link to="/products/cippe-exam-questions" target="_blank">
          CIPP/E Question Sets
        </Link>{" "}
        which each contain 50 distinct, never-before-seen questions, developed by our team at Data Strategy
        Professionals. In the answers section of each document, we have provided a detailed explanation for each
        question.
      </p>
    </div>
  </div>
);

const rest = (
  <TestimonialBox
    testimonialsToShow={testimonials.filter((testimonial) => testimonial.id === "cippe-study-plan-001")}
  />
);
