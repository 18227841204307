import posts from "../../../data/posts";
import NewPostCard from "../../../components/blog/NewPostCard";

import Grid from "@mui/material/Grid";

export default function CDMPResources() {
  const postsToShow = [
    posts.find((post) => post.nickname === "cdmp"),
    posts.find((post) => post.nickname === "specialist"),
    posts.find((post) => post.nickname === "cdmp-practice-questions"),
    posts.find((post) => post.nickname === "cdmp-training"),
    posts.find((post) => post.nickname === "aiken"),
    posts.find((post) => post.nickname === "data-strategy"),
  ];

  return (
    <div>
      <div
        className="homepage"
        style={{
          padding: "20px 40px 20px 40px",
          margin: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "2.2em",
            borderBottom: "2px solid #dbdbdb",
            textAlign: "center",
            position: "relative",
            zIndex: 0,
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontSize: "1.8em",
              marginTop: "11px",
              background: "#e9eff1",
              zIndex: 1,
              display: "inline-block",
              padding: "0 20px",
              fontFamily: "Raleway",
            }}
            id="resources"
          >
            <strong>Resources</strong>
          </p>
        </div>
        <div className="center-text-box" style={{ marginTop: 40 }}>
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            <Grid container spacing={4}>
              {postsToShow.map((post) => (
                <Grid item xs={12} sm={6} md={4} key={post.nickname}>
                  <NewPostCard post={post} />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
