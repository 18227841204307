import { useEffect, useState } from "react";
import CustomHelmet from "../CustomHelmet";
import ProductsModal from "../products-modal/ProductsModal";

import products from "../../data/products";

import { formatCurrencySimple } from "../../helpers/utils";

import ProductDescription from "./ProductDescription";
import BundleTealBox from "./BundleTealBox";

const PartnerProductPage: React.FC<{
  PRODUCT: string;
  description?: JSX.Element;
  tealBox?: JSX.Element;
  extendedDescription?: JSX.Element;
  rest?: JSX.Element;
  purchaseLink: string;
}> = ({
  PRODUCT,
  description = <ProductDescription PRODUCT={PRODUCT} />,
  tealBox = <BundleTealBox PRODUCT={PRODUCT} />,
  extendedDescription = <></>,
  rest = <></>,
  purchaseLink,
}) => {
  const product = products.find((product) => product.name === PRODUCT);

  const [showProductsPopup, setShowProductsPopup] = useState(false);

  const price = product.price;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {showProductsPopup && (
        <ProductsModal
          setShowProductsPopup={setShowProductsPopup}
          products={["Data Management Master Class", "CDMP Fundamentals Bundle", "Career Coaching"]}
        />
      )}
      <CustomHelmet
        description={product.description}
        name={product.name}
        link={product.link}
        img={product.previewImg || product.img}
        alt={product.alt}
      />

      <div className="product-layout">
        <h1 className="title product-title">{product.name}</h1>

        <div className="product-info thirds">
          <div id="product-left-side">
            <figure>
              <img id="product-img" src={product.img} alt={product.alt} />
            </figure>

            <button
              className="btn btn-accent"
              type="submit"
              id="btn-purchase"
              onClick={() => {
                window.open(purchaseLink, "_blank");
                setShowProductsPopup(true);
              }}
            >
              Purchase {formatCurrencySimple(price)}
            </button>
          </div>
          {description}
          {tealBox}
          {extendedDescription}
        </div>
        {rest}
      </div>
    </>
  );
};

export default PartnerProductPage;
