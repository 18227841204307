import { useMediaQuery } from "@mui/material";

import VerifiedIcon from "@mui/icons-material/Verified";

import person11 from "../../../../img/hero-people/11.webp";
import cdmpLogo from "../../../../img/logos/cdmp-logo.webp";

export default function CDMPHeroPerson() {
  const lrgDesktop = useMediaQuery("(min-width: 1600px)");
  const desktop = useMediaQuery("(min-width: 1200px)");
  const smDesktop = useMediaQuery("(min-width: 1000px)");
  const tablet = useMediaQuery("(min-width: 640px)");
  const tallScreen = useMediaQuery("(min-height: 920px)");

  return (
    <>
      {(desktop || tablet) && (
        <div
          className="carousel-container"
          style={{
            position: "absolute",
            top: smDesktop ? "15%" : "none",
            bottom: tablet ? "5%" : "none",
            right: desktop ? "15%" : smDesktop ? "5%" : "-5%",
            width: lrgDesktop ? 500 : 400,
            height: lrgDesktop ? 500 : 400,
          }}
        >
          {smDesktop ? (
            <div style={{ height: 500, width: lrgDesktop ? 400 : 350, position: "relative" }}>
              <img
                src={person11}
                alt="person"
                style={{
                  height: tallScreen ? 500 : 400,
                  width: lrgDesktop ? 400 : 350,
                  borderRadius: 60,
                  position: "absolute",
                  objectFit: "cover",
                  left: 0,
                  top: 0,
                  padding: 0,
                }}
              />
              <div
                className="angle animate splat delay-1"
                style={{ position: "absolute", top: 15, right: 10, textAlign: "center", color: "#efefef" }}
              >
                <img src={cdmpLogo} style={{ maxWidth: 170 }} alt="cdmp logo" />
              </div>
            </div>
          ) : (
            <div style={{ height: 400, width: 400, position: "relative" }}>
              <img
                src={person11}
                alt="person"
                style={{
                  position: "absolute",
                  height: 400,
                  width: 400,
                  objectFit: "cover",
                  borderRadius: "50%",
                  padding: 0,
                }}
              />
              <div
                className="angle animate splat delay-1"
                style={{ position: "absolute", top: 20, right: 28, textAlign: "center", color: "#efefef" }}
              >
                <VerifiedIcon sx={{ fontSize: 90 }} />
                <p
                  style={{
                    marginTop: -4,
                    fontSize: "1.4em",
                    lineHeight: "0.9",
                    fontWeight: 900,
                    color: "white",
                    fontFamily: "Raleway",
                  }}
                >
                  CDMP Master
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
