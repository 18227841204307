import { useState } from "react";
import ReactCardFlip from "react-card-flip";

import TestimonialCard from "./TestimonialCard";
import TestimonialCardSimplified from "./TestimonialCardSimplified";

export default function SubtleTestimonialCardFlip({
  testimonial,
  frontFontSize = 1.8,
  fontSize = 1,
  quotes = true,
  productPage = false,
}) {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div
      className="flex-item-testimonial"
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
    >
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <TestimonialCardSimplified
          key={testimonial.id}
          testimonial={testimonial}
          frontFontSize={frontFontSize}
          fontSize={fontSize}
          quotes={quotes}
          productPage={productPage}
        />
        <TestimonialCard
          key={testimonial.id}
          testimonial={testimonial}
          fontSize={fontSize}
          quotes={quotes}
          productPage={productPage}
        />
      </ReactCardFlip>
    </div>
  );
}
