import { useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

import navStyles from "../navStyles";

const NavButtons: React.VFC<{ navbuttons: any[] }> = ({ navbuttons }) => {
  const [hamburgerIsActive, setHamburgerIsActive] = useState(false);

  const location = useLocation();
  const desktop = useMediaQuery("(min-width:600px)");

  const button = navbuttons.find((button) => button.path !== location.pathname);

  return (
    <>
      {desktop ? (
        <div style={{ display: "flex", gap: 8 }}>
          <NavLink to={button.path} key={button.id}>
            <button className="btn btn-default" style={navStyles.navButton}>
              <span>
                {button.label} <ArrowForwardIos sx={{ fontSize: "0.8em" }} />
                <ArrowForwardIos sx={{ fontSize: "0.8em", marginLeft: -0.5 }} />
              </span>
            </button>
          </NavLink>
        </div>
      ) : (
        <>
          <button
            className={hamburgerIsActive ? "is-active navbar-burger" : "navbar-burger"}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasic"
            onClick={() => setHamburgerIsActive(!hamburgerIsActive)}
            style={{ zIndex: 3 }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
          <div style={{ position: "absolute", right: desktop ? 80 : 16 }}>
            {hamburgerIsActive && (
              <div style={{ ...navStyles.navbarMenu, right: -18 }}>
                <NavLink to={button.path} style={navStyles.navbarItem}>
                  <p style={navStyles.navbarItem}>{button.label}</p>
                </NavLink>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default NavButtons;
