const CustomButtons = ({ next, previous }) => {
  return (
    <>
      <button
        onClick={previous}
        aria-label="Go to previous slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left "
        type="button"
        style={{ position: "absolute", top: "calc(50% - 21px)", left: -14, cursor: "pointer", zIndex: 10 }}
      />

      <button
        onClick={next}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right "
        type="button"
        style={{ position: "absolute", top: "calc(50% - 21px)", right: -14, cursor: "pointer", zIndex: 10 }}
      />
    </>
  );
};

export default CustomButtons;
