import { useState } from "react";
import { Link } from "react-router-dom";

import cover from "../../img/products/notes/cover.webp";
import intro from "../../img/products/notes/intro_flipped.webp";
import ch3dark from "../../img/products/notes/ch3dark_zoom.webp";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";

import CustomModal from "../../components/CustomModal";
import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";

import testimonials from "../../data/testimonials";

const PRODUCT = "CDMP Fundamentals Notes";

export default function CDMPFundamentalsNotes() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);
    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage PRODUCT={PRODUCT} tealBox={tealBox} rest={rest(handleShowClick)} />
    </>
  );
}

const tealBox = (
  <div className="teal-box" id="product-testimonials">
    <h4>A Frequently Asked Question</h4>
    <p>
      <a href="https://www.facebook.com/groups/346145433213551/" target="_blank" rel="noreferrer">
        CDMP Study Group
      </a>{" "}
      members have been asking:{" "}
      <em>
        is this product the same as the{" "}
        <Link to="/products/cdmp-study-plan" target="_blank" rel="noopener noreferrer">
          CDMP Study Plan
        </Link>
        ?
      </em>{" "}
    </p>
    <br />
    <p>
      These products are <strong>distinct</strong> in terms of their content, format, and purpose:
    </p>
    <br />
    <p>
      The <strong>Study Plan</strong> offers 90 days worth of emails. The <strong>Fundamentals Notes</strong> are a{" "}
      <strong>30+ page cheat sheet</strong> to use as a reference as you study.
    </p>
    <br />
    <p>
      Also, don't forget you can practice with our <Link to="/products/cdmp-question-sets">Question Sets</Link>, which
      each contain 50 never-before-seen questions in the style of the test.
    </p>
  </div>
);

const rest = (handleShowClick) => {
  let testimonialsToShow = [];

  testimonialsToShow.push(
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-notes-001"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-notes-003"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-002"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-notes-004"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-001"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-notes-000")
  );

  return (
    <>
      <div className="white-box carousel-container">
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Photos
        </h4>
        <div className="image-display-tri">
          <figure>
            <img src={cover} alt="man reviewing notes" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          </figure>

          <figure>
            <img src={ch3dark} alt="Data Governance notes" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          </figure>

          <figure>
            <img src={intro} alt="woman reviewing notes" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          </figure>
        </div>
      </div>

      <ProductTestimonials testimonialsToShow={testimonialsToShow} />
    </>
  );
};
