import { useEffect } from "react";
import { NavLink, Switch, Route } from "react-router-dom";

import GitHub from "../../img/icons/black-github-icon.webp";

import OathDescription from "./OathDescription";
import DataPractitionersOath from "./DataPractitionersOath";
import DataScientistsOath from "./DataScientistsOath";

import CustomHelmet from "../../components/CustomHelmet";

export default function Oath() {
  function handleGitHubClick() {
    window.open("https://github.com/DataStrategyPros/oath", "_blank");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description="Oath of ethics for Data Strategists and Data Scientists."
        name="Oath"
        link="/oath"
        img="https://www.nicolejaneway.com/wp-content/uploads/2021/04/diego-ph-fIq0tET6llw-unsplash_square.jpg"
        alt="hand holding lightbulb"
      />

      <div className="home-layout">
        <p className="title">Oath</p>
        <div className="buttons" style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="float-left">
            <NavLink to="/oath/data-practitioner">
              <button className="btn btn-default">Data Practitioner's Oath</button>
            </NavLink>
            <NavLink to="/oath/data-scientist">
              <button className="btn btn-default">Data Scientist's Oath</button>
            </NavLink>
          </div>
          <button className="btn btn-default" onClick={handleGitHubClick}>
            {" "}
            <img src={GitHub} style={{ height: "1em", marginRight: "4px" }} alt="GitHub logo" /> Edit on GitHub
          </button>
        </div>

        <Switch>
          <Route exact path="/oath">
            <OathDescription />
          </Route>
          <Route exact path="/oath/data-practitioner">
            <DataPractitionersOath />
          </Route>
          <Route exact path="/oath/data-scientist">
            <DataScientistsOath />
          </Route>
        </Switch>
      </div>
    </>
  );
}
