import fetch from "node-fetch";

import payments from "../data/payments.ts";

async function checkDiscounts(
  event,
  discountCode: string,
  paymentsTable: confirmationTableRow[],
  email: string
): Promise<confirmationTableRow[]> {
  for (let idx = 0; idx < paymentsTable.length; idx++) {
    const paymentsTableRow = paymentsTable[idx];
    const data = await checkDiscountCode(event, discountCode, paymentsTableRow, email);
    if (parseFloat(data?.metadata?.discountValue) > 0) {
      paymentsTable[idx].metadata = { ...paymentsTableRow.metadata, ...data.metadata };
      if (parseFloat(data.metadata.discountValue) > 1) {
        break; // Breaks out of the loop if discountValue > 1
      }
    }
  }
  return paymentsTable;
}

async function checkDiscountCode(
  event: any,
  discountCode: string,
  paymentsTableRow: confirmationTableRow,
  email: string
) {
  let baseURL = new URL(event.rawUrl).origin;
  baseURL += "/.netlify/functions/";

  try {
    let response = await fetch(baseURL + "discount-code", {
      method: "POST",
      body: JSON.stringify({
        discountCode,
        paymentsTableRow,
        email,
      }),
    });

    if (response) {
      // Parse the response JSON
      const discountCodeData = await response.json();

      // Extract the discountValue from the JSON response
      const metadata = discountCodeData?.metadata;
      const message = discountCodeData?.error;

      return { metadata, message };
    }
  } catch (err) {
    console.log("Error", err);
    return {
      statusCode: 400,
      body: JSON.stringify({ error: err.message }),
    };
  }
}

function getPrice(payment: paymentType, selectedOptions: option[]): number {
  // Check if the price is defined at the top level of the payment object

  for (const option of selectedOptions) {
    if (option.price !== undefined) {
      return option.price; // Return the price of the first option that has it defined
    }
  }

  // If no options have a price, return the payment's price
  return payment.price;
}

function updatePrices(paymentsTable: confirmationTableRow[], prices: prices[]): confirmationTableRow[] {
  paymentsTable.forEach((row) => {
    // for bundles, set the price associated with the payment name
    if (row.paymentName.includes("bundle")) {
      row.price = prices.find((price) => price.name === row.paymentName).price;
    } else {
      for (const optionGroup of row.selectedOptions) {
        // return the price associated with selected option

        if ((optionGroup && typeof optionGroup.price === "number") || typeof optionGroup.emailList === "number") {
          row.price = prices.find((price) => price.name === optionGroup.label).price;
          // otherwise, return the price associated with payment
        } else {
          row.price = prices.find((price) => price.payment === row.paymentName).price;
        }
      }
    }
  });
  return paymentsTable;
}

function getOption(payment: paymentType, idx, value): option {
  return payment.options[idx].content.find((option) => option.label === value);
}

function getSelectedOptions(payment: paymentType, selectValues: string[]): option[] {
  const selectedOptions = [];

  selectValues.forEach((value, idx) => {
    selectedOptions.push(getOption(payment, idx, value));
  });

  return selectedOptions;
}

function updateConfirmationTableWithSelection(
  selectedValue: any,
  payment: paymentType,
  dropdownIndex: number,
  checkoutContext: checkoutContext
): void {
  const { confirmationTable, setConfirmationTable } = checkoutContext.checkout;

  const table = confirmationTable.map((row) => {
    if (row.paymentName === payment.name) {
      const updatedSelectedOptions = [...row.selectedOptions];
      // @ts-ignore
      updatedSelectedOptions[dropdownIndex] = getOption(payment, dropdownIndex, selectedValue);

      return {
        ...row,
        selectedOptions: updatedSelectedOptions,
        price: getPrice(payment, updatedSelectedOptions),
      };
    }
    return row;
  });

  setConfirmationTable(table);
}

function getTotalAmt(paymentsTable: confirmationTableRow[]): number {
  return paymentsTable.reduce((acc, row) => {
    const currentPrice = row.price * row.metadata.count - row.metadata.discountAmount;
    const currentTaxAmt = (currentPrice + row.metadata.shippingAmt) * row.metadata.taxRate;
    return Math.floor(acc + (currentPrice + row.metadata.shippingAmt + currentTaxAmt));
  }, 0);
}

function getShippingAmt(paymentsTable: confirmationTableRow[]): number {
  return paymentsTable.reduce((acc, row) => {
    return Math.floor(acc + row.metadata.shippingAmt);
  }, 0);
}

function getTaxAmt(paymentsTable: confirmationTableRow[]): number {
  return paymentsTable.reduce((acc, row) => {
    const currentPrice = row.price * row.metadata.count - row.metadata.discountAmount;
    const currentTaxAmt = (currentPrice + row.metadata.shippingAmt) * parseFloat(row.metadata.taxRate.toString());
    return Math.floor(acc + currentTaxAmt);
  }, 0);
}

function getTaxRates(paymentsTable: confirmationTableRow[]): number[] {
  return paymentsTable.map((row) => row.metadata.taxRate);
}

async function calculateShippingAndTaxes(event, confirmationTable, address) {
  let baseURL = new URL(event.rawUrl).origin;

  baseURL += "/.netlify/functions/";

  try {
    const taxResponse = await fetch(baseURL + "calculate-taxes", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address,
        confirmationTable,
      }),
    });

    const updatedConfirmationTable = await taxResponse.json();

    return updatedConfirmationTable;
  } catch (err) {
    console.log("Error", err);
    return {
      statusCode: 400,
      body: JSON.stringify({ error: err.message }),
    };
  }
}

function updateConfirmationTable(confirmationTable, paymentsTable) {
  paymentsTable.forEach((payment) => {
    confirmationTable.forEach((row, idx) => {
      if (row.paymentName === payment.paymentName) confirmationTable[idx] = payment;
    });
  });

  return confirmationTable;
}

function handleProductPurchase(event, paymentName, history, setStep) {
  event.preventDefault();

  setStep(0);
  history.push(`/products/${paymentName}/checkout`);
}

function handleFreeTrialClick(
  paymentName: paymentName,
  history: any,
  setConfirmationTable: React.Dispatch<React.SetStateAction<confirmationTableRow[] | null>>,
  setStep: React.Dispatch<React.SetStateAction<number>>
) {
  const payment = payments.find((paymentObject: paymentType) => paymentObject.name === `${paymentName}-free-trial`);

  const table: confirmationTableRow[] = [
    {
      selected: true,
      paymentName: payment.name,
      type: payment.type,
      selectedOptions: [payment.options[0].content[0]],
      price: getPrice(payment, [payment.options[0].content[0]]),
      metadata: {
        count: 1,
        unit: "",
        abandonedCartList: payment.abandonedCartList,
        discountCode: null,
        discountValue: 0,
        discountAmount: 0,
        shippingAmt: 0,
        taxCode: "",
        taxRate: 0,
      },
    },
  ];
  setConfirmationTable(table);
  history.push(`/products/${paymentName}-free-trial/checkout`);
  setStep(0);
}

function createPaymentInfo(paymentsTable: confirmationTableRow[]): paymentDetails {
  // todo -- write custom logic for bundles, courses, physical products
  const paymentDetails: paymentDetails = paymentsTable.reduce(
    (acc, row) => {
      // comma-separated list of payment names
      acc.paymentList.push(payments.find((payment) => payment.name === row.paymentName).product);

      // build payment info string
      const selectedValues = row.selectedOptions.map((option) => option.label).join(", ");
      // acc.paymentInfo.push(`{${row.paymentName}: ${selectedValues}}`);
      acc.paymentInfo.push({ [row.paymentName]: selectedValues });

      //  abandonedCartList
      acc.abandonedCartLists.push(row.metadata.abandonedCartList);

      //  flatten emailList values
      if (row.paymentName === "specialist-bundle") {
        acc.courses.push("Specialist Bundle");
        const options = row.selectedOptions.map((option) => option.label);
        const bundlePaymentNames = [];
        options.forEach((option) => {
          bundlePaymentNames.push(option + "-guide");
          bundlePaymentNames.push(option + "-questions");
        });

        bundlePaymentNames.forEach((name) => {
          payments.forEach((payment) => {
            if (payment.name === "specialist-guide" || payment.name === "specialist-question-set") {
              payment.options.forEach((optionGroup) => {
                optionGroup.content.forEach((contentItem) => {
                  if (contentItem.label === name) {
                    acc.emailLists.push(contentItem.emailList);
                    if (contentItem?.courseId) acc.courseList.push(...contentItem.courseId);
                  }
                });
              });
            }
          });
        });
      } else {
        row.selectedOptions.forEach((option) => {
          option?.emailList && acc.emailLists.push(option.emailList);
        });
        if (row.paymentName.includes("bundle")) {
          acc.emailLists.push(payments.find((payment) => payment.name === row.paymentName).emailList);
        }
      }

      // list of physical products
      if (row.metadata.taxCode === "txcd_99999999")
        acc.physicalProductsList.push(payments.find((payment) => payment.name === row.paymentName).product);

      // list of courses
      row.selectedOptions[0].courseId &&
        acc.courseList.push(...row.selectedOptions[0].courseId) &&
        acc.courses.push(payments.find((payment) => payment.name === row.paymentName).product);

      return acc;
    },
    {
      paymentList: [],
      paymentListString: "",
      paymentInfo: [],
      abandonedCartLists: [],
      emailLists: [],
      physicalProductsList: [],
      courseList: [],
      courses: [],
    }
  );

  //  string representation of paymentList
  paymentDetails.paymentListString = paymentDetails.paymentList.join(", ");

  return paymentDetails;
}

function createString(productList: productName[]): string {
  if (productList.length === 1) {
    return productList[0];
  } else if (productList.length === 2) {
    return `${productList[0]} and ${productList[1]}`;
  } else {
    return `${productList.slice(0, -1).join(", ")}, and ${productList.slice(-1)}`;
  }
}

export {
  checkDiscounts,
  checkDiscountCode,
  getPrice,
  updatePrices,
  getOption,
  getSelectedOptions,
  updateConfirmationTableWithSelection,
  getTotalAmt,
  getShippingAmt,
  getTaxAmt,
  getTaxRates,
  calculateShippingAndTaxes,
  updateConfirmationTable,
  handleProductPurchase,
  handleFreeTrialClick,
  createPaymentInfo,
  createString,
};
