import Carousel from "react-multi-carousel";

import dm from "../../img/products/courses/data-strategy-essentials-course/summaries/01_dm_summary.webp";
import dmma from "../../img/products/courses/data-strategy-essentials-course/summaries/02_dmma_summary.webp";
import dv from "../../img/products/courses/data-strategy-essentials-course/summaries/03_dv_summary.webp";
import dg from "../../img/products/courses/data-strategy-essentials-course/summaries/04_dg_summary.webp";
import da from "../../img/products/courses/data-strategy-essentials-course/summaries/05_da_summary.webp";
import dmd from "../../img/products/courses/data-strategy-essentials-course/summaries/06_dmd_summary.webp";
import dq from "../../img/products/courses/data-strategy-essentials-course/summaries/07_dq_summary.webp";
import mm from "../../img/products/courses/data-strategy-essentials-course/summaries/08_mm_summary.webp";
import dso from "../../img/products/courses/data-strategy-essentials-course/summaries/09_dso_summary.webp";
import cg from "../../img/products/courses/data-strategy-essentials-course/summaries/10_cg_summary.webp";
import ds from "../../img/products/courses/data-strategy-essentials-course/summaries/11_ds_summary.webp";
import dii from "../../img/products/courses/data-strategy-essentials-course/summaries/12_dii_summary.webp";
import mrd from "../../img/products/courses/data-strategy-essentials-course/summaries/13_mrd_summary.webp";
import dcm from "../../img/products/courses/data-strategy-essentials-course/summaries/14_dcm_summary.webp";
import dwbi from "../../img/products/courses/data-strategy-essentials-course/summaries/15_dwbi_summary.webp";
import aads from "../../img/products/courses/data-strategy-essentials-course/summaries/16_aads_summary.webp";
import de from "../../img/products/courses/data-strategy-essentials-course/summaries/17_de_summary.webp";
import dp from "../../img/products/courses/data-strategy-essentials-course/summaries/18_dp_summary.webp";
import cm from "../../img/products/courses/data-strategy-essentials-course/summaries/19_cm_summary.webp";

import { customResponsiveness } from "../../helpers/carouselStyles";

const DSEVideoSummaryCarousel: React.VFC<{ crashCourse: boolean }> = ({ crashCourse }) => {
  const carouselImgs = [];

  if (crashCourse) {
    carouselImgs.push(dg, dmd, dii, mrd, dwbi, mm, dq);
  } else {
    carouselImgs.push(dm, dmma, dv, dg, da, dmd, dq, mm, dso, cg, ds, dii, mrd, dcm, dwbi, aads, de, dp, cm);
  }

  return (
    <>
      <h4 className="box-title" style={{ textAlign: "center" }}>
        Content
      </h4>
      <div className="carousel-container" style={{ marginBottom: 30 }}>
        <Carousel
          responsive={customResponsiveness}
          swipeable={true}
          draggable={true}
          ssr={true}
          keyBoardControl={true}
          infinite={true}
          arrows={true}
        >
          {carouselImgs.map((source, idx) => {
            return <img src={source} alt="screenshot from course" key={idx} />;
          })}
        </Carousel>
      </div>
    </>
  );
};

export default DSEVideoSummaryCarousel;
