import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import scr0 from "../../img/products/document-checklist/scr0.webp";
import scr1 from "../../img/products/document-checklist/scr1.webp";
import scr2 from "../../img/products/document-checklist/scr2.webp";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";

import CustomModal from "../../components/CustomModal";
import SubtleTestimonialCardFlip from "../../components/testimonial-flip-card/SubtleTestimonialCardFlip";
import ExtendedDescription from "../../components/product-pages/ExtendedDescription";

import testimonials from "../../data/testimonials";

const PRODUCT = "Data Strategy Document Checklist";

export default function DocumentChecklist() {
  const desktop = useMediaQuery("(min-width:640px)");

  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        tealBox={tealBox}
        extendedDescription={extendedDescription}
        rest={rest(handleShowClick, desktop)}
      />
    </>
  );
}
const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>What People are Saying</h4>

    <div style={{ marginTop: "1em" }}>
      <SubtleTestimonialCardFlip
        testimonial={testimonials.find((testimonial) => testimonial.id === "data-strategy-workbook-000")}
        productPage
        fontSize={1.05}
      />
    </div>
  </div>
);

const extendedDescription = (
  <div className="extended-description-container">
    <ExtendedDescription
      description={
        <div className="extended-description">
          <p>
            Be sure to consider our other{" "}
            <Link to="/enterprise" target="_blank">
              Enterprise offerings
            </Link>{" "}
            to ensure your team is using the same vocabulary when it comes to the management of your valuable data
            assets.
          </p>
          <p>
            Don't miss our{" "}
            <Link to="/products/data-strategy-workbook" target="_blank">
              Data Strategy Workbook
            </Link>{" "}
            for a thought-provoking series of exercises to improve your organization's Data Management capabilities.
          </p>
          <p>
            We offer an{" "}
            <Link to="/landing/cdmp-study-program" target="_blank">
              CDMP Study Program
            </Link>{" "}
            that provides access to our best selling study materials as well as video lessons.
          </p>
          <p>
            We also offer{" "}
            <Link to="/landing/enterprise-custom-training" target="_blank">
              Custom Training
            </Link>{" "}
            and a{" "}
            <Link to="/landing/data-strategy-bootcamp" target="_blank">
              CDMP Bootcamp
            </Link>
            .
          </p>
        </div>
      }
    />
  </div>
);

const rest = (handleShowClick, desktop) => {
  return (
    <>
      <div className="white-box carousel-container">
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Screenshots
        </h4>
        <div className="image-display-tri">
          <img src={scr0} alt="product screenshot" onClick={handleShowClick} style={{ cursor: "pointer" }} />

          <img
            src={scr1}
            alt="product screenshot"
            onClick={handleShowClick}
            style={{
              objectFit: "scale-down",
              objectPosition: "0 0",
              cursor: "pointer",
            }}
          />

          <img src={scr2} alt="product screenshot" onClick={handleShowClick} style={{ cursor: "pointer" }} />
        </div>
      </div>

      {!desktop && (
        <div className="teal-box" style={{ background: "#efefef", marginBottom: 40 }}>
          <div className="center-text-box">
            <h4 className="box-title">Extended Description</h4>

            <div className="blog">
              <p>
                Confused about how to implement the{" "}
                <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                  <em>DMBOK</em>
                </a>
                ? Look no further than our <strong>Document Checklist</strong> as a guide to operationalize Data
                Management best practices.
              </p>
              <p>
                This guide contains 20 pages of content that provide detailed descriptions of the{" "}
                <strong>inputs</strong> and <strong>deliverables</strong>
                required to run a successful Data Management program.
              </p>
              <p>
                Support for any questions about the product at{" "}
                <a href="mailto:support@datastrategypros.com?subject=Data%20Strategy%20Workbook">
                  support@datastrategypros.com
                </a>
              </p>
              <p>
                Support for any questions about the product at{" "}
                <a href="mailto:support@datastrategypros.com?subject=Data%20Strategy%20Workbook">
                  support@datastrategypros.com
                </a>
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="teal-box" style={{ margin: "20px 0 20px 0" }}>
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Contents
        </h4>
        <div className="center-text-box">
          <div className="two-col contents" style={{ marginBottom: "1em" }}>
            <ul>
              <li>Data Management</li>
              <li>Data Ethics</li>
              <li>Data Governance</li>
              <li>Data Architecture</li>
              <li>Data Modeling</li>
              <li>Data Storage & Operations</li>
              <li>Data Security</li>
              <li>Data Integration & Interoperability</li>
              <li>Document & Content Management</li>
              <li>Master & Reference Data Management</li>
              <li>Data Warehousing & Business Intelligence</li>
              <li>Metadata Management</li>
              <li>Data Quality</li>
              <li>Data Science & Machine Learning</li>
              <li>Data Management Maturity Assessment</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
