import firstCDO from "../img/partners/first-CDO-partners.webp";
import vin from "../img/partners/vin.webp";
import northway from "../img/partners/northway.webp";
import John from "../img/people/john_odonovan.webp";

type Partner = {
  name: string;
  bestFor: string;
  description: string;
  services: string[];
  link: string;
  img: string;
};

const partners: Partner[] = [
  {
    name: "Simply Support Unlimited",
    bestFor: `operational Data Strategy development, assessment, and improvement planning`,
    description: `Advisory leadership delivering global data management strategies,
agile data program management, and hands-on implementation.`,
    services: [
      "Data Strategy workshop facilitation",
      "Development of roadmaps and blueprints",
      "Data Management and Data Architecture Team Lead (fractional contract)",
      "<a href='https://www.datastrategypros.com/products/career-coaching'>Executive coaching</a>",
    ],
    link: "https://odonovan5.com",
    img: John,
  },
  {
    name: "First CDO Partners",
    bestFor: "Data Governance improvement and implementations",
    description: `turn your data from a liability to a superpower with First CDO's proven, industry-leading techniques and frameworks.`,
    services: [
      "Develop your Data Strategy and Roadmap",
      "Implement Data Governance best practices",
      "Measure and increase data value",
      "Conduct Data Management and AI readiness assessments",
      "Define and adopt data policies, standards, and procedures",
    ],
    link: "https://www.firstcdopartners.com/",
    img: firstCDO,
  },
  {
    name: "V-Squared",
    bestFor: "monetization of data and AI",
    description: `Vin Vashishta is the author of 
    <a href='https://amzn.to/47CuXB1' target='_blank' rel='noreferrer'><em>From Data To Profit</em></a>
    (Wiley 2023).  It's the playbook for monetizing data and AI. Vin built V-Squared 
    from client 1 to one of the world's oldest data and AI consulting firms. 
    His background combines nearly 30 years in strategy, leadership, software engineering, and applied machine learning.`,
    services: ["Courses", "Seminars and workshops", "Excutive coaching"],
    link: "https://www.datascience.vin/",
    img: vin,
  },
  {
    name: "Northway Insights",
    bestFor: "leadership development for mid-career professionals in IT and Software Engineering",
    description: `most new leaders are unprepared for the internal changes they need to succeed as their circle of influence grows.
    Northway Insights offers professional leadership coaching for IT and Software Engineering organizations and individuals.`,
    services: ["Leadership development", "Executive coaching"],
    link: "https://www.northwayinsights.com/",
    img: northway,
  },
];

export default partners;
