import TestimonialCard from "../../../components/testimonial-flip-card/TestimonialCard";

import testimonials from "../../../data/testimonials";

import person from "../../../img/icons/person.webp";

import enterpriseStyles from "../../../helpers/enterpriseStyles";

const BootcampTestimonials: React.VFC = () => {
  const anon = {
    id: null,
    topic: "Data Strategy",
    name: "Several relieved, anonymous attendees",
    title: null,
    img: person,
    short: "I don't think I would have passed without this Bootcamp",
    content: "I don't think I would have passed without this Bootcamp.",
    plainText: "I don't think I would have passed without this Bootcamp.",
  };

  const testimonialsToShow = [
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-001"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-000"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-002"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-009"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-010"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-011"),
    testimonials.find((testimonial) => testimonial.id === "general-001"),
    testimonials.find((testimonial) => testimonial.id === "general-005"),
    testimonials.find((testimonial) => testimonial.id === "general-004"),
  ];

  testimonialsToShow.push(anon);

  return (
    <div style={{ ...enterpriseStyles.container, marginBottom: "2em" }}>
      <p className="title product-title">Testimonials</p>
      {testimonialsToShow.map((testimonial) => (
        <TestimonialCard key={testimonial.id} testimonial={testimonial} productPage />
      ))}
    </div>
  );
};

export default BootcampTestimonials;
