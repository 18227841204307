import LandingPage from "../../../components/lead-magnet/LandingPage";
import BestCertificationsScreenshots from "./BestCertificationsScreenshots";

const postName = "best-certifications";

const BestCertifications: React.FC = () => {
  const description = `Aim higher with your <strong>career ambitions</strong> for 2025. This list of seven
    <strong>top certifications</strong> span a variety of data-related domains from Data Management to Data
    Engineering. Check out our <strong>comprehensive report</strong> to learn how to attain the credentials to
    break into a new field or accelerate your career trajectory.`;

  return (
    <LandingPage
      postName={postName}
      description={description}
      leadMagnetName="our top certifications report"
      listNumber={96}
      Rest={<BestCertificationsScreenshots />}
    />
  );
};

export default BestCertifications;
