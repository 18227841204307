import { Grid } from "@mui/material";

import discussionGroups from "../../img/events/discussion_groups.webp";
import coworking from "../../img/events/coworking.webp";
import cdmpQA from "../../img/events/cdmp_qa.webp";
import youTube from "../../img/icons/youtube-play-button.webp";

const styles = {
  image: {
    textAlign: "center",
    cursor: "pointer",
  },
  youTube: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 50,
    height: 50,
  },
};

const pastEvents = [
  {
    title: "Discussion Groups",
    year: 2024,
    image: discussionGroups,
    link: "https://www.youtube.com/playlist?list=PLGd_Hl6NWLZfPu01s6FVfHBxf2rB2awG_",
  },
  {
    title: "Study Sessions",
    year: 2023,
    image: coworking,
    link: "https://www.youtube.com/playlist?list=PLGd_Hl6NWLZe1Jtz8oG0ZJ7lDzdgOGZn5",
  },
  {
    title: "CDMP Q&A",
    year: 2023,
    image: cdmpQA,
    link: "https://youtu.be/FICmilfBTKM",
  },
];

const EventsPast = () => {
  return (
    <Grid container spacing={4} sx={{ mt: 2 }}>
      {pastEvents.map((event) => (
        <Grid item key={event.title} xs={12} sm={4}>
          <figure onClick={() => window.open(event.link, "_blank")} style={styles.image}>
            <div style={{ overflow: "hidden", position: "relative" }}>
              <img src={event.image} alt={event.title} style={styles.image} className="zoom-on-hover" />
              <img src={youTube} alt="YouTube play button" style={styles.youTube} />
            </div>
            <figcaption>
              <span className="fake-link">
                {event.title} ({event.year})
              </span>
            </figcaption>
          </figure>
        </Grid>
      ))}
    </Grid>
  );
};

export default EventsPast;
