import { useState } from "react";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import CustomModal from "../../components/CustomModal";

const PRODUCT = "Data Science Practice Questions";

export default function DSPracticeQuestions() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage PRODUCT={PRODUCT} description={description} tealBox={tealBox} rest={rest} />
    </>
  );
}

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <div className="blog">
      <p>
        Prepare for your role as a <strong>Data Scientist</strong> or any professional certification in this field{" "}
        (e.g.,{" "}
        <a
          href="https://medium.com/@nicolejaneway/chartered-data-scientist-3d252278d626"
          target="_blank"
          rel="noreferrer"
        >
          Chartered Data Scientist [CDS]
        </a>{" "}
        by{" "}
        <a href="https://www.adasci.org/" target="_blank" rel="noreferrer">
          ADaSci
        </a>
        ) with these sets of 50 practice questions. These questions were developed by our team at Data Strategy
        Professionals to help you better understand the field of Data Science.
      </p>
      <p>
        Immediately upon purchase, you'll receive a PDF document via email containing 1) 50 multiple choice questions
        each with four answer choices and 2) <strong>answers section</strong> with domain and detailed explanation.
      </p>

      <h4>Features</h4>
      <ul>
        <li>28 pages of question and answer content</li>
        <li>
          <strong>50 multiple choice questions</strong> about Data Science, grouped by <strong>topic</strong>, with
          detailed <strong>explanation</strong> for each answer
        </li>
        <li>
          Support for any questions about the product at{" "}
          <a href="mailto:support@datastrategypros.com?subject=CDS%20Question%20Sets">support@datastrategypros.com</a>
        </li>
      </ul>
    </div>
  </div>
);

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>How to Learn Anything</h4>
    <div className="oath">
      <p>
        Research has shown{" "}
        <a href="http://pdf.poojaagarwal.com/McDaniel_Agarwal_etal_2011_JEP.pdf" target="_blank" rel="noreferrer">
          time
        </a>{" "}
        and{" "}
        <a href="http://pdf.poojaagarwal.com/Roediger_Agarwal_etal_2011_JEPA.pdf" target="_blank" rel="noreferrer">
          time again
        </a>{" "}
        that <strong>testing ourselves</strong> is one of the most effective ways to <strong>achieve mastery</strong>.
      </p>
      <p>
        So if you're really interested in becoming a <strong>Data Science expert</strong>, doing practice questions like
        these are among the best way to get there!
      </p>
      <br />
    </div>
  </div>
);

const rest = (
  <div className="white-outline-box">
    <div className="center-text-box">
      <h4 className="box-title">Frequently Asked Questions</h4>
      <p>
        <strong>Where did the Data Science Practice Questions come from?</strong>
      </p>
      <p>The Data Science Practice Questions were written by our team at Data Strategy Professionals.</p>
      <br />
      <p>
        <strong>What is included in my purchase of the Data Science Practice Questions?</strong>
      </p>
      <p>
        The Data Science Practice Questions are grouped by topic and include a detailed explanation for each correct
        answer. An introduction with test prep instructions is also provided.
      </p>
      <br />
      <p>
        <strong>What else can I do to prep for the Data Science exams?</strong>
      </p>
      <p>
        Check out{" "}
        <a
          href="https://medium.com/@nicolejaneway/chartered-data-scientist-3d252278d626"
          target="_blank"
          rel="noreferrer"
        >
          this article
        </a>{" "}
        for more information about the Chartered Data Scientist (CDS) designation from{" "}
        <a href="https://www.adasci.org/" target="_blank" rel="noreferrer">
          ADaSci
        </a>
        .
      </p>
      <br />
    </div>
  </div>
);
