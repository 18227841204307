const checkoutMessages = [
  { type: "membership", message: "information about your membership" },
  { type: "sendinblue", message: "your email course" },
  { type: "sendinblue-free-trial", message: "your free email course" },
  { type: "download", message: "your digital download" },
  { type: "physical", message: "information about your product" },
  { type: "service", message: "information about next steps" },
  { type: "bundle", message: "your product bundle" },
  { type: "course", message: "information about next steps" },
];

export default checkoutMessages;
