import { useState } from "react";
import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import CustomModal from "../../components/CustomModal";

import mugs from "../../img/products/mug/mugs_white.webp";
import laptop from "../../img/products/mug/mug15_with_laptop_crop.webp";
import cookies from "../../img/products/mug/cookie_mugs.webp";

const PRODUCT = "Data Strategist Mug";

const Mug: React.FC<{}> = () => {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);
    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        tealBox={tealBox}
        description={description}
        rest={rest(handleShowClick)}
      />
    </>
  );
};

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <p>
      Represent your Data Strategist pride with this ceramic mug from Data Strategy Professionals. We recognize that
      your work moves mountains. That's why we've chosen to represent your contributions with a mountain landscape.
    </p>
    <br />
    <h4>Features</h4>
    <ul>
      <li>Select either 11 or 15 oz, white or black</li>
      <li>Ceramic mug with Data Strategy Professionals logo</li>
      <li>
        Great for Data Strategy Professionals{" "}
        <Link to="/events" target="_blank">
          events
        </Link>
        !
      </li>
    </ul>
  </div>
);

const tealBox = (
  <div className="teal-box" id="product-testimonials">
    <h4>Other Physical Products</h4>
    <div className="blog">
      <p>
        Pick up our{" "}
        <Link to="/products/poster" target="_blank">
          Foundations of Data Strategy Poster
        </Link>
        , a great accent for your home or office that will help keep Data Management best practices top of mind.
      </p>
      <p>
        Also, check out our{" "}
        <Link to="/products/t-shirt" target="_blank">
          Data Strategist T-Shirt
        </Link>{" "}
        to proudly highlight your role as a data practitioner.
      </p>
    </div>
  </div>
);

const rest = (handleShowClick) => {
  return (
    <div className="white-box center-text-box">
      <h4 className="box-title" style={{ textAlign: "center" }}>
        Photos
      </h4>
      <div className="image-display-tri" style={{ gap: "40px", justifyContent: "space-between" }}>
        <figure>
          <img src={mugs} alt="mug" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          <figcaption style={{ textAlign: "center" }}>11 oz mugs</figcaption>
        </figure>
        <figure>
          <img src={cookies} alt="mug" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          <figcaption style={{ textAlign: "center" }}>11 oz mugs</figcaption>
        </figure>

        <figure>
          <img src={laptop} alt="mug" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          <figcaption style={{ textAlign: "center" }}>15 oz mug</figcaption>
        </figure>
      </div>
      <br />
    </div>
  );
};

export default Mug;
