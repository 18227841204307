import EventCard from "./EventCard";
import EventsSignup from "./EventsSignup";

import eventsList from "../../data/events";

export default function EventsDisplay() {
  const upcomingEvents = eventsList.filter((eventObj) => eventObj.start >= new Date());

  const eventsOne = upcomingEvents.slice(0, 3);
  const eventsTwo = upcomingEvents.slice(3, 20);

  return (
    <div style={{ margin: "20px 0px" }}>
      {eventsOne.map((eventObj) => (
        <EventCard eventObj={eventObj} />
      ))}
      <EventsSignup emailList={82} />

      {eventsTwo.map((eventObj) => (
        <EventCard eventObj={eventObj} />
      ))}
      <EventsSignup emailList={82} />
    </div>
  );
}
