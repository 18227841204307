import Lesh from "../../img/people/kathryn_lesh.webp";
import Aref from "../../img/people/zohaib_aref.webp";
import Magsamen from "../../img/people/benjamin_magsamen.webp";
import Johnson from "../../img/people/jeff_johnson.webp";
import Palamadai from "../../img/people/gopi_palamadai.webp";
import Bamburg from "../../img/people/amy_bamburg.webp";
import Penne from "../../img/people/olaf_penne.webp";

const people: Person[] = [
  {
    name: "Kathryn Lesh",
    title: "Mathematician",
    img: Lesh,
  },
  {
    name: "Zohaib Aref",
    title: "Data Quality Consultant",
    img: Aref,
  },
  {
    name: "Rob Egelink",
    title: "Senior Data Specialist",
  },
  {
    name: "Benjamin Magsamen",
    title: "Data Consultant",
    img: Magsamen,
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7191425849269600257",
  },
  {
    name: "Jeff Johnson",
    title: "Director of Privacy Programs",
    img: Johnson,
  },
  {
    name: "Gopi Palamadai",
    title: "Data Architect",
    img: Palamadai,
  },
  {
    name: "Amy Bamburg",
    title: "Data Governance Manager",
    img: Bamburg,
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7227103813864738817",
  },
  {
    name: "Olaf Penne",
    title: "AI Educator",
    img: Penne,
  },
];

export default people;
