import { useEffect, useState, useContext, useMemo } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { useParams } from "react-router-dom";

import CustomHelmet from "../../components/CustomHelmet";

import AdditionalProducts from "./AdditionalProducts";

import { CheckoutContext } from "../../contexts/CheckoutContext";

import payments from "../../data/payments";
import products from "../../data/products";
import messages from "../../data/messages/messages";

import { sendDataToSendinblue, shuffle } from "../../helpers/utils";
import { createPaymentInfo } from "../../helpers/checkoutUtils";

import SourceForm from "../../components/sign-up-forms/SourceFormContainer";
import ConfirmationTable from "./confirmation/ConfirmationTable";
import CheckAnimation from "../../components/sign-up-forms/CheckAnimation";

export default function PostCheckout({ firstName, lastName, email, freeTrial }) {
  const { id } = useParams<{ id: paymentName }>();

  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  let payment = payments?.find((payment) => payment?.name === id);
  const product = products?.find((product) => product?.name === payment?.product);
  const message = messages?.find((message) => message?.type === payment?.type).message ?? "";

  const checkoutContext = useContext(CheckoutContext);

  const { confirmationTable, paymentIntentId, finalPaymentArray } = checkoutContext.checkout;
  const { pastPurchases, potentialAddOns } = checkoutContext.purchasesAndAddons;

  const allPayments = new Set();

  allPayments.add(pastPurchases);
  allPayments.add(finalPaymentArray);
  allPayments.add(potentialAddOns);

  const isPaymentLabelInOptions = (productObject, paymentsSet) =>
    productObject?.options?.some(
      (option) =>
        option.optionsLabel === "paymentName" &&
        option.content.some((contentItem) => paymentsSet.has(contentItem.label))
    );

  const followOns = products.filter(
    (productObject) => !isPaymentLabelInOptions(productObject, allPayments) && productObject?.showOnHomepage
  );

  const shuffledFollowOns = useMemo(
    () => shuffle([...followOns]).splice(0, 3), // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!freeTrial) {
      setTimeout(() => {
        fetch("/.netlify/functions/check-payment-status", {
          method: "POST",
          body: JSON.stringify({ paymentIntentId }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "succeeded") {
              setDone(true);
            } else {
              setDone(true);
              setError(true);
            }
          })
          .catch((err) => {
            console.log(err);
            setDone(true);
            setError(true);
          });
      }, 1000);
    } else {
      setDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /* plausible event */
    try {
      if (process.env?.NODE_ENV !== "development") {
        // @ts-ignore
        window?.plausible("postCheckoutEvent", {
          revenue: { currency: "USD", amount: payment?.price },
          props: { product: payment.name },
        });
      }
    } catch (err) {
      console.log(err);
    }

    if (freeTrial) {
      // @ts-ignore
      const { abandonedCartLists, emailLists } = createPaymentInfo(confirmationTable);

      sendDataToSendinblue(firstName, lastName, email, payment, abandonedCartLists[0], emailLists[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (sendinblueResponse) {
  //     if (sendinblueResponse?.status === 200) {
  //       setDone(true);
  //     } else {
  //       setError(true);
  //       setDone(true);
  //     }
  //   } else if (sendinblueError) {
  //     setError(true);
  //     setDone(true);
  //   }
  // }, [sendinblueResponse, sendinblueError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description={product.description}
        name={product.name}
        link={product.link}
        img={product.previewImg}
        alt={product.alt}
      />
      <br />
      {!done ? (
        <div
          style={{
            height: "70%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FadeLoader color="#fe7f78" />
        </div>
      ) : !error ? (
        <div style={{ textWrap: "balance" }}>
          <CheckAnimation />

          <div style={{ height: "2em" }} />
          <p style={{ textAlign: "center", fontSize: "1.4em" }}>
            Thank you for {freeTrial ? "signing up for the free trial" : "your purchase"}!
          </p>
          <div style={{ height: "1em" }} />
          <div style={{ textAlign: "center", fontSize: ".9em" }}>
            <p
              style={{ textWrap: "balance" }}
              dangerouslySetInnerHTML={{
                __html:
                  confirmationTable.length > 1
                    ? "Within 5-10 minutes, you will receive information about your order via the email address provided in checkout:"
                    : message,
              }}
            />
            <p style={{ textAlign: "center" }}>
              <strong>{email}</strong>
            </p>
            <div style={{ height: "2em" }} />
            <p>
              If you need to contact us for any reason, please email{" "}
              <a href="mailto:support@datastrategypros.com">Customer Support</a>.
            </p>
            <div style={{ height: "2em" }} />

            <div style={{ display: "flex", justifyContent: "center" }}>
              <SourceForm firstName={firstName} email={email} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "4em",
            }}
          >
            <AdditionalProducts followOns={shuffledFollowOns} />

            {!freeTrial && (
              <div style={{ width: 800, marginTop: "4em", marginBottom: "1em" }}>
                <h4
                  style={{
                    fontSize: "1.2em",
                    marginBottom: "1em",
                    fontWeight: "900",
                    textAlign: "center",
                  }}
                >
                  Review your Order
                </h4>
                <ConfirmationTable step={4} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            height: 430,
            textWrap: "balance",
            margin: "0 auto",
          }}
        >
          <p style={{ fontSize: 96 }}>⚠️</p>
          <br />
          <br />
          <p style={{ fontSize: "1.1em" }}>
            We're having an issue accepting your payment. Please contact{" "}
            <a href="mailto:support@datastrategypros.com">support@datastrategypros.com</a> for assistance.
          </p>
        </div>
      )}
    </>
  );
}
