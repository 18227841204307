const cdmpChapters = [
  { number: 1, title: "Data Management", short: "dm", percent: 2, specialist: false, description: "" },
  { number: 2, title: "Data Ethics", short: "de", percent: 2, specialist: false, description: "" },
  {
    number: 3,
    title: "Data Governance",
    short: "dg",
    percent: 11,
    specialist: true,
    description:
      "the broadest of the Specialist Exams, this test pulls information from several DMBOK chapters, including Data Quality and Metadata Management",
  },
  {
    number: 4,
    title: "Data Architecture",
    short: "da",
    percent: 6,
    specialist: false,
    description: "",
  },
  {
    number: 5,
    title: "Data Modeling & Design",
    short: "dmd",
    percent: 11,
    specialist: true,
    description: "highly technical domain that includes questions from the Data Architecture chapter as well",
  },
  { number: 6, title: "Data Storage & Operations", short: "dso", percent: 6, specialist: false, description: "" },
  { number: 7, title: "Data Security", short: "ds", percent: 6, specialist: false, description: "" },
  {
    number: 8,
    title: "Data Integration & Interoperability",
    short: "dii",
    percent: 6,
    specialist: true,
    description: "a less frequently attempted exam that covers methodologies for data sharing across platforms",
  },
  {
    number: 9,
    title: "Document & Content Management",
    short: "dcm",
    percent: 6,
    specialist: false,
    description: "",
  },
  {
    number: 10,
    title: "Master &  Reference Data Management",
    short: "mdm",
    percent: 10,
    specialist: true,
    description: "focuses on techniques surrounding critical data elements",
  },
  {
    number: 11,
    title: "Data Warehousing & Business Intelligence",
    short: "dwbi",
    percent: 10,
    specialist: true,
    description: "covers the design and implementation of data warehouses and the tools used to analyze data",
  },
  {
    number: 12,
    title: "Metadata Management",
    short: "mm",
    percent: 11,
    specialist: true,
    description: "a challenging exam that covers knowledge related to metadata",
  },
  {
    number: 13,
    title: "Data Quality",
    short: "dq",
    percent: 11,
    specialist: true,
    description:
      "covers the tools and techniques used to ensure data quality, including basic statistical profiling methods",
  },
  { number: 14, title: "Big Data", short: "bd", percent: 2, specialist: false, description: "" },
];

export default cdmpChapters;
