import Carousel from "react-multi-carousel";

import { relatedPostsCarousel } from "../../../helpers/carouselStyles";

import CustomModal from "../../../components/CustomModal";
import CustomButtons from "../../../components/carousel/CustomButtons";

import title from "../../../img/blog/business-value-aig/business-value-aig.jpg";
import marvin from "../../../img/blog/business-value-aig/marvin.jpg";
import microsoft from "../../../img/blog/business-value-aig/microsoft.jpg";
import nist from "../../../img/blog/business-value-aig/nist.jpg";
import oecd from "../../../img/blog/business-value-aig/oecd.jpg";
import timeline from "../../../img/blog/business-value-aig/timeline.jpg";

import { useModal } from "../../../helpers/utils";

const BusinessValueAIGCarousel: React.FC = () => {
  const { showModal, image, alt, handleShowClick } = useModal();

  const imgs = [
    { src: title, alt: "business value of AI governance title slide" },
    { src: marvin, alt: "don't panic" },
    { src: timeline, alt: "timeline slide" },
    { src: nist, alt: "nist framework" },
    { src: oecd, alt: "oecd framework" },
    { src: microsoft, alt: "microsoft framework" },
  ];

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}

      <div
        className="carousel-container"
        style={{
          margin: "0px 20px 10px 20px",
          position: "relative",
          left: -8,
        }}
      >
        <Carousel
          responsive={relatedPostsCarousel}
          swipeable={true}
          draggable={true}
          ssr={true}
          keyBoardControl={true}
          infinite={true}
          arrows={false}
          renderButtonGroupOutside={true}
          // @ts-ignore
          customButtonGroup={<CustomButtons />}
        >
          {imgs.map((img, idx) => {
            return (
              <div style={{ margin: "0 8px", height: "100%" }} key={idx}>
                <img
                  src={img.src}
                  alt={img.alt}
                  style={{ width: "100%", cursor: "pointer" }}
                  onClick={handleShowClick}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default BusinessValueAIGCarousel;
