const messages = [
  {
    type: "membership",
    message:
      "Within 5-10 minutes, you will receive information about next steps via the email address provided in checkout:",
  },
  {
    type: "sendinblue",
    message: "Within 5-10 minutes, you will receive your email course via the email address provided in checkout:",
  },
  {
    type: "sendinblue-free-trial",
    message: "Within 5-10 minutes, you will receive your email course via the email address provided in checkout:",
  },
  {
    type: "download",
    message: "Within 5-10 minutes, you will receive your digital download via the email address provided in checkout:",
  },
  {
    type: "physical",
    message: "You will receive your order in 10-14 days.",
  },
  {
    type: "service",
    message: "Within 5-10 minutes, you will receive an email with information about next steps.",
  },
  {
    type: "moodle",
    message: "Within 5-10 minutes, you will receive an email with information about next steps.",
  },
  {
    type: "bundle",
    message: "Within 5-10 minutes, you will receive your product bundle via the email address provided in checkout:",
  },
  {
    type: "course",
    message: "Within 5-10 minutes, you will receive an email with information about next steps.",
  },
];

export default messages;
