import { useMediaQuery } from "@mui/material";

import videoLesson from "../../../img/products/enterprise/video-lesson.webp";
import pollEverywhere from "../../../img/products/enterprise/small-group/polleverywhere.webp";
import schedule from "../../../img/products/enterprise/small-group/schedule.webp";

import enterpriseStyles from "../../../helpers/enterpriseStyles";

export default function SmallGroupBox() {
  const showBox = useMediaQuery("(min-width: 1000px)");
  const IMAGE_HEIGHT = 120;
  return (
    <>
      {showBox && (
        <div style={{ width: "100%" }}>
          <div
            style={{
              ...enterpriseStyles.enterpriseBox,
              gridTemplateColumns: "repeat(3, 220px)",
            }}
          >
            <div style={enterpriseStyles.boxTitle}>
              <p className="title product-title">What's Included</p>
            </div>

            <figure style={{ width: IMAGE_HEIGHT + 120 }}>
              <img src={videoLesson} alt="video lesson" style={{ height: IMAGE_HEIGHT }} className="box-shadow" />
              <figcaption>
                <strong>14 introductory videos</strong>
              </figcaption>
            </figure>
            <figure style={{ width: IMAGE_HEIGHT + 120 }}>
              <img src={pollEverywhere} style={{ height: IMAGE_HEIGHT }} alt="pdf cover" className="box-shadow" />
              <figcaption>
                <strong>140 practice questions</strong>
              </figcaption>
            </figure>
            <figure style={{ width: IMAGE_HEIGHT + 120 }}>
              <img src={schedule} style={{ height: IMAGE_HEIGHT }} alt="email" className="box-shadow" />
              <figcaption>
                <strong>Recommended study schedule</strong>
              </figcaption>
            </figure>
          </div>
        </div>
      )}
    </>
  );
}
