import { ArrowBackIos } from "@mui/icons-material";

export default function BackButton({ handleClick }) {
  return (
    <button
      className="btn btn-trustpilot"
      style={{
        fontSize: "0.8em",
        borderRadius: 16,
        height: 28,
        marginTop: -2,
        marginBottom: 8,
        display: "flex",
        alignItems: "center",
      }}
      onClick={handleClick}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <ArrowBackIos sx={{ fontSize: "0.8em" }} />
        <ArrowBackIos sx={{ fontSize: "0.8em", marginLeft: -0.5 }} />
        {"Back "}{" "}
      </div>
    </button>
  );
}
