import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import AdjustIcon from "@mui/icons-material/Adjust";

const HeroButton: React.VFC<{ link: string; text: string }> = ({ link, text }) => {
  const desktop = useMediaQuery("(min-width:600px)");

  return (
    <div style={{ position: "relative", width: 280 }}>
      <Link to={link}>
        <div
          className="box-shadow right-on-hover"
          style={{
            width: 280,
            background: "#1a5e52E0",
            color: "white",
            padding: "8px 16px",
            textWrap: "balance",
            lineHeight: 1.15,
            position: "relative",
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 16,
            zIndex: 2,
            marginBottom: desktop ? 16 : 10,
            marginTop: 0,
          }}
        >
          <div style={{ display: "flex", gap: 12 }}>
            <AdjustIcon style={{ fontSize: "1.1em" }} />
            <p style={{ fontSize: "1.1em" }}>{text}</p>
          </div>
          <p style={{ fontSize: "1.6em", justifySelf: "flex-end" }}>→</p>
        </div>
      </Link>
    </div>
  );
};

export default HeroButton;
