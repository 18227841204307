import { useEffect, useState } from "react";

import CustomModal from "../CustomModal";

import grayPoster from "../../img/products/poster/poster-mock-gray.webp";
import blackMug from "../../img/products/mug/desk_crop.webp";
import grayShirt from "../../img/products/tshirt/light-gray.webp";
import darkGrayShirt from "../../img/products/tshirt/dark-gray2.webp";
import grayVneck from "../../img/products/tshirt/light-gray-vneck-model.webp";
import darkgrayVneck from "../../img/products/tshirt/dark-gray-vneck-model.webp";

import logo from "../../img/logos/logo_cdmp.webp";

import { BestValueIcon, NewIcon, UpdatedIcon } from "../../components/product-card/ProductIcons";

const ProductImage: React.VFC<{
  ROW: confirmationTableRow;
  product: productType;
  clickable?: boolean;
  showProductImage: boolean;
}> = ({ ROW, product, clickable = false, showProductImage }) => {
  const [image, setImage] = useState(product.img);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const options = ROW?.selectedOptions.map((option) => option.label);

    if (ROW.paymentName.includes("poster")) {
      if (options.includes("gray")) {
        setImage(grayPoster);
      } else {
        setImage(product.img);
      }
    } else if (ROW?.paymentName === "t-shirt") {
      if (options.includes("dark-gray")) {
        if (options.includes("regular")) {
          setImage(darkGrayShirt);
        } else {
          setImage(darkgrayVneck);
        }
      } else {
        if (options.includes("regular")) {
          setImage(grayShirt);
        } else {
          setImage(grayVneck);
        }
      }
    } else if (ROW?.paymentName.includes("mug")) {
      if (options.includes("white")) {
        setImage(product.img);
      } else {
        setImage(blackMug);
      }
    }
  }, [ROW, product]);

  const handleShowClick = (event) => {
    setShowModal(!showModal);
  };

  return (
    <>
      {showProductImage ? (
        <>
          {showModal && <CustomModal image={product.img} alt={product.alt} handleShowClick={handleShowClick} />}
          <div style={{ position: "relative" }}>
            <div style={{ position: "relative", top: 24 }}>{product.bestValue && <BestValueIcon />}</div>
            {product.new && <NewIcon size="small" />}
            {product.updated && <UpdatedIcon size="small" />}
            <figure>
              <img
                id="product-img"
                src={image}
                alt={product.alt}
                onClick={clickable ? handleShowClick : () => {}}
                style={{ cursor: clickable ? "pointer" : "auto", zIndex: 0 }}
              />
              {clickable && (
                <figcaption
                  style={{
                    fontSize: ".8em",
                    marginBottom: "1em",
                    textAlign: "center",
                  }}
                >
                  <em>click to zoom</em>
                </figcaption>
              )}
            </figure>
          </div>
        </>
      ) : (
        <img src={logo} alt="Data Strategy Professionals logo" />
      )}
    </>
  );
};

export default ProductImage;
