import { useEffect, useState, useContext, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useParams, useHistory } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";

import CheckoutLeftSide from "./checkout-left-side/CheckoutLeftSide";
import CheckoutRightSide from "./CheckoutRightSide";

import CustomerForm from "./CustomerForm";
import CheckPurchases from "./CheckPurchases";
import ConfirmationPage from "./confirmation/ConfirmationPage";
import StripeProvider from "./StripeProvider";
import AddressForm from "./AddressForm";
import CheckoutForm from "./CheckoutForm";
import PostCheckout from "./PostCheckout";

import { CheckoutContext } from "../../contexts/CheckoutContext";

import payments from "../../data/payments";

import { getPrice, updateConfirmationTable } from "../../helpers/checkoutUtils";

interface requestBody {
  discountCode: string;
  email: string;
  paymentsTable?: confirmationTableRow[];
  paymentsTableRow?: confirmationTableRow;
  paymentIntentId?: string;
}

interface handlePaymentIntentResponse {
  metadata: {
    discountValue: number;
    discountCode: string;
    shippingAmt: number;
    taxAmt: number;
    taxRate: number;
  };
  paymentsTable: confirmationTableRow[];
}

export default function CheckoutController() {
  const [fadeLoader, setFadeLoader] = useState<boolean>(false);
  const [showMultiple, setShowMultiple] = useState<boolean>(false);
  const [showProductImage, setShowProductImage] = useState<boolean>(true);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [companyIsBlank, setCompanyIsBlank] = useState<Boolean>(true);
  const [address, setAddress] = useState<any | null>(null);

  const { id } = useParams<{ id: paymentName }>();

  const checkoutContext = useContext(CheckoutContext);

  const { discountCodeInput, setDiscountCodeInput, discountValue, setDiscountValue, setDiscountMessage } =
    checkoutContext.discount;
  const {
    paymentIntentId,
    setPaymentIntentId,
    setClientSecret,
    confirmationTable,
    setConfirmationTable,
    step,
    setStep,
  } = checkoutContext.checkout as checkoutContext["checkout"];
  const { setDisplayPrice } = checkoutContext.price;

  const {
    calculateShippingAndTaxes,
    setCalculateShippingAndTaxes,
    taxCalcDone,
    setTaxCalcDone,
    errorCalculatingShippingAndTaxes,
    setErrorCalculatingShippingAndTaxes,
  } = checkoutContext.taxes;

  const { potentialAddOns } = checkoutContext.purchasesAndAddons;

  const [ROW, setROW] = useState<confirmationTableRow | null>(confirmationTable ? confirmationTable[0] : null);
  const [freeTrial, setFreeTrial] = useState<boolean>(
    confirmationTable ? confirmationTable[0].paymentName.includes("free-trial") : false
  );
  const history = useHistory();

  useEffect(() => {
    // reset paymentIntentId if user navigates back to first step of the payment process
    if (step === 0) {
      setPaymentIntentId(null);
      setClientSecret(null);
      setCalculateShippingAndTaxes(false);
      setShowMultiple(false);
      setDiscountCodeInput("");
      setDiscountValue(0);
      setErrorCalculatingShippingAndTaxes(false);
    }

    // whenever the payment process moves to a new step, clear the discount message
    setDiscountMessage(null);

    if (confirmationTable && step > 1) {
      setShowMultiple(
        confirmationTable.filter((row) => row.selected).length > 1 ||
          confirmationTable.filter((row) => row.selected).some((row) => row.type === "physical") ||
          discountValue > 0 ||
          confirmationTable.some((row) => row.metadata.count > 1)
      );
      if (confirmationTable.filter((row) => row.selected).length > 1) setShowProductImage(false);

      console.log("PRODUCT IMAGE FILTER", !(confirmationTable.filter((row) => row.selected).length > 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    // for free trial workflow, we should make sure that any user that directly navigates to the free trial checkout page can proceed
    // otherwise, if there's no confirmation table, send the user back to the previous page
    if (!confirmationTable && (id.includes("free-trial") || id.includes("continuation"))) {
      if (id.includes("free-trial")) {
        setFreeTrial(true);
      }

      const payment = payments.find((paymentObject: paymentType) => paymentObject.name === id);

      const table: confirmationTableRow[] = [
        {
          selected: true,
          paymentName: id,
          type: payment.type,
          selectedOptions: [payment.options[0].content[0]],
          price: getPrice(payment, [payment.options[0].content[0]]),
          metadata: {
            count: 1,
            unit: "",
            abandonedCartList: payment.abandonedCartList,
            discountCode: null,
            discountValue: 0,
            discountAmount: 0,
            shippingAmt: 0,
            taxCode: "",
            taxRate: 0,
          },
        },
      ];
      setConfirmationTable(table);
    } else if (!confirmationTable) {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationTable]);

  useMemo(() => {
    if (confirmationTable && confirmationTable.length > 0) {
      const discountValue = confirmationTable[0]?.metadata?.discountValue ?? 0;
      const unitPrice =
        discountValue === 0
          ? confirmationTable[0].price
          : discountValue > 1
          ? confirmationTable[0].price - discountValue
          : confirmationTable[0].price * (1 - discountValue);
      setDisplayPrice(unitPrice * (confirmationTable[0]?.metadata?.count || 1));
      setROW(confirmationTable[0]);
    }
  }, [confirmationTable, setDisplayPrice, setROW]);

  useEffect(() => {
    // at first step of the payment process, append the add-ons to the confirmation table
    if (confirmationTable && potentialAddOns) {
      function addProductsToTable() {
        if (potentialAddOns.some((addOn) => confirmationTable.some((row) => row.paymentName === addOn))) {
          console.error("Potential add-ons already exist in the confirmation table");
          return;
        }

        const rows: confirmationTableRow[] = potentialAddOns.map((addOn) => {
          const payment = payments.find((paymentObject: paymentType) => paymentObject.name === addOn);

          let selectedOptions;
          // Check if the payment is the "specialist-bundle" and set the options accordingly
          if (payment.name === "specialist-bundle") {
            selectedOptions = [
              {
                label: "dg",
                displayText: "Data Governance",
              },
              {
                label: "dq",
                displayText: "Data Quality",
              },
            ];
          } else {
            // For other payment types, set each dropdown to the first option as the default
            selectedOptions = payment.options.map((option) => option.content[0]);
          }

          return {
            selected: false,
            paymentName: payment.name,
            type: payment.type,
            selectedOptions,
            price: getPrice(payment, selectedOptions),
            metadata: {
              count: 1,
              unit: "",
              abandonedCartList: payment.abandonedCartList,
              discountCode: null,
              discountValue: 0,
              discountAmount: 0,
              shippingAmt: 0,
              taxCode: payment.taxCode,
              taxRate: 0,
            },
          };
        });

        setConfirmationTable(confirmationTable.concat(rows));
      }

      addProductsToTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [potentialAddOns]);

  // handle discount code
  useEffect(() => {
    if (discountCodeInput && discountCodeInput !== "INCORRECT_CODE") {
      let fetchEndpoint = "/.netlify/functions/";

      const requestBody: requestBody = {
        discountCode: discountCodeInput,
        email: email,
      };

      if (paymentIntentId) {
        fetchEndpoint += "handle-payment-intent";

        requestBody.paymentIntentId = paymentIntentId;
        requestBody.paymentsTable = confirmationTable.filter((row) => row.selected);
      } else {
        fetchEndpoint += "discount-code";
        requestBody.paymentsTableRow = ROW;
      }

      fetch(fetchEndpoint, {
        method: "POST",
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (data.error) {
            setDiscountCodeInput("INCORRECT_CODE");
            setDiscountMessage(data.error);
          } else {
            const discountValue = data.metadata.discountValue;
            setDiscountValue(discountValue);

            if (fetchEndpoint.includes("discount-code")) {
              confirmationTable[0].metadata = {
                ...confirmationTable[0].metadata,
                ...data.metadata,
              };
            }

            const paymentsTable = fetchEndpoint.includes("handle-payment-intent")
              ? data.paymentsTable
              : confirmationTable.filter((row) => row.selected);

            setConfirmationTable(updateConfirmationTable(confirmationTable, paymentsTable));

            setDisplayPrice(
              discountValue > 1
                ? confirmationTable[0].price - discountValue
                : confirmationTable[0].price * (1 - discountValue)
            );
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountCodeInput]);

  // get shipping and taxes
  useEffect(() => {
    if (calculateShippingAndTaxes) {
      fetch("/.netlify/functions/handle-payment-intent", {
        method: "POST",
        body: JSON.stringify({
          email: email,
          paymentIntentId: paymentIntentId,
          address: address?.address,
          paymentsTable: confirmationTable.filter((row) => row.selected),
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data: handlePaymentIntentResponse) => {
          const paymentsTable = data.paymentsTable;
          setConfirmationTable(updateConfirmationTable(confirmationTable, paymentsTable));
          setTaxCalcDone(true);
        })
        .catch((error) => {
          setErrorCalculatingShippingAndTaxes(true);
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateShippingAndTaxes]);

  useEffect(() => {
    if (errorCalculatingShippingAndTaxes) {
      setFadeLoader(false);
    }
  }, [errorCalculatingShippingAndTaxes]);

  useEffect(() => {
    if (step === 3) {
      setFadeLoader(!taxCalcDone);
    }
  }, [taxCalcDone, step]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {confirmationTable && ROW && (
        <>
          <Helmet>
            <title>Checkout</title>
            <meta name="robots" content="noindex" />
            <meta name="description" content="Data Strategy Professionals checkout page." />
          </Helmet>
          <div className="product-layout">
            <h1 className="title product-title">
              {showMultiple
                ? "Order Confirmation"
                : payments.find((payment) => ROW.paymentName === payment.name).product}{" "}
              {freeTrial &&
                !payments.find((payment) => ROW.paymentName === payment.name).product.includes("Free Trial") &&
                "Free Trial"}
            </h1>
            <div className="product-info thirds">
              <CheckoutLeftSide step={step} showMultiple={showMultiple} showProductImage={showProductImage} />
              <div className="checkout-form">
                {!fadeLoader ? (
                  <>
                    {(step === 0 || step === 2) && (
                      <p
                        style={{
                          textAlign: "left",
                          fontWeight: 900,
                          fontSize: "1.01em",
                        }}
                      >
                        Please enter your{" "}
                        {step === 2 ? "shipping details" : freeTrial ? "information" : "payment details"}
                      </p>
                    )}
                  </>
                ) : (
                  <div
                    style={{
                      height: "70%",
                      display: "grid",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FadeLoader color="#fe7f78" />
                  </div>
                )}
                {step === 0 && (
                  <CustomerForm
                    paymentName={ROW.paymentName}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    setEmail={setEmail}
                    setCompany={setCompany}
                    companyIsBlank={companyIsBlank}
                    setCompanyIsBlank={setCompanyIsBlank}
                    freeTrial={freeTrial}
                    setStep={setStep}
                  />
                )}
                {step === 0.5 && (
                  <CheckPurchases
                    setStep={setStep}
                    email={email}
                    paymentName={ROW.paymentName}
                    setFadeLoader={setFadeLoader}
                  />
                )}
                {step >= 1 && step < 4 && (
                  <StripeProvider
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    discountCode={discountCodeInput === "INCORRECT_CODE" ? null : discountCodeInput}
                    company={company}
                    setFadeLoader={setFadeLoader}
                  >
                    {step === 1 && <ConfirmationPage step={step} setStep={setStep} email={email} address={address} />}
                    {step === 2 && (
                      <AddressForm
                        setStep={setStep}
                        setAddress={setAddress}
                        firstName={firstName}
                        lastName={lastName}
                        setCalculateShippingAndTaxes={setCalculateShippingAndTaxes}
                        setTaxCalcDone={setTaxCalcDone}
                      />
                    )}
                    {step === 3 && (
                      <>
                        {errorCalculatingShippingAndTaxes ? (
                          <div
                            style={{
                              textAlign: "center",
                              height: 430,
                              // @ts-ignore
                              textWrap: "balance",
                              margin: "0 auto",
                            }}
                          >
                            <p style={{ fontSize: 96 }}>⚠️</p>
                            <br />
                            <br />
                            <p style={{ fontSize: "1.1em" }}>
                              We are unable to complete your payment at this time. Please try again later or email{" "}
                              <a href="mailto:support@datastrategypros.com">support@datastrategypros.com</a> to request
                              more information.
                            </p>
                          </div>
                        ) : (
                          taxCalcDone && (
                            <CheckoutForm
                              step={step}
                              setStep={setStep}
                              address={address}
                              email={email}
                              setFadeLoader={setFadeLoader}
                              showMultiple={showMultiple}
                              freeTrial={freeTrial}
                            />
                          )
                        )}
                      </>
                    )}
                  </StripeProvider>
                )}
                {step === 4 && (
                  <PostCheckout firstName={firstName} lastName={lastName} email={email} freeTrial={freeTrial} />
                )}
              </div>
              {freeTrial && step === 0 && <CheckoutRightSide paymentName={ROW.paymentName} />}
            </div>
          </div>
        </>
      )}
    </>
  );
}
