import { useMediaQuery } from "@mui/material";

import TestimonialCardHorizontal from "../../components/about/TestimonialCardHorizontal";

import { shuffle } from "../../helpers/utils";

import testimonials from "../../data/testimonials";

const TestimonialsGrid: React.FC = () => {
  const oneCol = useMediaQuery("(max-width: 640px)");
  const twoCol = useMediaQuery("(max-width: 960px)");
  const threeCol = useMediaQuery("(max-width: 1280px)");

  const colCount = oneCol ? 1 : twoCol ? 2 : threeCol ? 3 : 4;

  const testimonialWidth = oneCol
    ? "calc(100% - 4px)"
    : twoCol
    ? "calc(50% - 12px)"
    : threeCol
    ? "calc(33% - 12px)"
    : "calc(25% - 16px)";

  let testimonialsToShow = testimonials;

  testimonialsToShow = testimonialsToShow
    .filter((testimonial) => testimonial.id !== "general-001")
    .filter((testimonial) => testimonial.id !== "cdmp-question-sets-000")
    .filter((testimonial) => testimonial.id !== "cdmp-question-sets-001")
    .filter((testimonial) => testimonial.id !== "cdmp-fundamentals-notes-001")
    .filter((testimonial) => testimonial.id !== "cdmp-study-plan-001")
    .filter((testimonial) => testimonial.id !== "cdmp-question-sets-002")
    .filter((testimonial) => testimonial.id !== "cap-exam-questions-001")
    .filter((testimonial) => testimonial.id !== "cippe-study-plan-002")
    .filter((testimonial) => testimonial.id !== "cdmp-fundamentals-bundle-002")
    .filter((testimonial) => testimonial.id !== "cdmp-small-group-000")
    .filter((testimonial) => testimonial.id !== "data-strategy-bootcamp-007")
    .filter((testimonial) => testimonial.id !== "data-strategy-bootcamp-002");

  testimonialsToShow = shuffle(testimonialsToShow);

  return (
    <div style={{ overflow: "hidden" }}>
      {/* <p
        style={{
          textAlign: "center",
          fontSize: "1.3em",
          marginTop: 12,
          fontFamily: "Raleway",
          marginBottom: 20,
        }}
      >
        <strong>What People are Saying</strong>
      </p> */}
      <div
        className="center-text-box"
        style={{
          padding: "60px 6px 0 6px",
          gap: 20,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          height: `calc(14026px / ${colCount})`,
          overflow: "hidden",
        }}
      >
        {testimonialsToShow.map((testimonial) => (
          <div style={{ width: testimonialWidth }}>
            <TestimonialCardHorizontal
              key={testimonial.id}
              testimonial={testimonial}
              img={testimonial.img}
              quotes={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialsGrid;
