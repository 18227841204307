import { useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import EventsDisplay from "./EventsDisplay";
import EventsPast from "./EventsPast";
import NavButtons from "../../components/nav/navbar/NavButtons";

import specialist from "../../img/helmet/products/smiling_sq.jpeg";

import CustomHelmet from "../../components/CustomHelmet";

const navbuttons = [
  {
    id: "events",
    label: "Upcoming Events",
    path: "/events",
  },
  {
    id: "past-events",
    label: "Past Events",
    path: "/events/past",
  },
];

export default function Events() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description="Data Strategy Professionals offers engaging monthly events focused on helping data practitioners save time and ace the Certified Data Management Professional (CDMP) exam."
        name="Events"
        link="/events"
        img={specialist}
        alt="two smiling business people"
      />

      <div className="home-layout" style={{ minHeight: "60vh", position: "relative" }}>
        <div
          style={{
            height: "2em",
            width: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h1 className="title">Events</h1>
          <NavButtons navbuttons={navbuttons} />
        </div>

        <Switch>
          <Route exact path="/events">
            <EventsDisplay />
          </Route>
          <Route exact path="/events/past">
            <EventsPast />
          </Route>
        </Switch>
      </div>
    </>
  );
}
