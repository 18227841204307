const TeamCardStyles: { [key: string]: React.CSSProperties } = {
  container: {
    height: "100%",
    width: "100%",
    background: "white",
    borderRadius: 4,
    position: "relative",
  },
  header: {
    width: "100%",
    height: 92,
    background: "#bdd8da",
    position: "absolute",
    zIndex: 1,
    marginTop: 20,
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    zIndex: 2,
  },
  img: {
    objectFit: "cover",
    width: 120,
    height: 120,
    borderRadius: "50%",
    margin: "8px 12px 4px 16px",
    border: "4px solid #bdd8da",
  },
  name: {
    fontSize: "1.1em",
    fontFamily: "Raleway",
    fontWeight: "900",
  },
  title: {
    fontSize: ".9em",
    fontStyle: "italic",
  },
  content: {
    padding: "8px 20px 28px 20px",
    position: "relative",
  },
};

const TeamCard: React.FC = ({ teamMember }) => {
  const { name, title, img, content } = teamMember;

  return (
    <div className="box-shadow" style={TeamCardStyles.container}>
      <div style={TeamCardStyles.header} />

      <div style={TeamCardStyles.imgContainer}>
        <img src={img} alt={name} style={TeamCardStyles.img} />
        <div style={{ marginRight: 20, width: "100%" }}>
          <p style={TeamCardStyles.name}>{name}</p>
          <p style={TeamCardStyles.title}>{title}</p>
        </div>
      </div>

      <div style={TeamCardStyles.content}>
        <p
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </div>
  );
};

export default TeamCard;
