const navStyles: { [key: string]: React.CSSProperties } = {
  navbarMenu: {
    textAlign: "right",
    width: 180,
    zIndex: 2,
    fontSize: "16px",
    padding: "0.2rem 0.2rem",
    lineHeight: 0,
    position: "relative",
    top: 52,
    backgroundColor: "white",
    cursor: "pointer",
  },
  navbarItem: {
    textAlign: "right",
    color: "#4a4a4a",
    display: "block",
    lineHeight: "1",
    padding: "0.5rem",
    height: 38,
  },
  navButton: {
    width: 180,
    fontSize: ".8em",
  },
};

export default navStyles;
