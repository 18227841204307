import { Link } from "react-router-dom";

const CDMPStudyProgramDescription: React.VFC = () => {
  return (
    <div style={{ maxWidth: 1100 }}>
      <p className="title product-title">What are the benefits of the Study Program?</p>
      <div className="blog">
        <p>
          This <strong>Study Program</strong> provides basically everything* needed to ace the{" "}
          <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
            CDMP
          </Link>
          . As busy professionals, your team members don't have abundant time to get ready for the test. The{" "}
          <Link to="/products/cdmp-study-plan" target="_blank">
            90-Day Study Plan
          </Link>{" "}
          distills the most important information, key vocabulary, and crucial concepts from the 14 chapters of the{" "}
          <em>Data Management Body of Knowledge</em> (
          <em>
            <a href="amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              DMBOK
            </a>
          </em>
          ) that are tested on the CDMP into a streamlined study schedule. The Study Plan also provides hints on when to
          review key chapters to maximize retention.
        </p>
        <p>
          The{" "}
          <Link to="/products/cdmp-fundamentals-notes" target="_blank">
            Fundamentals Notes
          </Link>{" "}
          help data practitioners <strong>fast-track</strong> their preparation for the CDMP exam through 30+ pages of
          notes. This handy reference walks through every step of the CDMP accreditation process and serves as an
          easy-to-read companion to DAMA's reference book. The guide includes instructions for testing and for obtaining
          your badge and DAMA membership after completing the exam.
        </p>
        <p>
          The{" "}
          <Link to="/products/cdmp-question-sets" target="_blank">
            Question Sets
          </Link>{" "}
          are designed to help test takers challenge themselves with practice questions in the style of the CDMP exam
          (i.e., choose the best answer out of five). The three Question Sets collectively contain{" "}
          <strong>150 multiple choice questions</strong>, as well as a detailed explanation of each correct answer with
          the associated reference page number for the <i>DMBOK</i>.
        </p>
        <p>
          In addition, participants will receive <strong>3+ hours</strong> of pre-recorded{" "}
          <strong>video lessons</strong> on the key concepts in each of the 14 <i>DMBOK</i> chapters tested on the CDMP
          Fundamentals Exam.
        </p>
        <p>
          Ensure preparedness with the <strong>CDMP Exam Readiness Check</strong>, a two part online assessment.{" "}
          <strong>Part 1</strong> contains various exercises to help identify potential areas of weakness.{" "}
          <strong>Part 2</strong> is a mock exam composed of 20 multiple choice questions in the style of the CDMP
          Fundamentals Exam.
        </p>
      </div>
    </div>
  );
};

export default CDMPStudyProgramDescription;
