const groupLinks: LinkObj[] = [
  {
    name: "CDMP",
    linkedIn: "https://www.linkedin.com/groups/12965811/",
    facebook: "https://www.facebook.com/groups/346145433213551",
  },
  {
    name: "CAP",
    linkedIn: "https://www.linkedin.com/groups/13026365/",
    facebook: "https://www.facebook.com/groups/certifiedanalyticsprofessionals/",
  },
];

export default groupLinks;
