import { useState, useMemo } from "react";
import ReactCardFlip from "react-card-flip";

import quote from "../../img/icons/quote.webp";
import arrow from "../../img/icons/arrow.webp";

const styles = {
  card: {
    width: "100%",
    height: "100%",
    background: "white",
    borderRadius: 4,
    position: "relative",
    minHeight: 280,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  content: {
    zIndex: 1,
    position: "relative",
    padding: "40px 40px 0 40px",
    marginBottom: "0.5em",
  },
  customer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 16,
    padding: "0 35px 40px 40px",
  },
  front: {
    padding: 60,
    position: "absolute",
    top: 0,
    zIndex: 3,
    display: "flex",
    height: "calc(100% - 100px)",
    width: "100%",
  },
};

export default function ProductTestimonialCardFlip({
  testimonial,
  frontFontSize = 1.8,
  fontSize = 1.1,
  productPage = false,
}) {
  const [isFlipped, setIsFlipped] = useState(false);

  const testimonialText = productPage ? testimonial.plainText : testimonial.content;

  const colors = ["#1a5e52", "#1a485e", "#2b769a", "#6f8ce8", "#13334f", "#5f7496"];
  const color = useMemo(
    () => colors[Math.floor(Math.random() * colors.length)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      className="flex-item-testimonial w-100 px-4 py-5"
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
      style={{ display: "flex", alignItems: "stretch" }}
    >
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <ProductTestimonialCardSimplified
          key={testimonial.id}
          testimonialText={testimonialText}
          short={testimonial.short}
          frontFontSize={frontFontSize}
          fontSize={fontSize}
          customer={<Customer testimonial={testimonial} color={color} />}
        />
        <ProductTestimonialCard
          key={testimonial.id}
          testimonialText={testimonialText}
          fontSize={fontSize}
          customer={<Customer testimonial={testimonial} color={color} />}
        />
      </ReactCardFlip>
    </div>
  );
}

function Customer({ testimonial, color }) {
  return (
    <div style={styles.customer}>
      <div style={{ zIndex: 1 }}>
        <p style={{ textAlign: "right" }}>
          — <span style={{ fontWeight: 400 }}>{testimonial.name}</span>,
        </p>
        <p
          style={{
            textAlign: "right",
            fontStyle: "italic",
            textWrap: "balance",
          }}
        >
          {testimonial.title}
        </p>
      </div>
      {testimonial.img ? (
        <img
          src={testimonial.img}
          alt="customer"
          style={{
            objectFit: "cover",
            width: 80,
            height: 80,
            borderRadius: "50%",
            border: "4px solid white",
            zIndex: 2,
          }}
        />
      ) : (
        <div
          style={{
            background: color,
            objectFit: "cover",
            width: 80,
            height: 80,
            borderRadius: "50%",
            border: "3px solid white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <p style={{ color: "white", fontSize: "2em", fontWeight: 600 }}>{testimonial.name[0]}</p>
        </div>
      )}
    </div>
  );
}

function ProductTestimonialCardSimplified({ testimonialText, short, frontFontSize, fontSize, customer }) {
  return (
    <div style={styles.card} className="box-shadow">
      <div>
        <div style={styles.content} className="product-testimonial-card-simplified">
          <p
            style={{ fontSize: `${fontSize}em`, color: "white" }}
            dangerouslySetInnerHTML={{
              __html: `"${testimonialText}"`,
            }}
          />
        </div>
        <div style={styles.front}>
          <img
            src={quote}
            alt="quote"
            style={{
              position: "absolute",
              width: 40,
              height: 40,
              objectFit: "cover",
              left: 32,
              top: 48,
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: short,
            }}
            style={{ fontSize: `${frontFontSize}em`, zIndex: 3, alignSelf: "start" }}
          />
        </div>
      </div>
      {customer}
      <img
        src={arrow}
        alt="arrow"
        style={{ position: "absolute", bottom: 12, right: 12, maxWidth: "7%", transform: "rotate(10deg)" }}
      />
    </div>
  );
}

function ProductTestimonialCard({ testimonialText, fontSize, customer }) {
  return (
    <div style={styles.card} className="box-shadow">
      <div style={styles.content}>
        <p
          style={{ fontSize: `${fontSize}em` }}
          dangerouslySetInnerHTML={{
            __html: `"${testimonialText}"`,
          }}
        />
      </div>
      {customer}
    </div>
  );
}
