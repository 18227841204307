import LandingPage from "../../../components/lead-magnet/LandingPage";
import BusinessValueAIGCarousel from "./BusinessValueAIGCarousel";

const BusinessValueAIG: React.FC = () => {
  const description = `AI Governance frameworks can help ensure that AI systems are as ethical, accountable, and transparent as
            possible. This report provides a comparison of seven leading AI Governance frameworks from institutions such
            as NIST, OECD, Microsoft, Google, and AWS.`;

  return (
    <LandingPage
      postName="business-value-aig"
      description={description}
      leadMagnetName="our comparison report"
      listNumber={137}
      Rest={<BusinessValueAIGCarousel />}
    />
  );
};

export default BusinessValueAIG;
