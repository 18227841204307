import ProductTestimonials from "../../../components/product-testimonials/ProductTestimonials";
import WhatPeopleAreSaying from "../../../components/product-pages/WhatPeopleAreSaying";

import testimonials from "../../../data/testimonials";

const CDMPBootcampTealBox: React.FC = () => {
  return <WhatPeopleAreSaying selectedTestimonials={["data-strategy-bootcamp-006"]} />;
};

const CDMPBootcampTestimonialsOne: React.FC = () => {
  const testimonialsToShow = [
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-000"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-002"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-005"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-004"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-010"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-006"),
  ];
  return <ProductTestimonials testimonialsToShow={testimonialsToShow} />;
};

const CDMPBootcampTestimonialsTwo: React.FC = () => {
  const testimonialsToShow = [
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-009"),
    testimonials.find((testimonial) => testimonial.id === "general-003"),
    testimonials.find((testimonial) => testimonial.id === "general-001"),
    testimonials.find((testimonial) => testimonial.id === "general-000"),
    testimonials.find((testimonial) => testimonial.id === "general-005"),
    testimonials.find((testimonial) => testimonial.id === "general-011"),
  ];
  return <ProductTestimonials testimonialsToShow={testimonialsToShow} />;
};

const CDMPBootcampFeatures: React.FC<{ mockExams: number }> = ({ mockExams }) => {
  return (
    <div className="white-outline-box">
      <h4 className="box-title">Features</h4>
      <div className="center-text-box two-col" id="features">
        <ul>
          <li>
            Instruction on each of the <strong>14 chapters</strong> of the <em>Data Management Body of Knowledge</em> (
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>
            )
          </li>
          <li>
            <strong>Interactive approach</strong> to ensure you're learning best practices from your fellow Bootcamp
            students
          </li>
          <li>
            <strong>Practice questions</strong> throughout to assess your progress and identify any gaps in your
            knowledge
          </li>
          {mockExams === 1 ? (
            <li>
              <strong>Mock exam</strong> to test your skills
            </li>
          ) : mockExams === 2 ? (
            <li>
              <strong>Two mock exams</strong>, one on each day of the Bootcamp, to test your skills
            </li>
          ) : (
            <></>
          )}
          <li>
            <strong>Study tips</strong> to ensure you're maximizing your time and energy
          </li>
        </ul>
      </div>
    </div>
  );
};

export { CDMPBootcampTealBox, CDMPBootcampTestimonialsOne, CDMPBootcampTestimonialsTwo, CDMPBootcampFeatures };
