import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import model1 from "../../img/products/tshirt/model_sq.webp";
import model2 from "../../img/products/tshirt/model2_sq.webp";
import nicole from "../../img/products/tshirt/tshirt_lrg.webp";

import CustomModal from "../../components/CustomModal";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";

const PRODUCT = "Data Strategist T-Shirt";

export default function TShirt() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        description={description}
        tealBox={tealBox}
        rest={rest(handleShowClick)}
      />
    </>
  );
}

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <div className="blog">
      <p>
        Represent your Data Strategist pride in comfort with this cotton t-shirt from Data Strategy Professionals. We
        recognize that your work moves mountains. That's why we've chosen to represent your contributions with a
        mountain landscape on this classic t-shirt design.
      </p>

      <h4>Features</h4>
      <ul>
        <li>Ultra comfy t-shirt made of 100% combed and ringspun cotton</li>
        <li>Classic fit with sturdy construction</li>
        <li>
          Great for Data Strategy Professionals{" "}
          <Link to="/events" target="_blank">
            events
          </Link>
          !
        </li>
      </ul>
    </div>
  </div>
);

const tealBox = (
  <div className="teal-box" id="product-testimonials">
    <h4>Other Physical Products</h4>
    <div className="blog">
      <p>
        Pick up our{" "}
        <Link to="/products/poster" target="_blank">
          Foundations of Data Strategy Poster
        </Link>
        , a great accent for your home or office that will help keep Data Management best practices top of mind.
      </p>
      <p>
        Also, check out our{" "}
        <Link to="/products/mug" target="_blank">
          Data Strategist Mug
        </Link>{" "}
        to proudly highlight your role as a data practitioner while enjoying your morning tea or coffee.
      </p>
    </div>
  </div>
);

const rest = (handleShowClick) => {
  return (
    <div className="white-box center-text-box">
      <h4 className="box-title" style={{ textAlign: "center" }}>
        Photos
      </h4>
      <div className="image-display-tri">
        <img src={model1} alt="shirt" onClick={handleShowClick} style={{ cursor: "pointer" }} />
        <img src={nicole} alt="shirt" onClick={handleShowClick} style={{ cursor: "pointer" }} />
        <img src={model2} alt="shirt" onClick={handleShowClick} style={{ cursor: "pointer" }} />
      </div>
    </div>
  );
};
