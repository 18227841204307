import { Link } from "react-router-dom";

import pomodoro from "../img/blog/pomodoro_lrg.webp";
import alarm from "../img/blog/alarm.webp";
import focusmate from "../img/blog/focusmate-fist-bump.webp";
import tomato from "../img/blog/tomato.webp";

import Post from "../components/blog/Post";
import Contents from "../components/blog/Contents";

const POSTNAME = "pomodoro";

const Pomodoro: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default Pomodoro;

const Rest = () => {
  const contents: Content[] = [
    {
      anchor: "basics",
      text: "Basics",
    },
    {
      anchor: "introduction",
      text: "Introduction",
    },
    {
      anchor: "how",
      text: "How it Works",
    },
    {
      anchor: "break",
      text: "What Constitutes a Good Break?",
    },
    {
      anchor: "timers",
      text: "Pomodoro Timers",
    },
    {
      anchor: "coworking",
      text: "Benefits of Coworking",
    },
    {
      anchor: "conclusion",
      text: "Conclusion",
    },
  ];

  return (
    <>
      <figure>
        <img src={pomodoro} alt="woman holding cherry tomatoes" />
        <figcaption>
          Photo by{" "}
          <a href="https://www.pexels.com/@d-ng-nhan-324384/" target="_blank" rel="noopener noreferrer">
            Dương Nhân
          </a>{" "}
          on{" "}
          <a
            href="https://www.pexels.com/photo/photo-of-person-holding-cherry-tomato-2817549/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pexels
          </a>
        </figcaption>
      </figure>
      <p>
        Pomodoros are an ideal <strong>productivity strategy</strong> to incorporate into your everyday routine. They
        help keep work fun and novel.
      </p>
      <p>
        The pomodoro technique was created by{" "}
        <a href="https://francescocirillo.com/" target="_blank" rel="noopener noreferrer">
          Francesco Cirillo
        </a>{" "}
        in the late 1980s. As a student, Cirillo used a timer to divide the duration of his work into intervals of 25
        minutes, separated by 5 minute breaks. Cirillo named the technique for the{" "}
        <strong>tomato-shaped kitchen timer</strong> that he had on hand when inventing the method. The word{" "}
        <strong>
          <i>pomodoro</i>
        </strong>
        , which is Italian for tomato, has come to refer to a cycle of work and rest.
      </p>
      <p>
        The method is supported by{" "}
        <a
          href="http://www.sciencedaily.com/releases/2011/02/110208131529.htm"
          target="_blank"
          rel="noopener noreferrer"
        >
          research
        </a>{" "}
        showing how{" "}
        <a
          href="https://ideas.repec.org/a/asi/aeafrj/v10y2020i12p1366-1383id2021.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          planned breaks
        </a>{" "}
        can increase creativity and focus, helping us stay alert and accomplish more over time. By giving yourself an
        endpoint (i.e., the conclusion of a work interval) to focus on, you can push yourself to{" "}
        <strong>concentrate a little harder</strong> with the knowledge a break is not far away.
      </p>
      <Contents contents={contents} />
      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <p>How to do a pomodoro working session:</p>
      <ul>
        <li>
          <strong>Fight procrastination</strong>: get started by setting a 25 minute timer, or if that's too much, a 5
          minute one
        </li>
        <li>
          <strong>Eliminate distractions to increase focus</strong>: session should take place with just you, your
          timer, and your work (and maybe some energizing music)
        </li>
        <li>
          <strong>Commit to single-tasking</strong>: set a defined goal for the session and work on it until it's done
        </li>
        <li>
          <strong>Gain a better understanding of how long tasks take you to complete</strong>: estimate how many
          pomodoros you'll need to complete each task ahead of you
        </li>
        <li>
          <strong>Have fun</strong>: racing against the clock gives work an element of excitement
        </li>
        <li>
          <strong>Work toward a deadline</strong>: pomodoro sessions provide good practice at getting tasks done by a
          specific time
        </li>
        <li>
          <strong>Stay accountable to your goals</strong>: scheduling pomodoro sessions is a great way to think through
          what you want to accomplish in a day
        </li>
      </ul>
      <p>How to do a pomodoro break:</p>
      <ul>
        <li>
          <strong>Try something totally different</strong>: for maximum relaxation,{" "}
          <a
            href="https://www.sciencedirect.com/science/article/abs/pii/S074959781630108X"
            target="_blank"
            rel="noopener noreferrer"
          >
            science
          </a>{" "}
          tells us your best bet is to step away from the computer or textbook
        </li>
        <li>
          <strong>Manage your energy levels</strong>: consistent breaks give you time to relax and unwind
        </li>
        <li>
          <strong>Promote creativity</strong>: when our attention is unfocused, our brains make creative connections
          between ideas
        </li>
        <li>
          <strong>Enhance long-term memory retention</strong>: another benefit of taking breaks is they help our
          long-term memories to store information
        </li>
        <li>
          <strong>Touch grass</strong>
        </li>
      </ul>
      <h2 id={contents[1].anchor}>{contents[1].text}</h2>
      <p>
        The pomodoro technique has many readily accessible benefits, such as helping you{" "}
        <strong>break through the urge to procrastinate</strong> and <strong>increasing your focus as you work</strong>.
        Breaking work into smaller, more manageable chunks makes it easier to{" "}
        <strong>get started and stay on task</strong>. The short breaks between pomodoros allow for a mental reset,
        which can help to <strong>prevent burnout</strong> and spur ideation, engage the long-term memory, and enhance
        overall productivity.
      </p>
      <p>
        You may find that over multiple pomodoro cycles, you're able to develop <strong>intense concentration</strong>{" "}
        during the work interval. Doing pomodoros will train you to move quickly between an indistractable state of{" "}
        <strong>intense focused attention</strong> and a state of <strong>relaxed and unfocused attention</strong> that
        promotes creativity and recovery.
      </p>
      <p>
        While finding time for deep work in our <i>hyperbusyalwaysconnected</i> world can be an immense challenge, it's
        good to know that the pomodoro method is always there for you. Pomodoros can be used to work intensely toward a
        goal for a <strong>set duration of uninterrupted time</strong>. Blocking off your calendar for multiple
        pomodoros is an ideal approach to making sure you have enough time and focus to achieve major milestones.
      </p>
      <br />
      <figure>
        <img src="https://miro.medium.com/max/720/1*brpW3ljBaN34guavoGqoIA.jpeg" alt="woman in front of chalk board" />
        <figcaption>
          Photo by{" "}
          <a
            href="https://www.pexels.com/@max-fischer?utm_content=attributionCopyText&amp;utm_medium=referral&amp;utm_source=pexels"
            target="_blank"
            rel="noreferrer"
          >
            Max Fischer
          </a>{" "}
          from{" "}
          <a
            href="https://www.pexels.com/photo/woman-in-brown-blazer-wearing-sunglasses-5212325/?utm_content=attributionCopyText&amp;utm_medium=referral&amp;utm_source=pexels"
            target="_blank"
            rel="noreferrer"
          >
            Pexels
          </a>
        </figcaption>
      </figure>
      <h2 id={contents[2].anchor}>{contents[2].text}</h2>
      <p>
        The pomodoro technique is a time management method that breaks work into focused intervals, traditionally{" "}
        <strong>25 minutes of work</strong> followed by a <strong>5 minute break</strong>.
      </p>
      <p>
        The technique can be adapted to intervals of <strong>any length</strong> that are{" "}
        <strong>productive for you</strong>. The important thing is that you're deciding{" "}
        <strong>how long you want to work</strong> on your specific task and{" "}
        <strong>how long you want to step away</strong> from your work to recharge.
      </p>{" "}
      <p>
        Some <strong>variations</strong> of the traditional pomodoro interval include: 32 minutes of work followed by 8
        minutes of break, 50 minutes of work followed by 10 minutes of break, and 75 minutes of work followed by 15
        minutes of break. As the focus time increases, so does the break time in order to allow for adequate rest and
        recovery. This helps to prevent burnout and maintain productivity over the long haul.
      </p>
      <p>
        After three sessions of work, it's recommended to take a longer break, such as a{" "}
        <strong>15 minute break</strong> if you're doing 25 minute work sessions or a <strong>30 minute break</strong>{" "}
        if doing 50 minute work sessions. This longer break serves as a more{" "}
        <strong>complete mental and physical reset</strong>.
      </p>
      <p>
        This opportunity to recharge gives you extra time to finish a small personal task or really get into a truly
        relaxing activity. The long break can serve as something to look forward to before you get back into the cycle
        of three working sessions with short breaks followed by a fourth working session then another long break. We
        recommend repeating the metacycle (i.e., four work sessions followed by the longer break) throughout the day to{" "}
        <strong>keep track of time</strong> and <strong>maintain a high level of energy and concentration</strong> while
        working.
      </p>
      <h2 id={contents[3].anchor}>{contents[3].text}</h2>
      <p>
        The breaks during the pomodoro technique can be used for any activity that is 1){" "}
        <strong>substantively different</strong> from the task you're working on, and 2) leaves you feeling{" "}
        <strong>refreshed and rejuvenated</strong>. Some examples may include taking a walk, practicing a deep breathing
        technique, reading a book, or making a cup of tea.
      </p>
      <p>
        Whatever you're doing, try to encourage yourself to fully detach from work. For example, if your task involves
        sitting in front of a computer, taking a break to do some stretching or yoga would be more beneficial than
        continuing to stare at a screen while checking social media or looking at cat photos. If your task is studying a
        textbook like the{" "}
        <a href="http://amzn.to/32oK8hH" target="_blank" rel="noopener noreferrer">
          <i>DMBOK</i>
        </a>
        , don't pick up a different book, even a fun one, during your break.
      </p>
      <p>
        By experimenting with <strong>many different kinds of activities</strong> during your breaks, you can learn
        which ones increase your focus and creativity.
      </p>
      <br />
      <figure>
        <img src={alarm} alt="alarm clock" />
        <figcaption>
          Photo by{" "}
          <a href="https://www.pexels.com/@koolshooters/" target="_blank" rel="noopener noreferrer">
            KoolShooters
          </a>{" "}
          on{" "}
          <a
            href="https://www.pexels.com/photo/yellow-alarm-clock-on-yellow-surface-6976596/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pexels
          </a>
        </figcaption>
      </figure>
      <h2 id={contents[4].anchor}>{contents[4].text}</h2>
      <p>Setting up a kitchen timer could be enough to get started with the pomodoro method.</p>
      <p>
        Alternatively, many <strong>web-based</strong> pomodoro timers are available if you find yourself able to use
        one without getting distracted by the internet. Some options, such as{" "}
        <a href="http://pomofocus.io" target="_blank" rel="noopener noreferrer">
          Pomofocus
        </a>
        , will assist you in keeping track of your tasks. Others, like the{" "}
        <a href="http://studywithme.io/aesthetic-pomodoro-timer/" target="_blank" rel="noopener noreferrer">
          Aesthetic Pomodoro Timer
        </a>
        , offer a lovely backdrop and music player.
      </p>
      <p>
        If you're running an online session with a <strong>study group</strong>, you might find{" "}
        <a href="http://cuckoo.team" target="_blank" rel="noopener noreferrer">
          Cuckoo
        </a>
        , a pomodoro timer for remote teams, to be extremely useful for keeping everyone on the same page.
      </p>
      <p>
        And then there's{" "}
        <a href="https://www.focusmate.com/" target="_blank" rel="noopener noreferrer">
          Focusmate
        </a>
        , which is our favorite. It connects you with a study partner anywhere in the world for a motivating deep work
        session any time you need one.
      </p>
      <br />
      <figure>
        <a href="https://www.focusmate.com/" target="_blank" rel="noopener noreferrer">
          <img src={focusmate} alt="focusmate logo" />
        </a>
        <figcaption>
          Get more done with less stress on{" "}
          <a href="https://www.focusmate.com/" target="_blank" rel="noopener noreferrer">
            Focusmate
          </a>{" "}
        </figcaption>
      </figure>
      <br />
      <p>
        Focusmate is an <strong>easy-to-use platform</strong> that goes beyond the standard pomodoro timer functionality
        by providing <strong>accountability and comradery</strong> in every working session.
      </p>
      <p>
        You can opt for a session lasting either 25, 50, or 75 minutes, and then Focusmate will pair you in a{" "}
        <strong>video call</strong> with another person <strong>anywhere in the world</strong>. Each session starts at
        the fifteen minute mark (i.e., when the clock shows :00, :15, :30, or :45) and begins with a quick checkin, in
        which participants state their objectives for the session.
      </p>
      <p>
        The checkin is followed by the <strong>deep work</strong> portion of a traditional pomodoro. Users have the
        option of <strong>sharing their screen</strong> for added accountability and there is also a{" "}
        <strong>chat</strong> feature that can be used to unobtrusively share progress updates. Both partners work
        independently until the timer goes off. Then the participants check in again at the end to share their
        achievements from the work interval.
      </p>
      <p>
        By adding a <strong>social aspect</strong> to the beginning and end of the pomodoro session, Focusmate takes the
        process of getting things done and makes it significantly more motivating. This helps people stay accountable to
        their study goals and achieve them.
      </p>
      <h2 id={contents[5].anchor}>{contents[5].text}</h2>
      <p>
        Focusmate provides you with an <strong>accountability partner and study buddy</strong> whenever you need one.
        The presence of another person can help to keep you motivated and focused on your task. This concept is formally
        referred to as "<strong>body doubling</strong>."
      </p>
      <p>Working in the company of someone else kicks off five work-oriented behavioral triggers:</p>
      <ol>
        <li>Task implementation</li>
        <li>Social pressure</li>
        <li>Accountability</li>
        <li>Specific task direction</li>
        <li>Activation of productivity neurotransmitters</li>
      </ol>
      <p>
        While this approach can be especially beneficial for individuals with ADHD, body doubling is helpful for all of
        us as a{" "}
        <a href="https://add.org/the-body-double/" target="_blank" rel="noopener noreferrer">
          proven method
        </a>{" "}
        for improving effectiveness and promoting a sense of ease during difficult work. Your Focusmate partner offers a
        reminder to stay focused and not to disengage from the task at hand.
      </p>
      <p>
        Within groups, Focusmate allows you to connect with others in a similar field. You'll be astonished by the
        things you pick up during checkin conversations. Particularly when you've been paired with someone from one of
        your Focusmate groups, hearing the tasks your partner has planned for their work session can{" "}
        <strong>inspire new approaches</strong> to your own work. And though it's not the primary focus of the site,
        Focusmate can be an incredible place to get a fresh perspective if you use your breaks for conversation with
        your partner.
      </p>
      <p>
        Also cool — doing pomodoros on the platform gives you the opportunity to{" "}
        <strong>meet people from all over the world</strong>, particularly if you're joining early or late in your day.
      </p>
      <br />
      <figure>
        <img src={tomato} alt="tomato" />
        <figcaption>
          Photo by{" "}
          <a href="https://www.pexels.com/@pixabay/" target="_blank" rel="noopener noreferrer">
            Pixabay
          </a>{" "}
          on{" "}
          <a
            href="https://www.pexels.com/photo/food-table-tomatoes-vegetables-209401/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pexels
          </a>
        </figcaption>
      </figure>
      <h2 id={contents[6].anchor}>{contents[6].text}</h2>
      <p>
        We love the Pomodoro technique as a way to get more done with less stress. Implementing blocks of focused work
        can be a <strong>game changer</strong> for those of us who have demanding jobs that require oversight of many
        projects. As a knowledge worker, you need to task switch effectively, store a lot of information, and achieve
        ambitious goals. The pomodoro method is extremely effective for those looking to improve their productivity and
        reach their goals.
      </p>
      <p>
        If you're currently studying for the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        , you may be interested in using this{" "}
        <a
          href="https://www.youtube.com/playlist?list=PLGd_Hl6NWLZe1Jtz8oG0ZJ7lDzdgOGZn5"
          target="_blank"
          rel="noopener noreferrer"
        >
          playlist
        </a>{" "}
        of pomodoro sessions to study. If you would like to study with your peers, check out our{" "}
        <Link to="/events" target="_blank">
          upcoming events
        </Link>
        .
      </p>
    </>
  );
};
