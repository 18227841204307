import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import OurProcess from "./OurProcess";
import { Coaches } from "../../../components/product-pages/Coaches";

import MultiPriceProductPage from "../../../components/product-pages/MultiPriceProductPage";

const PRODUCT = "Career Coaching";

const CareerCoaching = () => {
  const desktop = useMediaQuery("(min-width: 640px)");
  return <MultiPriceProductPage PRODUCT={PRODUCT} tealBox={tealBox} rest={rest(desktop)} />;
};

export default CareerCoaching;

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>Results</h4>
    <div className="blog">
      <p>Each Career Coaching session results in a document with the following sections:</p>
      <p>
        <strong>Goals</strong> — outlines our understanding of your unique career goals and other relevant information
      </p>
      <p>
        <strong>Action items</strong> — describes the specific steps you can take to level up your career
      </p>
      <p>
        <strong>Recommended qualifications</strong> — list of recommended skills and credentials relevant to the roles
        you're seeking
      </p>
      <p>
        <strong>Jobs near you</strong> and/or <strong>remote jobs</strong> — list of available roles that match your
        specifications (<em>as needed</em>)
      </p>
    </div>
  </div>
);

const rest = (desktop) => {
  return (
    <>
      <div
        className="white-outline-box"
        style={{
          maxWidth: "1400px",
          padding: "30px 40px",
          margin: "40px auto",
        }}
      >
        <div style={{ maxWidth: 1200, margin: "auto" }}>
          <p>
            Career Coaching can offer tremendous benefit <strong>before</strong> you take a{" "}
            <Link to="/landing/best-certifications" target="_blank">
              certification exam
            </Link>{" "}
            or <strong>afterward</strong>. You'll be working together with your coach to develop a plan to leverage your
            credentials for maximum benefit in your career. If you have any <strong>questions</strong> or you're
            interested in booking a <strong>free 15 minute intro session</strong>, send us an email at{" "}
            <a href="mailto:coaching@datastrategypros.com?subject=Career%20Coaching%20inquiry">
              coaching@datastrategypros.com
            </a>
            .
          </p>
          <br />
          <p>
            Our objective is to help every Data Strategist find their ideal role. Whether you're seeking a position in
            Data Stewardship, Data Architecture, Business Analytics, or the C-Suite, we can help you achieve success on
            your unique career path.{" "}
            <a href="mailto:coaching@datastrategypros.com?subject=Career%20Coaching%20inquiry">Contact us</a> to learn
            more.
          </p>
        </div>
      </div>

      {!desktop && (
        <div className="teal-box" style={{ background: "#efefef", marginBottom: 40 }}>
          <div className="center-text-box">
            <h4 className="box-title">Extended Description</h4>
            <div className="blog">
              <p>
                Are you looking for a <strong>clear pathway</strong> to an impressive <strong> career in data</strong>?
                Looking to <strong>transition</strong> into a data-related job? Preparing for the{" "}
                <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
                  CDMP exam
                </Link>{" "}
                but feeling stuck? Receive guidance from our <strong>expert Career Coach</strong>.
              </p>
              <p>We will help you:</p>
              <ul>
                <li>
                  <strong>Gain confidence</strong> to build your skills and seek out your ideal position
                </li>
                <li>
                  Leverage best practices to grow your organization's <strong>Data Management capabilities</strong> and
                  become recognized as a leader in your work
                </li>
                <li>
                  <strong>Prepare for certifications</strong> with ease, resolving any doubts or uncertainties you may
                  hold
                </li>
              </ul>
              <p>
                Our Career Coaches are expert data practitioners that can offer friendly guidance on any of the
                challenges you're facing. <strong>Whether you're a newbie</strong> looking to land your first job, a{" "}
                <strong>career switcher</strong> trying to figure out if a data career is right for you or an{" "}
                <strong>experienced practitioner</strong> trying to solve tough Data Management challenges, we look
                forward to helping you!
              </p>
            </div>
          </div>
        </div>
      )}

      <OurProcess />
      <Coaches />
    </>
  );
};
