type mediaElement = {
  date: number;
  type: "🎧" | "📖" | "🎥";
  title: string;
  source: string;
  link: string;
};

const media: mediaElement[] = [
  {
    date: 45298,
    type: "🎧",
    title: "From Data Scientist to CEO",
    source: "Women Lead Data Podcast",
    link: "https://www.secoda.co/women-lead-data/episode-3-nicole-janeway-bills",
  },
  {
    date: 45238,
    type: "📖",
    title: "DAMA Days Toronto 2023: Recap",
    source: "Secoda",
    link: "https://www.linkedin.com/pulse/dama-days-toronto-2023-recap-secodahq-whahc/?trk=public_post_main-feed-card_reshare_feed-article-content",
  },
  {
    date: 45057,
    type: "🎧",
    title: "Trends in Data Management and the study approach for Data Certifications",
    source: "Data Perspectives Podcast",
    link: "https://nicolejaneway.medium.com/trends-in-data-management-d0288235e9f",
  },
  {
    date: 44987,
    type: "📖",
    title: "CDMP 'Data Fundamentals Exam'​: tip to help you study and how I use the framework in my work",
    source: "Stephanie Wagenaar",
    link: "https://www.linkedin.com/pulse/cdmp-data-fundamentals-exam-pro-tip-how-i-use-my-work-wagenaar/",
  },
  {
    date: 44983,
    type: "📖",
    title: "How I Passed the CDMP (Certified Data Management Professional)",
    source: "Giuseppe Trivigno",
    link: "https://www.linkedin.com/pulse/how-i-passed-cdmp-certified-data-management-less-than-trivigno/",
  },
  {
    date: 44977,
    type: "📖",
    title: "Ten Books to Expand Your Worldview as a Data Professional",
    source: "Data Science Talent",
    link: "https://issuu.com/datasciencetalent/docs/the_data_scientist_mag_issue_2_digital_copy_for_is",
  },
  {
    date: 44901,
    type: "🎧",
    title: "A Funny Thing Happened on the Way to Chicago",
    source: "Raw Data Podcast",
    link: "https://p3adaptive.com/rawdatapodcast/episode/a-funny-thing-happened-on-the-way-to-chicago-w-nicole-janeway-bills/",
  },
  {
    date: 44727,
    type: "🎧",
    title: "Data Scientist Profits from Viral Article",
    source: "Side Hustle School",
    link: "https://sidehustleschool.com/episode/1984/",
  },
  {
    date: 44573,
    type: "🎥",
    title: "Jan 2022 Meetup: Foundations of Data Strategy",
    source: "Data Science DC",
    link: "https://youtu.be/fDCNCLkiIyg",
  },
  {
    date: 44568,
    type: "📖",
    title: "How I passed my CDMP ‘Data Fundamentals' exam: Tips & Tricks",
    source: "BOLD.digital - FTI-Bold/FTI consulting",
    link: "https://www.linkedin.com/pulse/how-i-passed-my-cdmp-data-fundamentals-exam-tips-tricks-/",
  },
  {
    date: 44365,
    type: "🎥",
    title: "Data in Government (DiG) panel discussion and lightning talks",
    source: "Data Science DC",
    link: "https://www.youtube.com/live/kpRLT_tRinM",
  },
  {
    date: 44211,
    type: "🎧",
    title: "Helping the Government Work Better with AI",
    source: "So You Want to Be a Data Scientist Podcast",
    link: "https://youtu.be/ia5eezyk9H4",
  },
  {
    date: 43935,
    type: "🎥",
    title: "Walkthrough: Mapping GIS Data in Python",
    source: "Towards Data Science",
    link: "https://youtu.be/b9G2T4CPYVM",
  },
];

export default media;
