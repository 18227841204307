import { Paid, Work, Star } from "@mui/icons-material";

const styles = {
  text: {
    marginBottom: 10,
  },
  icon: {
    position: "relative",
    left: -20,
    top: 4,
  },
};

export default function HeroBullets({ desktop }) {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "7.5%",
        left: "10%",
        width: "100%",
      }}
    >
      <p
        style={{
          fontSize: "1.4em",
          marginTop: 20,
          marginLeft: desktop ? 20 : 0,
          lineHeight: 1.25,
        }}
      >
        <ul className="homepage">
          <li className="animate" style={{ ...styles.text, animationDelay: ".5s" }}>
            <span style={styles.icon}>
              <Paid />
            </span>
            Boost your salary
          </li>
          <li className="animate" style={{ ...styles.text, animationDelay: "1s" }}>
            <span style={styles.icon}>
              <Work />
            </span>
            Gain confidence in your career
          </li>
          <li className="animate" style={{ ...styles.text, animationDelay: "1.5s" }}>
            <span style={styles.icon}>
              <Star />
            </span>
            Learn best practices to drive value
          </li>
        </ul>
      </p>
    </div>
  );
}
