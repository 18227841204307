import { useState, useEffect, useContext } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from "@mui/material";

import { CheckoutContext } from "../../../contexts/CheckoutContext";

import payments from "../../../data/payments";
import products from "../../../data/products";

import { formatCurrency } from "../../../helpers/utils";
import {
  updateConfirmationTableWithSelection,
  getTotalAmt,
  getTaxAmt,
  getShippingAmt,
} from "../../../helpers/checkoutUtils";

const ConfirmationTable: React.FC<{ activeRows?: number; step: number }> = ({ activeRows = 5, step }) => {
  const [taxAmt, setTaxAmt] = useState(0);
  const [shippingAmt, setShippingAmt] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);

  const checkoutContext = useContext(CheckoutContext);
  const { confirmationTable, setConfirmationTable } = checkoutContext.checkout;

  const handleChange = (e, paymentName, dropdownIndex) => {
    const payment = payments.find((payment) => payment.name === paymentName);
    if (confirmationTable) {
      updateConfirmationTableWithSelection(e.target.value, payment, dropdownIndex, checkoutContext);
    }
  };

  const handleCheckboxChange = (paymentName) => {
    const updatedConfirmationTable = confirmationTable.map((row) => {
      if (row.paymentName === paymentName) {
        return {
          ...row,
          selected: !row.selected,
        };
      }
      return row;
    });

    setConfirmationTable(updatedConfirmationTable);
  };

  useEffect(() => {
    if (confirmationTable) {
      setTaxAmt(getTaxAmt(confirmationTable.filter((row) => row.selected)));
      setShippingAmt(getShippingAmt(confirmationTable.filter((row) => row.selected)));
      setTotalAmt(getTotalAmt(confirmationTable.filter((row) => row.selected)));
    }
  }, [confirmationTable]);

  const input = step === 1 ? confirmationTable.slice(1, activeRows) : confirmationTable.filter((row) => row.selected);

  return (
    <>
      {confirmationTable && (
        <TableContainer style={{ background: "white", border: "1px solid #c1c1c1" }}>
          <Table aria-label="simple table">
            {step === 1 && (
              <TableHead>
                <TableRow>
                  {step === 1 && <TableCell></TableCell>}
                  <TableCell>
                    <p style={{ color: "#4a4a4a", fontWeight: 800, fontSize: "1.1em" }}>Product description</p>
                  </TableCell>
                  <TableCell align="right" sx={{ width: 100 }}>
                    <p style={{ color: "#4a4a4a", fontWeight: 800, fontSize: "1.1em", textAlign: "right" }}>Price</p>
                  </TableCell>
                  {step === 1 && (
                    <TableCell>
                      <p
                        style={{
                          textAlign: "center",
                          lineHeight: 0.9,
                          color: "#4a4a4a",
                          fontWeight: 800,
                          fontSize: "1.1em",
                        }}
                      >
                        Add to order
                      </p>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {input.map((row) => {
                const payment = payments.find((payment) => payment.name === row.paymentName);
                const product = products.find((product) => product.name === payment.product);

                let options;

                if (row.selectedOptions.length === 1) {
                  options = row.selectedOptions[0].displayText;
                } else if (row.selectedOptions.length > 1) {
                  options = row.selectedOptions.map((option) => option.displayText).join(", ");
                }

                if (row.selectedOptions.length > 2) {
                  options = options.toLowerCase();
                }

                return (
                  <TableRow
                    key={row.paymentName}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      background: row.selected ? "inherit" : "#f9f9f9",
                    }}
                  >
                    {step === 1 && (
                      <TableCell sx={{ minWidth: 100 }}>
                        <img
                          src={product.img}
                          alt={product.alt}
                          style={{
                            width: 160,
                            objectFit: "cover",
                            filter: row.selected ? "saturate(1)" : "saturate(0.5)",
                          }}
                        />
                      </TableCell>
                    )}
                    <TableCell style={{ verticalAlign: "top" }}>
                      <p>
                        <span
                          style={{
                            fontWeight: 800,
                            marginBottom: payment.options[0]?.content.length > 1 ? 0 : 4,
                            color: "#4a4a4a",
                          }}
                        >
                          {product.name}
                        </span>
                        {step !== 1 && (
                          <>
                            {payment.options[0].content.find((opt) => opt.displayText) && (
                              <span> {` (${options})`}</span>
                            )}
                            {row.metadata.count > 1 && ` (${row.metadata.count} ${row.metadata.unit})`}
                          </>
                        )}
                      </p>

                      {step === 1 && payment.options[0]?.content.length > 1 && (
                        <form style={{ marginBottom: 4 }}>
                          <label htmlFor="options" style={{ display: "flex", gap: 4 }}>
                            <p style={{ fontStyle: "italic" }}>Options:</p>
                            {payment.options.map((optionGroup, idx) => (
                              <select
                                key={idx}
                                name={optionGroup.optionsLabel}
                                style={{ width: payment.options.length > 2 ? 110 : 140 }}
                                value={row.selectedOptions[idx].label}
                                onChange={(e) => handleChange(e, row.paymentName, idx)}
                              >
                                {optionGroup.content.map((opt) => {
                                  const isDisabled =
                                    payment.options.length > 1 &&
                                    row.selectedOptions[(idx + 1) % 2].label === opt.label;
                                  return (
                                    <option key={opt.label} value={opt.label} disabled={isDisabled}>
                                      {opt.displayText}
                                    </option>
                                  );
                                })}
                              </select>
                            ))}
                          </label>
                        </form>
                      )}
                      {step === 1 && (
                        <p style={{ color: "#4a4a4a" }} dangerouslySetInnerHTML={{ __html: product.description }} />
                      )}
                    </TableCell>
                    <TableCell align="right" style={{ textAlign: "right" }}>
                      <p style={{ color: row.selected ? "#4a4a4a" : "#959393" }}>
                        {formatCurrency(row.price * row.metadata.count)}
                      </p>
                      {row?.metadata?.discountAmount > 0 && (
                        <p style={{ color: "#959393", fontSize: "0.95em" }}>
                          - {formatCurrency(row.metadata.discountAmount)}
                        </p>
                      )}
                    </TableCell>
                    {step === 1 && (
                      <TableCell align="center" sx={{ width: 100 }}>
                        <Checkbox
                          disableRipple
                          sx={{
                            color: "#a2a1a1",
                            backgroundColor: "transparent",
                            marginLeft: "25%",
                            "&:hover": {
                              backgroundColor: "transparent !important",
                            },
                          }}
                          onChange={() => {
                            handleCheckboxChange(row.paymentName);
                          }}
                          checked={row.selected}
                          inputProps={{ "aria-label": "additional product" }}
                          color="default"
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {step !== 1 && (
                <>
                  {confirmationTable.filter((row) => row.selected).some((row) => row.type === "physical") && (
                    <>
                      <TableRow>
                        <TableCell sx={{ textAlign: "end !important", color: "#4a4a4a", padding: "4px 16px" }}>
                          Taxes:
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "end !important",
                            color: "#4a4a4a",
                            padding: "4px 16px",
                          }}
                        >
                          {formatCurrency(taxAmt)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ textAlign: "end !important", color: "#4a4a4a", padding: "4px 16px" }}>
                          Shipping:
                        </TableCell>
                        <TableCell sx={{ textAlign: "end !important", color: "#4a4a4a", padding: "4px 16px" }}>
                          {formatCurrency(shippingAmt)}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  <TableRow>
                    <TableCell
                      sx={{ textAlign: "end !important", fontWeight: 800, color: "#4a4a4a", padding: "8px 16px" }}
                    >
                      Total:
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "end !important", fontWeight: 800, color: "#4a4a4a", padding: "8px 16px" }}
                    >
                      {formatCurrency(totalAmt)}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ConfirmationTable;
