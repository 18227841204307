import { useMediaQuery } from "@mui/material";
import { formatDate } from "../../helpers/utils";

import InfoTooltip from "../../components/InfoTooltip";
import EventDescription from "./EventDescription";

import eventTypes from "../../data/eventTypes";

const EventCard: React.VFC<{ eventObj: eventDetails }> = ({ eventObj }) => {
  const desktop = useMediaQuery("(min-width: 640px)");
  const eventType = eventTypes.find((typeObj) => typeObj.type === eventObj.type);

  return (
    <div className="card about-card" style={{ boxShadow: "none", padding: 16 }}>
      <img
        src={eventObj.image}
        alt="event"
        style={{ cursor: "pointer", minHeight: 215, objectFit: "cover" }}
        onClick={() => window.open(eventObj.link, "_blank")}
      />

      <div style={{ marginLeft: desktop ? 16 : 0 }}>
        <p
          className="title card-title"
          style={{ marginTop: 0, marginBottom: 0 }}
          dangerouslySetInnerHTML={{
            __html: eventObj.name,
          }}
        />
        <div style={{ marginTop: 4 }}>
          {eventObj.start.getDate() === eventObj.end.getDate() ? (
            <p>
              <em>Date</em>:&nbsp;{" "}
              {eventObj.start.toLocaleDateString("en-us", {
                month: "short",
                day: "numeric",
              })}{" "}
              at {formatDate(eventObj.start)} ET
            </p>
          ) : (
            <p>
              {eventObj.start.toLocaleDateString("en-us", {
                month: "short",
                day: "numeric",
              })}{" "}
              -{" "}
              {eventObj.end.toLocaleDateString("en-us", {
                month: "short",
                day: "numeric",
              })}
            </p>
          )}
          <p>
            <span>
              <em>Location</em>:&nbsp;&nbsp;
            </span>
            <span>
              <span dangerouslySetInnerHTML={{ __html: eventType.location ?? eventObj.location }} />
              <InfoTooltip text={eventType.hoverText ?? eventObj.hoverText} placement="top-start" />
            </span>
          </p>

          <EventDescription eventObj={eventObj} eventsPage={false} />
          <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: 16 }}>
            <button
              className="btn btn-accent"
              style={{ minWidth: 200 }}
              onClick={() =>
                // @ts-ignore
                window.open(eventObj.link, "_blank")
              }
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
