import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { Fade } from "react-awesome-reveal";

import CDMPHero from "./hero/CDMPHero";
import CDMPCommunity from "./CDMPCommunity";
import TrustedByYourPeersAt from "./TrustedByYourPeersAt";
import BestSellers from "./BestSellers";
import CDMPJourneys from "./CDMPJourneys";
import CDMPTestimonials from "./CDMPTestimonials";
import MoreTestimonials from "./MoreTestimonials";
import CDMPResources from "./CDMPResources";

import CustomHelmet from "../../../components/CustomHelmet";

export default function CDMPLanding() {
  const desktop = useMediaQuery("(min-width:960px)");
  const [scroll, setScroll] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScroll(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div style={{ background: "#e9eff1", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <CustomHelmet
        description="Training for data practitioners and CDMP exam takers."
        name="Data Strategy Professionals"
        link=""
        img="https://www.nicolejaneway.com/wp-content/uploads/2021/04/diego-ph-fIq0tET6llw-unsplash_square.jpg"
        alt="hand holding lightbulb"
      />
      <div style={{ width: "100%" }}>
        <CDMPHero scroll={scroll} />
      </div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            margin: "auto",
            maxWidth: 2000,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: desktop ? 60 : 40,
              marginTop: desktop ? 60 : 40,
              marginBottom: desktop ? 60 : 40,
            }}
          >
            <BestSellers />
            <Fade duration={2000}>
              <CDMPTestimonials />
            </Fade>
          </div>

          <Fade duration={2000}>
            <CDMPJourneys />
          </Fade>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: desktop ? 60 : 40,
              marginTop: desktop ? 60 : 40,
              marginBottom: desktop ? 60 : 40,
            }}
          >
            <Fade duration={2000}>
              <CDMPResources />
            </Fade>
            <Fade duration={2000}>
              <CDMPCommunity />
            </Fade>
            <Fade duration={2000}>
              <TrustedByYourPeersAt />
            </Fade>
            <Fade duration={2000}>
              <MoreTestimonials />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}
