import { useState } from "react";

import { Link } from "react-router-dom";

import Post from "../components/blog/Post";
import PostImage from "../components/blog/PostImage";
import Contents from "../components/blog/Contents";
import RelatedPostsCarousel from "../components/blog/related-posts-carousel/RelatedPostsCarousel";

import CDMPLevelsModal from "../components/CDMPLevelsModal";

import dcam from "../img/blog/dcam.webp";

const POSTNAME = "dcam";

const DCAM: React.VFC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default DCAM;

const Rest = () => {
  const [showModal, setShowModal] = useState(false);

  const contents: Content[] = [
    {
      anchor: "cdmp",
      text: "What is the CDMP?",
    },
    {
      anchor: "dcam",
      text: "What is DCAM?",
    },
    {
      anchor: "comparison",
      text: "Which Should You Choose?",
    },
  ];

  return (
    <>
      {showModal && <CDMPLevelsModal setShowModal={setShowModal} />}

      <PostImage postName={POSTNAME} />

      <p>
        Individuals and organizations need to ensure that their Data Management practices are solid. Certifications such
        as the{" "}
        <strong>
          Certified Data Management Professional (
          <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
            CDMP
          </Link>
          )
        </strong>{" "}
        and the{" "}
        <strong>
          Data Management Capability Assessment Model (
          <a href="https://edmcouncil.org/frameworks/dcam/" target="_blank" rel="noreferrer">
            DCAM
          </a>
          )
        </strong>{" "}
        provide a clear standard for knowledge related to this crucial field. These two certifications serve different
        purposes, so in this post, we'll break down the key differences between CDMP and DCAM, helping you decide which
        is the better fit for your career goals.
      </p>

      <Contents contents={contents} />

      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <p>
        The{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank" rel="noreferrer">
          Certified Data Management Professional
        </Link>
        , offered by{" "}
        <a href="https://www.dama.org/cpages/home" target="_blank">
          DAMA International
        </a>
        , is a globally recognized accreditation aimed at individuals who want to demonstrate their proficiency in data
        management disciplines. The certification focuses on core areas such as:{" "}
        <Link to="/resources/foundations-of-data-strategy#data-governance" target="_blank">
          Data Governance
        </Link>
        ,{" "}
        <Link to="/resources/foundations-of-data-strategy#data-architecture" target="_blank">
          Data Architecture
        </Link>
        ,{" "}
        <Link to="/resources/foundations-of-data-strategy#data-modeling" target="_blank">
          Data Modeling
        </Link>
        ,{" "}
        <Link to="/resources?topic=data-privacy" target="_blank">
          Data Privacy
        </Link>
        , etc.
      </p>

      <p>
        The CDMP accreditation is divided into three levels:{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          Associate
        </span>{" "}
        (60-69% on{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          Fundamentals Exam
        </Link>
        ),{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          Practitioner
        </span>{" "}
        (70%+ on{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          Fundamentals
        </Link>{" "}
        and two{" "}
        <Link to="/resources/cdmp-specialist-exam" target="_blank">
          Specialist Exams
        </Link>
        ), and{" "}
        <span className="fake-link-1" onClick={() => setShowModal(true)}>
          Master
        </span>{" "}
        (80%+ on{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          Fundamentals
        </Link>{" "}
        and two{" "}
        <Link to="/resources/cdmp-specialist-exam" target="_blank">
          Specialist Exams
        </Link>
        ). Each level is representative of deeper understanding and expertise in Data Management principles.
      </p>
      <p>
        The certification is based on the contents of the{" "}
        <strong>
          <em>Data Management Body of Knowledge</em> (
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>
          )
        </strong>
        , which ensures a solid foundation in both theoretical and practical aspects of the field.
      </p>
      <p>
        The CDMP is a good choice for early career professionals looking to gain a <strong>broad understanding</strong>{" "}
        of data-related fields. For experienced Data Management practitioners looking to advance their careers or
        validate their expertise, proceeding to the Practitioner- or Master-level certification by passing two{" "}
        <Link to="/resources/cdmp-specialist-exam" target="_blank">
          Specialist Exams
        </Link>{" "}
        is an excellent choice.
      </p>
      <RelatedPostsCarousel
        postNicknames={[
          "cdmp",
          "cdmp-training",
          "cdmp-practice-questions",
          "data-strategy",
          "best-practices",
          "steward",
        ]}
        conclusion={false}
      />

      <h2 id={contents[1].anchor}>{contents[1].text}</h2>
      <p>
        The{" "}
        <a href="https://edmcouncil.org/frameworks/dcam/" target="_blank" rel="noreferrer">
          Data Management Capability Assessment Model
        </a>{" "}
        certification, offered by{" "}
        <a href="https://edmcouncil.org/" target="_blank" rel="noreferrer">
          EDM Council
        </a>
        , signifies that a data practitioner is equipped to assess and enhance an organization's data management
        capabilities using{" "}
        <Link to="/resources/data-management-maturity-assessment-dmma#dcam" target="_blank">
          DCAM
        </Link>{" "}
        as a{" "}
        <Link to="/resources/data-management-maturity-assessment-dmma" target="_blank">
          Data Management Maturity Assessment
        </Link>
        .
      </p>
      <p>
        This framework helps a data practitioner evaluate how well the organization adheres to industry standards in
        areas such as: Data Governance, Data Architecture, Data Security, and Data Privacy. By regularly benchmarking
        progress toward alignment with best practices, an organization can improve its data management processes,
        mitigate risk, and ensure compliance with regulations.
      </p>
      <p>
        DCAM is ideal for data practitioners interested in <strong>evaluating and enhancing</strong> overall data
        management strategies. It may be an especially good fit for data professionals working in the{" "}
        <strong>finance industry</strong>, given the background and focus of EDM Council.
      </p>

      <figure>
        <img
          src={dcam}
          alt="dcam diagram"
          style={{ background: "white", cursor: "pointer" }}
          onClick={() =>
            window.open("https://www.datastrategypros.com/resources/data-management-maturity-assessment-dmma#dcam")
          }
        />
        <figcaption>
          <Link to="/resources/data-management-maturity-assessment-dmma#dcam" target="_blank">
            DCAM diagram
          </Link>{" "}
          from{" "}
          <a href="https://edmcouncil.org/" target="_blank" rel="noreferrer">
            EDM Council
          </a>
        </figcaption>
      </figure>

      <h2 id={contents[2].anchor}>{contents[2].text}</h2>
      <p>
        The decision between pursuing CDMP or DCAM accreditation largely depends on whether you're looking to build a
        broad foundation of data-related knowledge or focus specifically on learning EDM Council's methodology for
        evaluating and improving an organization's data management capabilities.
      </p>
      <p>
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP
        </Link>{" "}
        is helpful for professionals who want to solidify their understanding of a range of data management disciplines.
        The CDMP offers an excellent pathway to grow your career in data management. The three tiers each provide an
        engaging challenge for entry level, intermediate, and advanced data practitioners.
      </p>
      <p>
        <a href="https://edmcouncil.org/frameworks/dcam/" target="_blank" rel="noreferrer">
          DCAM
        </a>{" "}
        is useful for data practitioners and consultants looking to better understand{" "}
        <Link to="/resources/data-management-maturity-assessment-dmma">data management maturity</Link> and ensure
        alignment with industry standards.
      </p>

      <p>
        Both <a href="#cdmp">CDMP</a> and <a href="#dcam">DCAM</a> are highly respected certifications, but they cater
        to different objectives. By understanding key differences between these two well-respected{" "}
        <Link to="/landing/best-certifications" target="_blank">
          data-related certifications
        </Link>
        , you can make an informed decision that aligns with your personal and professional goals. If you still have
        questions about which option is best for your unique situation, you may be interested in speaking with one of
        our expert{" "}
        <Link to="/products/career-coaching" target="_blank">
          Career Coaches
        </Link>
        .
      </p>
    </>
  );
};
