const cdmpPracticeQuestions: Question[] = [
  {
    id: "de_7",
    question:
      "Which of the following is one of the ethical principles for data handling as adapted from the Belmont Principles?",
    options: ["Honesty", "Transparency", "Integrity", "Respect for persons", "Fairness"],
    correctAnswer: 3,
    explanation: `Respect for Persons, Justice, and Beneficence form the Belmont Principles, which
    were developed by the US Department of Health and Human Services in 1979 to guide the ethics of medical
    research. They are also applicable as guiding principles within the field of Data Management (ch. 2, section
    3.1 Ethical Principles for Data).`,
  },
  {
    id: "da_18",
    question: `Which of the following options is NOT an accurate characterization of Data Architecture?`,
    options: [
      "A description of the current state and components of a system",
      "The team behind the design of the state of a system",
      "The future state of proposed design of the system",
      "The design of a system",
      "The steps involved in designing a system",
    ],
    correctAnswer: 4,
    explanation: `Data Architecture is a broad term that covers the design, documentation, and adoption of a system. While Data Architecture is suitable for referring to the design of a system and the team behind it, the steps involved in designing the system are typically not the responsibility of the Data Architect (ch. 4, section 1 Introduction).`,
  },
  {
    id: "dmd_1",
    question: `Which of the following is true of a recursive relationship?`,
    options: [
      "It is unary",
      "It is also referred to as self-referencing",
      "It involves only one entity",
      "All of the above",
      "None of the above",
    ],
    correctAnswer: 3,
    explanation: `A recursive relationship could be used to represent a hierarchy or a network where only one entity is involved (for example, the relationship between a course and prerequisites, which are themselves courses) (ch. 5, section 1.3.3.2.4.1 Unary (Recursive) Relationship).`,
  },
  {
    id: "mm_72",
    question: `What is the intention of ISO's Metadata Registry Standard, ISO/IEC 11179?`,
    options: [
      "To enable metadata-driven exchange of data in a heterogeneous environment",
      "To enable effective risk management",
      "To enable effective data lifecycle management",
      "For easy data referencing",
      "To ensure easier data usage",
    ],
    correctAnswer: 0,
    explanation: `The goal of ISO's Metadata Registry Standard, ISO/IEC 11179 is to enable metadata-driven exchange of data in a heterogeneous environment (ch. 12, section 1 Introduction).`,
  },
  {
    id: "dq_11",
    question: `The Shewhart / Deming cycle is also known as...`,
    options: ["Database cycle", "Data Management", "Content Generation", "Redman Cycle", "Plan-do-check-act"],
    correctAnswer: 4,
    explanation: `Based on the scientific method, the Shewhart / Deming cycle is a problem-solving model known as 'plan-do-check-act' (ch. 13, section 1.3.6 Data Quality Improvement Lifecycle).`,
  },
  {
    id: "dg_sp_00",
    question: `Suppose that in a healthcare organization, the data management practices do not adequately protect patient privacy, leading to a breach of data privacy regulations such as an unauthorized access to patient records by an employee who does not have a legitimate need to view or use this information. How can a data steward address this challenge?`,
    options: [
      "The data steward can collaborate with IT and security teams to implement access controls and user authentication mechanisms to restrict access to patient records based on roles and permissions",
      "The data steward can advocate for the encryption of patient data both at rest and in transit to prevent unauthorized access and protect patient privacy",
      "The data steward can establish audit trails to track and monitor access to patient records",
      "The data steward can develop training programs to educate employees about data privacy regulations, the importance of protecting patient information, and the consequences of non-compliance",
      "All of the above",
    ],
    correctAnswer: 4,
    explanation: `The data steward should take the actions of collaborating with IT and security, advocating for the encryption of patient data, establishing audit trails, and developing training programs.`,
  },
  {
    id: "dmd_5",
    question: `What is the definition of a surrogate key?`,
    options: [
      "A unique metadata element attached to each record in a dataset",
      "A key that links records in a dataset to other data tables",
      "A set of data records that are independent of any other data",
      "A unique identifier attached to each record, which may be used as a primary key",
      "A document that identifies how data is linked to business rules",
    ],
    correctAnswer: 3,
    explanation: `The surrogate key is often used as the primary key. It is typically an alphanumeric string that is incremented per each record and does not have any meaning beyond its usefulness as an identifier (DMBOK ch. 5, section 1.3.3.3.2.2 Function-type Keys).`,
  },
  {
    id: "dii_0",
    question: `Which of the following represents the number of connections in a point-to-point Data Integration system, where n represents the number of databases to be integrated?`,
    options: ["(n -1)!", "(n (n - 1) ) / 2", "(n -1) / 2", "n / 2", "(n -1)2"],
    correctAnswer: 1,
    explanation: `In a data storage system connected using the point-to-point approach, the number of integrations will be the number of unique pairs that can be formed from n items: or "n choose 2" (DMBOK ch. 8, section 1.3.6.1 Point-to-point).`,
  },
  {
    id: "mdm_65",
    question: `Malcolm Chisholm proposed a ________ layer data taxonomy and defines Master Data as an aggregation of ________.`,
    options: [
      "6; reference data, enterprise structure data, and transaction structure data",
      "4; reference data, enterprise structure data, and transaction structure data",
      "6; reference data, metadata, and transaction data",
      "4; reference data, metadata, and transaction data",
      "2; reference data, enterprise structure data, and transaction structure data",
    ],
    correctAnswer: 0,
    explanation: `Malcolm Chisholm proposed a six layer data taxonomy, In this taxonomy, he defines Master Data as an aggregation of reference data, enterprise structure data, and transaction structure data (DMBOK ch. 10, section 1.3.1 Differences Between Master and Reference Data).`,
  },
  {
    id: "dwbi_17",
    question: `Which Data Warehousing model emphasizes a normalized approach for data storage?`,
    options: [
      "Inmon model",
      "Kimball model",
      "Both models",
      "Neither model",
      "It depends on the organization's requirements",
    ],
    correctAnswer: 0,
    explanation: `Normalization is a database design technique that aims to minimize data redundancy and improve data integrity. The Inmon Model promotes normalization to keep data consistent while being flexible enough to allow data to be easily updated (DMBOK ch. 11, section 1.3.5 Corporate Information Factory (Inmon)).`,
  },
  {
    id: "mm_sp_00",
    question: `For a financial institution, which of these business terms can be aligned to the 'asset' data domain?`,
    options: [
      "Transaction Id – a unique identifier assigned to each financial transaction processed by a bank or financial institution.",
      "Loan Portfolio – the collection of loans that a bank has issued to borrowers",
      "Investment Advice – the recommendations and guidance provided by financial advisors or investment professionals to clients regarding their investment decisions",
      "All of the above",
      "None of the above",
    ],
    correctAnswer: 1,
    explanation: `The loan portfolio represents the collection of loans that a bank has issued to borrowers. It is considered an asset and should be managed as part of the respective data domain.`,
  },
  {
    id: "dq_sp_00",
    question: `Which of the following is the mode of the following set of numbers: [11, 11, 20, 28, 33, 50, 52, 87, 91, 97]?`,
    options: ["10", "11", "41.5", "48", "86"],
    correctAnswer: 1,
    explanation: `Mode is a measure of central tendency that reflects the value that appears the most frequently in a dataset.`,
  },
];

export default cdmpPracticeQuestions;
