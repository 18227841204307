import { Link } from "react-router-dom";

import Post from "../components/blog/Post";

const POSTNAME = "cippe-october-23";

export default function CIPPEUpdate() {
  return <Post postName={POSTNAME} rest={<Rest />} />;
}

const Rest = () => {
  return (
    <>
      <p>
        The{" "}
        <strong>
          International Association of Privacy Professionals (
          <a href="https://iapp.org/" target="_blank" rel="noreferrer">
            IAPP
          </a>
          )
        </strong>{" "}
        is the <strong>preeminent source</strong> for training and certification related to Data Privacy. IAPP offers a{" "}
        <a href="https://iapp.org/certify/get-certified/" target="_blank" rel="noreferrer">
          variety of challenging exams
        </a>{" "}
        that allow practitioners to showcase their understanding of Data Protection regulations all around the world. In
        fall 2023, their European-focused exam received an upgrade.
      </p>
      <p>
        Studying for the <strong>Certified Information Privacy Professional/Europe (</strong>
        <a href="https://iapp.org/certify/cippe/" target="_blank" rel="noreferrer">
          <strong>CIPP/E</strong>
        </a>
        <strong>) exam</strong> confers knowledge of regulatory frameworks in Europe, particularly the complex and
        sweeping{" "}
        <strong>
          General Data Protection Regulation (
          <a href="https://gdpr.eu/tag/gdpr/" target="_blank" rel="noreferrer">
            GDPR
          </a>
          )
        </strong>
        .
      </p>
      <figure>
        <img src="https://miro.medium.com/v2/resize:fit:1400/0*6LvGmPGgw77vUJLo" alt="two people looking at a book" />
        <figcaption>
          Photo by{" "}
          <a href="https://www.pexels.com/@mikhail-nilov/" target="_blank" rel="noreferrer">
            Mikhail Nilov
          </a>{" "}
          on{" "}
          <a
            href="https://www.pexels.com/photo/colleagues-standing-in-white-long-sleeve-shirts-discussing-and-reading-a-financial-report-8297478/"
            target="_blank"
            rel="noreferrer"
          >
            Pexels
          </a>
        </figcaption>
      </figure>
      <p>
        What do you need to know about <strong>changes</strong> to this exam on <strong>October 2nd, 2023</strong>?
      </p>
      <ol>
        <li>
          <a href="#a7d2">Special categories of personal data</a>
        </li>
        <li>
          <a href="#1ed6">Schrems I and II</a>
        </li>
        <li>
          <a href="#988a">New enforcement guidelines</a>
        </li>
        <li>
          <a href="#6f93">Social media's dark patterns</a>
        </li>
        <li>
          <a href="#8897">New and updated legislation</a>
        </li>
      </ol>
      <p>
        Exams held after October 2nd will include new questions on the latest developments in the European Data Privacy
        regime, ranging from content that made the headlines — such as the new{" "}
        <a
          href="https://www.weforum.org/agenda/2023/03/the-european-union-s-ai-act-explained/"
          target="_blank"
          rel="noreferrer"
        >
          Artificial Intelligence Act
        </a>{" "}
        — to textbook footnotes — such as{" "}
        <a
          href="https://edpb.europa.eu/sites/default/files/files/file1/edpb_guidelines_202006_psd2_afterpublicconsultation_en.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Guidelines 06/2020
        </a>{" "}
        on the{" "}
        <a
          href="https://www.ecb.europa.eu/paym/intro/mip-online/2018/html/1803_revisedpsd.en.html"
          target="_blank"
          rel="noreferrer"
        >
          Payment Services Directive 2
        </a>
        .
      </p>
      <p>
        In this article, I'll brief you on the <strong>five categories</strong> of new information so that you have your
        best shot at attaining the valuable CIPP/E credential after the changes on October 2nd. We'll{" "}
        <a href="#25ff">wrap up</a> by discussing how these changes impact our{" "}
        <Link to="/products/#cippe" target="_blank">
          study materials
        </Link>{" "}
        for the exam.
      </p>
      <h2 id="a7d2">#1 — Special Categories of Personal Data</h2>
      <p>
        A couple of older subjects are now emphasized in the{" "}
        <a href="https://iapp.org/media/pdf/certification/CIPP_E_BoK_1.3.1.pdf" target="_blank" rel="noreferrer">
          <strong>updated CIPP/E Body of Knowledge</strong>
        </a>
        , even if they're not entirely new. This applies to the special categories of personal data and the two Schrems
        decisions.
      </p>
      <p>
        <a href="https://gdpr-info.eu/art-9-gdpr/" target="_blank" rel="noreferrer">
          <strong>Special categories</strong>
        </a>{" "}
        <strong>of personal data</strong> include that which is likely to be able to identify a natural person (e.g., an
        individual) and carries particular influence over their identity. These categories include race and ethnicity,
        religion, political opinion, trade union membership, genetic or biometric data, health data, and sexual
        orientation.
      </p>
      <p>
        If an organization is processing special categories of personal data, then regardless of the organization's
        size, they are required to keep detailed records of their processing operations in writing. These records must
        be made available to Data Protection Authorities upon request.
      </p>
      <h2 id="1ed6">#2 — Schrems I and II</h2>
      <p>
        <a
          href="https://www2.deloitte.com/dl/en/pages/legal/articles/safe-harbor-entscheidung-eugh.html"
          target="_blank"
          rel="noreferrer"
        >
          <strong>Schrems I</strong>
        </a>{" "}
        and{" "}
        <a
          href="https://globalfreedomofexpression.columbia.edu/cases/maximilian-schrems-v-facebook-ireland-limited/"
          target="_blank"
          rel="noreferrer"
        >
          <strong>Schrems II</strong>
        </a>{" "}
        are two court decisions from lawsuits involving Maximilian Schrems against Facebook. In 2015, Schrems I resulted
        in the <strong>invalidation of the</strong>{" "}
        <a
          href="https://www.ftc.gov/business-guidance/privacy-security/us-eu-safe-harbor-framework"
          target="_blank"
          rel="noreferrer"
        >
          <strong>Safe Harbor Framework</strong>
        </a>
        , which had provided a legal conveyor belt for the transference of data from Europe to the United States,
        despite differing data protection regulations. The Safe Harbor was <strong>replaced by the EU-US</strong>{" "}
        <a href="https://www.privacyshield.gov/welcome" target="_blank" rel="noreferrer">
          <strong>Privacy Shield Framework</strong>
        </a>
        , although this also had to be radically revised in light of Schrems II, in which the Court of Justice of the
        European Union found that the Privacy Shield also did not sufficiently mirror the data privacy protections
        afforded to European citizens and residents.
      </p>

      <figure>
        <img src="https://miro.medium.com/v2/resize:fit:1400/0*5wRzc-TN5oYAilfu" alt="two people looking at a report" />
        <figcaption>
          Photo by{" "}
          <a href="https://www.pexels.com/@alexander-suhorucov/" target="_blank" rel="noreferrer">
            Alexander Suhorucov
          </a>{" "}
          on{" "}
          <a
            href="https://www.pexels.com/photo/multiracial-colleagues-checking-important-report-on-papers-6457491/"
            target="_blank"
            rel="noreferrer"
          >
            Pexels
          </a>
        </figcaption>
      </figure>
      <h2 id="988a">#3 — New Guidelines</h2>
      <p>
        The updates to the CIPP/E exam also cover important guidelines which{" "}
        <strong>resolve ambiguities in the law</strong> and indicate how the laws are intended to be implemented on a
        day-to-day basis.
      </p>
      <p>
        For example,{" "}
        <a
          href="https://www.whitecase.com/insight-alert/edpb-issues-guidelines-right-access-under-art-15-gdpr"
          target="_blank"
          rel="noreferrer"
        >
          Guidelines 01/2022
        </a>{" "}
        on data subject rights strengthens the individual <strong>Right of Access</strong> and names the party
        responsible for responding to data subject access requests.{" "}
        <a
          href="https://www.dataguidance.com/news/eu-edpb-publishes-updated-guidelines-identifying"
          target="_blank"
          rel="noreferrer"
        >
          Guidelines 8/2022
        </a>{" "}
        clarifies the confusing issue of <strong>joint controllershi</strong>p and helps track the chain of
        responsibility in an organization.
      </p>
      <p>
        <a
          href="https://www.dataguidance.com/news/eu-edpb-publishes-guidelines-examples-data-breach"
          target="_blank"
          rel="noreferrer"
        >
          Guidelines 01/2021
        </a>{" "}
        and{" "}
        <a
          href="https://www.dataguidance.com/news/eu-edpb-publishes-guidelines-personal-data-breach"
          target="_blank"
          rel="noreferrer"
        >
          Guidelines 9/2022
        </a>{" "}
        concern <strong>personal data breach notifications,</strong> providing examples and addressing its interplay
        with GDPR, respectively. Legally demanding open communication about breaches encourages organizations to be more
        stringent in their data security, for fear of reputational damage with their client base.
      </p>
      <p>
        The abovementioned <strong>Payment Services Directive 2</strong> was recently clarified through{" "}
        <a
          href="https://edpb.europa.eu/sites/default/files/files/file1/edpb_guidelines_202006_psd2_afterpublicconsultation_en.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Guidelines 06/2020
        </a>
        , in order to simplify the complex piece of legislation and more clearly articulate that payment service
        providers — such as Stripe, Square, and PayPal — are required to comply with GDPR. The Guidelines also function
        as advice on how to do so.
      </p>
      <h2 id="6f93">#4 — Dark Patterns</h2>
      <p>
        Other additions to the exam materials dive deeper into preexisting topics in interesting ways. This October,
        test takers will need to be familiar with the EU's concept of{" "}
        <a
          href="https://www.insideprivacy.com/eu-data-protection/the-eu-stance-on-dark-patterns/"
          target="_blank"
          rel="noreferrer"
        >
          <strong>dark patterns</strong>
        </a>{" "}
        — “interfaces and user experiences” on social media which “lead users into making unintended, unwilling, and
        potentially harmful decisions” with their personal data. There are six categories of dark patterns. See if you
        recognize any:
      </p>
      <ol>
        <li>
          <strong>Overloading</strong>: overwhelming users with requests, information, options, or possibilities in
          order to prompt them to share more data
        </li>
        <li>
          <strong>Skipping</strong>: designing the user experience or interface to cause users to forget or not consider
          data protection aspects of decisions
        </li>
        <li>
          <strong>Stirring</strong>: appealing to users' emotions, usually through visual nudges
        </li>
        <li>
          <strong>Hindering</strong>: blocking users from information about how their data is used, or blocking users
          from exercising control over it
        </li>
        <li>
          <strong>Fickle</strong>: designing the user interface inconsistently, making it difficult to understand
          processing purposes
        </li>
        <li>
          <strong>Left in the dark</strong>: designing the user interface in such a way as to hide information or
          privacy controls
        </li>
      </ol>
      <p>
        There are also five stages of the social media account lifecycle at which these patterns can occur, each of
        which require consideration from a data protection point of view:
      </p>
      <ol>
        <li>Opening an account</li>
        <li>Staying informed on social media</li>
        <li>Staying protected on social media</li>
        <li>Exercising personal data rights on social media</li>
        <li>Closing an account</li>
      </ol>
      <p>
        Manipulative design can exploit our emotions and cognitive biases, in turn leading to the erosion of informed
        consent on social media. GDPR has carefully worked to protect rights to privacy <strong>in reality</strong>,
        rather than in name only, and for this reason Data Privacy advocates need to be on guard against these tricks on
        public platforms.
      </p>
      <figure>
        <img
          src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*kwzY8r99tC1SwETRM_7EvQ.jpeg"
          alt="three stressed people"
        />
        <figcaption>
          Photo by{" "}
          <a
            href="https://www.pexels.com/photo/stressed-woman-between-her-colleagues-7433871/"
            target="_blank"
            rel="noreferrer"
          >
            Vlada Karpovich
          </a>{" "}
          on{" "}
          <a href="https://www.pexels.com/" target="_blank" rel="noreferrer">
            Pexels
          </a>
        </figcaption>
      </figure>
      <h2 id="8897">#5 — New and Updated Legislation</h2>
      <p>
        Further content will be entirely new to the CIPP/E exam, based on the latest legal developments in the Data
        Privacy field.
      </p>
      <p>
        <strong>Brexit</strong>, of course, led to serious regulatory changes in England, as it is no longer subject to
        GDPR by default. However, the provisions of the EU GDPR have been{" "}
        <strong>incorporated directly into UK law</strong> as the{" "}
        <a href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/" target="_blank" rel="noreferrer">
          UK GDPR
        </a>
        , which took effect on January 31, 2020, and the amended{" "}
        <a href="https://www.gov.uk/data-protection" target="_blank" rel="noreferrer">
          Data Protection Act
        </a>{" "}
        of 2018. UK businesses and organizations which target European clients or customers or which operate inside the
        European Economic Area are still bound by the standard EU GDPR.
      </p>
      <p>
        The{" "}
        <a
          href="https://digital-strategy.ec.europa.eu/en/policies/data-governance-act"
          target="_blank"
          rel="noreferrer"
        >
          <strong>European Data Governance Act</strong>
        </a>{" "}
        entered into force just last year, on 23 June, 2022. Organizations and Data Privacy professionals will need to
        work quickly to understand the ramifications of GDPR on the Data Governance Act, which intends to{" "}
        <strong>facilitate the voluntary sharing of data</strong> for the benefit of businesses and for altruistic
        purposes through regulating novel data intermediaries to boost data sharing.
      </p>
      <p>
        The longstanding Convention 108, first opened for signature in 1981, has just been updated to{" "}
        <a
          href="https://www.coe.int/en/web/data-protection/convention108-and-protocol"
          target="_blank"
          rel="noreferrer"
        >
          <strong>Convention 108+</strong>
        </a>{" "}
        in order to address challenges resulting from new information technologies and to strengthen the Convention's
        implementation in defense of human dignity. The new text of the Convention is designed to{" "}
        <strong>alleviate some confusions</strong> with the lack of definitions in the original text as well as to
        clarify the duties of controllers and processors as they relate to data security.
      </p>
      <p>
        The{" "}
        <a
          href="https://www.insideprivacy.com/cybersecurity-2/new-eu-cyber-law-nis2-enters-into-force/"
          target="_blank"
          rel="noreferrer"
        >
          <strong>NIS 2 Directive</strong>
        </a>
        , also known as Directive 2022/2555, entered into force Jan 16th, 2023. It{" "}
        <strong>expands the categories of organizations</strong> which are subject to NIS law (i.e., European
        legislation on cybersecurity) and also <strong>creates a stricter enforcement regime</strong>. Management bodies
        are required to oversee, approve, and be trained on the cybersecurity measures taken by the entity, as they will
        be held liable for their organization's breaches of NIS2. Authorities charged with enforcing this directive may
        be able to conduct on-site inspections, security audits, and requests for information to assess cybersecurity
        measures.
      </p>
      <p>
        Finally, within the past month, EU lawmakers have recently passed a draft of the{" "}
        <a
          href="https://www.weforum.org/agenda/2023/03/the-european-union-s-ai-act-explained/"
          target="_blank"
          rel="noreferrer"
        >
          <strong>Artificial Intelligence Act</strong>
        </a>
        . Under GDPR, decisions made by machines, including artificial intelligence, can be challenged by data subjects
        on the grounds that they are not lawful and fair. Humans are usually required to be involved in{" "}
        <strong>decision-making processes</strong>, more substantially than just approving the machine's decision,
        unless automated decision making is explicitly authorized by law or by the data subject.
      </p>
      <figure>
        <img src="https://miro.medium.com/v2/resize:fit:1400/0*iipS5wkQr1InQcJ3" alt="woman in a library" />
        <figcaption>
          Photo by{" "}
          <a href="https://www.pexels.com/@tima-miroshnichenko/" target="_blank" rel="noreferrer">
            Tima Miroshnichenko
          </a>{" "}
          on{" "}
          <a
            href="https://www.pexels.com/photo/a-woman-researching-at-the-library-6549851/"
            target="_blank"
            rel="noreferrer"
          >
            Pexels
          </a>
        </figcaption>
      </figure>
      <h2 id="25ff">Next Steps</h2>
      <p>
        When the update was announced, my colleagues and I were curious about how the changes would impact our existing
        resources to help data practitioners prepare for the exam. The new content added to the{" "}
        <a href="https://iapp.org/media/pdf/certification/CIPP_E_BoK_1.3.1.pdf" target="_blank" rel="noreferrer">
          Body of Knowledge
        </a>{" "}
        enhances our{" "}
        <Link to="/products/cippe-study-plan" target="_blank">
          <strong>90-Day CIPP/E Study Plan</strong>
        </Link>
        <strong>,</strong> adding to the quality by providing more recent and more specific information.
      </p>
      <p>
        In the{" "}
        <Link to="/products/cippe-study-plan" target="_blank">
          Study Plan
        </Link>
        , we send daily emails over 90 days. This comprehensive program also leverages science-backed tips to help you
        make the most of your time and keep you on track for success. We also offer{" "}
        <Link to="/products/cippe-exam-questions" target="_blank">
          <strong>practice questions</strong>
        </Link>{" "}
        <strong>and</strong>{" "}
        <Link to="/products/gdpr-mind-maps" target="_blank">
          <strong>mind maps</strong>
        </Link>{" "}
        so you can build your understanding of the old and new material on the exam!
      </p>
      <p>
        Finally, if you are interested in joining a community of data privacy practitioners who share your ambition to
        get certified, check out the{" "}
        <a href="https://www.facebook.com/groups/477151570900811" target="_blank" rel="noreferrer">
          <strong>Data Privacy Study Group</strong>
        </a>{" "}
        on Facebook. Each Monday, we do a practice question, with the answer revealed on Friday. Joining the group is
        also a great way to make sure you stay abreast of changes impacting IAPP's exams and the Data Privacy landscape
        more broadly.
      </p>
      <h2>Conclusion</h2>
      <p>
        To earn the{" "}
        <a href="https://iapp.org/certify/cippe/" target="_blank" rel="noreferrer">
          CIPP/E credential
        </a>
        , it's important to show that you're ready to take on the challenge of <strong>remaining up-to-date</strong> on
        the ever-changing landscape of Data Privacy regulation.
      </p>
      <p>
        As the field continues to evolve, you need to <strong>stay informed</strong> about the latest legal developments
        and understand how they impact day-to-day operations. Evolving issues such as the draft of the Artificial
        Intelligence Act and the ability of corporations to exchange data with US partners have significant implications
        for data professionals and data subjects as well as our international economy.
      </p>
      <p>
        By staying up-to-date with the latest developments and regulations, you contribute to a{" "}
        <strong>more secure data environment</strong>. The CIPP/E credential will continue to be an valuable asset on
        your resume.
      </p>
    </>
  );
};
