const LandingPageDisclaimer: React.FC = () => {
  return (
    <>
      <div className="blog">
        <h4 style={{ fontSize: "1.2em", marginTop: 40 }}>We can't wait to get to know you!!</h4>
        <p>
          At Data Strategy Professionals, we offer a professional community for data practitioners. We'll help you build
          your skills, so you can help your organization connect data to value.
        </p>
        <p>
          Join thousands of professionals who have transformed the way they work with data. By signing up today, you
          will also receive twice-monthly emails packed with:
        </p>
        <ul>
          <li>Access to exclusive events, resources, and special offers</li>
          <li>Updates on the latest trends and best practices for data practitioners</li>
          <li>Tips and insights related to Data Management, Data Privacy, Data Analytics and more</li>
        </ul>
      </div>
      <p>Your privacy is our priority. We will never share or sell your information. Unsubscribe at any time.</p>
    </>
  );
};

export default LandingPageDisclaimer;
