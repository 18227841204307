import { CSSProperties } from "react";

const enterpriseStyles = {
  container: {
    maxWidth: 1100,
  },
  enterpriseBox: {
    display: "grid",
    margin: "4em auto 5.5em auto",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center" as CSSProperties["textAlign"],
    columnGap: 100,
    textWrap: "balance" as CSSProperties["textWrap"],
    border: "3px solid #6fb2b7",
    backgroundColor: "#f5f5f566",
    padding: 40,
    maxWidth: "100%",
  },
  boxTitle: {
    gridColumn: "1 / -1",
    marginTop: -20,
    marginBottom: 20,
  },
};

export default enterpriseStyles;
