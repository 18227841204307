import { useEffect, useState } from "react";
import { AddressElement } from "@stripe/react-stripe-js";

export default function AddressForm({
  setStep,
  setAddress,
  firstName,
  lastName,
  setCalculateShippingAndTaxes,
  setTaxCalcDone,
}) {
  const [buttonActive, setButtonActive] = useState(false);

  useEffect(() => {
    setTaxCalcDone(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setCalculateShippingAndTaxes(true);
    setStep(3);
  };

  return (
    <form onSubmit={handleSubmit} style={{ marginTop: "16px", marginBottom: "2em" }}>
      <AddressElement
        options={{
          mode: "shipping",
          defaultValues: {
            name: firstName + " " + lastName,
          },
          // autocomplete: {
          //   mode: "google_maps_api",
          //   apiKey: "AIzaSyAR8IThSRbPno95kQFJKQuuzKojQFM95ec",
          // },
        }}
        onChange={(event) => {
          if (event.complete) {
            setAddress(event.value);
            setButtonActive(true);
          }
        }}
      />
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ fontStyle: "italic", marginBottom: "1em" }}></p>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn btn-accent"
          type="submit"
          style={{
            width: "200px",
            padding: "10px 20px",
            margin: "4px 0",
          }}
          disabled={!buttonActive}
        >
          Submit
        </button>
      </div>
    </form>
  );
}
