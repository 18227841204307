import citi from "../../../img/logos/citi.webp";
import EY from "../../../img/logos/EY.webp";
import slalom from "../../../img/logos/slalom.webp";
import starbucks from "../../../img/logos/starbucks.webp";
import TR from "../../../img/logos/TR.webp";
import Accenture from "../../../img/logos/Accenture.webp";
import BAH from "../../../img/logos/BAH.webp";
import Chase from "../../../img/logos/Chase.webp";
import Deloitte from "../../../img/logos/Deloitte.webp";

export default function TrustedByYourPeersAt() {
  return (
    <div
      className="white-box homepage"
      style={{
        padding: "0px 40px",
        margin: "0px auto",
        background: "#e9eff1",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "2.2em",
          borderBottom: "2px solid #dbdbdb",
          textAlign: "center",
          position: "relative",
          zIndex: 0,
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "1.8em",
            marginTop: "11px",
            background: "#e9eff1",
            zIndex: 1,
            display: "inline-block",
            padding: "0 20px",
            fontFamily: "Raleway",
          }}
          id="clients"
        >
          <strong>Trusted By Your Peers At...</strong>
        </p>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <img src={citi} style={{ objectFit: "contain", width: "200px" }} alt="citibank logo" />
        <img src={starbucks} style={{ height: "160px", width: "160px" }} alt="starbucks logo" />
        <img src={TR} style={{ height: "200px", width: "200px" }} alt="Thompson Reuters logo" />
        <img src={EY} style={{ height: "180px", width: "180px", padding: "30px" }} alt="EY logo" />
        <img
          src={slalom}
          style={{ objectFit: "contain", height: "200px", width: "200px", position: "relative", top: 20 }}
          alt="slalom logo"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <img src={Deloitte} style={{ objectFit: "contain", width: "200px" }} alt="Deloitte logo" />
        <img src={Chase} style={{ objectFit: "contain", width: "200px" }} alt="Chase Bank logo" />
        <img src={Accenture} style={{ objectFit: "contain", width: "200px" }} alt="Accenture logo" />
        <img src={BAH} style={{ height: "180px", width: "180px", padding: "30px" }} alt="BAH logo" />
      </div>
      <br />
    </div>
  );
}
