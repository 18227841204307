import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import specialist from "../../img/helmet/products/smiling_sq.jpeg";

import CustomHelmet from "../../components/CustomHelmet";
import LeadMagnetSignup from "../../components/lead-magnet/LeadMagnetSignup";
import Product from "../../components/product-card/ProductCardContainer";

import products from "../../data/products";

const Enterprise: React.FC = () => {
  const desktop = useMediaQuery("(min-width:1200px)");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description="Data Strategy Professionals provides expertly crafted test prep materials for DAMA's Certified Data Management Professional (CDMP) exam as well as other data-related training."
        name="Enterprise"
        link="/enterprise"
        img={specialist}
        alt="two smiling business people"
      />

      <div className="home-layout" style={{ minHeight: "70vh" }}>
        <h1 className="title">For Enterprise</h1>
        <p style={{ fontSize: "1.3em", margin: "-0.9em 0 1.5em 0" }}>
          Give your team members everything they need to achieve mastery of Data Management.
        </p>

        <div
          style={{
            display: desktop ? "grid" : "inherit",
            gridTemplateColumns: "2fr 1fr",
            marginLeft: desktop ? 40 : 0,
            marginRight: desktop ? 40 : 0,
            gap: 80,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <div className="blog" style={{ fontSize: "1.2em", width: desktop ? "80%" : "100%" }}>
              <p>
                We provide expertly crafted test prep materials for{" "}
                <strong>
                  <a href="https://www.dama.org/" target="_blank" rel="noreferrer">
                    DAMA
                  </a>
                  's Certified Data Management Professional (
                  <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
                    CDMP
                  </Link>
                  ) exam
                </strong>
                , as well as <Link to="/landing/document-drafting-and-review">Document Drafting & Review</Link>{" "}
                services.
              </p>
              <p>
                Data Strategy training can play an essential role in getting everyone in your organization speaking the
                same language when it comes to the management of your valuable data assets.
              </p>
            </div>
          </div>

          <div style={{ marginTop: desktop ? 0 : 40 }}>
            <LeadMagnetSignup leadMagnetName="more information" emailList={39} />
          </div>
        </div>
        <div
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "2em auto 2.5em auto",
          }}
        >
          <div className="landing-page-divider" />
        </div>
        <div className="product-catalog">
          {products
            .filter(
              (productObj) =>
                productObj.name === "Data Strategy Document Checklist" ||
                productObj.name === "Data Strategy Workbook" ||
                productObj.category === "enterprise"
            )
            .filter((productObj) => productObj.name !== "CDMP Small Group Study Materials")
            .map((productObj, idx) => {
              return <Product key={productObj.name} productObj={productObj} idx={idx} />;
            })}
        </div>
      </div>
    </>
  );
};

export default Enterprise;
