import validator from "validator";

const EmailForm: React.VFC<EmailFormProps> = ({ setEmail, setValidEmail }) => {
  const validateEmail = (e) => {
    const email = e.target.value;

    if (validator.isEmail(email)) {
      setValidEmail(true);
      setEmail(email);
    } else if (!validator.isEmail(email)) {
      setValidEmail(false);
    }
  };

  return <input type="text" id="userEmail" onKeyUp={(e) => validateEmail(e)} className="field checkout-form-input" />;
};

export default EmailForm;
