import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { CheckoutContext } from "../../../contexts/CheckoutContext";

import CDMPStudyProgramOfferings from "./CDMPStudyProgramOfferings";
import CDMPStudyProgramCheckoutController from "./CDMPStudyProgramCheckoutController";
import CDMPrationale from "./CDMPrationale";
import CDMPStudyProgramDescription from "./CDMPStudyProgramDescription";
import CDMPStudyProgramNextSteps from "./CDMPStudyProgramNextSteps";
import CDMPStudyProgramTestimonials from "./CDMPStudyProgramTestimonials";
import CDMPStudyProgramBox from "./CDMPStudyProgramBox";

import CustomHelmet from "../../../components/CustomHelmet";
import CDMPStudyProgramVisualDescription from "./CDMPStudyProgramVisualDescription";
import BuyNowButton from "../../../components/product-pages/BuyNowButton";

import visualDescriptions from "../../../data/visualDescriptions";

import products from "../../../data/products";
import payments from "../../../data/payments";

const PRODUCT = "CDMP Study Program";

const product = products.find((product) => product.name === PRODUCT);
const payment = payments.find((payment) => payment.product === PRODUCT);
const vizDescObj = visualDescriptions.find((vizDesc) => vizDesc.product === PRODUCT);

const CDMPStudyProgram: React.VFC = () => {
  const [count, setCount] = useState<number>(4);

  const checkoutContext = useContext(CheckoutContext) as checkoutContext;
  const { confirmationTable, setConfirmationTable, setStep } = checkoutContext.checkout;

  const showPartial = useMediaQuery("(min-width:1024px)");
  const showVisualDescription = useMediaQuery("(min-width: 1200px)");

  useEffect(() => {
    window.scrollTo(0, 0);

    const table = [
      {
        selected: true,
        paymentName: payment.name as paymentName,
        type: payment.type as paymentType["type"],
        selectedOptions: payment.options[0].content,
        price: payment.price * count,
        metadata: {
          count: count,
          unit: "participants" as "participants",
          abandonedCartList: payment.abandonedCartList,
          discountCode: "",
          discountValue: 0,
          discountAmount: 0,
          shippingAmt: 0,
          taxCode: "",
          taxRate: 0,
        },
      },
    ];

    setConfirmationTable(table);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (confirmationTable) {
      const updatedRow = {
        ...confirmationTable[0],
        price: payment.price * count,
        metadata: { ...confirmationTable[0].metadata, count: count },
      };

      const table = [updatedRow];

      setConfirmationTable(table);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <>
      <CustomHelmet
        description={product.description}
        name={product.name}
        link={product.link}
        img={product.img}
        alt={product.alt}
      />

      <div className="landing-page">
        <div className="landing-page-inner">
          <Link to="/enterprise">
            <span className="landing-page-close-icon">x</span>
          </Link>
          <h1 className="title landing-page-title">CDMP Study Program</h1>
          <div className="landing-page-divider" style={{ marginTop: "-1.7em" }} />
          <p style={{ fontSize: "1.3em", margin: "-0.9em 0 1.5em 0" }}>
            <strong>Save time</strong> and help your team members advance their knowledge of Data Strategy! Purchase the{" "}
            <strong>CDMP Study Program</strong> for your team.
          </p>

          <p style={{ marginBottom: ".2em", fontWeight: 900, fontSize: "1.2em", fontFamily: "Raleway" }}>
            Each participant will receive:
          </p>
          {showVisualDescription ? (
            <CDMPStudyProgramVisualDescription vizDescObj={vizDescObj} />
          ) : (
            <CDMPStudyProgramOfferings />
          )}

          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "1.5em auto 1.5em auto",
            }}
          >
            <div className="landing-page-divider" />
            <div style={{ width: showPartial ? "80%" : "95%", margin: "0 auto" }}>
              <CDMPStudyProgramCheckoutController count={count} setCount={setCount} setStep={setStep} />
            </div>
            <div className="landing-page-divider" />
          </div>

          {showVisualDescription && <CDMPStudyProgramBox />}

          <CDMPrationale />
          <CDMPStudyProgramBuyNowButton count={count} />
          <CDMPStudyProgramDescription />
          <div style={{ height: "3em" }} />

          <CDMPStudyProgramNextSteps />
          <CDMPStudyProgramBuyNowButton count={count} />

          <CDMPStudyProgramTestimonials />
          <div style={{ height: "1em" }} />

          <CDMPStudyProgramBuyNowButton count={count} />
        </div>
      </div>
    </>
  );
};

const CDMPStudyProgramBuyNowButton: React.VFC<{ count: number }> = ({ count }) => {
  return (
    <BuyNowButton
      checkoutLink={`/products/enterprise-study-program/checkout?count=${count}`}
      learnMore="https://mcusercontent.com/c164c67d8c9591e3b056f39b1/files/1f04a5d8-0378-da0e-6b76-a250816919d1/Enterprise_Study_Program.pdf"
    />
  );
};

export default CDMPStudyProgram;
