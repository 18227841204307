import { useEffect, useState, useRef } from "react";
import { RadioGroup, Radio, FormControl, FormControlLabel } from "@mui/material";
import ReactCardFlip from "react-card-flip";

import arrow from "../../img/icons/arrow.webp";

const answerChoices = ["a", "b", "c", "d", "e"];

const QuestionCard: React.VFC<{ question: Question }> = ({ question }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);

  const frontRef = useRef(null);
  const backRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const frontHeight = frontRef.current ? frontRef.current.clientHeight : 0;
    const backHeight = backRef.current ? backRef.current.clientHeight : 0;
    setMaxHeight(Math.max(frontHeight, backHeight));
  }, [width]);

  useEffect(() => {
    if (answerChoices[question.correctAnswer] === selectedAnswer) {
      setIsCorrect(true);
    } else setIsCorrect(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswer]);

  return (
    <div
      style={{
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: isFlipped ? (isCorrect ? "#006600" : "#8B0000") : "#959393",
        backgroundColor: "#efefef",
        margin: 10,
        padding: 20,
        position: "relative",
        height: maxHeight + 40,
        maxWidth: 1000,
      }}
    >
      <div style={{ position: "absolute", visibility: "hidden" }}>
        <Front
          frontRef={frontRef}
          question={question}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
        />
        <Back backRef={backRef} question={question} />
      </div>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <Front
          frontRef={frontRef}
          question={question}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
        />
        <Back backRef={backRef} question={question} />
      </ReactCardFlip>
      <Arrow isFlipped={isFlipped} setIsFlipped={setIsFlipped} />
    </div>
  );
};

type FrontProps = {
  frontRef: React.RefObject<HTMLDivElement>;
  question: Question;
  selectedAnswer: string;
  setSelectedAnswer: (value: string) => void;
};

const Front: React.VFC<FrontProps> = ({ frontRef, question, selectedAnswer, setSelectedAnswer }) => {
  return (
    <div ref={frontRef}>
      <p>{question.question}</p>

      {answerChoices.map((choice, idx) => {
        return (
          <FormControl style={{ display: "flex", flexDirection: "column", maxWidth: 900 }}>
            <RadioGroup
              defaultValue={""}
              key={choice}
              value={selectedAnswer}
              onChange={(e) => setSelectedAnswer(e.target.value)}
            >
              <FormControlLabel
                value={choice}
                control={<Radio disableRipple style={{ color: "gray", padding: "3px 9px" }} />}
                label={choice + `.${" "}${" "}` + question.options[idx]}
              />
            </RadioGroup>
          </FormControl>
        );
      })}
    </div>
  );
};

type BackProps = {
  backRef: React.RefObject<HTMLDivElement>;
  question: Question;
};

const Back: React.VFC<BackProps> = ({ backRef, question }) => {
  return (
    <div ref={backRef}>
      <p>{question.question}</p>
      <div style={{ maxWidth: 900 }}>
        <p style={{ marginBottom: 0 }}>
          <em>Correct answer</em>:&nbsp; {answerChoices[question.correctAnswer]}.{" "}
          {question.options[question.correctAnswer]}
        </p>
        <p>
          <em>Explanation</em>:&nbsp; {question.explanation}
        </p>
      </div>
    </div>
  );
};

const Arrow: React.VFC<{ isFlipped: boolean; setIsFlipped: (value: boolean) => void }> = ({
  isFlipped,
  setIsFlipped,
}) => {
  return (
    <img
      src={arrow}
      alt="arrow"
      style={{ position: "absolute", bottom: 8, right: 10, width: 45, transform: "rotate(10deg)", zIndex: 1000 }}
      onClick={() => setIsFlipped(!isFlipped)}
    />
  );
};

export default QuestionCard;
