import { useState } from "react";
import CustomModal from "../../../components/CustomModal";

const CDMPStudyProgramVisualDescription: React.VFC<{ vizDescObj: visualDescription }> = ({ vizDescObj }) => {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);
    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}

      <div
        style={{
          margin: "1em 20px 2em 20px",
          display: "grid",
          gridTemplateColumns: `repeat(${vizDescObj.icons.length}, 1fr)`,
          justifyItems: "center",
          textAlign: "center",
          textWrap: "balance",
        }}
      >
        {vizDescObj.icons.map((_icon, idx) => (
          <div key={idx}>
            <div style={{ position: "relative", marginBottom: 4, width: 200, margin: "auto" }}>
              <img
                src={vizDescObj.images[idx]}
                alt=""
                style={{ height: 200, width: 200, cursor: "pointer", objectFit: "cover" }}
                onClick={handleShowClick}
                className="box-shadow"
              />
              {/* <div style={{ position: "absolute", bottom: 4, right: 4 }}>{icon}</div> */}
            </div>
            <p dangerouslySetInnerHTML={{ __html: vizDescObj.descriptions[idx] }} />
          </div>
        ))}
      </div>
    </>
  );
};

export default CDMPStudyProgramVisualDescription;
