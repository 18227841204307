import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useScrollToLocation } from "../../helpers/utils";
import { useMediaQuery } from "@mui/material";

import Hero from "./hero/Hero";
import OurCommunity from "./OurCommunity";
import TrustedByYourPeersAt from "./TrustedByYourPeersAt";
import BestSellers from "./BestSellers";
import Journeys from "./Journeys";
import Testimonials from "./Testimonials";
import MoreTestimonials from "./MoreTestimonials";

import CustomHelmet from "../../components/CustomHelmet";

const Home: React.VFC = () => {
  const scroll = useScrollToLocation();
  const desktop = useMediaQuery("(min-width:960px)");

  const location = useLocation();

  useEffect(() => {
    return scroll;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.hash === "") {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ background: "#f9f9f9", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <CustomHelmet
        description="Training for data practitioners and CDMP exam takers."
        name="Data Strategy Professionals"
        link=""
        img="https://www.nicolejaneway.com/wp-content/uploads/2021/04/diego-ph-fIq0tET6llw-unsplash_square.jpg"
        alt="hand holding lightbulb"
      />
      <div style={{ width: "100%" }}>
        <Hero />
      </div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            margin: "auto",
            maxWidth: 2000,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: desktop ? 60 : 40,
              marginTop: desktop ? 60 : 40,
              marginBottom: desktop ? 60 : 40,
            }}
          >
            <TrustedByYourPeersAt />
            <Testimonials />
            <BestSellers />
          </div>
          <Journeys />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: desktop ? 60 : 40,
              marginTop: desktop ? 60 : 40,
              marginBottom: desktop ? 60 : 40,
            }}
          >
            <OurCommunity />
            <MoreTestimonials />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
