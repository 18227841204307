import { useState, useEffect, useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useScrollToLocation } from "../helpers/utils";

import CustomHelmet from "../components/CustomHelmet";
import ProductCardBasic from "../components/product-card/ProductCardContainer";
import EnterpriseBanner from "../components/EnterpriseBanner";

import productsList from "../data/products";

import { titles, categories } from "../data/productPage";

const products = productsList.filter((product) => product.showOnHomepage);

const Products: React.VFC = () => {
  const scroll = useScrollToLocation();
  const [value, setValue] = useState("");
  const desktop = useMediaQuery("(min-width:770px)");

  useEffect(() => {
    return scroll;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrolledRef = useRef(false);

  useEffect(() => {
    if (value && !scrolledRef.current) {
      const element = document.getElementById(value);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        scrolledRef.current = true;
      }
    }
    scrolledRef.current = false;
  }, [value]);

  function handleChange(event) {
    setValue(event.target.value);
  }

  return (
    <div style={{ width: "100%" }}>
      <CustomHelmet
        description="Data Strategy Professionals provides expertly crafted test prep materials for DAMA's Certified Data Management Professional (CDMP) exam as well as other data-related training."
        name="Products"
        link="/products"
        img="https://www.nicolejaneway.com/wp-content/uploads/2021/04/diego-ph-fIq0tET6llw-unsplash_square.jpg"
        alt="hand holding lightbulb"
      />

      <EnterpriseBanner />

      <div className="home-layout">
        <div style={{ height: "2em", position: "relative" }}>
          <h1 className="title" style={{ position: "absolute" }}>
            Products
          </h1>
          {desktop && (
            <div style={{ float: "right", position: "absolute", bottom: 0, right: 0 }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}>
                Jump to:
                <select name="options" onChange={handleChange} value={value}>
                  <option value="" />
                  {titles.map((title, index) => {
                    return (
                      <option key={title} value={categories[index]}>
                        {title}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
        </div>

        {titles.map((title, index) => {
          return <ProductSection title={title} category={categories[index]} key={index} />;
        })}
      </div>
    </div>
  );
};

type ProductSectionProps = {
  title: string;
  category: string;
};

const ProductSection: React.VFC<ProductSectionProps> = ({ title, category }) => {
  return (
    <>
      <div className="product-page-divider">
        <p className="product-page-divider-title" id={category}>
          <strong>{title}</strong>
        </p>
      </div>
      <div className="product-catalog">
        {products
          .filter((productObj) => productObj.category === category)
          .map((productObj, idx) => {
            return <ProductCardBasic key={productObj.name} productObj={productObj} idx={idx} />;
          })}
      </div>
    </>
  );
};

export default Products;
