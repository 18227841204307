import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import validator from "validator";

const fetch = require("node-fetch").default;

function useScrollToLocation() {
  const scrolledRef = useRef(false);
  const { hash } = useLocation();
  useEffect(() => {
    if (hash && !scrolledRef.current) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        scrolledRef.current = true;
      }
    }
  }, [hash]);
}

function formatDate(date) {
  let reformattedDate = date
    .toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric" })
    .toString()
    .replace(" ", "")
    .toLowerCase();

  if (reformattedDate.includes(":00")) {
    reformattedDate = reformattedDate.replace(":00", "");
  }

  return reformattedDate;
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function excelDateToDate(excelDate) {
  const date = new Date((excelDate - (25567 + 1)) * 86400 * 1000); // Convert Excel date to JavaScript date

  const formattedDate = `${months[date.getUTCMonth()]} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
  return formattedDate;
}

function excelDateToStyleGuideDate(excelDate) {
  const date = new Date((excelDate - (25567 + 1)) * 86400 * 1000); // Convert Excel date to JavaScript date

  const formattedDate = `${days[date.getUTCDay()]}, ${
    months[date.getUTCMonth()]
  } ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
  return formattedDate;
}

function simplifyDate(date) {
  let reformattedDate = date.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });

  if (reformattedDate.includes(":00")) {
    reformattedDate = reformattedDate.replace(":00", "");
  }

  return reformattedDate;
}

function sendDataToSendinblue(firstName, lastName, email, payment, abandonedCartList, emailList) {
  const paymentName = payment.name;
  if (payment.type !== "course") {
    fetch("/.netlify/functions/sendinblue-free-trial", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        payment: paymentName,
        abandonedCartList,
        emailList,
      }),
    })
      .then((response) => console.log(response))
      .catch((err) => {
        console.log("Sendinblue error:", err);
        console.log(err);
        return { statusCode: 400, body: JSON.stringify({ error: err.message }) };
      });
  } else {
    fetch("/.netlify/functions/sendinblue-email-list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        firstName,
        emailList: emailList ?? 38,
        source: paymentName,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .catch((err) => {
        console.log("Sendinblue newsletter error:", err);
        return { statusCode: 400, body: JSON.stringify({ error: err.message }) };
      });

    fetch("/.netlify/functions/add-to-free-course", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        payment,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .catch((err) => {
        console.log("Add to free course error:", err);
        return { statusCode: 400, body: JSON.stringify({ error: err.message }) };
      });
  }
}

function validateEmail(e, setEmail, setValidEmail) {
  const email = e.target.value;

  if (validator.isEmail(email)) {
    setValidEmail(true);
    setEmail(email);
  } else if (!validator.isEmail(email)) {
    setValidEmail(false);
  }
}

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

function formatCurrency(amount: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  }).format(amount * 0.01);
}

function formatCurrencySimple(amount: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(amount * 0.01);
}

function createRows(data: any) {
  const fields: string[] = [...new Set(data.flatMap(Object.keys) as string)];

  return data.map((item) => {
    const rowData = {};
    fields.forEach((field) => {
      rowData[field] = item.hasOwnProperty(field) ? item[field] : null; // Use hasOwnProperty to check for the field and assign null if not present
    });
    return rowData;
  });
}

const useModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);
    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return {
    showModal,
    image,
    alt,
    handleShowClick,
  };
};

export {
  useScrollToLocation,
  formatDate,
  excelDateToDate,
  excelDateToStyleGuideDate,
  simplifyDate,
  sendDataToSendinblue,
  validateEmail,
  shuffle,
  formatCurrency,
  formatCurrencySimple,
  createRows,
  useModal,
};
