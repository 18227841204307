import "./index.css";
import { useEffect } from "react";
import Hotjar from "@hotjar/browser";
import ReactGA from "react-ga4";
import { Switch, Route, useHistory, useLocation, Redirect } from "react-router-dom";

import { LayoutProvider } from "./contexts/LayoutContext";
import { CheckoutProvider } from "./contexts/CheckoutContext";

import Home from "./pages/home/Home";
import Navbar from "./components/nav/navbar/Navbar";
import Start from "./pages/Start";
import About from "./pages/about/About";
import GetCertified from "./pages/user-stories/GetCertified";
import Enterprise from "./pages/user-stories/Enterprise";
import AdvanceYourCareer from "./pages/user-stories/AdvanceYourCareer";
import Events from "./pages/events/Events";
import NewsletterPage from "./pages/newsletter/Newsletter";

import Products from "./pages/Products";

import CDMPBootcamp from "./products/cdmp-fundamentals/bootcamps/CDMPBootcamp";

import DataStrategyWorkbook from "./products/data-strategy/DataStrategyWorkbook";
import DocumentChecklist from "./products/data-strategy/DocumentChecklist";
import CDMPStudyPlan from "./products/cdmp-fundamentals/CDMPStudyPlan";
import CDMPExamReadinessCheck from "./products/cdmp-fundamentals/CDMPExamReadinessCheck";
import DSEmailBootcamp from "./products/data-science/DSEmailBootcamp";
import TShirt from "./products/physical/TShirt";
import Mug from "./products/physical/Mug";
import CDMPFundamentalsNotes from "./products/cdmp-fundamentals/CDMPFundamentalsNotes";
import CDMPExamQuestions from "./products/cdmp-fundamentals/CDMPExamQuestions";
import CAPStudyPlan from "./products/cap/CAPStudyPlan";
import CAPExamQuestions from "./products/cap/CAPExamQuestions";
import DSPracticeQuestions from "./products/data-science/DSPracticeQuestions";
import CIPPEExamQuestions from "./products/data-privacy/CIPPEExamQuestions";
import CIPPEStudyPlan from "./products/data-privacy/CIPPEStudyPlan";
import GDPRMindMaps from "./products/data-privacy/GDPRMindMaps";
import CDMPTutoring from "./products/cdmp-fundamentals/CDMPTutoring";
import CDMP_Small_Group_Old from "./products/cdmp-fundamentals/small-group/CDMP_Small_Group_Old";
import CDMP_Small_Group from "./products/cdmp-fundamentals/small-group/CDMP_Small_Group_Current";

import Poster from "./products/physical/Poster";
import SpecialistGuides from "./products/cdmp-specialist/SpecialistGuides";
import SpecialistQuestions from "./products/cdmp-specialist/SpecialistQuestions";
import CareerCoaching from "./products/services/CareerCoaching/CareerCoaching";
import ResumeReview from "./products/services/ResumeReview";
import DSECourse from "./products/data-strategy/data-strategy-essentials-course/DSECourse";
import GDPRCourse from "./products/data-privacy/GDPRCourse";
import CDMPCrashCourse from "./products/cdmp-fundamentals/CDMPCrashCourse";

import EnterpriseStudyProgram from "./products/enterprise-training/cdmp-study-program/CDMPStudyProgram";
import SmallGroup from "./products/enterprise-training/small-group/SmallGroup";
import EnterpriseBootcamp from "./products/enterprise-training/bootcamp/EnterpriseBootcamp";
import DocumentDrafting from "./products/enterprise-training/document-drafting/DocumentDrafting";

import CDMPFundamentalsBundle from "./products/bundles/CDMPFundamentalsBundle";
import CDMPSpecialistBundle from "./products/bundles/CDMPSpecialistBundle";
import CIPPEBundle from "./products/bundles/CIPPEBundle";
import CAPBundle from "./products/bundles/CAPBundle";
import DSBundle from "./products/bundles/DSBundle";

import DAMA_GA_CDMP_Bootcamp from "./products/cdmp-fundamentals/bootcamps/DAMA_GA_CDMP_Bootcamp";
import DAMA_WI_CDMP_Bootcamp from "./products/cdmp-fundamentals/bootcamps/DAMA_WI_CDMP_Bootcamp";

import CheckoutController from "./pages/checkout/CheckoutController";
import Oath from "./pages/oath/Oath";
import Resources from "./pages/Resources";
import Foundations from "./posts/Foundations";
import OathPost from "./posts/OathPost";
import Warehouse from "./posts/Warehouse";
import CDMP from "./posts/CDMP";
import Specialist from "./posts/Specialist";
import DataScience from "./posts/DataScience";
import Coaching from "./posts/Coaching";
import CAP from "./posts/CAP";
import Pomodoro from "./posts/Pomodoro";
import CIPPEUpdate from "./posts/CIPPEUpdate";
import KSA from "./posts/KSA";
import Steward from "./posts/Steward";
import DataAnalyst from "./posts/DataAnalyst";
import Projections from "./posts/Projections";
import Aiken from "./posts/Aiken";
import ResearchTools from "./posts/ResearchTools";
import DMMA from "./posts/data-management-maturity-assessment/DMMA";
import CDMPTraining from "./posts/CDMPTraining";
import BestPractices from "./posts/best-practices/BestPractices";
import DataDriven from "./posts/DataDriven";
import CDMPPracticeQuestions from "./posts/CDMPPracticeQuestions";
import Charter from "./posts/Charter";
import Valuation from "./posts/Valuation";
import DCAM from "./posts/DCAM";
import DMBOKv2R from "./posts/DMBOKv2R";
import BusinessValueAIG from "./pages/landing-pages/business-value-aig/BusinessValueAIG";

import KSADocument from "./pages/landing-pages/ksa-doc/KSADoc";
import BestCertifications from "./pages/landing-pages/best-certifications/BestCertifications";

import Footer from "./components/footer/Footer";

import BIDSCert from "./pages/BIDSCert";
import CDMPLanding from "./pages/landing-pages/cdmp-landing/CDMPLanding";

import TermsAndConditions from "./pages/footer/TermsAndConditions";
import CodeOfConduct from "./pages/footer/CodeOfConduct";
import ThankYou from "./pages/newsletter/ThankYou";

const App: React.VFC = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (window.location.hostname.includes("datastrategypros.com")) {
      /* Hotjar */
      const siteId = 3528435;
      const hotjarVersion = 6;

      Hotjar.init(siteId, hotjarVersion);

      /* Google Analytics */

      // Track the initial pageview
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

      // Listen for changes to the current location.
      const unlisten = history.listen((location) => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
      });

      // Cleanup the listener on unmount
      return () => {
        unlisten();
      };
    }
  }, [history]);

  const isCheckoutRoute = location.pathname.includes("/checkout");

  useEffect(() => {
    if (window.Tawk_API) {
      if (isCheckoutRoute) {
        window.Tawk_API.hideWidget();
      } else {
        window.Tawk_API.showWidget();
      }
    }
  }, [isCheckoutRoute]);

  return (
    <>
      <div className="page">
        <LayoutProvider>
          <Navbar />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/pages/certified-data-management-professional-cdmp">
              <CDMPLanding />
            </Route>
            <Route path="/start-here">
              <Start />
            </Route>

            <Route exact path="/products/dama-ga-cdmp-bootcamp">
              <DAMA_GA_CDMP_Bootcamp />
            </Route>

            <Route exact path="/products/dama-wi-cdmp-bootcamp">
              <DAMA_WI_CDMP_Bootcamp />
            </Route>

            <Route path="/about">
              <About />
            </Route>
            <Route exact path="/get-certified">
              <GetCertified />
            </Route>
            <Route exact path="/advance-your-career">
              <AdvanceYourCareer />
            </Route>
            <Route exact path="/enterprise">
              <Enterprise />
            </Route>
            <Route path="/events">
              <Events />
            </Route>
            <Route path="/events/upcoming/:id">
              <Events />
            </Route>
            <Route path="/newsletter">
              <NewsletterPage />
            </Route>
            <Route exact path="/products">
              <Products />
            </Route>

            <Route path="/oath">
              <Oath />
            </Route>

            <Route exact path="/resources">
              <Resources />
            </Route>
            <Route path="/resources/foundations-of-data-strategy">
              <Foundations />
            </Route>
            <Route path="/resources/oath">
              <OathPost />
            </Route>
            <Route path="/resources/warehouse">
              <Warehouse />
            </Route>
            <Route path="/resources/cdmp-fundamentals-exam">
              <CDMP />
            </Route>
            <Route path="/resources/cdmp-specialist-exam">
              <Specialist />
            </Route>
            <Route path="/resources/data-science-for-leaders">
              <DataScience />
            </Route>
            <Route path="/resources/career-coaching">
              <Coaching />
            </Route>
            <Route path="/resources/certified-analytics-professional">
              <CAP />
            </Route>
            <Route path="/resources/pomodoro-technique">
              <Pomodoro />
            </Route>
            <Route path="/resources/cippe-update-october-2023">
              <CIPPEUpdate />
            </Route>
            <Route path="/resources/aiken-pyramid">
              <Aiken />
            </Route>
            <Route path="/resources/ksa-data-standards">
              <KSA />
            </Route>
            <Route path="/landing/ksa-data-standards">
              <KSADocument />
            </Route>
            <Route path="/landing/best-certifications">
              <BestCertifications />
            </Route>
            <Route path="/landing/business-value-ai-governance-frameworks">
              <BusinessValueAIG />
            </Route>
            <Route path="/resources/data-steward">
              <Steward />
            </Route>
            <Route path="/resources/dmbok-v2-revised">
              <DMBOKv2R />
            </Route>
            <Route path="/resources/data-analyst">
              <DataAnalyst />
            </Route>
            <Route path="/resources/data-management-job-projections">
              <Projections />
            </Route>
            <Route path="/resources/research-tools">
              <ResearchTools />
            </Route>
            <Route path="/resources/data-management-maturity-assessment-dmma">
              <DMMA />
            </Route>
            <Route path="/resources/cdmp-study-guide">
              <CDMPTraining />
            </Route>
            <Route path="/resources/free-cdmp-practice-questions">
              <CDMPPracticeQuestions />
            </Route>
            <Route path="/resources/data-strategy-best-practices">
              <BestPractices />
            </Route>
            <Route path="/resources/data-driven-decision-making">
              <DataDriven />
            </Route>
            <Route path="/resources/data-governance-charter">
              <Charter />
            </Route>
            <Route path="/resources/data-valuation">
              <Valuation />
            </Route>
            <Route path="/resources/comparing-dcam-and-cdmp">
              <DCAM />
            </Route>

            <Route path="/terms-and-conditions">
              <TermsAndConditions />
            </Route>
            <Route path="/code-of-conduct">
              <CodeOfConduct />
            </Route>
            <Route path="/thank-you">
              <ThankYou />
            </Route>

            <Redirect from="/products/membership" to="/products/cdmp-fundamentals-bundle" />
            <Redirect from="/landing/enterprise-membership" to="/landing/cdmp-study-program" />
            <Redirect from="/landing/enterprise-study-program" to="/landing/cdmp-study-program" />
            <Redirect from="/products/cdmp-study-plan-jon-shaulis/checkout" to="/products/cdmp-study-plan" />
            <Redirect from="/products/cdmp-exam-questions" to="/products/cdmp-question-sets" />
            <Redirect from="/products/specialist-exam-questions" to="/products/specialist-question-sets" />
            <Redirect from="/products/specialist-exam-guides" to="/products/specialist-guides" />
            <Redirect from="/products/cippe-exam-questions" to="/products/cippe-question-sets" />
            <Redirect from="/products/cap-exam-questions" to="/products/cap-question-sets" />
            <Redirect from="/products/cds-exam-questions" to="/products/data-science-practice-questions" />
            <Redirect from="/products/cds-exam-questions" to="/products/data-science-practice-questions" />
            <Redirect from="/products/data-strategy-essentials-course" to="/products/data-management-master-class" />
            <Redirect from="/resources/cdmp" to="/resources/cdmp-fundamentals-exam" />
            <Redirect from="/resources/specialist" to="/resources/cdmp-specialist-exam" />
            <Redirect from="/resources/projections" to="/resources/data-management-job-projections" />
            <Redirect from="/resources/foundations" to="/resources/foundations-of-data-strategy" />
            <Redirect from="/resources/cdmp-practice-questions" to="/resources/free-cdmp-practice-questions" />
            <Redirect from="/newsletter/sign-up" to="/newsletter" />
            <Redirect from="/resources/cdmp-training-course" to="/resources/cdmp-study-guide" />
            <Redirect from="/products/cdmp-small-group-sessions-q4" to="/products" />

            <CheckoutProvider>
              <Route exact path="/products/data-management-master-class">
                <DSECourse />
              </Route>
              <Route exact path="/products/gdpr-essentials-course">
                <GDPRCourse />
              </Route>
              <Route exact path="/products/resume-review">
                <ResumeReview />
              </Route>
              <Route exact path="/products/data-strategy-workbook">
                <DataStrategyWorkbook />
              </Route>
              <Route exact path="/products/document-checklist">
                <DocumentChecklist />
              </Route>
              <Route exact path="/products/cdmp-study-plan">
                <CDMPStudyPlan />
              </Route>
              <Route exact path="/products/cdmp-small-group-sessions-q4">
                <CDMP_Small_Group_Old />
              </Route>
              <Route exact path="/products/cdmp-small-group-sessions">
                <CDMP_Small_Group />
              </Route>
              <Route exact path="/products/virtual-cdmp-bootcamp">
                <CDMPBootcamp />
              </Route>

              <Route exact path="/products/data-science-email-bootcamp">
                <DSEmailBootcamp />
              </Route>
              <Route exact path="/products/data-science-email-bootcamp/certificate">
                <BIDSCert />
              </Route>
              <Route exact path="/products/t-shirt">
                <TShirt />
              </Route>
              <Route exact path="/products/mug">
                <Mug />
              </Route>
              <Route exact path="/products/cdmp-question-sets">
                <CDMPExamQuestions />
              </Route>
              <Route exact path="/products/cdmp-tutoring">
                <CDMPTutoring />
              </Route>
              <Route exact path="/products/cdmp-crash-course">
                <CDMPCrashCourse />
              </Route>
              <Route exact path="/products/cdmp-fundamentals-notes">
                <CDMPFundamentalsNotes />
              </Route>
              <Route exact path="/products/cdmp-exam-readiness-check">
                <CDMPExamReadinessCheck />
              </Route>
              <Route exact path="/landing/cdmp-study-program">
                <EnterpriseStudyProgram />
              </Route>
              <Route exact path="/landing/small-group-study-materials">
                <SmallGroup />
              </Route>
              <Route exact path="/landing/data-strategy-bootcamp">
                <EnterpriseBootcamp />
              </Route>
              <Route exact path="/landing/document-drafting-and-review">
                <DocumentDrafting />
              </Route>
              <Route exact path="/products/poster">
                <Poster />
              </Route>
              <Route exact path="/products/specialist-guides">
                <SpecialistGuides />
              </Route>
              <Route exact path="/products/specialist-question-sets">
                <SpecialistQuestions />
              </Route>
              <Route exact path="/products/cap-study-plan">
                <CAPStudyPlan />
              </Route>
              <Route exact path="/products/cap-question-sets">
                <CAPExamQuestions />
              </Route>
              <Route exact path="/products/data-science-practice-questions">
                <DSPracticeQuestions />
              </Route>
              <Route exact path="/products/cippe-study-plan">
                <CIPPEStudyPlan />
              </Route>
              <Route exact path="/products/cippe-question-sets">
                <CIPPEExamQuestions />
              </Route>
              <Route exact path="/products/gdpr-mind-maps">
                <GDPRMindMaps />
              </Route>
              <Route exact path="/products/career-coaching">
                <CareerCoaching />
              </Route>
              <Route exact path="/products/cdmp-fundamentals-bundle">
                <CDMPFundamentalsBundle />
              </Route>
              <Route exact path="/products/specialist-bundle">
                <CDMPSpecialistBundle />
              </Route>
              <Route exact path="/products/cippe-bundle">
                <CIPPEBundle />
              </Route>
              <Route exact path="/products/cap-bundle">
                <CAPBundle />
              </Route>
              <Route exact path="/products/data-science-bundle">
                <DSBundle />
              </Route>

              <Route path="/products/:id/checkout">
                <CheckoutController />
              </Route>
            </CheckoutProvider>
          </Switch>

          <TawkToScript />
        </LayoutProvider>
      </div>

      <Footer />
    </>
  );
};

const TawkToScript = () => {
  // eslint-disable-next-line no-unused-vars
  const Tawk_API = (function () {
    const s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/64b32f3094cf5d49dc63d37b/1h5dv0l7i";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  })();

  return null;
};

export default App;
