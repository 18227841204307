import TestimonialCard from "../../../components/testimonial-flip-card/TestimonialCard";

import testimonials from "../../../data/testimonials";

const CDMPStudyProgramTestimonials: React.VFC = () => {
  const testimonialsToShow = [
    testimonials.find((testimonial) => testimonial.id === "general-005"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-001"),
    testimonials.find((testimonial) => testimonial.id === "general-002"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-002"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-notes-001"),
    testimonials.find((testimonial) => testimonial.id === "general-003"),
  ];

  return (
    <div style={{ maxWidth: 1100, marginBottom: "2em" }}>
      <p className="title product-title">Testimonials</p>
      {testimonialsToShow.map((testimonial) => (
        <TestimonialCard key={testimonial.id} testimonial={testimonial} />
      ))}
    </div>
  );
};

export default CDMPStudyProgramTestimonials;
