import TestimonialCard from "../../components/testimonial-flip-card/TestimonialCard";

import testimonials from "../../data/testimonials";

const MoreTestimonials: React.VFC = () => {
  const testimonial = testimonials.find((testimonial) => testimonial.id === "general-001");

  return (
    <>
      <div
        className="teal-box homepage"
        style={{
          padding: "40px 40px 60px 40px",
          margin: "auto",
        }}
      >
        <TestimonialCard testimonial={testimonial} fontSize={1.1} />
      </div>
    </>
  );
};

export default MoreTestimonials;
