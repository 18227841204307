import { useState } from "react";

import NameForm from "../../components/sign-up-forms/NameFormContainer";
import EmailFormNewsletter from "../../components/sign-up-forms/EmailFormContainer";
import ThanksAnimation from "../../components/sign-up-forms/ThanksAnimation";

const NewsletterSignUp: React.VFC = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [validEmail, setValidEmail] = useState<boolean>(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    fetch("/.netlify/functions/sendinblue-email-list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstName,
        email: email,
        emailList: 36,
        source: "newsletter signup page",
      }),
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error);
      });

    setSubmitted(true);
  };

  return (
    <>
      <div className="blog" style={{ marginBottom: 40 }}>
        <h2 style={{ marginTop: 0 }}>Join 6,100+ data practitioners in our community.</h2>
        <p>Non-annoying, twice monthly email newsletters. Learn about best practices in Data Strategy.</p>
      </div>
      <div style={{ maxWidth: 600 }}>
        {!submitted ? (
          <form onSubmit={handleSubmit}>
            <div>
              <NameForm setFirstName={setFirstName} />
              <EmailFormNewsletter setEmail={setEmail} setValidEmail={setValidEmail} />
            </div>
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btn btn-accent"
                type="submit"
                style={{
                  width: "200px",
                  padding: "10px 20px",
                  margin: "4px 0",
                }}
                disabled={!firstName || !validEmail}
              >
                Sign Up
              </button>
            </div>
          </form>
        ) : (
          <ThanksAnimation firstName={firstName} email={email} />
        )}
        <div style={{ height: "2em" }} />
      </div>
    </>
  );
};

export default NewsletterSignUp;
