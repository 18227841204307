import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";

import { validateEmail } from "../../helpers/utils";
import SourceForm from "../../components/sign-up-forms/SourceFormContainer";
import CheckAnimation from "../../components/sign-up-forms/CheckAnimation";

export default function EventsSignup({ emailList }) {
  const desktop = useMediaQuery("(min-width: 900px)");
  const location = useLocation();

  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    fetch("/.netlify/functions/sendinblue-email-list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstName,
        email: email,
        emailList: emailList,
        source: "events page",
      }),
    });

    setSubmitted(true);
  };

  return (
    <>
      {desktop && (
        <div
          style={{
            margin: 40,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!submitted ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
                width: "calc(100% - 40px)",
                maxWidth: 1200,
              }}
            >
              <p
                style={{
                  fontFamily: "Raleway",
                  fontWeight: 900,
                  fontSize: "1.1em",
                  paddingTop: ".7em",
                  paddingRight: 20,
                  textWrap: "balance",
                }}
              >
                Sign up for event notifications:
              </p>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: desktop ? "end" : "center",
                  width: desktop ? 900 : "100%",
                  gap: 8,
                }}
              >
                <div>
                  <label htmlFor={"firstName"}>
                    <p
                      style={{
                        fontSize: ".9em",
                        color: "rgb(48, 49, 61)",
                      }}
                    >
                      <strong>First Name</strong>
                    </p>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    onBlur={(e) => setFirstName(e.target.value)}
                    className="field checkout-form-input"
                    style={{ width: 250 }}
                  />
                </div>
                <div>
                  <label htmlFor={"userEmail"}>
                    <p
                      style={{
                        fontSize: ".9em",
                        color: "rgb(48, 49, 61)",
                      }}
                    >
                      <strong>Email</strong>
                    </p>
                  </label>
                  <input
                    type="text"
                    id="userEmail"
                    onKeyUp={(e) => validateEmail(e, setEmail, setValidEmail)}
                    className="field checkout-form-input"
                    style={{ width: 250 }}
                  />
                </div>
                <button
                  className="btn"
                  type="submit"
                  style={{
                    background: location.pathname === "/events" ? "#08443a" : "#a2a1a1",
                    width: "200px",
                    padding: "10px 20px",
                    height: "3em",
                    color: location.pathname === "/events" ? "white" : "#000",
                    fontWeight: 900,
                  }}
                  disabled={!firstName || !validEmail}
                >
                  Subscribe
                </button>
              </form>
            </div>
          ) : (
            <>
              <div
                style={{
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 16,
                }}
              >
                <div
                  style={{
                    width: 54,
                    height: 54,
                  }}
                >
                  <CheckAnimation small />
                </div>
                <h1 style={{ textAlign: "center", marginRight: 100 }}>Thanks!</h1>
                <SourceForm firstName={firstName} email={email} />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
