import { useState } from "react";
import { NavLink } from "react-router-dom";

import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";

import ShopBurger from "./ShopBurger";
import { useMediaQuery } from "@mui/material";

const ShopButton = ({ shake }) => {
  const [shopBurgerIsActive, setShopBurgerIsActive] = useState(false);
  const desktop = useMediaQuery("(min-width: 1000px)");

  return (
    <li
      className="nav-item"
      onMouseOver={() => setShopBurgerIsActive(true)}
      onMouseLeave={() => setShopBurgerIsActive(false)}
    >
      <NavLink activeClassName="active" to="/products">
        <button className="btn btn-nav">
          <span style={{ display: "flex", alignItems: "center", gap: 8, fontWeight: 800 }}>
            {desktop && (
              <span key={shake} className="jump-shake">
                <ShoppingBasketIcon style={{ fontSize: 14 }} />
              </span>
            )}
            <span style={{ fontWeight: 900, fontSize: ".9em" }}>Shop</span>
          </span>
        </button>
      </NavLink>
      <ShopBurger shopBurgerIsActive={shopBurgerIsActive} setShopBurgerIsActive={setShopBurgerIsActive} />
    </li>
  );
};

export default ShopButton;
