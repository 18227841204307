import VisualDescription from "./VisualDescription";

export default function ProductDescription({ PRODUCT }) {
  return (
    <div className="gray-box" id="product-description">
      <h4>Description</h4>
      <VisualDescription product={PRODUCT} />
    </div>
  );
}
