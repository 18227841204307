import { useState } from "react";
import { Link } from "react-router-dom";

import CDMPLevelsModal from "../../components/CDMPLevelsModal";
import CDMPBundleBox from "./CDMPBundleBox";
import ExtendedDescription from "../../components/product-pages/ExtendedDescription";
import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";

import testimonials from "../../data/testimonials";
import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import BuyNowButton from "../../components/product-pages/BuyNowButton";

const PRODUCT = "CDMP Fundamentals Bundle";

const CDMPFundamentalsBundle: React.FC<{}> = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && <CDMPLevelsModal setShowModal={setShowModal} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        description={description(setShowModal)}
        extendedDescription={extendedDescription(setShowModal)}
        rest={rest()}
      />
    </>
  );
};

const description = (setShowModal) => {
  return (
    <div className="gray-box" id="product-description">
      <h4>Description</h4>
      <div className="blog">
        <p>
          Fully prepare for the{" "}
          <Link to="/resources/cdmp-fundamentals-exam" target="_blank" rel="noreferrer">
            Certified Data Management Professional (CDMP) Fundamentals Exam
          </Link>{" "}
          with speed and confidence! Our <strong>best selling study materials</strong> provide guidance from the very
          beginning through the point you are ready to take the exam and <strong>attain your goal score</strong>. Plus,
          you'll <strong>save $20</strong> relative to buying each item in the CDMP Fundamentals Bundle individually.
        </p>
        <br />
        <p>
          The <strong>CDMP Fundamentals Bundle</strong> will help you:
        </p>
        <ul>
          <li>
            Retain the <strong>most important information</strong> from the 500+ page{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>
          </li>
          <li>
            Manage your time effectively as you <strong>develop a better understanding of Data Management</strong>{" "}
            concepts on the{" "}
            <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
              CDMP Fundamentals Exam
            </Link>
          </li>
          <li>
            <strong>Study efficiently</strong> and <strong>pass the exam</strong> at the{" "}
            <span className="fake-link-1" onClick={() => setShowModal(true)}>
              Associate, Practitioner, or Master level
            </span>
            !
          </li>
        </ul>
        <p>
          <em>
            Note: the products contained in the CDMP Fundamentals Bundle have been updated to reflect the content of the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <span style={{ fontStyle: "normal" }}>DMBOK v2 Revised</span>
            </a>
            .
          </em>
        </p>
      </div>
    </div>
  );
};

const rest = () => {
  const testimonialsToShowOne = [
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-001"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-001"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-002"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-006"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-notes-003"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-notes-001"),
  ];

  const testimonialsToShowTwo = [
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-000"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-020"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-004"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-003"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-exam-readiness-check-000"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-019"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-notes-000"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-018"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-014"),
  ];

  const testimonialsToShowThree = [
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-notes-004"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-bundle-002"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-008"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-007"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-bundle-001"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-005"),
  ];

  return (
    <>
      <CDMPBundleBox />
      <ProductTestimonials testimonialsToShow={testimonialsToShowOne} color="#efefef" />
      <BuyNowButton
        paymentName="cdmp-fundamentals-bundle"
        learnMore="https://mcusercontent.com/c164c67d8c9591e3b056f39b1/files/ff37e2f0-2a4e-b822-c12a-7f7b6c158ade/CDMP_Fundamentals_Bundle.pdf"
      />
      <ProductTestimonials testimonialsToShow={testimonialsToShowTwo} color="#efefef" />
      <BuyNowButton
        paymentName="cdmp-fundamentals-bundle"
        learnMore="https://mcusercontent.com/c164c67d8c9591e3b056f39b1/files/ff37e2f0-2a4e-b822-c12a-7f7b6c158ade/CDMP_Fundamentals_Bundle.pdf"
      />
      <ProductTestimonials testimonialsToShow={testimonialsToShowThree} color="#efefef" />
      <BuyNowButton
        paymentName="cdmp-fundamentals-bundle"
        learnMore="https://mcusercontent.com/c164c67d8c9591e3b056f39b1/files/ff37e2f0-2a4e-b822-c12a-7f7b6c158ade/CDMP_Fundamentals_Bundle.pdf"
      />
    </>
  );
};

const extendedDescription = (setShowModal) => {
  return (
    <div className="extended-description-container">
      <ExtendedDescription
        description={
          <div className="extended-description">
            <p>
              The <strong>CDMP Fundamentals Bundle</strong> includes the{" "}
              <Link to="/products/cdmp-study-plan" target="_blank">
                CDMP Study Plan
              </Link>
              , <Link to="/products/cdmp-fundamentals-notes">Fundamentals Notes</Link>,{" "}
              <Link to="/products/cdmp-question-sets" target="_blank">
                Question Sets
              </Link>
              , and the{" "}
              <Link to="/products/cdmp-exam-readiness-check" target="_blank">
                Exam Readiness Check
              </Link>
              . These <strong>best selling study materials</strong> are designed to help data practitioners ace the{" "}
              <Link to="/resources/cdmp-fundamentals-exam" target="_blank" rel="noreferrer">
                CDMP Fundamentals Exam
              </Link>{" "}
              (
              <span className="fake-link-1" onClick={() => setShowModal(true)}>
                Associate level
              </span>
              ), and they are also an excellent foundation for those who choose to continue to the{" "}
              <Link to="/resources/cdmp-specialist-exam" target="_blank" rel="noreferrer">
                Specialist Exams
              </Link>{" "}
              (
              <span className="fake-link-1" onClick={() => setShowModal(true)}>
                Practitioner and Master levels
              </span>
              ).
            </p>
            <ul>
              <li>
                The{" "}
                <Link to="/products/cdmp-study-plan" target="_blank">
                  Study Plan
                </Link>{" "}
                provides a thorough guide to the concepts tested on the{" "}
                <Link to="/resources/cdmp-fundamentals-exam" target="_blank" rel="noreferrer">
                  CDMP Fundamentals Exam
                </Link>{" "}
                through 75+ emails.
              </li>
              <li>
                The <Link to="/products/cdmp-fundamentals-notes">Fundamentals Notes</Link> offers 30+ pages of summary
                of the{" "}
                <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                  <em>DMBOK</em>
                </a>
                , designed to help you retain the most important information from the book.
              </li>
              <li>
                The three{" "}
                <Link to="/products/cdmp-question-sets" target="_blank">
                  Question Sets
                </Link>{" "}
                each contain 50 distinct, never-before-seen practice questions written by our team at Data Strategy
                Professionals. In the answers section of each document, we have provided a detailed explanation for each
                question.
              </li>
              <li>
                The{" "}
                <Link to="/products/cdmp-exam-readiness-check" target="_blank">
                  Exam Readiness Check
                </Link>{" "}
                helps you understand if you're ready to take the test and attain your target score (60%+ for{" "}
                <span className="fake-link-1" onClick={() => setShowModal(true)}>
                  Associate level
                </span>
                , 70%+ for{" "}
                <span className="fake-link-1" onClick={() => setShowModal(true)}>
                  Practitioner level
                </span>
                , 80%+ for{" "}
                <span className="fake-link-1" onClick={() => setShowModal(true)}>
                  Master level
                </span>
                ).
              </li>
            </ul>
            <p>
              If you have any questions about the product, please check out the <strong>FAQs</strong> below or reach out
              to us at <a href="mailto:support@datastrategypros.com">support@datastrategypros.com</a>.
            </p>
          </div>
        }
      />
      <ExtendedDescription
        title="Frequently Asked Questions"
        description={
          <div className="extended-description faq">
            <p>
              <strong>What's included in the CDMP Fundamentals Bundle?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              You'll receive access to a{" "}
              <Link to="/products/cdmp-study-plan" target="_blank">
                Study Plan
              </Link>{" "}
              for reading the 500+ page{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>{" "}
              as well as the{" "}
              <Link to="/products/cdmp-fundamentals-notes" target="_blank">
                Fundamentals Notes
              </Link>{" "}
              and{" "}
              <Link to="/products/cdmp-question-sets" target="_blank">
                150 practice questions
              </Link>{" "}
              to enhance your knowledge of Data Management. Finally, wrap up your studies with the{" "}
              <Link to="/products/cdmp-exam-readiness-check" target="_blank">
                Exam Readiness Check
              </Link>{" "}
              that assesses your readiness to attain your target score on the exam.
            </p>
            <p>
              <strong>
                Is the <em>DMBOK</em> included in my purchase?
              </strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              No, you are responsible for purchasing the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>{" "}
              on your own, either as a{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                hard copy
              </a>{" "}
              or an{" "}
              <a href="https://technicspub.com/dmbok/" target="_blank" rel="noreferrer">
                ebook
              </a>{" "}
              for the open book{" "}
              <Link to="/resources/cdmp-fundamentals-exam" target="_blank" rel="noreferrer">
                CDMP Fundamentals Exam
              </Link>
              .
            </p>
            <p>
              <strong>Is the CDMP Fundamentals Bundle sufficient to prepare for the CDMP Fundamentals Exam?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              We very strongly recommend that you read (or at least very thoroughly skim) the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK v2 Revised</em>
              </a>{" "}
              with a particular focus on the seven chapters listed as options for the{" "}
              <Link to="/resources/cdmp-specialist-exam" target="_blank" rel="noreferrer">
                CDMP Specialist Exams
              </Link>
              , which collectively comprise 70% of the CDMP Fundamentals Exam. Also, taking notes in your book is an
              important part of preparing for the open book exam. You may also be interested in our{" "}
              <a
                href="https://www.youtube.com/playlist?list=PLGd_Hl6NWLZe1Jtz8oG0ZJ7lDzdgOGZn5"
                target="_blank"
                rel="noreferrer"
              >
                video series on YouTube
              </a>
              .
            </p>
            <p>
              <strong>
                Has the content of the CDMP Fundamentals Bundle been updated to reflect the{" "}
                <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                  <em>DMBOK</em> v2 Revised
                </a>
                ?
              </strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Yes, the{" "}
              <a href="https://www.dama.org/cpages/changes-to-dama-dmbok-2" target="_blank" rel="noreferrer">
                revisions
              </a>{" "}
              to the <em>DMBOK</em> have been incorporated into all the products contained in the CDMP Fundamentals
              Bundle.
            </p>

            <p>
              <strong>How long will it take me to study for the CDMP Fundamentals Exam?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              We recommend one to two hours of study per day over 90 days. You may be able to prepare for the exam more
              quickly if you have background knowledge of Data Management knowledge areas on the test.
            </p>
            <p>
              <strong>What's next after I finish the CDMP Fundamentals Exam?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Be sure to share your achievement with our team via{" "}
              <a href="mailto:info@datastrategypros.com" target="_blank" rel="noreferrer">
                email
              </a>{" "}
              or on social media (
              <a href="https://www.facebook.com/DataStrategyPros" target="_blank" rel="noreferrer">
                Facebook
              </a>{" "}
              or{" "}
              <a href="https://www.linkedin.com/company/data-strategy-professionals/" target="_blank" rel="noreferrer">
                LinkedIn
              </a>
              ) so we can celebrate your accomplishment with you 🎉 Then check out our{" "}
              <Link to="/products/specialist-bundle">CDMP Specialist Bundle</Link> to prepare for the{" "}
              <Link to="/resources/cdmp-specialist-exam">Specialist Exams</Link>.
            </p>
            <p>
              <strong>I have another question about the exam that is not listed here.</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              We suggest you check out the official{" "}
              <a href="https://cdmp.info/faqs/" target="_blank" rel="noreferrer">
                CDMP FAQs
              </a>{" "}
              or{" "}
              <a href=" https://cdmp.info/general-help/" target="_blank" rel="noreferrer">
                reach out to DAMA
              </a>{" "}
              for any questions not listed here.
            </p>
          </div>
        }
      />
    </div>
  );
};

export default CDMPFundamentalsBundle;
