import { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import day0 from "../../img/products/breaking/emails/day0.webp";
import day1 from "../../img/products/breaking/emails/day1.webp";
import day8 from "../../img/products/breaking/emails/day8.webp";
import day15 from "../../img/products/breaking/emails/day15.webp";
import day22 from "../../img/products/breaking/emails/day22.webp";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import CustomModal from "../../components/CustomModal";
import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";

import { responsive } from "../../helpers/carouselStyles";
import { handleFreeTrialClick } from "../../helpers/checkoutUtils";

import testimonials from "../../data/testimonials";

import { CheckoutContext } from "../../contexts/CheckoutContext";

const PRODUCT = "Data Science Email Bootcamp";

export default function BIDSEmailBootcamp() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        description={description}
        tealBox={tealBox}
        rest={Rest(handleShowClick)}
      />
    </>
  );
}

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <div className="blog">
      <p>
        Email Bootcamp delivers the opportunity to learn Data Science through{" "}
        <strong>three to four emails each week</strong> on a different foundational topic. You'll gain access to an{" "}
        <strong>exclusive Slack channel</strong> to interact with other data professionals and get any of your questions
        related to the course content <strong>answered by an expert</strong>.
      </p>
      <p>
        On a different timeline to achieve Data Science mastery? No problem! Select <strong>Immediate Access</strong> to
        receive all 60+ emails of the Data Science Email Bootcamp at one time.
      </p>
      <p>
        <em>
          Note: this product is available as part of the{" "}
          <Link to="/products/data-science-bundle" target="_blank">
            Data Science Bundle
          </Link>
        </em>
      </p>
    </div>
  </div>
);

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>What's Included</h4>
    <ul>
      <li>
        Key concepts in <strong>Statistics</strong>, <strong>SQL</strong>, and <strong>Data Management</strong>
      </li>
      <li>
        Fundamentals for <strong>Python</strong>, including a deep dive on <strong>pandas</strong> and{" "}
        <strong>sklearn</strong>
      </li>
      <li>
        Overview of <strong>Machine Learning</strong> and <strong>Deep Learning</strong>
      </li>
      <li>
        Practical advice on how to stand out as a leader in <strong>Data Ethics</strong> and{" "}
        <strong>Data Strategy</strong>
      </li>
      <li>
        <strong>Sample projects</strong> and <strong>job hunt tips</strong> to advance your Data Science career
      </li>
      <li>
        <strong>Certificate of completion</strong> to showcase your accomplishment on LinkedIn
      </li>
    </ul>
  </div>
);

const Rest = (handleShowClick) => {
  const history = useHistory();
  const checkoutContext = useContext(CheckoutContext) as checkoutContext;
  const { setStep, setConfirmationTable } = checkoutContext.checkout;

  const testimonialsToShow = testimonials.filter((testimonial) => testimonial.id.includes("bids-email-bootcamp"));
  return (
    <>
      <div
        className="white-outline-box"
        style={{
          maxWidth: "1400px",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "30px 40px",
        }}
      >
        <h4 className="box-title">Breaking into Data Science Guarantee</h4>
        <p>
          Our team at Data Strategy Professionals is so confident that Email Bootcamp will prepare you to get a job in
          this field, that{" "}
          <strong>
            if you aren't working in a data-related role six months after completing the course, we will refund your
            purchase
          </strong>
          . You can get in touch with{" "}
          <a href="mailto:support@datastrategypros.com?subject=BIDS%20Email%20Bootcamp%20-%20Refund%20Request">
            Customer Support
          </a>{" "}
          for more information.
        </p>
      </div>

      <ProductTestimonials testimonialsToShow={testimonialsToShow} />

      <div className="gray-box">
        <div className="center-text-box">
          <h4 className="box-title" style={{ textAlign: "center" }}>
            Want to Check it out First?
          </h4>
          <div className="product-info sevenths">
            <div style={{ gridColumn: "1 / -2" }}>
              <p>
                Get the <strong>first seven days</strong> of the Data Science Email Bootcamp delivered straight to your
                inbox for free. In seven days, you'll learn:
              </p>
              <div className="two-col">
                <ul>
                  <li>The responsibilities of a Data Scientist</li>
                  <li>The mathematical concepts you need to be familiar with</li>
                  <li>Which computer science languages you need to know</li>
                  <li>How to use Python and SQL to analyze data</li>
                  <li>
                    What Data Scientists need to understand about{" "}
                    <Link to="/resources/foundations-of-data-strategy" target="_blank">
                      Data Strategy
                    </Link>
                  </li>
                  <li>Essential concepts in Machine Learning</li>
                  <li>Tips and tricks to advance your career</li>
                </ul>
                <p>& more!</p>
              </div>
              <br />
              <p>
                This is a great way to <strong>experience the course</strong> and decide if it's a good fit for you.
              </p>
              <br />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-accent"
                style={{
                  width: "300px",
                  height: "37px",
                  padding: "10px 20px",
                  margin: "4px 0",
                }}
                onClick={() => {
                  const paymentName = "data-science-email-bootcamp";
                  handleFreeTrialClick(paymentName, history, setConfirmationTable, setStep);
                }}
              >
                Get the free trial
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="white-box carousel-container">
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Screenshots
        </h4>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          keyBoardControl={true}
          infinite={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          // @ts-ignore
          dotListclassName="custom-dot-list-style"
          itemclassName="carousel-item"
          shouldResetAutoplay={false}
        >
          <img src={day0} alt="welcome email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          <img src={day1} alt="day one email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          <img src={day8} alt="day eight email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          <img src={day15} alt="day 15 email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
          <img src={day22} alt="day 22 email" onClick={handleShowClick} style={{ cursor: "pointer" }} />
        </Carousel>
      </div>

      <div className="gray-box">
        <h4 className="box-title">Features</h4>
        <div className="two-col three-col center-text-box">
          <p>
            🔬&nbsp;&nbsp;Week 1 – an&nbsp;<strong>overview</strong>&nbsp;of the Data Science field
            <br />
            ⚛️&nbsp;&nbsp;Week 2 – the key skill of&nbsp;
            <strong>statistics</strong>
            <br />
            🐍&nbsp;&nbsp;Week 3 -&nbsp;intro to&nbsp;<strong>Python</strong>
            &nbsp;for Data Science
            <br />
            🐼&nbsp;&nbsp;Week 4 -&nbsp;learn&nbsp;<strong>pandas</strong>
            &nbsp;for manipulating tabular data
            <br />
            🧪&nbsp;&nbsp;Week 5 -&nbsp;<strong>sklearn</strong>&nbsp;for machine learning
            <br />
            ⚙️&nbsp;&nbsp;Week 6 – fundamental applications of <strong>SQL</strong>
            <br />
            🧭&nbsp;&nbsp;Week 7 – data in the enterprise with&nbsp;
            <strong>Data Strategy</strong>
            <br />
            ⚖️&nbsp;&nbsp;Week 8 – fundamental importance of&nbsp;
            <strong>Data Ethics</strong>
            <br />
            🚗&nbsp;&nbsp;Week 9 – <strong>neural networks</strong>&nbsp;for Machine Learning
            <br />
            🏎&nbsp;&nbsp;Week 10 -&nbsp;GPUs and cloud for&nbsp;
            <strong>Deep Learning</strong>
            <br />
            🚀&nbsp;&nbsp;Week 11 -&nbsp;<strong>sample projects</strong>
            &nbsp;to grow your portfolio
            <br />
            💻&nbsp;&nbsp;Week 12 – building your career to attain a&nbsp;
            <strong>Data Science job</strong>
          </p>
        </div>
      </div>
    </>
  );
};
