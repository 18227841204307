export default function TestimonialCard({
  testimonial,
  frontFontSize = 1.8,
  fontSize = 1,
  quotes = true,
  productPage = false,
}) {
  const testimonialText = productPage ? testimonial.plainText : testimonial.content;
  return (
    <div className="visual-description-item">
      <div
        className="product-testimonial-card-simplified"
        style={{
          zIndex: 1,
          position: "relative",
        }}
      >
        <p
          className="testimonial-text"
          dangerouslySetInnerHTML={{
            __html: quotes ? `"${testimonialText}"` : testimonialText,
          }}
          style={{ fontSize: `${fontSize}em`, position: "relative", color: "transparent" }}
        />
      </div>
      <div
        style={{
          padding: "20px 25px 0px 0px",
          position: "absolute",
          top: 0,
          zIndex: 3,
          width: "100%",
          lineHeight: 1.05,
        }}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: `"${testimonial.short}" →`,
          }}
          style={{ fontSize: `${frontFontSize}em`, zIndex: 3, position: "relative", margin: "0 8px" }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <div>
          <p style={{ textAlign: "right" }}>— {testimonial.name},</p>
          <p
            style={{
              textAlign: "right",
              fontStyle: "italic",
            }}
          >
            {testimonial.title}
          </p>
        </div>
        {testimonial.img && <img className="testimonial-img" src={testimonial.img} alt="" />}
      </div>
    </div>
  );
}
