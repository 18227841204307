import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";

import email0 from "../../img/products/data-privacy-mind-maps/gdpr_01.webp";
import email1 from "../../img/products/data-privacy-mind-maps/gdpr_02.webp";
import email2 from "../../img/products/data-privacy-mind-maps/gdpr_03.webp";

import tiny0 from "../../img/products/data-privacy-mind-maps/tiny/1.1.webp";
import tiny1 from "../../img/products/data-privacy-mind-maps/tiny/1.2.webp";
import tiny2 from "../../img/products/data-privacy-mind-maps/tiny/1.3.webp";
import tiny3 from "../../img/products/data-privacy-mind-maps/tiny/2.1.webp";
import tiny4 from "../../img/products/data-privacy-mind-maps/tiny/2.2.webp";
import tiny5 from "../../img/products/data-privacy-mind-maps/tiny/2.3.webp";
import tiny6 from "../../img/products/data-privacy-mind-maps/tiny/2.4.webp";
import tiny7 from "../../img/products/data-privacy-mind-maps/tiny/3.1.webp";
import tiny8 from "../../img/products/data-privacy-mind-maps/tiny/3.2.webp";
import tiny9 from "../../img/products/data-privacy-mind-maps/tiny/3.3.webp";
import tiny10 from "../../img/products/data-privacy-mind-maps/tiny/4.1.webp";
import tiny11 from "../../img/products/data-privacy-mind-maps/tiny/4.2.webp";
import tiny12 from "../../img/products/data-privacy-mind-maps/tiny/4.3.webp";
import tiny13 from "../../img/products/data-privacy-mind-maps/tiny/4.4.webp";
import tiny14 from "../../img/products/data-privacy-mind-maps/tiny/4.5.webp";
import tiny15 from "../../img/products/data-privacy-mind-maps/tiny/5.1.webp";
import tiny16 from "../../img/products/data-privacy-mind-maps/tiny/5.2.webp";
import tiny17 from "../../img/products/data-privacy-mind-maps/tiny/5.3.webp";
import tiny18 from "../../img/products/data-privacy-mind-maps/tiny/6.1.webp";
import tiny19 from "../../img/products/data-privacy-mind-maps/tiny/6.2.webp";
import tiny20 from "../../img/products/data-privacy-mind-maps/tiny/7.1.webp";
import tiny21 from "../../img/products/data-privacy-mind-maps/tiny/7.2.webp";

import CustomModal from "../../components/CustomModal";

const customResponsiveness = {
  desktop: {
    breakpoint: { max: 6000, min: 1600 },
    items: 7,
    slidesToSlide: 7,
  },
  tablet: {
    breakpoint: { max: 1600, min: 640 },
    items: 5,
    slidesToSlide: 5,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 3,
    slidesToSlide: 3,
  },
};

const PRODUCT = "GDPR Mind Maps and Checklists";

export default function GDPRMindMaps() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        description={description}
        clickable
        tealBox={tealBox}
        rest={rest(handleShowClick)}
      />
    </>
  );
}

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <div className="blog">
      <p>
        This brilliant set of mind maps, documents, and checklists created by Privacy and Cybersecurity expert,{" "}
        <a href="https://www.linkedin.com/in/andreyprozorov/" target="_blank" rel="noreferrer">
          Andrey Prozorov
        </a>
        , is intended for privacy professionals who deal with GDPR compliance. Reviewing these documents is excellent
        preparation for the{" "}
        <a href="https://iapp.org/certify/cippe/" target="_blank" rel="noreferrer">
          Certified Information Privacy Professional/Europe (CIPP/E) exam
        </a>{" "}
        from{" "}
        <a href="https://iapp.org/" target="_blank" rel="noreferrer">
          IAPP
        </a>
        .
      </p>
      <p>
        These mind maps and checklists are truly a wonderful resource for anyone who wants to understand the European
        Union's Data Privacy & Protection regime in greater detail.
      </p>
      <p>
        Don't miss our{" "}
        <Link to="/products/cippe-study-plan" target="_blank">
          CIPP/E Study Plan
        </Link>{" "}
        and{" "}
        <Link to="/products/cippe-exam-questions" target="_blank">
          CIPP/E Question Sets
        </Link>{" "}
        for additional guidance and practice for the{" "}
        <a href="https://iapp.org/" target="_blank" rel="noreferrer">
          IAPP
        </a>{" "}
        exam.
      </p>
    </div>
  </div>
);

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>What's Included</h4>
    <ul>
      <li>23 highly detailed, full color pages</li>
      <li>Includes 10 mind maps, 8 documents, and 4 checklists to improve your understanding of GDPR</li>
      <li>
        Support for <strong>any questions</strong> about the contents at{" "}
        <a href="mailto:support@datastrategypros.com?subject=GDPR%20Mind%20Maps">support@datastrategypros.com</a>
      </li>
    </ul>
  </div>
);
const rest = (handleShowClick) => {
  const galleryImgs = [];

  galleryImgs.push(
    tiny0,
    tiny1,
    tiny2,
    tiny3,
    tiny4,
    tiny5,
    tiny6,
    tiny7,
    tiny8,
    tiny9,
    tiny10,
    tiny11,
    tiny12,
    tiny13,
    tiny14,
    tiny15,
    tiny16,
    tiny17,
    tiny18,
    tiny19,
    tiny20,
    tiny21
  );

  return (
    <>
      <div className="white-box">
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Gallery
        </h4>
        <div className="gallery">
          <div className="carousel-container">
            <Carousel
              responsive={customResponsiveness}
              swipeable={true}
              draggable={true}
              ssr={true}
              keyBoardControl={true}
              infinite={true}
              arrows={true}
            >
              {galleryImgs.map((source, idx) => {
                return <img src={source} alt="screenshot from GDPRMindMaps" key={idx} />;
              })}
            </Carousel>
          </div>
        </div>
      </div>

      <div className="teal-box" style={{ margin: "20px 0 20px 0" }}>
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Contents
        </h4>
        <div className="center-text-box">
          <div className="two-col contents">
            <p>
              <strong>1.1 GDPR mind map</strong> – detailed outline of the main GDPR articles and requirements
            </p>
            <p>
              <strong>1.2 Key points of GDPR</strong> – one-page document articulating the key points of GDPR
            </p>
            <p>
              <strong>1.3 GDPR Scope</strong> – one-page document on the extraterritorial applicability of GDPR
            </p>
            <p>
              <strong>2.1 GDPR Principles</strong> – mind map that outlines seven fundamental principles related to the
              processing of personal data in accordance with GDPR
            </p>
            <p>
              <strong>2.2 Rights of the data subject</strong> – table listing the Rights of the data subject and related
              articles
            </p>
            <p>
              <strong>2.3 Personal data rights (ICO UK)</strong> – mind map articulating the core Personal data rights
              with the comments by the International Commissioner's Office of the UK
            </p>
            <p>
              <strong>2.4 Right to be informed and Right of access</strong> – table listing articles of GDPR related to
              the data subject notification
            </p>
            <p>
              <strong>3.1 GDPR Lawfulness</strong> – mind map of six available lawful bases for data processing (GDPR
              Article 6)
            </p>
            <p>
              <strong>3.2 The lawful basis and rights of the data subject</strong> – table listing of the lawful basis
              and rights of the data subject as defined by GDPR
            </p>
            <p>
              <strong>3.3 GDPR Legitimate Interests</strong> – mind map outlining main requirements and recommendations
              for using Legitimate Interest as a legal basis for processing
            </p>
            <p>
              <strong>4.1 GDPR Consent</strong> – mind map outlining main requirements and recommendations for using
              Consent as a legal basis for processing
            </p>
            <p>
              <strong>4.2 GDPR Consent Checklist</strong> – table listing of checklist for GDPR Consent compliance
            </p>
            <p>
              <strong>4.3 Privacy Notice Checklist</strong> – table listing of checklist for GDPR Privacy Notice
              compliance
            </p>
            <p>
              <strong>4.4 Privacy Notice</strong> – mind map articulating a typical structure of a privacy notice
            </p>
            <p>
              <strong>4.5 Privacy Notice vs Privacy Policy</strong> – table listing the difference between the two
              approaches
            </p>
            <p>
              <strong>5.1 Data Protection Officer (DPO)</strong> – mind map articulating concepts and responsibilities
              of a Data Protection Office under GDPR
            </p>
            <p>
              <strong>5.2 Data Protection Impact Assessment (DPIA)</strong> – mind map covering essential information on
              a DPIA
            </p>
            <p>
              <strong>5.3 Records of processing activities (RoPA) checklist</strong> – table listing requirements for
              GDPR RoPA compliance
            </p>
            <p>
              <strong>6.1 GDPR and Security</strong> – mind map emphasizing the importance of implementing security
              measures in GDPR
            </p>
            <p>
              <strong>6.2 Personal Data Breach Notification</strong> – table listing of the main requirements for
              Personal Data Breach Notification
            </p>
            <p>
              <strong>7.1 The Key Steps to Take to Ensure GDPR Compliance</strong> – table listing of main tasks for
              GDPR compliance
            </p>
            <p>
              <strong>7.2 GDPR Accountability Checklist</strong> – table listing of checklist with documents and records
              to confirm compliance with the Accountability principle
            </p>
          </div>
          <br />
        </div>
      </div>

      <div className="white-box">
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Screenshots
        </h4>
        <div className="image-display-tri">
          <figure>
            <img src={email0} alt="GDPR mind map" onClick={handleShowClick} style={{ cursor: "pointer" }} />
            <figcaption style={{ textAlign: "center" }}>GDPR mind map</figcaption>
          </figure>
          <figure>
            <img src={email1} alt="Key points of GDPR" onClick={handleShowClick} style={{ cursor: "pointer" }} />
            <figcaption style={{ textAlign: "center" }}>Key points of GDPR</figcaption>
          </figure>
          <figure>
            <img src={email2} alt="GDPR scope criteria" onClick={handleShowClick} style={{ cursor: "pointer" }} />
            <figcaption style={{ textAlign: "center" }}>GDPR scope criteria</figcaption>
          </figure>
        </div>
      </div>
    </>
  );
};
