import { useEffect } from "react";
import { Link } from "react-router-dom";

import logo from "../../img/helmet/products/logo_cdmp.png";

import CustomHelmet from "../../components/CustomHelmet";
import InfoTooltip from "../../components/InfoTooltip";

import IntellectualProperty from "./IntellectualProperty";

export default function CodeOfConduct() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description="Code of Conduct"
        name="Code of Conduct"
        link="/code-of-conduct"
        img={logo}
        alt="hand holding lightbulb"
        author="Data Strategy Professionals"
      />
      <div className="home-layout">
        <h1 className="title">Code of Conduct</h1>
        <div className="blog">
          <p>Data Strategy Professionals is a professional community that is supportive to all members.</p>

          <div style={{ marginBottom: "2em" }}>
            <h4>Harassment-Free Community</h4>
            <p>
              We are dedicated to creating a harassment-free experience for every community member, regardless of
              experience level, country of origin, race, ethnicity, gender, gender identity, sexual orientation, age,
              religion, socioeconomic status, physical appearance, caste, or creed.
            </p>
            <p>
              We do not tolerate harassment of community members in any form. Violations may result in sanction or
              expulsion at the discretion of the organizer.
            </p>
          </div>

          <div style={{ marginBottom: "2em" }}>
            <h4>Social Media Guidelines</h4>
            <p>
              Our{" "}
              <Link to="/#community" target="_blank">
                social media spaces
              </Link>{" "}
              are designed to create a supportive, professional, and harassment-free environment for all members. To
              ensure that we maintain this atmosphere, we ask all community participants to follow these guidelines:
            </p>
            <ul style={{ marginTop: "1em" }}>
              <li>To comply with fair use doctrine, please refrain from requesting PDF resources</li>
              <li>
                Soliciting direct messages is prohibited; we encourage all conversations and requests for assistance
                take place within the group or dedicated group chat
              </li>
              <li>
                Please respect the privacy of community members and refrain from sharing personal or sensitive
                information
              </li>
            </ul>

            <p>Violations of these guidelines may result in expulsion at the discretion of the organizer.</p>
          </div>

          <div style={{ marginBottom: "2em" }}>
            <h4>Synchronous Training Guidelines</h4>
            <p>
              For participants of synchronous training sessions (including but not limited to{" "}
              <Link to="/events" target="_blank">
                Discussion Groups
              </Link>
              ,{" "}
              <Link to="/products/cdmp-small-group-sessions" target="_blank">
                Small Group Sessions
              </Link>
              ,{" "}
              <Link to="/products/virtual-cdmp-bootcamp" target="_blank">
                Bootcamps
              </Link>
              , and{" "}
              <Link to="/events" target="_blank">
                Q&A sessions
              </Link>
              ), we ask that you follow these guidelines:
            </p>
            <ul style={{ marginTop: "1em" }}>
              <li>
                Please join from a quiet area if you're planning to speak during the session; please do your best to
                remain on mute unless speaking
                <InfoTooltip text="We know mistakes happen! If you forget to mute yourself, don't worry. We'll remind you to mute your microphone." />
              </li>
              <li>
                Please use the{" "}
                <a
                  href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0068290"
                  target="_blank"
                  rel="noreferrer"
                >
                  Zoom hand raise feature
                </a>{" "}
                within the React toolbar before coming off mute to ask a question or contribute to the discussion
              </li>
              <li>
                If you come off mute, please make a good faith effort to limit any background noises that may distract
                other participants
                <InfoTooltip text="A pair of quality headphones can help reduce background noise and improve the audio quality of your microphone." />
              </li>
              <li>
                Keep discussions, comments, and questions on topic; participants engaging in off-topic conversation will
                be asked to redirect their focus back on topic
                <InfoTooltip text="If you have any questions about what constitutes 'on-topic discussion,' don't hesitate to ask in the chat." />
              </li>
            </ul>
            <p>
              Violations of these guidelines may result in restriction of privileges, removal from the session, or
              expulsion from the training program at the discretion of the organizer. In the case of expulsion,
              customers will be offered a prorated refund.
            </p>
          </div>

          <IntellectualProperty />

          <h4>Support</h4>
          <p>
            If you have any questions or experience any issues related to community conduct, please contact{" "}
            <a
              href="mailto:support@datastrategypros.com?subject=Code%20of%20Conduct%20concern"
              target="_blank"
              rel="noreferrer"
            >
              Support
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
}
