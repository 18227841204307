import LeadMagnetScreenshots from "../../../components/lead-magnet/LeadMagnetScreenshots";

import scr1 from "../../../img/lead-magnets/cert/scr1.webp";
import scr2 from "../../../img/lead-magnets/cert/scr2.webp";
import scr3 from "../../../img/lead-magnets/cert/scr3.webp";

const BestCertificationsScreenshots: React.FC = () => {
  const images = [
    { img: scr1, alt: "cover" },
    { img: scr2, alt: "page" },
    { img: scr3, alt: "page" },
  ];

  return <LeadMagnetScreenshots images={images} />;
};

export default BestCertificationsScreenshots;
