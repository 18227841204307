import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

const InfoTooltip: React.FC<{ text: string; placement?: TooltipProps["placement"] }> = ({
  text,
  placement = "top",
}) => {
  return (
    <Tooltip
      title={<span style={{ fontSize: 13 }}>{text}</span>}
      placement={placement}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-12, -12],
              },
            },
          ],
        },
      }}
    >
      <InfoIcon style={{ fontSize: "1em", color: "#959393", marginLeft: 4, paddingTop: 2, cursor: "pointer" }} />
    </Tooltip>
  );
};

export default InfoTooltip;
