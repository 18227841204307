import { Link } from "react-router-dom";

import Post from "../components/blog/Post";
import PostImage from "../components/blog/PostImage";
import CAPQuickStart from "../components/blog/quick-start/CAPQuickStart";
import Advice from "../components/blog/Advice";

const POSTNAME = "cap";

const CAP: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default CAP;

const Rest = () => {
  return (
    <>
      <CAPQuickStart />
      <PostImage postName={POSTNAME} />
      <p>
        The{" "}
        <a href="https://certifiedanalytics.org/" target="_blank" rel="noreferrer">
          Certified Analytics Professional (CAP) exam
        </a>{" "}
        is an independent verification of the technical expertise needed for top performance at a data-driven
        organization. The certification is vendor-neutral, so it's a great foundation for any role involving data
        analytics. The CAP exam evaluates seven domains, which we'll cover in detail in{" "}
        <a href="#topics">this section</a>.
      </p>
      <p>
        If you're a data practitioner looking for a way to distinguish yourself in today's highly competitive workforce,
        read on. Certifications like CAP may help you <strong>broaden your skillset</strong> beyond the tasks you
        perform in your day-to-day work. Attaining this well recognized credential can help you solidify relevant
        skills, leading to <strong>more opportunities</strong>, <strong>higher pay</strong>, and{" "}
        <strong>increased job security</strong>. Earning the CAP is sure to help set you apart in the job hunt or in
        your current position.
      </p>
      <p>
        💡 If you would like to study alongside others, be sure to check out the{" "}
        <a href="https://www.facebook.com/groups/certifiedanalyticsprofessionals/" target="_blank" rel="noreferrer">
          CAP Study Group
        </a>{" "}
        on Facebook. If you're looking for extra practice, check out this set of{" "}
        <Link to="/products/cap-question-sets" target="_blank">
          practice questions
        </Link>
        . And finally, we offer a{" "}
        <Link to="/products/cap-study-plan" target="_blank">
          65-day Study Plan
        </Link>{" "}
        to guide your preparation with daily emails.
      </p>
      <br />
      <div style={{ maxWidth: 1000 }}>
        <div className="video-box" style={{ margin: "1em auto" }}>
          <iframe
            src="https://www.youtube.com/embed/MP3_ZBFosZ4"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            frameBorder="0"
            height="315"
          />
        </div>
      </div>
      <h2>Contents</h2>
      <ul>
        <li>
          <a href="#intro">Introduction</a>
          <li>
            <a href="#eligibility">Eligibility</a>
          </li>
          <li>
            <a href="#prep">Preparation and logistics</a>
          </li>
          <li>
            <a href="#alternatives">Alternatives</a>
          </li>
        </li>
        <li>
          <a href="#topics">Topics</a>
        </li>
        <li>
          <a href="#test-taking">Test Taking</a>
        </li>
        <li>
          <a href="#maintenance">Maintenance</a>
        </li>
        <li>
          <a href="#conclusion">Conclusion</a>
        </li>
        <li>
          <a href="#related-posts">Related Posts</a>
        </li>
      </ul>
      <h2 id="intro">Introduction</h2>
      <p>
        The Certified Analytics Professional examination is based on the{" "}
        <a href="https://www.certifiedanalytics.org/jta" target="_blank" rel="noreferrer">
          <strong>Job Task Analysis (JTA) framework</strong>
        </a>{" "}
        developed and validated by the{" "}
        <a href="https://www.ansi.org/" target="_blank" rel="noreferrer">
          American National Standards Institute (ANSI)
        </a>
        . CAP evaluates mastery of the assessment of <strong>complex problems</strong> and application of{" "}
        <strong>advanced analytics</strong>. It also tests <strong>business acumen</strong>.
      </p>
      <h4 id="eligibility">Eligibility</h4>
      <p>
        To get started with the certification process, you'll first{" "}
        <strong>submit your professional credentials</strong>. The CAP requires a set number of years of experience
        depending upon the level and relevance of your academic qualifications:
      </p>
      <ul style={{ marginBottom: "1em" }}>
        <li>3 years with MA/MS in related area</li>
        <li>5 years with BA/BS in unrelated area</li>
        <li>7 years with any degree in an unrelated area</li>
      </ul>
      <h4 id="prep">Preparation and logistics</h4>
      <p>
        After successfully applying, you must pledge to adhere to the{" "}
        <a href="https://www.certifiedanalytics.org/code-of-ethics" target="_blank" rel="noreferrer">
          <strong>Code of Ethics</strong>
        </a>
        .
      </p>
      <p>
        Training materials for the exam include the CAP Handbook, Job Task Analysis (JTA), and the CAP Study Guide.
        Supplementary material is available through the INFORMS CAP Prep Online Courses, which are designed to orient
        analytics professionals to the seven domains of analytics knowledge.
      </p>
      <p>
        The exam costs $495 if you are an{" "}
        <a
          href="https://www.informs.org/About-INFORMS/Member-Benefits/Join-INFORMS-and-or-INFORMS-Communities"
          target="_blank"
          rel="noreferrer"
        >
          INFORMS Member
        </a>{" "}
        and $695 for nonmembers.
      </p>
      <h4 id="alternatives">Alternatives to the CAP exam</h4>
      <p>
        If you are not yet eligible for the CAP exam, then you should consider the Associate Certified Analytics
        Professional (
        <a href="https://www.certifiedanalytics.org/acap" target="_blank" rel="noreferrer">
          aCAP
        </a>
        ). This test is the exact same exam as the CAP. If you pass, you can convert your credential to the CAP when you
        become eligible.
      </p>
      <p>
        Looking for more alternatives to the CAP exam? Check out our report on{" "}
        <Link to="/landing/best-certifications" target="_blank">
          top data-related certifications
        </Link>
        .
      </p>
      <h2 id="topics">Topics</h2>
      <ul>
        <li>
          <strong>Data</strong> (23%):&nbsp; systematically applying statistical and/or logical techniques to describe,
          illustrate, condense, summarize, and evaluate data.
        </li>
        <li>
          <strong>Analytic Problem Framing</strong> (17%):&nbsp; translating the business problem into terms that can be
          addressed analytically via data and modeling.
        </li>
        <li>
          <strong>Business Problem Framing</strong> (14%):&nbsp; aligning the entire team with one solution through
          collaborative structuring.
        </li>
        <li>
          <strong>Model Building</strong> (16%):&nbsp; developing a probabilistic model that best describes the
          relationship between the dependent and independent variables.
        </li>
        <li>
          <strong>Methodology Framing</strong> (14%):&nbsp; scrutinizing approaches used by other researchers with
          similar aims and objectives. Researchers from the same disciplines often follow a common methodological
          approach or set of approaches.
        </li>
        <li>
          <strong>Deployment</strong> (10%):&nbsp; putting machine learning models into production. Makes predictions
          available to users so that they can make data-driven business decisions.
        </li>
        <li>
          <strong>Model Lifecycle Management</strong> (6%):&nbsp; testing and updating models in production without
          interrupting service to downstream applications.
        </li>
      </ul>
      <br />
      <figure>
        <img
          src="https://miro.medium.com/max/720/1*brpW3ljBaN34guavoGqoIA.jpeg"
          alt="woman in front of chalk board"
          className="box-shadow"
        />
        <figcaption>
          Photo by{" "}
          <a href="https://www.pexels.com/@max-fischer" target="_blank" rel="noreferrer">
            Max Fischer
          </a>{" "}
          from{" "}
          <a
            href="https://www.pexels.com/photo/woman-in-brown-blazer-wearing-sunglasses-5212325/"
            target="_blank"
            rel="noreferrer"
          >
            Pexels
          </a>
        </figcaption>
      </figure>{" "}
      <h2 id="test-taking">Test Taking</h2>
      <p>
        Once your educational credentials are approved, you can use your CAP account to schedule the test through the{" "}
        <a href="https://www.kryteriononline.com/frequently-asked-questions" target="_blank" rel="noreferrer">
          <strong>Kryterion platform</strong>
        </a>
        .
      </p>
      <p>
        Candidates have <strong>three hours </strong>to complete the exam. This means that on average, you'll have just
        under two minutes to answer each question. Sounds like a lot of time, but each question is a mini-business case
        that requires significant care to answer.
      </p>
      <p>
        INFORMS encourages test takers to <strong>read each question two to three times</strong> and{" "}
        <strong>take short breaks</strong> throughout the exam. It's in your best interest to pace yourself such that
        you use the full three hours to complete the test.
      </p>
      <p>The passing score for each iteration of the CAP is computed separately but hovers around 70%.</p>
      <Advice id="cap-000" />
      <h2 id="maintenance">Maintenance</h2>
      <p>
        Once you receive recognition as a Certified Analytics Professional, it's time to waltz into the sunset, right?
      </p>
      <p>
        Well, not exactly — CAP recipients are required to maintain active certification status by earning{" "}
        <a href="https://www.certifiedanalytics.org/maintaining-your-certification" target="_blank" rel="noreferrer">
          <strong>Professional Development Units (PDUs)</strong>
        </a>
        . The idea here is to make sure that CAPs are at the cutting edge of industry and academia.
      </p>
      <p>PDUs can be formal or informal learning opportunities and must be logged on the CAP dashboard.</p>
      <figure>
        <img
          alt="woman studying"
          src="https://miro.medium.com/max/700/1*VV52ug41OfL0Z1VsiJgqiQ.jpeg"
          className="box-shadow"
        />
        <figcaption>
          Photo by{" "}
          <a href="https://www.pexels.com/@cottonbro" target="_blank" rel="noreferrer">
            cottonbro
          </a>{" "}
          from{" "}
          <a
            href="https://www.pexels.com/photo/woman-in-white-crew-neck-t-shirt-sitting-on-bed-4769482/"
            target="_blank"
            rel="noreferrer"
          >
            Pexels
          </a>
        </figcaption>
      </figure>
      <h2 id="conclusion">Conclusion</h2>
      <p>
        The CAP <strong>enhances traditional analytical skills</strong> such as data modeling, and it also emphasizes
        the ability to <strong>transform data into insight and action</strong> via{" "}
        <strong>improved business acumen</strong>. Because the CAP is so holistic and rigorous, it provides a trusted
        foundation for hiring and career advancement.
      </p>
      <p>
        To learn CAP content along side your peers, check out the{" "}
        <a href="https://www.facebook.com/groups/certifiedanalyticsprofessionals" target="_blank" rel="noreferrer">
          CAP Study Group
        </a>
        . If you're interested in evaluating your knowledge, check out our{" "}
        <Link to="/products/cap-question-sets" target="_blank">
          practice questions
        </Link>
        . And finally, we released a{" "}
        <Link to="/products/cap-study-plan" target="_blank">
          65-day Study Plan
        </Link>{" "}
        with daily emails to guide your preparation. Our team put together this great video introduction to the course:
      </p>
      <br />
      <div style={{ maxWidth: 1000 }}>
        <div className="video-box" style={{ margin: "1em auto" }}>
          <iframe
            src="https://www.youtube.com/embed/U0ae9ngNGJo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            frameBorder="0"
            height="315"
          />
        </div>
      </div>
      <br />
      <p>
        If you're interested in more ways to improve your analytical capabilities, consider signing up for our{" "}
        <Link to="/newsletter" target="_blank">
          Newsletter
        </Link>
        . Each month we do a deep dive into topics related to Data Management and provide tips to enhance your work as a
        data practitioner.
      </p>
    </>
  );
};
