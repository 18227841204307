import { useHistory } from "react-router-dom";

const RelatedPostsCard: React.VFC<{ post: Post }> = ({ post }) => {
  const history = useHistory();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "88%",
        cursor: "pointer",
        borderRadius: 4,
        backgroundColor: "white",
      }}
      className="box-shadow"
      onClick={() => history.push(post.link)}
    >
      <img
        key={post.nickname}
        src={post.image.img}
        alt={post.image.alt}
        style={{
          margin: 0,
          padding: "15px 15px 0 15px",
          height: 100,
          width: "100%",
          objectFit: "cover",
        }}
        onClick={() => history.push(post.link)}
      />
      <div style={{ padding: 15 }}>
        <p className="title card-title" style={{ marginBottom: "0" }}>
          {post.title}
        </p>

        <p
          style={{
            fontSize: "0.95em",
            marginTop: 6,
            marginBottom: 10,
          }}
          dangerouslySetInnerHTML={{ __html: post.short }}
        />
      </div>
    </div>
  );
};

export default RelatedPostsCard;
