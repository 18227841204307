import TestimonialCard from "../../../components/testimonial-flip-card/TestimonialCard";

import Knuttel from "../../../img/people/marc_knuttel.webp";

const testimonials = [
  {
    id: "general-001",
    name: "Marc Knüttel",
    title: "Senior Manager of Enterprise Data Architecture",
    product: "General",
    content:
      "Data Strategy Professionals offers preparation in a very practical, in a very straightforward way, cutting out all the noise to get people ready for a proper career in this area.",
    img: Knuttel,
  },
];

export default function MoreTestimonials() {
  return (
    <>
      <div
        className="teal-box homepage"
        style={{
          padding: "40px 40px 50px 40px",
          margin: "auto",
          background: "white",
          border: "5px solid white",
        }}
      >
        {testimonials.map((testimonial) => (
          <div key={testimonial.id}>
            <TestimonialCard testimonial={testimonial} fontSize={1.4} img={testimonial.img} />
          </div>
        ))}
      </div>
    </>
  );
}
