export default function GetInTouch() {
  function onClick() {
    return window.open(
      "https://airtable.com/appcyUYlyE8Bm9vPx/pagJDnjhe3vnzvsQx/form",
      "_blank",
      "noopener,noreferrer"
    );
  }
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: 40,
      }}
    >
      <button
        className="btn btn-accent"
        onClick={onClick}
        style={{
          minWidth: 250,
          fontSize: "1.2em",
          padding: "20px 32px",
          marginBottom: 20,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          gap: 8,
        }}
      >
        Get in Touch
      </button>
      <p>Our team will follow up with you to better understand your organization's unique needs</p>
    </div>
  );
}
