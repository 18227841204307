import { excelDateToDate } from "../../helpers/utils";

import media from "../../data/media";

const Media: React.VFC = () => {
  return (
    <div style={{ marginBottom: "1em" }}>
      {media.map((item) => (
        <p key={item.date}>
          {item.type}{" "}
          <a href={item.link} target="_blank" rel="noreferrer">
            {item.title}
          </a>{" "}
          from {item.source} – {excelDateToDate(item.date)}
        </p>
      ))}
    </div>
  );
};

export default Media;
