export default function SelectorButtons({ marginTop = 10, topics, topic, setTopic }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 16,
        justifyContent: "center",
        marginTop: marginTop,
        width: "100%",
        fontSize: "0.9em",
      }}
    >
      {topics.map((selectedTopic) => {
        return (
          <button
            style={{ height: 38 }}
            className={topic === selectedTopic ? "btn btn-fade active" : "btn btn-fade"}
            onClick={() => setTopic(selectedTopic)}
            key={selectedTopic}
          >
            {selectedTopic}
          </button>
        );
      })}
    </div>
  );
}
