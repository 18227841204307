import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import cdmp from "../../../img/logos/logo_cdmp.webp";
import li from "../../../img/grps/buildings.webp";
import dmbok from "../../../img/grps/dmbok_sq.webp";

export default function CDMPCommunity() {
  const desktop = useMediaQuery("(min-width:640px)");

  return (
    <div
      className="white-box"
      style={{
        padding: "0",
        margin: "0px auto",
        width: "85%",
        background: "none",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "2.2em",
          borderBottom: "2px solid #dbdbdb",
          textAlign: "center",
          position: "relative",
          zIndex: 0,
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "1.8em",
            marginTop: "11px",
            background: "#e9eff1",
            zIndex: 1,
            display: "inline-block",
            padding: "0 20px",
            fontFamily: "Raleway",
          }}
          id="community"
        >
          <strong>Our Community</strong>
        </p>
      </div>

      {!desktop && (
        <div>
          <br />
        </div>
      )}

      {desktop && (
        <>
          <div
            className="image-display-tri"
            style={{
              gap: "40px",
              justifyContent: "space-around",
              marginTop: "50px",
            }}
          >
            <figure>
              <a href="https://www.facebook.com/groups/cdmpstudygroup" target="_blank" rel="noreferrer">
                <div style={{ overflow: "hidden", position: "relative" }}>
                  <img src={cdmp} alt="hand holding lightbulb" className="zoom-on-hover" />
                </div>
              </a>
              <figcaption style={{ textAlign: "center", fontSize: "1.2em" }}>
                <a href="https://www.facebook.com/groups/cdmpstudygroup" target="_blank" rel="noreferrer">
                  CDMP Study Group (Facebook)
                </a>
              </figcaption>
            </figure>

            <figure>
              <a href="https://www.linkedin.com/groups/12965811/" target="_blank" rel="noreferrer">
                <div style={{ overflow: "hidden", position: "relative" }}>
                  <img
                    src={dmbok}
                    alt="Data Management Body of Knowledge"
                    className="zoom-on-hover"
                    style={{ height: "100%" }}
                  />
                </div>
              </a>
              <figcaption style={{ textAlign: "center", fontSize: "1.2em" }}>
                <a href="https://www.linkedin.com/groups/12965811/" target="_blank" rel="noreferrer">
                  CDMP Study Group (LinkedIn)
                </a>
              </figcaption>
            </figure>

            <figure>
              <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer">
                <div style={{ overflow: "hidden", position: "relative" }}>
                  <img src={li} alt="buildings" className="zoom-on-hover" />
                </div>
              </a>
              <figcaption style={{ textAlign: "center", fontSize: "1.2em" }}>
                <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer">
                  Data Strategy Professionals on LinkedIn
                </a>
              </figcaption>
            </figure>
          </div>
          <br />
          <br />
        </>
      )}

      <div className="center-text-box">
        <p>
          The best credential for Data Strategists is an{" "}
          <a rel="noreferrer noopener" href="http://amzn.to/32oK8hH" target="_blank">
            open book
          </a>{" "}
          exam. The <Link to="/resources/cdmp-fundamentals-exam">Certified Data Management Professional exam</Link> is
          wonderfully practical in its approach to testing Data Management fundamentals. In the{" "}
          <a rel="noreferrer noopener" href="https://www.facebook.com/groups/cdmpstudygroup/" target="_blank">
            CDMP Study Group
          </a>
          , we cover a different chapter of the{" "}
          <a rel="noreferrer noopener" href="http://amzn.to/32oK8hH" target="_blank">
            <em>Data Management Body of Knowledge </em>
          </a>
          each month. Don't miss our{" "}
          <Link rel="noreferrer noopener" to="/products/cdmp-fundamentals-bundle" target="_blank">
            CDMP Fundamentals Bundle
          </Link>{" "}
          that will help you save time and effortlessly ace the exam.
        </p>
        <br />
        <p>
          We also offer a{" "}
          <a href="https://www.linkedin.com/groups/12965811/" target="_blank" rel="noreferrer">
            community on LinkedIn
          </a>{" "}
          for folks interested in reading the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>{" "}
          and preparing for the CDMP exams.
        </p>
        <br />
        <p>
          <a rel="noreferrer noopener" href="https://www.linkedin.com/groups/13951141" target="_blank">
            Data Strategy Professionals
          </a>{" "}
          is the place for thoughtful conversations about how to tackle new challenges and opportunities presented by
          the ever-increasing wealth of data. In this LinkedIn group, we trade career advice, resources, and thoughts on{" "}
          <a href="https://www.youtube.com/watch?v=SUOsHOxuvtI&amp;ab_channel=NicoleJanewayBills">
            what to measure and why
          </a>
          .
        </p>
        <br />
      </div>
    </div>
  );
}
