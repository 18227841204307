import { useState } from "react";
import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
// import SubtleTestimonialCardFlip from "../../components/testimonial-flip-card/SubtleTestimonialCardFlip";
import ExtendedDescriptionContainer from "../../components/product-pages/ExtendedDescriptionContainer";
// import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";
import BuyNowButton from "../../components/product-pages/BuyNowButton";
import CDMPLevelsModal from "../../components/CDMPLevelsModal";
import CustomModal from "../../components/CustomModal";
import DSEVideoSummaryCarousel from "../../components/product-pages/DSEVideoSummaryCarousel";

const PRODUCT = "CDMP Crash Course";

const CDMPCrashCourse = () => {
  const [showCDMPLevelsModal, setShowCDMPLevelsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);
    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showCDMPLevelsModal && <CDMPLevelsModal setShowModal={setShowCDMPLevelsModal} />}
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}

      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        tealBox={tealBox}
        extendedDescription={extendedDescription(setShowCDMPLevelsModal)}
        rest={rest(handleShowClick)}
      />
    </>
  );
};

const tealBox = (
  // <div className="teal-box cdmp" id="product-testimonials">
  //   <h4>What People Are Saying</h4>
  //   <div style={{ marginTop: "1em" }}>
  //     <SubtleTestimonialCardFlip
  //       testimonial={testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-005")}
  //       productPage
  //       fontSize={1.05}
  //     />
  //   </div>
  // </div>

  <div className="teal-box cdmp" id="product-testimonials">
    <h4>Additional ways to learn Data Strategy</h4>
    <div className="blog">
      <p>
        Don't miss our{" "}
        <Link to="/products/data-strategy-workbook" target="_blank">
          Data Strategy Workbook
        </Link>{" "}
        for thought-provoking activities to improve your organization's Data Management capabilities.{" "}
      </p>
      <p>
        You'll also want to check out{" "}
        <Link to="/products/document-checklist" target="_blank">
          Data Strategy Document Checklist
        </Link>{" "}
        for comprehensive list of crucial supporting documents listed in the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        .
      </p>
      <p>
        We also offer the{" "}
        <Link to="/products/cdmp-fundamentals-bundle" target="_blank">
          CDMP Fundamentals Bundle
        </Link>{" "}
        to help you prepare for the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        .
      </p>
    </div>
  </div>
);

const extendedDescription = (setShowCDMPLevelsModal) => {
  const items = [
    {
      title: "Learn More ",
      description: (
        <div className="extended-description">
          <p>
            Improve your understanding of key concepts on the{" "}
            <Link to="/resources/cdmp-fundamentals-exam">CDMP Fundamentals Exam</Link> with the{" "}
            <strong>CDMP Crash Course</strong>. Through thoughtfully crafted <strong>video lessons</strong>, you'll
            learn how to navigate the <strong>seven most important</strong> Data Management knowledge areas on the test.
          </p>
          <p>
            Each of the <strong>97 lessons</strong> in this <strong>self-paced course</strong> includes video and text
            content to help you fully comprehend each concept. The chapters wrap up with{" "}
            <strong>additional resources</strong>, <strong>case studies</strong>, and{" "}
            <strong>practice questions</strong>.
          </p>

          <p style={{ marginBottom: 0 }}>Key features:</p>
          <ul>
            <li>
              83 video lessons across 7 chapters of the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>
              , each providing key information for the CDMP exam
            </li>
            <li>14 lessons about test-taking and study skills</li>
            <li>Links to additional resources</li>
            <li>21 practice questions to challenge your knowledge</li>
          </ul>
        </div>
      ),
    },
    {
      title: "Frequently Asked Questions",
      description: (
        <div className="extended-description faq">
          <p>
            <strong>Who is this course for?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            This course is for anyone who wants to speed up their preparation for the{" "}
            <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
              CDMP Fundamentals Exam
            </Link>{" "}
            or{" "}
            <Link to="/resources/cdmp-specialist-exam" target="_blank">
              CDMP Specialist Exams
            </Link>
            .
          </p>

          <p>
            <strong>What is included in the course?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Each of the 7 chapters about the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>{" "}
            includes video and text content to help you fully comprehend each topic. Each chapter wrap up with{" "}
            additional resources, case studies, and practice questions.
          </p>
          <p>
            <strong>How long will it take to complete the course?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>The course is self-paced and can be completed in about 5 hours.</p>
          <p>
            <strong>Is this course suitable preparation for the CDMP exam?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Yes, the course covers approximately 70% of the content on the{" "}
            <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
              CDMP Fundamentals Exam
            </Link>
            . In order to prepare fully for the CDMP Fundamentals Exam, we recommend pairing this course with the{" "}
            <Link to="/products/data-management-master-class" target="_blank">
              Data Management Master Class
            </Link>{" "}
            and the{" "}
            <Link to="/products/cdmp-fundamentals-bundle" target="_blank">
              CDMP Fundamentals Bundle
            </Link>
            . Purchase of the CDMP Crash Course entitles you to a $185 discount on{" "}
            <Link to="/products/data-management-master-class" target="_blank">
              Data Management Master Class
            </Link>
            .
          </p>
        </div>
      ),
    },
  ];
  return <ExtendedDescriptionContainer items={items} />;
};

const rest = (handleShowClick) => {
  const testimonialsToShow = [];

  testimonialsToShow
    .push
    // testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-002"),
    // testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-007"),
    // testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-006"),
    // testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-009"),
    // testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-010"),
    // testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-012")
    ();

  return (
    <>
      {/* <div
        className="white-outline-box"
        style={{
          maxWidth: 1400,
          marginLeft: "auto",
          marginRight: "auto",
          padding: "30px 40px 32px 40px",
        }}
      >
        <h4 className="box-title">CDMP Study Plan Guarantee</h4>
        <p>
          Our team at Data Strategy Professionals is so confident you'll pass the exam with this plan, that{" "}
          <strong>if you need to retake, we will refund your purchase of the Study Plan</strong>. You can get in touch
          with <a href="mailto:support@datastrategypros.com?subject=CDMP%20Study%20Plan%20Refund">Customer Support</a>{" "}
          for more information.
        </p>
      </div> */}

      <div className="white-box">
        <DSEVideoSummaryCarousel crashCourse={true} />
        <div className="center-text-box">
          <div className="three-col contents">
            <ul>
              <li>Introduction & Welcome</li>
              <li>Data Governance (ch. 3)</li>
              <li>Data Modeling (ch. 5)</li>
              <li>Data Integration & Interoperability (ch. 8)</li>
              <li>Master & Reference Data (ch. 10)</li>
              <li>Data Warehousing & Business Intelligence (ch. 11)</li>
              <li>Metadata Management (ch. 12)</li>
              <li>Data Quality (ch. 13)</li>
              <li>Wrap Up & Next Steps</li>
            </ul>
          </div>
        </div>
      </div>

      {/* <ProductTestimonials testimonialsToShow={testimonialsToShow} /> */}

      <BuyNowButton margins="60px" paymentName={"cdmp-crash-course" as paymentName} />
    </>
  );
};

export default CDMPCrashCourse;
