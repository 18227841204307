import { Bookmark, Verified } from "@mui/icons-material";

import cardStyles from "./cardStyles";

const BestValueIcon: React.VFC = () => {
  return (
    <div style={cardStyles.bookmark}>
      <Bookmark sx={{ fontSize: 100 }} />
      <p style={cardStyles.bookmarkText}>best value</p>
    </div>
  );
};

const styles = {
  smallText: { fontSize: "0.95em" },
  verySmallText: { fontSize: "0.85em" },
};

const NewIcon: React.VFC<{ size?: string }> = ({ size = "regular" }) => {
  return (
    <div style={cardStyles.verified}>
      <Verified sx={{ fontSize: size === "regular" ? 100 : 80 }} />
      <div style={cardStyles.verifiedBackground} />
      <p style={size === "regular" ? cardStyles.verifiedText : { ...cardStyles.verifiedText, ...styles.smallText }}>
        new
      </p>
    </div>
  );
};

const UpdatedIcon: React.VFC<{ size?: string }> = ({ size = "regular" }) => {
  return (
    <div style={cardStyles.verified}>
      <Verified sx={{ fontSize: size === "regular" ? 100 : 80 }} />
      <div style={cardStyles.verifiedBackground} />
      <p style={size === "regular" ? cardStyles.updatedText : { ...cardStyles.updatedText, ...styles.verySmallText }}>
        recently
        <br />
        updated
      </p>
    </div>
  );
};

export { BestValueIcon, NewIcon, UpdatedIcon };
