import { useState, useEffect } from "react";
import { format } from "date-fns";

import CustomHelmet from "../components/CustomHelmet";

const BIDSCert: React.VFC = () => {
  const [name, setName] = useState("");
  const [urlInput, setUrlInput] = useState("");
  const [url, setUrl] = useState("");

  const date = new Date();
  const formattedDate = format(date, "EEEE, MMMM d, yyyy");
  const year = format(date, "yyyy");
  const month = format(date, "M");
  const urlDate = formattedDate.replaceAll(" ", "%20").replaceAll(",", "%252C%2520");

  useEffect(() => {
    setUrl(
      `https://res.cloudinary.com/data-strategy-pros/image/upload/l_text:montserrat_28:${urlInput},co_white,y_20/l_text:montserrat_18:${urlDate},co_white,y_-120/v1635190701/BIDS_Certificate_of_Completion_q0bcga.pdf`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlInput, url]);

  function handleSubmit(e) {
    e.preventDefault();
    setUrlInput(name);
  }

  function handleViewClick() {
    window.open(url, "_blank");
  }

  function handleLinkedInClick() {
    const LinkedInUrl = `https://www.linkedin.com/profile/add?startTask=BIDS-cert&name=Breaking%20into%20Data%20Science%20Certificate%20of%20Completion&&organizationId=76608014&issueYear=${year}&issueMonth=${month}&certUrl=https://res.cloudinary.com/data-strategy-pros/image/upload/l_text:montserrat_28:${urlInput.replaceAll(
      " ",
      ""
    )},co_white,y_20/l_text:montserrat_18:${format(
      date,
      "MMddyy"
    )},co_white,y_-120/v1635190701/BIDS_Certificate_of_Completion_q0bcga.pdf`;
    window.open(LinkedInUrl, "_blank");
  }

  return (
    <>
      <CustomHelmet
        description="Breaking into Data Science Certificate of Completion."
        name="Certificate of Completion"
        link="/products/data-science-email-bootcamp/certificate"
        img="https://www.nicolejaneway.com/wp-content/uploads/2021/04/diego-ph-fIq0tET6llw-unsplash_square.jpg"
        alt="hands stacked over table"
      />
      <div className="home-layout">
        <p className="title">Breaking into Data Science Certificate of Completion</p>
        <div className="oath">
          <p>
            Congratulations on completing the Data Science Email Bootcamp! Having reached the end of twelve weeks, you
            should have a great foundation to enter the Data Science field! If you would like to leave feedback, you can
            do so{" "}
            <a href="https://forms.gle/uxdHRKorL9uTjKcd7" target="_blank" rel="noreferrer">
              here
            </a>
            .
          </p>
          <p>
            As a reminder, this course is backed by the <strong>Breaking into Data Science Guarantee</strong>: our team
            is so confident that Email Bootcamp will prepare you to get a job in this field,{" "}
            <strong>
              if you aren't working in a data-related role six months after completing the course, we will refund your
              purchase
            </strong>
            . You can get in touch with{" "}
            <a href="mailto:support@datastrategypros.com?subject=BIDS%20Email%20Bootcamp%20-%20Refund%20Request">
              Customer Support
            </a>{" "}
            for more information.
          </p>
          <hr />

          <p>
            On this page, you can create a <strong>certificate of completion</strong> to showcase on LinkedIn.
          </p>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">
              Please enter your <strong>full name</strong> as you would like it to appear on your certificate:{" "}
            </label>
            <br />
            <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
            <input type="submit" style={{ margin: "4px 10px 4px 10px" }} />
          </form>
          <br />
          {urlInput !== "" && (
            <>
              <br />
              <div
                style={{
                  textAlign: "center",
                  padding: "20px",
                  border: "5px solid #f9f9f9",
                  background: "none",
                  width: "45vw",
                  margin: "auto",
                }}
              >
                <p style={{ marginBottom: "8px" }}>{name.split(" ")[0] + ", your certificate has been generated"}</p>
                <div className="buttons" style={{ justifyContent: "center" }}>
                  <button className="btn btn-default" onClick={handleViewClick}>
                    View your certificate
                  </button>
                  <button className="btn btn-accent" onClick={handleLinkedInClick} style={{ marginRight: 0 }}>
                    Add to LinkedIn
                  </button>
                </div>
              </div>
              <br />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BIDSCert;
