import { useContext } from "react";

import { CheckoutContext } from "../../../contexts/CheckoutContext";

import payments from "../../../data/payments";

export function transformIdToText(input) {
  const translation = {
    "cdmp-fundamentals-bundle": "CDMP Fundamentals Exam",
    "cap-bundle": "CAP Exam",
    "cippe-bundle": "CIPP/E Exam",
  };
  return translation[input];
}

export default function CheckoutMessages() {
  const checkoutContext = useContext(CheckoutContext);
  const { confirmationTable } = checkoutContext.checkout;

  const ROW = confirmationTable[0];

  let description =
    payments.find((p) => p.name === ROW.paymentName)?.description ?? ROW.selectedOptions[0]?.description ?? "";

  if (ROW.paymentName === "specialist-bundle") {
    description = `Prepare for the CDMP Specialist Exams with your choice of <strong>${ROW.selectedOptions[0].displayText}</strong> and <strong>${ROW.selectedOptions[1].displayText}</strong> Specialist Guides and Question Sets.`;
  } else if (
    ROW.paymentName === "cdmp-fundamentals-bundle" ||
    ROW.paymentName === "cap-bundle" ||
    ROW.paymentName === "cippe-bundle"
  ) {
    description = `Prepare for the ${transformIdToText(
      ROW.paymentName
    )} with all our best selling study materials, including the <strong>${
      ROW.selectedOptions[0].displayText
    }</strong>.`;
  } else if (ROW.paymentName === "enterprise-study-program") {
    description = `Your <strong>team of ${ROW.metadata.count}</strong> will receive access to instruction on <strong>Data Strategy best practices</strong>.`;
  } else if (ROW.paymentName === "t-shirt") {
    description = `Thank you for your purchase of the ${ROW.selectedOptions[0].displayText.toLowerCase()}
    <strong>Data Strategist t-shirt</strong> in ${ROW.selectedOptions[1].displayText.toLowerCase()},
    size ${ROW.selectedOptions[2].displayText.toLowerCase()}.`;
  }

  return (
    <div style={{ marginTop: 20 }}>
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
}
