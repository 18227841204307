import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const FundamentalsAd: React.VFC = () => {
  const desktop = useMediaQuery("(min-width:640px)");
  return (
    <div className="teal-box" style={{ maxWidth: "1000px", marginTop: 57.6 }}>
      <h4
        style={{
          fontSize: "1.2em",
          marginBottom: "12px",
          textAlign: "center",
        }}
      >
        Save time and ace the CDMP
      </h4>
      {desktop && (
        <div
          style={{
            float: "left",
            margin: "0px 30px 1em 0",
          }}
        >
          <Link to="/products/cdmp-study-plan">
            <img
              src="https://www.nicolejaneway.com/wp-content/uploads/2021/04/diego-ph-fIq0tET6llw-unsplash_square.jpg"
              alt="hand holding lightbulb"
              style={{ maxWidth: "215px" }}
            />
          </Link>
        </div>
      )}
      <div className="blog" style={{ marginRight: 20 }}>
        <p>
          Have you taken the{" "}
          <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
            CDMP Fundamentals Exam
          </Link>{" "}
          yet?
        </p>
        <p>
          If not, join the thousands of Data Professionals who have opted to maximize their{" "}
          <strong>time, energy, and motivation</strong> while studying for the CDMP exam with the help of Data Strategy
          Professionals' <Link to="/products/cdmp-study-plan">CDMP Study Plan</Link>.
        </p>
        <p>
          The plan is delivered as 5-6 emails each week that cover each of the 14 chapters{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>
          . The 90-day plan outlines a study and review schedule scientifically proven to maximize long term retention
          of the material.
        </p>
        <p>
          On a different timeline to achieve Data Strategy mastery? No problem! Purchase{" "}
          <strong>Immediate Access</strong> to the <Link to="/products/cdmp-study-plan">CDMP Study Plan</Link> to
          receive all 75 emails at one time.
        </p>
        <p>
          Because becoming a Data Strategist is about more than a test score, the{" "}
          <Link to="/products/cdmp-study-plan">CDMP Study Plan</Link> also provides real world examples, case studies,
          and job prep resources that will help you advance your career. You may also be interested the{" "}
          <Link to="/products/cdmp-fundamentals-notes">CDMP Fundamentals Notes</Link> as a boost to your studies.
        </p>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Link to="/products/cdmp-study-plan">
          <button
            className="btn btn-accent"
            style={{
              margin: "1em auto",
              width: 250,
            }}
          >
            Buy Now
          </button>
        </Link>
      </div>
    </div>
  );
};

export default FundamentalsAd;
