import { useState } from "react";
import { useMediaQuery } from "@mui/material";

import navStyles from "../navStyles";

export default function ResourcesBurger({ topics, setTopic }) {
  const [hamburgerIsActive, setHamburgerIsActive] = useState(false);

  const desktop = useMediaQuery("(min-width: 1024px)");

  return (
    <>
      <button
        className={hamburgerIsActive ? "is-active navbar-burger" : "navbar-burger"}
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasic"
        onClick={() => setHamburgerIsActive(!hamburgerIsActive)}
        style={{ zIndex: 3 }}
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </button>
      <div style={{ position: "absolute", right: desktop ? 80 : 16 }}>
        {hamburgerIsActive && (
          <div style={{ ...navStyles.navbarMenu, right: 8 }}>
            {topics.map((selectedTopic) => {
              return (
                <p
                  className="resources-menu-item"
                  onClick={() => {
                    setTopic(selectedTopic);
                    setHamburgerIsActive(false);
                  }}
                  key={selectedTopic}
                  style={{ ...navStyles.navbarItem }}
                >
                  {selectedTopic}
                </p>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
