import { useMediaQuery } from "@mui/material";

import tshirt from "../../img/products/tshirt/tshirt_crop.webp";
import pdf from "../../img/products/bundles/cdmp-fundamentals-bundle.webp";
import email from "../../img/products/enterprise/enterprise-email.webp";

const CDMPBundleBox: React.VFC = () => {
  const showBox = useMediaQuery("(min-width: 928px)");

  const IMAGE_HEIGHT = 120;
  const body = `Hi there,
  
I'm writing to request reimbursement for this Data Strategy training: https://www.datastrategypros.com/products/cdmp-fundamentals-bundle

Here's a document that the Data Strategy Professionals team put together to explain the program:  https://mcusercontent.com/c164c67d8c9591e3b056f39b1/files/ff37e2f0-2a4e-b822-c12a-7f7b6c158ade/CDMP_Fundamentals_Bundle.pdf

If you have more questions, you can email their team at info@datastrategypros.com

I believe this training will be a valuable investment in my professional development and will align with our organization's goals and needs.

Thank you for your consideration,
[yournamehere]`;

  const link =
    "mailto:colleagues@example.com" +
    "?cc=info@datastrategypros.com" +
    "&subject=" +
    encodeURIComponent("Data Strategy training") +
    "&body=" +
    encodeURIComponent(body);

  return (
    <>
      {showBox && (
        <div
          className="white-outline-box"
          style={{
            maxWidth: 1400,
            marginLeft: "auto",
            marginRight: "auto",
            padding: "30px 40px 32px 40px",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            margin: "40px auto 60px auto",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            columnGap: 120,
            textWrap: "balance",
          }}
        >
          <a href="mailto:info@datastrategypros.com?subject=CDMP%20Fundamentals%20Bundle">
            <img
              src={tshirt}
              alt="woman wearing a t-shirt"
              style={{
                height: IMAGE_HEIGHT,
              }}
              className="box-shadow"
            />
          </a>
          <a
            href="https://mcusercontent.com/c164c67d8c9591e3b056f39b1/files/ff37e2f0-2a4e-b822-c12a-7f7b6c158ade/CDMP_Fundamentals_Bundle.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={pdf} style={{ height: IMAGE_HEIGHT }} alt="pdf cover" className="box-shadow" />
          </a>
          <a href={link}>
            <img src={email} style={{ height: IMAGE_HEIGHT }} alt="email" className="box-shadow" />
          </a>
          <div style={{ minWidth: 180 }}>
            <p>Questions? Email our team at</p>
            <p>
              <a href="mailto:info@datastrategypros.com?subject=CDMP%20Fundamentals%20Bundle">
                info@datastrategypros.com
              </a>
            </p>
          </div>
          <div style={{ minWidth: 180 }}>
            <p>
              Download a{" "}
              <a
                href="https://mcusercontent.com/c164c67d8c9591e3b056f39b1/files/ff37e2f0-2a4e-b822-c12a-7f7b6c158ade/CDMP_Fundamentals_Bundle.pdf"
                target="_blank"
                rel="noreferrer"
              >
                PDF
              </a>{" "}
              explaining the contents of the program
            </p>
          </div>
          <div style={{ minWidth: 180 }}>
            <p>
              Draft an <a href={link}>email</a> requesting reimbursement
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default CDMPBundleBox;
