import LandingPage from "../../../components/lead-magnet/LandingPage";
import KSADocScreenshots from "./KSADocScreenshots";

const KSADocument: React.FC = () => {
  const description = `<strong>Sign up</strong> to receive a pdf explaining the Data Management Standards of Saudi Arabia's
            National Data Management Office. The document contains <strong>definitions</strong>, <strong>goals</strong>,
            and <strong>actions</strong> associated with each of the 15 standards.`;

  return (
    <LandingPage
      postName="data-standards"
      closeLink="/resources/ksa-data-standards"
      description={description}
      leadMagnetName="15 Data Management Standards"
      listNumber={37}
      Rest={<KSADocScreenshots />}
    />
  );
};

export default KSADocument;
