export default function PartnerCard({ partner }) {
  return (
    <div
      style={{ width: "100%", background: "white", borderRadius: 4, height: "content", marginBottom: 70 }}
      className="box-shadow"
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a href={partner.link} target="_blank" rel="noreferrer">
          <img
            src={partner.img}
            alt="customer"
            style={{
              objectFit: "cover",
              width: 100,
              height: 100,
              borderRadius: "50%",
              marginTop: -60,
              border: "4px solid white",
            }}
          />
        </a>
      </div>

      <div style={{ padding: 20, paddingTop: 20, lineHeight: 1.3 }}>
        <p
          style={{ fontSize: "1.3em", fontWeight: 600, marginBottom: "0.2em" }}
          dangerouslySetInnerHTML={{
            __html: partner.name,
          }}
        />
        <p style={{ marginBottom: "0.5em" }}>
          <strong>Best For</strong>:&nbsp;&nbsp;
          <span
            dangerouslySetInnerHTML={{
              __html: partner.bestFor,
            }}
          />
        </p>

        <p style={{ marginBottom: "0.5em" }}>
          <strong>Description</strong>:&nbsp;&nbsp;
          <span
            dangerouslySetInnerHTML={{
              __html: partner.description,
            }}
          />
        </p>
        <p>
          <strong>Services</strong>:&nbsp;&nbsp;
        </p>
        <ul style={{ marginBottom: "0.5em" }}>
          {partner.services.map((service, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: service }}></li>
          ))}
        </ul>
        <p>
          <a href={partner.link} target="_blank" rel="noreferrer">
            Learn more
          </a>
        </p>
      </div>
    </div>
  );
}
